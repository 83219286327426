<script setup lang="ts">
import DiagnosticSection from '@/components/video-diagnostic/DiagnosticSection.vue'
import { Stage } from '@/types/enums/video-diagnostic.enum'
import UAParser from 'ua-parser-js'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

defineProps({
  stage: {
    type: Number,
    required: true,
  },
})
const i18n = useI18n()
const emit = defineEmits<{ (e: 'nextStage'): void }>()

const isWarned = ref<boolean>(false)
const warnText = ref<string>('')

const userAgentParser = new UAParser()
const userAgentInfo = userAgentParser.getResult()
const browser = userAgentInfo.browser
const operatingSystem = userAgentInfo.os

onMounted(async () => {
  /**
   * ref: https://docs.microsoft.com/en-us/microsoft-edge/web-platform/how-to-detect-win11
   * check os window 11
   */
  const userAgentData = await navigator.userAgentData?.getHighEntropyValues(['platformVersion'])
  if (navigator.userAgentData?.platform === 'Windows') {
    const majorPlatformVersion = parseInt(userAgentData?.platformVersion?.split('.')[0] || '')

    if (majorPlatformVersion >= 13) {
      operatingSystem.version = '11'
    }
  }

  /**
   * show warning when Browsers that's not supported User-Agent Client Hints
   */
  if (!userAgentData && operatingSystem.name?.includes('Windows')) {
    warnText.value = i18n.t('video-diagnostic.window-warn')
    isWarned.value = true
  }

  /**
   * show warning when browser might be Firefox on iPad
   * *** also warning when using Safari on Max OS ***
   */
  if (browser.name?.includes('Safari') && operatingSystem.name?.includes('Mac OS')) {
    warnText.value = i18n.t('video-diagnostic.ipad-warn')
    isWarned.value = true
  }
})
</script>

<template>
  <DiagnosticSection
    :id="Stage.BROWSER_TEST"
    :class="
      stage === Stage.BROWSER_TEST || stage === Stage.RESULTS
        ? 'block'
        : 'hidden md:block opacity-30 pointer-events-none'
    "
  >
    <template #default>
      <div class="row-span-1 text-sm sm:text-base lg:text-sm xl:text-base">
        <h1 class="font-bold text-xl sm:text-4xl lg:text-xl xl:text-4xl">
          {{ $t('video-diagnostic.browser-supported') }}
        </h1>
        <div
          class="h-fit bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 my-4"
          role="alert"
        >
          <p class="mb-3">{{ $t('video-diagnostic.chrome-suggestion') }}</p>
          <p>{{ $t('video-diagnostic.safari-suggestion') }}</p>
        </div>
      </div>

      <div
        class="row-span-2 my-10 p-6 max-w-sm h-fit bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 text-sm sm:text-base lg:text-sm xl:text-base"
      >
        <p class="mb-3">
          <span class="font-bold">{{ $t('browser-card.browser') }} </span>
          {{ browser.name }} {{ browser.version }}
        </p>
        <p class="mb-3">
          <span class="font-bold">{{ $t('browser-card.operating-system') }} </span>
          {{ operatingSystem.name }} {{ operatingSystem.version }}
        </p>

        <div
          v-if="isWarned"
          class="h-fit bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 my-4"
          role="alert"
        >
          <p class="font-bold">{{ warnText }}</p>
        </div>
      </div>

      <div class="row-span-1 text-sm sm:text-base lg:text-sm xl:text-base">
        <p class="pb-10">{{ $t('video-diagnostic.browser-supported-detail') }}</p>
        <button
          :class="{ 'opacity-30 pointer-events-none': stage === Stage.RESULTS }"
          class="text-white border-2 rounded-md border-white py-2 px-4"
          type="button"
          @click="emit('nextStage')"
        >
          {{ $t('video-diagnostic.ok') }}
        </button>
      </div>
    </template>
  </DiagnosticSection>
</template>

<style lang="scss" scoped>
button {
  background-color: #f67519;
}
.h-fit {
  height: fit-content;
}
</style>
