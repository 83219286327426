import { SpecialDayEnum } from '../types/enums/sticker.enum'
import { ISpecialSticker } from '../types/interfaces/sticker.interface'

export const encouragementSticker = [
  '5YpBkoc96cXO1BVe0L',
  'pOPC91U517OKoX32jO',
  '450IMJ6IQEdPe5pHFe',
  'kigoThJuS2TzDjnpTs',
  'B20uFib1zrY4R89RU0',
  'ZeL13JHhvfvcKcbtb3',
  'l1J9LUpGQ4TCtzO24',
  '3ohhwhs3tJXlE1jDhe',
  '1k3uvfLu2cdtRX2pIw',
  'f3pcWUodU7mzpGgwMq',
  'L4MshCh0R5buqa8Tz1',
  'KFzOImm4zGzx2TWUEh',
  'hWoh0SN1uPpq7O0BUM',
  'TFNbcscr9JUUigDzrZ',
]

export const rewardSticker = [
  '7JsAxTNJTkMJfak7KD',
  'deo0IiysCArqhmHy6K',
  '8FM8uSSg5ck0QJbExc',
  'L2rAl9kIUK4Kjg198R',
  'WRcfBtF7oF1TYEwBGn',
  'l0Exj6t3iK0Xzv00E',
  '8cAZavcCKfrnDUJOKO',
  '1isLh9pFieLSaJmnzk',
  'KFPUY9KQ7wW4kHCTfW',
  'dAcQAuZJLfgqL2u1aS',
  'WnNCTaX3x7khhAkZXn',
  'S6f3L1Mibud9u85wCy',
  'Wp5CIL0eQj8D6',
  'WT4P3PIYEWKayxRN9a',
]

export const specialSticker: ISpecialSticker[] = [
  {
    specialDay: SpecialDayEnum.HAPPY_BIRTHDAY,
    ids: ['M9HuciuqyFP85Xkt9c', 'Lr3gAxRRlKUdsD2tlI', '1wX5TJZPqVw3HhyDYn'],
  },
  {
    specialDay: SpecialDayEnum.LUNAR_NEW_YEAR,
    ids: ['ukDgURDsBbIFzjHDV1', 'cPeuA9nl262DZMZXUY', '5t0xQsgOlJIdqSrYtJ'],
    startMonth: 2,
    endMonth: 2,
  },
  {
    specialDay: SpecialDayEnum.SONGKRAN,
    ids: [
      'jmOR8DxURFmhpsC2yw',
      'zGCLYVVKZcKVckRq0E',
      'AAghW0BOayiqGi8w99',
      'lo4rNLQMqAQ0FtpMvu',
      'RB0jCaKPBiESYMJ3sS',
    ],
    startMonth: 4,
    endMonth: 4,
  },
  {
    specialDay: SpecialDayEnum.HALLOWEEN,
    ids: [
      'h6liiBnHX1d1ymoctK',
      'xOmqPQogR54BxDaP5G',
      'FAqsaySG84tSL20khe',
      'JREUbpicoxLMyPOyCg',
      'UVHE39kJcK4yOzzCJ6',
      'FTSMroJ4LV6Q2MiDEg',
    ],
    startMonth: 10,
    endMonth: 10,
  },
  {
    specialDay: SpecialDayEnum.LOY_KRATHONG,
    ids: ['6h6DMHQ3NDUwg7ME8l', 'J9YdupP6TFZm3UmHgr'],
    startMonth: 11,
    endMonth: 11,
  },
  {
    specialDay: SpecialDayEnum.CHRISTMAS_AND_HNY,
    ids: [
      '3ksOEZckWJ3TY0hJFy',
      'FyZqtwPPBMvau1fO5g',
      'xlfBYddaIKihSL6DGR',
      'XZATMclWlDGsCrVpNL',
      '9V5tmbpfUQcPV6IARH',
      '1APdPRkGjzy21K8f9i',
      'QrG1ekNztZywBfoZ7H',
      '089t1Sw9yjDh1rQ3Mg',
      'w9msZpRZlqRsMfaVYv',
      'yc5R8jGowlxjMMJds9',
      'hjNovUmJWTkjn9UW91',
      'PxVU9pCqDjgcjJrkRe',
    ],
    startMonth: 12,
    endMonth: 1,
  },
]
