import { Engine } from '@/types/enums/room.enum'
import { ConnectingLogs } from '@/types/interfaces/connecting-logs.interface'
import { ISpaceParticipants } from '@/types/interfaces/participant.interface'
import {
  IConnectRoom,
  IConnectRoomParams,
  IGetZoomToken,
  ILesson,
  IRoomInfo,
  ISticker,
} from '@/types/interfaces/room.interface'
import {
  ICreateCheckupDevices,
  ICreateCheckupDevicesZoom,
  IVideoDiagnosticInterface,
} from '@/types/interfaces/video-diagnostic.interface'
import { API_STUDENT_URL, WH_AUTH_PASS, WH_AUTH_USER } from '.'
import {
  ISaveLogChangeBackground,
  ISaveLogHelpRequest,
  ISaveLogHelpResolve,
} from '../../types/interfaces/log.interface'
import useApi from './useApi'
import { ISubmitProblemCancel } from '@/types/interfaces/help.interface'

export default function useSpaceApi() {
  const api = useApi()

  const getRoomInfo = (engine?: Engine) => {
    return api.get<IRoomInfo>('/Spaces/Rooms', { params: { engine } })
  }

  const connectRoom = (params: IConnectRoomParams) => {
    const { roomId, participantId, engine, token } = params
    return api.post<IConnectRoom>(
      `/Spaces/Rooms/${roomId}/Participants/${participantId}/Connect`,
      undefined,
      {
        params: { engine, token },
      },
    )
  }

  const getJoinTokenWithZoom = () => {
    return api.get<IGetZoomToken>('/Spaces/Zoom/Token')
  }

  const getParticipantsByRoomId = (roomId: string) => {
    return api.get<ISpaceParticipants[]>(`/Spaces/Rooms/${roomId}/Participants`)
  }

  const toggleEngine = (comment: string[]) => {
    return api.patch('/Spaces/Rooms/ToggleEngine', { userAgent: navigator.userAgent, comment })
  }

  const switchToWhereBy = (comment: string[]) => {
    return api.patch('/Spaces/Rooms/ToggleEngine/Whereby', {
      userAgent: navigator.userAgent,
      comment,
    })
  }

  const saveLesson = (payload: ILesson) => {
    return api.post('/Spaces/Lesson', payload)
  }

  const saveStickers = (payload: ISticker) => {
    return api.post('/Spaces/Stickers', payload)
  }

  const getParticipantsAlreadyInRoom = (roomId: string) => {
    return api.get<string[]>(`/Spaces/Rooms/${roomId}/Participants/AlreadyInRoom`)
  }

  const getTokenVideoDiagnostic = () => {
    return api.get<IVideoDiagnosticInterface>('/Spaces/Token/VideoDiagnostic')
  }

  const createCheckupDevices = (payload: ICreateCheckupDevices) => {
    return api.post(`${API_STUDENT_URL}/Shares/Student/Orientation/Devices/Checkup`, payload, {
      auth: {
        username: WH_AUTH_USER,
        password: WH_AUTH_PASS,
      },
    })
  }

  const submitProblemCancel = (payload: ISubmitProblemCancel) => {
    return api.post('/Spaces/ClassReportIssue/HelpAction', payload)
  }

  const saveConnectingLogs = (payload: ConnectingLogs) => {
    return api.post('/Spaces/Logs/Connecting', {
      ...payload,
      userAgent: navigator.userAgent,
    })
  }

  // Spaces Logs
  const saveLogsAttend = (engine: Engine) => {
    return api.post('/Spaces/Logs/Attend', {
      userAgent: navigator.userAgent,
      engine,
    })
  }

  const saveLogsReport = (comment: string[]) => {
    return api.post('/Spaces/Logs/Report', { userAgent: navigator.userAgent, comment })
  }

  const saveLogsLeaveButton = (roomId: string, participantId: string) => {
    return api.post('/Spaces/Logs/LeaveButton', {
      userAgent: navigator.userAgent,
      roomId,
      participantId,
    })
  }

  const saveLogsChangeBackground = (payload: ISaveLogChangeBackground) => {
    return api.post('/Spaces/Logs/ChangeBackground', {
      ...payload,
      userAgent: navigator.userAgent,
    })
  }

  const saveLogsChangeResolution = (resolution: string) => {
    return api.post('/Spaces/Logs/ChangeResolution', {
      resolution,
      userAgent: navigator.userAgent,
    })
  }

  const saveLogsHelpRequest = (payload: ISaveLogHelpRequest) => {
    return api.post('/Spaces/Logs/HelpRequest', {
      ...payload,
      userAgent: navigator.userAgent,
    })
  }

  const saveLogsHelpResolve = (payload: ISaveLogHelpResolve) => {
    return api.post('/Spaces/Logs/HelpResolve', {
      ...payload,
      userAgent: navigator.userAgent,
    })
  }

  return {
    getRoomInfo,
    connectRoom,
    getJoinTokenWithZoom,
    getParticipantsByRoomId,
    toggleEngine,
    switchToWhereBy,
    saveLesson,
    saveStickers,
    getParticipantsAlreadyInRoom,
    getTokenVideoDiagnostic,
    createCheckupDevices,
    saveConnectingLogs,
    submitProblemCancel,
    //Logs
    saveLogsAttend,
    saveLogsReport,
    saveLogsLeaveButton,
    saveLogsChangeBackground,
    saveLogsChangeResolution,
    saveLogsHelpRequest,
    saveLogsHelpResolve,
  }
}
