<script setup lang="ts">
defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  content: {
    type: String,
    default: '',
  },
  isRepublish: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['update:modelValue', 'republish'])

const close = () => {
  emit('update:modelValue', false)
}

const republishDevice = () => {
  emit('republish')
  close()
}
</script>

<template>
  <Popup title="Something went wrong" :open="modelValue" @close="close">
    <div class="text-base text-content">
      <span>{{ content }}</span>
    </div>
    <template #footer>
      <button
        v-if="isRepublish"
        type="button"
        class="btn btn-primary text-base w-full"
        @click="republishDevice"
      >
        Republish
      </button>
      <button v-else type="button" class="btn btn-primary text-base w-full" @click="close">
        {{ $t('close-button') }}
      </button>
    </template>
  </Popup>
</template>

<style lang="scss" scoped>
.text-content {
  color: #4a4a4a;
}
</style>
