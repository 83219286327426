<script setup lang="ts">
import { computed, ref, PropType } from 'vue'
import useTheme from '@/composables/useTheme'
// import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import { MoonIcon, SunIcon } from '@heroicons/vue/24/outline'
import LeavePopup from '@/components/room/LeavePopup.vue'
import useAuth from '@/composables/api/useAuth'
import LessonPopup from '@/components/room/LessonPopup.vue'
import { IParticipantCapabilities } from '@/types/interfaces/participant.interface'
import { logoPathDark, logoPathLight, logoIcon } from '@/data/room'
import FeedbackClassButton from '@/components/room/FeedbackClassButton.vue'
import { VERSION } from '@/composables/api'
const { getAccessInfo } = useAuth()

const props = defineProps({
  userPermissions: {
    type: Object as PropType<IParticipantCapabilities>,
    required: true,
  },
  appVersion: {
    type: String,
    default: VERSION,
  },
  avatar: {
    type: String,
    default: logoIcon,
  },
  logoPath: {
    type: String,
    default: logoPathDark,
  },
  isMainEngine: {
    type: Boolean,
    default: true,
  },
  supportTel: {
    type: String,
    default: '',
  },
  supportEmail: {
    type: String,
    default: '',
  },
  currentEngine: {
    type: String,
    default: 'twilio',
  },
  exitPath: {
    type: String,
    default: '',
  },
  isRecording: {
    type: Boolean,
    default: false,
  },
  haveTrackLesson: {
    type: Boolean,
    default: false,
  },
  helpEnable: {
    type: Boolean,
    default: false,
  },
  isWhereby: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['toggle-help', 'open-device-setting'])
/**
 * State
 */
const { isDark, changeTheme } = useTheme()
const classId = ref(parseInt(sessionStorage.getItem('external_room_id') || ''))
const isOpenedLeavePopup = ref(false)
const isOpenedLessonPopup = ref(false)
const recordTooltip = ref(false)
const isFromLessonBtn = ref(false)
const isSavedLesson = ref(false)
const lessonTooltip = ref(false)
const currentRoomTooltip = ref(false)
/**
 * Computed
 */
const showTrackLesson = computed(
  () =>
    !!classId.value &&
    !isNaN(classId.value) &&
    props.userPermissions?.trackLesson &&
    props.haveTrackLesson,
)
const showFeedbackClass = computed(
  () => !!classId.value && !isNaN(classId.value) && props.userPermissions?.feedbackClass,
)
const logo = computed(() => (isDark.value ? props.logoPath : logoPathLight))

/**
 * Methods
 */

const goToMainPage = () => {
  const { queryToken } = getAccessInfo()
  window.location.href = `/?token=${queryToken}`
  location.reload()
}

const clickLeaveButton = (fromlessonBtn?: boolean) => {
  isFromLessonBtn.value = fromlessonBtn || false
  if (showTrackLesson.value) {
    isOpenedLessonPopup.value = true
  } else {
    isOpenedLeavePopup.value = true
  }
}
const clickLeaveFromLessonPopup = () => {
  isOpenedLessonPopup.value = false
  isOpenedLeavePopup.value = true
}

const submitLessonSuccess = () => {
  isSavedLesson.value = true
  if (isFromLessonBtn.value) return
  isOpenedLeavePopup.value = true
}

const openHelp = () => {
  emit('toggle-help')
}

const openDeviceSettingPopup = () => {
  emit('open-device-setting')
}
</script>
<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="nav">
    <div class="left">
      <div class="logo-wrapper">
        <img
          class="_prevent-text-select logo"
          :src="logo"
          draggable="false"
          @click="goToMainPage"
        />
        <img
          class="_prevent-text-select logo-icon"
          :src="logoIcon"
          draggable="false"
          @click="goToMainPage"
        />
      </div>

      <div
        class="them-wrapper p-1 _pointer-on-hover rounded-full hover:text-gray-700 hover:bg-gray-200 dark:hover:bg-gray-700 dark:hover:text-white"
      >
        <!-- Change Theme -->
        <MoonIcon v-if="isDark" aria-hidden="true" @click="changeTheme('light')" />
        <SunIcon v-else aria-hidden="true" @click="changeTheme('dark')" />
      </div>
      <!-- Change Language -->
      <LanguageDropdown class="language-dropdown-navbar" by-theme />
      <Popper
        v-if="!isWhereby"
        placement="right"
        :show="currentRoomTooltip"
        :hover="true"
        :arrow="true"
        :content="
          isMainEngine
            ? $t('current-room-tooltip.main-room')
            : $t('current-room-tooltip.secondary-room')
        "
        @mouseover="currentRoomTooltip = true"
        @mouseleave="currentRoomTooltip = false"
      >
        <div class="_pointer-on-hover _prevent-text-select mr-3">
          <SvgIcon
            v-show="!isMainEngine"
            class="_pointer-on-hover"
            icon="siren"
            :inherit-fill="true"
            height="21"
          ></SvgIcon>
          <SvgIcon
            v-show="isMainEngine"
            class="_pointer-on-hover"
            icon="home"
            :inherit-fill="true"
            height="21"
          ></SvgIcon>
        </div>
      </Popper>
      <Popper
        v-if="isRecording"
        :show="recordTooltip"
        :hover="true"
        :arrow="true"
        :content="$t('recording-tooltip')"
        @mouseover="recordTooltip = true"
        @mouseleave="recordTooltip = false"
      >
        <div class="rec-badge _pointer-on-hover _prevent-text-select">
          <SvgIcon
            class="_pointer-on-hover"
            icon="record"
            :inherit-fill="true"
            height="21"
          ></SvgIcon>
          <span class="ml-1">Rec</span>
        </div>
      </Popper>
    </div>
    <div class="right">
      <slot />
      <Popper
        v-if="showTrackLesson"
        :show="isSavedLesson ? lessonTooltip : false"
        :hover="true"
        :arrow="true"
        :content="$t('lesson-tooltip')"
        @mouseover="lessonTooltip = true"
        @mouseleave="lessonTooltip = false"
      >
        <button class="btn-action-nav nav-menu-item" @click="clickLeaveButton(true)">
          <SvgIcon
            class="_pointer-on-hover mr-1"
            icon="pen"
            :inherit-fill="true"
            height="26"
          ></SvgIcon>
          <span>{{ $t('lesson') }}</span>
        </button>
      </Popper>
      <button class="btn-action-nav nav-menu-item sos-btn" @click="openHelp">
        <SvgIcon
          class="_pointer-on-hover mr-1"
          icon="warning"
          :inherit-fill="true"
          height="26"
        ></SvgIcon>
        <span>{{ $t('help') }}</span>
      </button>

      <!-- <Menu as="div" class="relative inline-block text-right">
        <MenuButton class="btn-action-nav nav-menu-item ml-auto">
          <SvgIcon
            class="_pointer-on-hover mr-1"
            icon="help"
            :inherit-fill="true"
            height="26"
          ></SvgIcon>
          <span>{{ $t('help') }}</span>
        </MenuButton>

        <transition
          enter-active-class="transition duration-100 ease-out"
          enter-from-class="transform scale-95 opacity-0"
          enter-to-class="transform scale-100 opacity-100"
          leave-active-class="transition duration-75 ease-in"
          leave-from-class="transform scale-100 opacity-100"
          leave-to-class="transform scale-95 opacity-0"
        >
          <MenuItems class="pop-index menu-wrapper">
            <div class="px-1 py-1">
              <MenuItem v-slot="{ active }">
                <button
                  class="menu-nav-list-item"
                  :class="[active && 'bg-gray-100']"
                  @click="reload"
                >
                  <SvgIcon
                    class="_pointer-on-hover ml-1 mr-3"
                    icon="reload"
                    :inherit-fill="true"
                    height="21"
                  ></SvgIcon>
                  <span>{{ $t('reload-room') }}</span>
                </button>
              </MenuItem>
              <template v-if="userPermissions?.engineControl">
                <MenuItem v-if="isMainEngine" v-slot="{ active }">
                  <button
                    class="menu-nav-list-item"
                    :class="[active && 'bg-gray-100']"
                    @click="openChangeVideoEngine()"
                  >
                    <SvgIcon
                      class="_pointer-on-hover ml-1 mr-3"
                      icon="siren"
                      :inherit-fill="true"
                      height="21"
                    ></SvgIcon>
                    <span>{{ $t('move-secondary-room') }}</span>
                  </button>
                </MenuItem>
                <template v-else>
                  <MenuItem v-slot="{ active }">
                    <button
                      class="menu-nav-list-item"
                      :class="[active && 'bg-gray-100']"
                      @click="openChangeVideoEngine()"
                    >
                      <SvgIcon
                        class="_pointer-on-hover ml-1 mr-3"
                        icon="change-room"
                        :inherit-fill="true"
                        height="21"
                      ></SvgIcon>
                      <span>{{ $t('move-main-room') }}</span>
                    </button>
                  </MenuItem>
                  <MenuItem v-if="userPermissions.engineWhereby" v-slot="{ active }">
                    <button
                      class="menu-nav-list-item"
                      :class="[active && 'bg-gray-100']"
                      @click="openChangeVideoEngine(Engine.WHEREBY)"
                    >
                      <SvgIcon
                        class="_pointer-on-hover ml-1 mr-3"
                        icon="siren"
                        :inherit-fill="true"
                        height="21"
                      ></SvgIcon>
                      <span>{{ $t('move-whereby-room') }}</span>
                    </button>
                  </MenuItem>
                </template>
              </template>
              <MenuItem v-slot="{ active }">
                <button
                  class="menu-nav-list-item"
                  :class="[active && 'bg-gray-100']"
                  @click="isOpenReportProblem = true"
                >
                  <SvgIcon
                    class="_pointer-on-hover ml-1 mr-3"
                    icon="system-report"
                    :inherit-fill="true"
                    height="21"
                  ></SvgIcon>
                  <span>{{ $t('report-problem') }}</span>
                </button>
              </MenuItem>
              <MenuItem v-if="supportTel" v-slot="{ active }">
                <a :href="`tel:${supportTel}`" target="_blank" rel="noopener noreferrer">
                  <button class="menu-nav-list-item" :class="[active && 'bg-gray-100']">
                    <SvgIcon
                      class="_pointer-on-hover ml-1 mr-3"
                      icon="headset"
                      :inherit-fill="true"
                      height="21"
                    ></SvgIcon>
                    <div class="text-group">
                      <span class="heading">{{ $t('contact-support') }}</span>
                      <span class="sub-heading">{{ addDashes(supportTel) }}</span>
                    </div>
                  </button>
                </a>
              </MenuItem>
              <MenuItem v-if="supportEmail" v-slot="{ active }">
                <a :href="`mailto:${supportEmail}`" target="_blank" rel="noopener noreferrer">
                  <button class="menu-nav-list-item" :class="[active && 'bg-gray-100']">
                    <SvgIcon
                      class="_pointer-on-hover ml-1 mr-3"
                      icon="mail"
                      :inherit-fill="true"
                      height="21"
                    ></SvgIcon>
                    <div class="text-group">
                      <span class="heading">{{ $t('email-support') }}</span>
                      <span class="sub-heading">{{ supportEmail }}</span>
                    </div>
                  </button>
                </a>
              </MenuItem>
            </div>

            <div class="px-1 py-1">
              <MenuItem v-if="currentEngine" disabled>
                <div
                  class="menu-nav-list-item flex rounded-md items-center w-full px-2 py-2 text-sm h-12 justify-between"
                >
                  <span class="font-medium"> App Version </span>
                  <small class="text-gray-500"
                    >v{{ appVersion }} <span class="capitalize">({{ currentEngine }})</span></small
                  >
                </div>
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu> -->
      <Teleport to="body">
        <LessonPopup
          v-model="isOpenedLessonPopup"
          :class-id="classId"
          :is-from-lesson-btn="isFromLessonBtn"
          @success="submitLessonSuccess"
          @leave="clickLeaveFromLessonPopup"
        />
      </Teleport>
      <Teleport to="body">
        <LeavePopup v-model="isOpenedLeavePopup" :exit-path="exitPath" />
      </Teleport>
      <FeedbackClassButton
        v-if="showFeedbackClass"
        :exit-path="exitPath"
        class="ml-5"
      ></FeedbackClassButton>

      <button class="btn-action-nav leave-button nav-menu-item" @click="clickLeaveButton(false)">
        <SvgIcon
          class="_pointer-on-hover mr-1"
          icon="leave"
          :inherit-fill="true"
          height="26"
        ></SvgIcon>
        <span>{{ $t('leave-button') }}</span>
      </button>
      <!-- <Avatar :avatar="avatar" :size="32" class="flex flex-col items-center" /> -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.nav {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  @include font-weight(regular);
  flex-wrap: nowrap;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  .left {
    display: flex;
    align-items: center;
    margin-right: 15px;

    > *:not(:last-child) {
      margin-right: 10px;
    }
    .logo-wrapper {
      min-width: 35px;
      img {
        &:hover {
          cursor: pointer;
        }
      }
      .logo {
        height: 32px;
      }
      .logo-icon {
        width: 35px;
      }

      @media screen and (max-width: 430px) {
        .logo {
          display: none;
        }
        .logo-icon {
          display: block;
        }
      }
      @media screen and (min-width: 431px) {
        .logo {
          display: block;
        }
        .logo-icon {
          display: none;
        }
      }
    }
  }

  .right {
    display: flex;
    align-items: center;

    > *:not(:first-child) {
      margin-left: 18px;
    }
  }

  .secondary-sign {
    color: $gs-4;

    &:hover {
      color: white;
    }
  }

  .avatar {
    border-radius: 20px;
    overflow: hidden;
    border: 3px solid rgba(#ffffff, 0);
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;

    // &:hover {
    //   border: 3px solid rgba(#ffffff, 0.1);
    // }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .leave {
    color: $danger;
  }

  .pop-index {
    z-index: $z-index-nav-bar;
  }

  .menu-wrapper {
    width: calc(100% - 30px);
    @include media-breakpoint-up-custom(500px) {
      min-width: 320px;
      width: auto;
    }
  }
  .menu-nav-list-item {
    color: $gs-1;
  }
  .text-group {
    display: flex;
    flex-direction: column;
    text-align: start;
    color: $gs-1;
    .sub-heading {
      @include font-weight(light);
      font-size: 0.8rem;
      text-align: start;
      margin-top: 2px;
      color: $gs-2;
    }
  }
}
.leave-button {
  color: $danger;
}

.leave-button:hover {
  color: $danger;
}
.sos-btn {
  color: #f2c94c;
  &:hover {
    color: #f2c94c;
  }
}
.rec-badge {
  display: flex;
  align-items: center;
  padding: 4px;
  font-size: 14px;
  background: $danger;
  border-radius: 50%;
  color: white;
  > span {
    display: none;
  }

  @include media-breakpoint-up-custom(500px) {
    padding: 5px 10px 5px 8px;
    border-radius: 20px;
    > span {
      display: unset;
    }
  }
}

:deep(.popper) {
  font-size: 0.8rem;
}
.them-wrapper {
  width: 32px;
  height: 32px;
  :deep(svg) {
    width: 24px;
    height: 24px;
  }
}

.report-btn {
  ::v-deep(svg.svg-icon.svg-class) {
    width: 23px !important;
    height: 23px !important;
    margin-top: 2px;
  }
}
</style>

<style lang="scss">
@include media-breakpoint-down-custom(720px) {
  .nav-menu-item span {
    display: none;
  }
}
.language-dropdown-navbar {
  .relative.flex {
    position: unset;
  }
  &.select-option .list-box-option {
    max-width: 100px;
    margin-top: 1.75rem !important;
    z-index: calc($z-index-chat + 1) !important;

    @include media-breakpoint-down-custom(700px) {
      max-width: 72px;
      margin-top: 1rem !important;
    }
  }
}
</style>
