<script setup lang="ts">
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['confirm', 'close', 'update:modelValue'])

const close = () => {
  if (props.modelValue) {
    emit('update:modelValue', false)
    emit('close')
  }
}

const confirm = () => {
  emit('confirm')
  close()
}
</script>

<template>
  <Popup :open="modelValue" @close="close">
    <div class="flex flex-col content">
      <SvgIcon class="warning-icon mx-auto mb-3" icon="warning" :inherit-fill="true" height="36" />
      <p class="m-0 text-base">{{ $t('feedback-help-request.no-more-solve-problem') }}</p>
    </div>
    <template #footer>
      <div class="flex flex-col">
        <button
          type="button"
          class="btn btn-primary text-white text-base w-full mb-2"
          @click="close"
        >
          {{ $t('feedback-help-request.no-stay-in-class') }}
        </button>
        <button type="button" class="btn btn-tertiary text-white text-base w-full" @click="confirm">
          {{ $t('feedback-help-request.yes-cancel-class') }}
        </button>
      </div>
    </template>
  </Popup>
</template>

<style lang="scss" scoped>
.content {
  color: #4a4a4a;
  .warning-icon {
    color: $warning;
  }
}
</style>
