<script setup lang="ts">
import { ref } from 'vue'
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['confirm', 'close', 'update:modelValue'])

const detail = ref('')

const close = () => {
  if (props.modelValue) {
    emit('update:modelValue', !props.modelValue)
    emit('close')
  }
}

const confirm = () => {
  emit('confirm', detail.value)
  close()
}
</script>

<template>
  <Popup
    :title="$t('feedback-help-request.confirm-cancel-class')"
    :open="modelValue"
    @close="close"
  >
    <div class="content">
      <p class="content__detail text-center">
        <span>{{ $t('feedback-help-request.if-you-press-confirm') }}</span
        ><br />
        <strong class="mr-1">{{ $t('feedback-help-request.this-class-is-canceled') }}</strong
        ><br />
        <span>{{ $t('feedback-help-request.and-can-not-join-room') }}</span>
      </p>
      <div class="mt-3">
        <strong>{{ $t('video-diagnostic.report-comment-header') }}</strong>
        <textarea
          v-model="detail"
          class="rounded border border-zinc-300 w-full p-3"
          rows="6"
          :placeholder="$t('video-diagnostic.report-comment-placeholder')"
        ></textarea>
      </div>
    </div>
    <template #footer>
      <div class="flex">
        <button type="button" class="btn btn-danger text-white text-base w-full" @click="confirm">
          {{ $t('feedback-help-request.confirm-cancel') }}
        </button>
      </div>
    </template>
  </Popup>
</template>

<style lang="scss" scoped>
.content {
  color: #4a4a4a;
  font-size: 16px;

  &__detail {
    background: #f4f4f4;
    border-radius: 6px;
    padding: 2rem;
    color: rgba(244, 54, 60, 1);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  }
}
</style>
