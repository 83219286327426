<script setup lang="ts">
import ExcellentQuality from '@/components/video-diagnostic/ExcellentQuality.vue'
import PoorQuality from '@/components/video-diagnostic/PoorQuality.vue'
import { getQualityScore } from '@/composables/video-diagnostic/useGetQualityScore'
import { Stage, Topic, QualityScore } from '@/types/enums/video-diagnostic.enum'
import { IUpdateTopic } from '@/types/interfaces/video-diagnostic.interface'
import { ref, watch } from 'vue'
import QualityCard from './QualityCard.vue'

interface QualityTestEmits {
  (e: 'nextStage', value: number): void
  (e: 'updateTopic', value: IUpdateTopic): void
}

const props = defineProps({
  stage: {
    type: Number,
    required: true,
  },
})

const emit = defineEmits<QualityTestEmits>()

const { totalQualityScore } = getQualityScore()

watch(
  () => props.stage,
  async (stage, prevStage) => {
    if (prevStage === Stage.QUALITY && stage === Stage.CONNECTIVITY) {
      emit('updateTopic', { topic: Topic.QUALITY, active: false })
    }
    if (stage === Stage.QUALITY) {
      emit('updateTopic', { topic: Topic.QUALITY, active: true })
    }
  },
)

const toggle = ref(false)
</script>

<template>
  <ExcellentQuality
    v-if="totalQualityScore === QualityScore.Good || totalQualityScore === QualityScore.Excellent"
    :stage="stage"
    :total-quality-score="totalQualityScore"
    @next-stage="emit('nextStage', Stage.RESULTS)"
    @updateToggle="toggle = true"
  ></ExcellentQuality>
  <PoorQuality
    v-else
    :stage="stage"
    :total-quality-score="totalQualityScore"
    @next-stage="emit('nextStage', Stage.RESULTS)"
    @updateToggle="toggle = true"
  ></PoorQuality>

  <Popup title="Detailed quality information" width="550" :open="toggle" @close="toggle = false">
    <QualityCard />
  </Popup>
</template>

<style lang="scss" scoped>
thead > tr {
  background-color: #32323209;
}

::v-deep(svg) {
  display: inline-block !important;
  margin-bottom: 0.4rem;
}
</style>
