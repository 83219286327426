import { Engine } from '@/types/enums/room.enum'

/* eslint-disable quotes */
export const logoPathDark =
  'https://space-public-storage.s3-ap-southeast-1.amazonaws.com/logo/globish-logo-light.png'

export const logoPathLight =
  'https://space-public-storage.s3.ap-southeast-1.amazonaws.com/logo/globish-logo-color.png'

export const logoIcon = 'https://d1bnvx5vhcnf8w.cloudfront.net/logo/globish-logo.png'

export const COACH_ATTEND_MINUTE = {
  LATE: 5,
  ABSENT: 10,
}

export const AVATAR_DEFAULT = 'https://d1bnvx5vhcnf8w.cloudfront.net/logo/globish-logo.png'

export const ALLOW_TIME_AUTO = {
  START: '00:00:00',
  END: '06:00:00',
}

export const ZOOM_MY_VIDEO_ELEMENT_ID = 'my-self-view-video'
export const ZOOM_MY_CANVAS_ELEMENT_ID = 'my-self-view-canvas'

export const WELCOME_AUTO_CLOSE_TIME = 3000
export const WELCOME_STUDENT_MESSAGES = [
  {
    th: '🎉 ยินดีต้อนรับ! การเดินทางในการเรียนรู้ของคุณกำลังเริ่มต้น มาสนุกกับการเรียนกันเถอะ! 🌈📖',
    en: "Welcome! 🌟 Your learning journey begins now, let's enjoy it! 🚀📚",
    vn: 'Chào mừng bạn đến với hành trình học tiếng Anh cùng Globish. Hãy học một cách vui vẻ nhé!',
  },
  {
    th: 'สวัสดีค่ะ! 🌼  มาเดินทางไปสู่เป้าหมายของคุณในวันนี้กัน! 🚶‍♀️🎯',
    en: "👋 Hi there! Let's make strides toward your goals today. 🌠💪",
    vn: 'Hãy cùng tiến về mục tiêu của bạn hôm nay nào.',
  },
  {
    th: 'สวัสดีค่ะ! 🌼  มาเดินทางไปสู่เป้าหมายของคุณในวันนี้กัน! 🚶‍♀️🎯',
    en: "Hello! 🌞 Let's dive into learning together, one step at a time. 🌊📘",
    vn: 'Xin chào. Chúng ta cùng bắt đầu lớp học nhé.',
  },
  {
    th: '🌟 ยินดีต้อนรับสู่การผจญภัยแห่งการเรียนรู้ของคุณนะคะ! เตรียมพร้อมที่จะสำรวจ ค้นพบ และเติบโต มาทำให้การเดินทางแห่งความรู้นี้น่าจดจำกันเถอะ! 🌌🔭🌿',
    en: "Welcome to your learning adventure! 🌍 Get ready to explore, discover, and grow. Let's make this journey of knowledge unforgettable! 🚀🔍🌱",
    vn: 'Chào mừng bạn đến với hành trình học tập của riêng bạn! Hãy sẵn sàng để khám phá, và làm cho hành trình này đáng nhớ nhé!',
  },
  {
    th: '🌟 ยินดีต้อนรับนักเรียนทุกท่านสู่จุดเริ่มต้นของการผจญภัยทางวิชาการของคุณ! มาดำดิ่งสู่การเรียนรู้ด้วยกัน โดยที่ทุกความท้าทายจะเป็นก้าวย่างสู่ความสำเร็จ! 🎒✨',
    en: "📚 Welcome, students, to the beginning of your academic adventure! Let's dive into learning together, where every challenge is a stepping stone to success! 🎓💡",
    vn: 'Chào mừng bạn đến với hành trình học tập cùng Globish! Hãy cùng nhau học tập nhé. Mỗi thử thách đều là bước đệm dẫn đến thành công!',
  },
  {
    th: 'ยินดีต้อนรับนักเรียน! 🌈  การเดินทาง ทางการศึกษาของคุณได้เริ่มต้นแล้ว มาเริ่มต้นเส้นทางนี้ด้วยกัน เต็มเปี่ยมไปด้วยการค้นพบ การเติบโต และความท้าทายที่น่าตื่นเต้น! 🚀💫🌟',
    en: "📚 Welcome, students! Your educational journey starts now. Let's embark on this path together, filled with discovery, growth, and exciting challenges! 🌱🌟💡",
    vn: 'Hành trình học tập của bạn cùng Globish đã bắt đầu. Hãy cùng nhau dấn thân vào hành trình này, khám phá và học tập cùng với những thử thách thú vị nhé!',
  },
]
