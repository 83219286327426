<script setup lang="ts">
import { ISelectOption } from '@/types/interfaces/common.interface'
import { useToggle, onClickOutside } from '@vueuse/core'
import { PropType, ref } from 'vue'
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/24/solid'

const props = defineProps({
  value: {
    type: String,
    default: '',
  },
  isEnable: {
    type: Boolean,
    default: false,
  },
  options: {
    type: Array as PropType<ISelectOption[]>,
    default: () => [],
  },
})

const emit = defineEmits(['change-device'])

const [isOpen, toggle] = useToggle()

const containerRef = ref<HTMLElement>()
onClickOutside(containerRef, () => {
  toggle(false)
})

const select = (selectedValue: string) => {
  if (props.value === selectedValue) return
  emit('change-device', selectedValue)
}
</script>

<template>
  <div ref="containerRef" class="device-btn-container">
    <div class="action-btn relative">
      <slot />
      <div v-show="options.length" class="arrow-box" @click="toggle(!isOpen)">
        <ChevronDownIcon v-if="isOpen" class="w-3 h-3" />
        <ChevronUpIcon v-else class="w-3 h-3" />
      </div>
    </div>
    <div class="options" :class="isOpen ? 'active' : ''">
      <div
        v-for="(option, index) in options"
        :key="`opt-${index}`"
        class="option"
        @click="select(option.value)"
      >
        <div class="option-label" :class="{ selected: value === option.value }">
          <CheckIcon v-if="value === option.value" aria-hidden="true" class="w-4 h-4 mt-1" />
          <p>{{ option.label }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.options {
  visibility: hidden;
  position: absolute;
  bottom: 75px;
  left: 20px;
  background: var(--bg-chat);
  color: var(--text-chat);
  border-radius: 6px;
  width: 320px;
  padding: 0.25rem 0.75rem;

  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  z-index: 5;

  &.active {
    visibility: visible;
    // transition
    transition: transform 0.5s ease;
    transform: translateY(-15px);
  }
  .option {
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.5rem 0;
    opacity: 0.8;
    cursor: pointer;
    .option-label {
      padding: 5px;
      border-radius: 4px;
      display: flex;
      gap: 5px;
      color: var(--text-chat);
      &.selected {
        font-weight: 600;
      }
    }
    &.active,
    &:hover {
      opacity: 1;
    }
    &:hover {
      .option-label {
        background-color: rgba(189, 189, 189, 0.3);
      }
    }
    &:not(:last-child) {
      border-bottom: 1px solid #4f4f4f;
    }
  }
}

.arrow-box {
  position: absolute;
  top: 0;
  right: -14px;
  height: 100%;
  padding: 4px 6px;
  background: #e6e6e6;
  border-radius: 0 6px 6px 0;
  color: #4a4a4a;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    background: #dedede;
  }
}
</style>

<style lang="scss">
body.dark {
  .device-btn-container .arrow-box {
    background: #272727;
    color: #838383;
    &:hover {
      background: #323232;
      color: #c4c4c4;
    }
  }
}
</style>
