<script setup lang="ts">
import DiagnosticSection from '@/components/video-diagnostic/DiagnosticSection.vue'
import { Stage } from '@/types/enums/video-diagnostic.enum'
import {
  permissions,
  getUserMediaContent,
} from '@/composables/video-diagnostic/useCheckPermissions'
import { useRouter } from 'vue-router'
import { computed } from 'vue'
import UAParser from 'ua-parser-js'

const router = useRouter()

defineProps({
  stage: {
    type: Number,
    required: true,
  },
})

const permissionDeniedImg = computed(() => {
  const userAgentParser = new UAParser()
  const userAgentInfo = userAgentParser.getResult()
  const browser = userAgentInfo.browser

  if (browser.name?.includes('Chrome')) {
    return require('../../assets/video-diagnostic/permission-denied-chrome.png')
  }
  if (browser.name?.includes('Firefox')) {
    return require('../../assets/video-diagnostic/permission-denied-firefox.png')
  }
  if (browser.name?.includes('Edge')) {
    return require('../../assets/video-diagnostic/permission-denied-edge.png')
  }
  if (browser.name?.includes('Safari')) {
    return require('../../assets/video-diagnostic/permission-denied-safari.png')
  }
  return require('../../assets/video-diagnostic/permission-denied-default.png')
})

const refreshPage = () => {
  router.go(0)
}
</script>

<template>
  <DiagnosticSection
    v-if="!permissions"
    :id="Stage.PERMISSION_ERROR"
    :class="
      [Stage.GET_STARTED, Stage.CHECK_PERMISSIONS].includes(stage)
        ? 'opacity-0'
        : stage === Stage.PERMISSION_ERROR || stage === Stage.RESULTS
        ? 'block'
        : 'hidden md:block opacity-30 pointer-events-none'
    "
  >
    <template #default>
      <div class="row-span-2 text-sm sm:text-base lg:text-sm xl:text-base pt-10">
        <h1 class="font-bold text-xl sm:text-4xl lg:text-xl xl:text-4xl">
          <SvgIcon class="error-icon" :icon="'error-sign'" :inherit-fill="true" height="24" />
          {{ $t(`${getUserMediaContent.headline}`) }}
        </h1>
        <p class="py-4 sm:py-10 lg:py-4 xl:py-10">
          {{ $t(`${getUserMediaContent.message}`) }}
        </p>
        <p class="py-4 sm:py-10 lg:py-4 xl:py-10 font-bold">
          {{ $t('video-diagnostic.permission-error.update-setting') }}
        </p>
        <button
          :class="{ 'opacity-30 pointer-events-none': stage === Stage.RESULTS }"
          class="text-white border-2 rounded-md border-white py-2 px-4 mb-10"
          type="button"
          @click="refreshPage"
        >
          {{ $t('video-diagnostic.refresh-page') }}
        </button>
      </div>

      <div
        class="row-span-2 mt-10 p-6 max-w-sm h-fit bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700"
      >
        <p class="font-bold mb-3">{{ $t('video-diagnostic.permission-error.permissions') }}</p>
        <img :src="permissionDeniedImg" alt="permission denied img for globish video diagnostic" />
      </div>
    </template>
  </DiagnosticSection>
</template>

<style lang="scss" scoped>
button {
  background-color: #f67519;
}

.h-fit {
  height: fit-content;
}

::v-deep(.error-icon svg) {
  display: inline-block !important;
  margin-bottom: 1rem;
}
</style>
