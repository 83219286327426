<script setup lang="ts">
import { useDeviceStore } from '@/stores'
import { Engine } from '@/types/enums/room.enum'
import { computed, defineAsyncComponent, PropType } from 'vue'
import BackgroundGallery from '@/components/checkup/BackgroundGallery.vue'
import { Stream } from '@zoom/videosdk'

const VideoTwilio = defineAsyncComponent(() => import('@/components/checkup/VideoTwilio.vue'))
const VideoZoom = defineAsyncComponent(() => import('@/components/checkup/VideoZoom.vue'))

const props = defineProps({
  engine: {
    type: String as PropType<Engine>,
    default: Engine.TWILIO,
  },
  // for Zoom
  mediaStream: {
    type: Object as PropType<typeof Stream>,
    default: undefined,
  },
})

const device = useDeviceStore()

const canChangeBackground = computed(() => device.canChangeBackground)

/**
 * Computed
 */
const videoComponentByEngine = computed(() => {
  if (props.engine === Engine.TWILIO) return VideoTwilio
  else if (props.engine === Engine.ZOOM) return VideoZoom
  else return ''
})
</script>

<template>
  <div class="background-setting-container">
    <template v-if="!canChangeBackground">
      <div class="effect-not-suppot">
        <p>This browser does not support video effect</p>
      </div>
    </template>
    <template v-else>
      <div class="video-box">
        <LoadingProcess :loading="device.isProcessing" />
        <component
          :is="videoComponentByEngine"
          v-if="videoComponentByEngine && device.maskCameraOn"
          :media-stream="mediaStream"
        />
        <div v-show="!device.maskCameraOn" class="video-off">
          <p>Please enable camera before use video effect</p>
        </div>
      </div>
      <BackgroundGallery />
    </template>
  </div>
</template>

<style lang="scss" scoped>
.background-setting-container {
  display: flex;
  flex-direction: column;
  position: relative;
  .effect-not-suppot {
    color: #4a4a4a;
    p {
      font-size: 15px;
    }
  }
  .video-off {
    color: #4a4a4a;
    p {
      font-size: 15px;
    }
  }
  .video-box {
    position: relative;
    height: 320px;
    padding: 1rem 0;
    .video-off {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      display: flex;
      p {
        margin: auto;
      }
    }
  }
}
</style>
