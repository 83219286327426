<script setup lang="ts">
import useWebViewer from '@/composables/document/useDocWebViewer'
import { computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
const route = useRoute()

const docId = computed(() => route.params.id as string)
const userEmail = computed(() => route.query.email as string)

const { isLoadingAllAnnots, isLoading, viewerRef, initWebViewer } = useWebViewer()

onMounted(async () => {
  await initWebViewer({
    documentId: docId.value,
    email: userEmail.value,
    isRenderAllAnnotation: true,
  })
})
</script>

<template>
  <div class="doc-page">
    <div v-if="isLoading || isLoadingAllAnnots" class="loadding-wrapper">
      <DoubleBounceLoader :size="40"></DoubleBounceLoader>
      <span>Loading</span>
    </div>
    <div id="viewer" ref="viewerRef"></div>
  </div>
</template>

<style lang="scss" scoped>
.doc-page {
  width: 100%;
  height: 100%;
  position: relative;
  .loadding-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--room-body-color);
    color: rgba(#4a4a4a, 0.8);
    font-size: 1rem;
    @include font-weight(normal);
    text-align: center;
  }
  #viewer {
    width: 100%;
    height: 100%;
  }
}

.dark .loadding-wrapper {
  color: rgba(#fff, 0.8);
}
</style>
