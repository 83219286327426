<script setup lang="ts">
import { ITab } from '@/types/interfaces/common.interface'
import { PropType, useSlots } from 'vue'

interface TabsEmits {
  (e: 'update:modelValue', value: string): void
}
defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  tabs: {
    type: Array as PropType<ITab[]>,
    default: () => [],
  },
})
const emit = defineEmits<TabsEmits>()
const slots = useSlots()

/**
 * Methods
 */
const changeTab = (tabValue: string) => {
  emit('update:modelValue', tabValue)
}
</script>

<template>
  <div class="tab-component">
    <div v-if="tabs.length > 1" class="tab-headers">
      <div
        v-for="tab in tabs"
        :key="tab.value"
        :class="{ active: tab.value === modelValue }"
        class="tab-header"
        @click="changeTab(tab.value)"
      >
        <h6>{{ tab.i18n ? $t(`${tab.i18n}`) : tab.label }}</h6>
      </div>
    </div>
    <div
      v-for="tab in tabs"
      :key="tab.value"
      class="tab-content"
      :class="[modelValue && modelValue === tab.value && 'active']"
    >
      <div v-show="slots[tab.value] && tab.value === modelValue" class="dynamic-copmponent-wrapper">
        <slot :name="tab.value" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.tab-component {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 6px 6px 6px 6px;
  overflow: hidden;

  .tab-headers {
    height: 40px;
    display: flex;
    align-items: center;
    background: rgba(#ffffff, 0.03);
    overflow-x: auto;
    font-size: 1rem;
    h6 {
      text-transform: capitalize;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .tab-header {
      padding: 0 30px;
      height: 100%;
      display: flex;
      align-items: center;
      color: rgba(#ffffff, 0.6);
      cursor: pointer;
      background: rgba(#ffffff, 0);
      @include font-weight(regular);
      transition-property: color background;
      transition-duration: 0.35s;
      transition-timing-function: $primary-tc;

      &.active {
        background: rgba(#ffffff, 0.1);
        color: rgba(#ffffff, 1);
        border-radius: 6px 6px 0 0;

        // &::after {
        //   content: "";
        //   display: block;
        //   width: 10px;
        //   height: 10px;
        //   position: relative;
        //   right: -37px;
        //   bottom: -16px;
        //   width: 0;
        //   height: 0;
        //   border-bottom: 7px solid rgba(255, 255, 255, 0.1);
        //   border-right: 7px solid transparent;
        // }
      }

      &:hover:not(.active) {
        background: rgba(#ffffff, 0.05);
        color: rgba(#ffffff, 0.85);
        border-radius: 6px 6px 0 0;
      }
    }
  }

  .tab-content {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    height: 0;
    &:not(.active) {
      display: none;
    }
    .dynamic-copmponent-wrapper {
      width: 100%;
    }
  }
}
</style>
