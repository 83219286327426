<script setup lang="ts">
import { useTrack } from '@/composables/twilio/useTrack'
import { IParticipantTrack } from '@/types/interfaces/participant.interface'
import { PropType } from 'vue'
import { useFullscreen } from '@vueuse/core'

const props = defineProps({
  track: {
    type: Object as PropType<IParticipantTrack>,
    required: true,
  },
})
const { trackRef } = useTrack(props.track)
const { toggle } = useFullscreen(trackRef)
</script>

<template>
  <div class="screen">
    <div class="screen-action">
      <div class="action-btn-group">
        <div class="action-btn">
          <SvgIcon
            class="action-icon _pointer-on-hover"
            icon="fullscreen"
            :inherit-fill="true"
            height="26"
            @click="toggle"
          ></SvgIcon>
        </div>
      </div>
    </div>
    <div ref="trackRef" class="video-track"></div>
  </div>
</template>

<style lang="scss" scoped>
::v-deep(.video-track video) {
  height: auto;
  margin: auto;
}
.icon-box {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  padding: 5px;
  color: rgba(#fff, 0.75);
}
.screen {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (hover: hover) {
    &:hover:not(.-republish) {
      .action-btn-group {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  > .screen-action {
    position: absolute;
    width: 100%;
    top: 0;
    padding: 7px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .action-btn-group {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      top: 7px;
      right: 7px;
      z-index: 3;
      display: flex;
      justify-content: space-between;

      .action-btn {
        flex: 1 1 auto;
        @extend .icon-box;
        &:not(:last-child) {
          margin-right: 5px;
        }

        @media (hover: hover) {
          &:hover {
            color: rgba(#fff, 0.95);
          }
        }
      }
    }
  }
}
</style>
