import { useBackgroundStore } from '@/stores'
import {
  isSupported,
  GaussianBlurBackgroundProcessor,
  ImageFit,
  VirtualBackgroundProcessor,
} from '@twilio/video-processors'
import { LocalVideoTrack } from 'twilio-video'
import { onBeforeMount } from 'vue'

const virtualBackgroundAssets = '/virtualbackground'

export default function useTwilioBackground() {
  let blurProcessor: GaussianBlurBackgroundProcessor
  let virtualBackgroundProcessor: VirtualBackgroundProcessor
  const backgroundStore = useBackgroundStore()
  const removeProcessor = (track: LocalVideoTrack) => {
    if (track && track.processor) {
      track.removeProcessor(track.processor)
    }
  }

  const addProcessor = (
    track: LocalVideoTrack,
    processor: GaussianBlurBackgroundProcessor | VirtualBackgroundProcessor,
  ) => {
    if (!track || track.processor === processor) {
      return
    }
    removeProcessor(track)
    track.addProcessor(processor)
  }

  const loadBlurBackground = async () => {
    if (!blurProcessor) {
      blurProcessor = new GaussianBlurBackgroundProcessor({
        assetsPath: virtualBackgroundAssets,
      })
      await blurProcessor.loadModel()
    }
  }

  const loadImageBackground = async (backgroundImage: HTMLImageElement) => {
    virtualBackgroundProcessor = new VirtualBackgroundProcessor({
      assetsPath: virtualBackgroundAssets,
      backgroundImage,
      fitType: ImageFit.Cover,
    })
    await virtualBackgroundProcessor.loadModel()
  }

  const setImageBackground = async (track: LocalVideoTrack, value?: string) => {
    const selected = backgroundStore.backgroundGallery.get(value || '')
    const type = selected?.type
    if (!isSupported) {
      return
    }
    if (type === 'blur') {
      if (!blurProcessor) {
        await loadBlurBackground()
      }
      addProcessor(track, blurProcessor)
    } else if (type === 'image') {
      const imageValue = selected?.value || 'globish-bg-color-orange'
      const backgroundImage = backgroundStore.backgroundGallery.get(imageValue)?.imgHtml
      if (!backgroundImage) return
      if (!virtualBackgroundProcessor) {
        loadImageBackground(backgroundImage)
      } else {
        virtualBackgroundProcessor.backgroundImage = backgroundImage
      }
      addProcessor(track, virtualBackgroundProcessor)
    } else {
      removeProcessor(track)
    }
  }
  onBeforeMount(() => {
    if (isSupported) {
      if (!blurProcessor) {
        loadBlurBackground()
      }
      if (!virtualBackgroundProcessor) {
        const backgroundImage =
          backgroundStore.backgroundGallery.get('globish-bg-color-orange')?.imgHtml
        if (!backgroundImage) return
        loadImageBackground(backgroundImage)
      }
    }
  })

  return { setImageBackground, removeProcessor }
}
