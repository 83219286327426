import { AudioNumber } from '@/types/enums/video-diagnostic.enum'
import {
  testAudioInputDevice,
  AudioInputTest,
  testAudioOutputDevice,
  AudioOutputTest,
} from '@twilio/rtc-diagnostics'
import { ref, reactive, toRefs } from 'vue'
import { getAudioLevelPercentage, getStandardDeviation } from '@/helpers/audio-test.helper'

interface IUseAudioTestState {
  inputLevel: number
  outputLevel: number
  enableRecording: boolean
  isRecording: boolean
  isAudioInputTestRunning: boolean
  isAudioOutputTestRunning: boolean
  audioInputDeviceTest?: AudioInputTest
  audioOutputDeviceTest?: AudioOutputTest
  selectedAudio?: string
  selectedSpeaker?: string
  playbackURI: string
  timeCounter: number
  countPlay: number
}

export const audioInputTestReport = ref<any>(undefined)
export const audioOutputTestReport = ref<any>(undefined)
export const audioTestPassed = ref<boolean>(true)

export const useAudioTest = () => {
  const state = reactive<IUseAudioTestState>({
    inputLevel: 0,
    outputLevel: 0,
    enableRecording: false,
    isRecording: false,
    isAudioInputTestRunning: false,
    isAudioOutputTestRunning: false,
    audioInputDeviceTest: undefined,
    audioOutputDeviceTest: undefined,
    selectedAudio: undefined,
    selectedSpeaker: undefined,
    playbackURI: '',
    timeCounter: 0,
    countPlay: 0,
  })

  const countdown = (duration: number) => {
    state.timeCounter = duration / 1000
    const timeCount = setInterval(() => {
      state.timeCounter -= 1
      if (state.timeCounter === 0) {
        clearInterval(timeCount)
      }
    }, 1000)
  }

  const readAudioInput = () => {
    if (state.audioInputDeviceTest) {
      state.audioInputDeviceTest.stop()
    }
    const duration = state.enableRecording
      ? AudioNumber.RECORD_DURATION
      : AudioNumber.INPUT_TEST_DURATION
    state.audioInputDeviceTest = testAudioInputDevice({
      duration,
      deviceId: state.selectedAudio,
      enableRecording: state.enableRecording,
    })
    state.isAudioInputTestRunning = true
    if (state.enableRecording) {
      state.playbackURI = ''
      state.isRecording = true
      state.countPlay = 0
      countdown(AudioNumber.RECORD_DURATION)
    }

    state.audioInputDeviceTest.on(AudioInputTest.Events.Volume, (volume) => {
      state.inputLevel = getAudioLevelPercentage(volume)
    })

    state.audioInputDeviceTest.on(AudioInputTest.Events.Error, (error) => {
      console.error(error)
      clearAudioDeviceTest()
    })

    state.audioInputDeviceTest.on(AudioInputTest.Events.End, (testReport) => {
      if (state.playbackURI && testReport.recordingUrl) {
        URL.revokeObjectURL(state.playbackURI)
      }
      if (testReport.recordingUrl) {
        state.playbackURI = testReport.recordingUrl
        playAudio()
      }
      state.isRecording = false
      state.isAudioInputTestRunning = false
      audioInputTestReport.value = testReport
    })
  }

  const playAudio = () => {
    const cannotSetSpeaker = !Audio.prototype.setSinkId
    state.audioOutputDeviceTest = testAudioOutputDevice({
      doLoop: false,
      deviceId: cannotSetSpeaker ? '' : state.selectedSpeaker,
      testURI: state.playbackURI,
    })
    state.isAudioOutputTestRunning = true

    state.audioOutputDeviceTest.on(AudioOutputTest.Events.Volume, (volume) => {
      state.outputLevel = getAudioLevelPercentage(volume)
    })

    state.audioOutputDeviceTest.on(AudioOutputTest.Events.Error, (error) => {
      console.error(error)
      clearAudioDeviceTest()
    })

    state.audioOutputDeviceTest.on(AudioOutputTest.Events.End, (testReport) => {
      state.isAudioOutputTestRunning = false
      state.countPlay += 1
      state.outputLevel = 0
      const stdDev = getStandardDeviation(testReport.values)
      if (stdDev === 0) {
        console.error('No audio detected')
        state.audioOutputDeviceTest?.stop()
      }
      audioOutputTestReport.value = testReport
    })
  }

  const clearAudioDeviceTest = () => {
    state.audioInputDeviceTest?.stop()
    state.audioOutputDeviceTest?.stop()
    state.enableRecording = false
    state.isRecording = false
    state.inputLevel = 0
    state.outputLevel = 0
  }

  const setAudioTestResult = (result: boolean) => {
    audioTestPassed.value = result
  }

  return {
    ...toRefs(state),
    readAudioInput,
    playAudio,
    clearAudioDeviceTest,
    setAudioTestResult,
  }
}
