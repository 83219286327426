<script setup lang="ts">
// DeviceSettingPopup Component
import DeviceSetting from '@/components/checkup/DeviceSetting.vue'
import BackgroundSetting from '@/components/checkup/BackgroundSetting.vue'
import VideoQualitySetting from '@/components/checkup/VideoQualitySetting.vue'
import { computed, ref, PropType } from 'vue'
import { useBackgroundStore, useDeviceStore } from '@/stores'
import { getDeviceEnableState } from '@/services/device-state'
import useSpaceApi from '../../composables/api/useSpaceApi'
import { Engine } from '../../types/enums/room.enum'
import { covertVideoBgToBackgroundType } from '../../helpers/background.helper'
import { currentResolution } from '@/composables/twilio/useTwilioState'

interface DeviceSettingPopupEmits {
  (e: 'update:modelValue', value: boolean): void
  (e: 'update:currentTab', value: string): void
  (e: 'update-device'): void
  (e: 'cancel'): void
}
type Option = 'video' | 'quality' | 'setting'
interface IMenus {
  name: string
  icon: string
  value: string
  isBeta?: boolean
}

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  currentTab: {
    type: String,
    default: 'setting',
  },
  engine: {
    type: String as PropType<Engine>,
    default: Engine.TWILIO,
  },
  option: {
    type: Array as PropType<Option[]>,
    default() {
      return ['setting', 'video', 'quality']
    },
  },
})

const emit = defineEmits<DeviceSettingPopupEmits>()

/**
 * Api
 */
const { saveLogsChangeBackground, saveLogsChangeResolution } = useSpaceApi()

/**
 * Store
 */
const background = useBackgroundStore()
const device = useDeviceStore()

/**
 * State
 */
const nextTab = ref('')
const enableChanged = ref<boolean>(false)
const isOpenConfirm = ref<boolean>(false)

/**
 * Computed
 */

const menus = computed<IMenus[]>(() => {
  const list: IMenus[] = []
  if (props.option.includes('setting'))
    list.push({ name: 'Mic & Camera', icon: 'setting', value: 'setting', isBeta: false })
  if (device.canChangeBackground && props.option.includes('video')) {
    list.push({ name: 'Video Effect', icon: 'effect', value: 'video', isBeta: true })
  }
  if (props.engine === Engine.TWILIO && props.option.includes('quality')) {
    list.push({
      name: 'Video Quality',
      icon: 'video-quality',
      value: 'quality',
      isBeta: true,
    })
  }
  return list
})
const deviceChanged = computed(
  () =>
    device.audioChanged ||
    device.speakerChanged ||
    device.videoChanged ||
    device.cameraOnChanged ||
    device.microphoneOnChanged,
)
const effectChanged = computed(() => background.backgroundChanged)
const qualityChanged = computed(() => device.videoQualityChanged)
const content = computed(() =>
  nextTab.value ? 'Confirm your change before switch new tab' : 'Confirm your change before close',
)
const isChanged = computed(() =>
  props.currentTab === 'setting'
    ? deviceChanged.value
    : props.currentTab === 'video'
    ? effectChanged.value || device.cameraOnChanged
    : props.currentTab === 'quality'
    ? qualityChanged.value
    : false,
)

const changeMenu = (value?: string) => {
  if (!value) {
    toggle()
    return
  }
  if (props.currentTab === value) return
  if (isChanged.value || enableChanged.value) {
    nextTab.value = value
    isOpenConfirm.value = true
    return
  }
  emit('update:currentTab', value)
  nextTab.value = ''
}

const saveChanged = () => {
  switch (props.currentTab) {
    case 'setting': {
      device.setVideoDevice(device.maskSelectedVideo)
      device.setMicrophoneDevice(device.maskSelectedAudio)
      device.setSpeakerDevice(device.maskSelectedSpeaker)
      device.cameraOn = device.maskCameraOn
      device.microphoneOn = device.maskMicrophoneOn
      device.setStateEnableToLocal()
      break
    }
    case 'video': {
      if (background.backgroundChanged) {
        background.currentBackground = background.selectedBackground
        background.setStateBackground(background.currentBackground)
        // Log Change Background
        const { backgroundType, imagePath } = covertVideoBgToBackgroundType(
          background.currentBackground,
        )
        saveLogsChangeBackground({ engine: props.engine, backgroundType, imagePath })
      }
      device.cameraOn = device.maskCameraOn
      device.setStateEnableToLocal()
      break
    }
    case 'quality': {
      device.setCurrentVideoQuality()
      saveLogsChangeResolution(currentResolution.value)
      break
    }
  }
}

const discardChanged = () => {
  const localData = getDeviceEnableState()
  device.maskCameraOn = device.cameraOn = localData.video
  switch (props.currentTab) {
    case 'setting': {
      device.maskSelectedAudio = device.selectedAudio
      device.maskSelectedSpeaker = device.selectedSpeaker
      device.maskSelectedVideo = device.selectedVideo
      device.maskMicrophoneOn = device.microphoneOn = localData.audio
      break
    }
    case 'video': {
      background.selectedBackground = background.currentBackground
      break
    }
    case 'quality': {
      device.selectedVideoQuality = device.currentVideoQuality
      device.resolution = currentResolution.value
      break
    }
  }
}

const toggle = () => {
  emit('update:modelValue', !props.modelValue)
}

const applyDevice = async () => {
  saveChanged()
  toggle()
  emit('update-device')
}
const cancel = () => {
  if (isChanged.value || enableChanged.value) {
    isOpenConfirm.value = true
    return
  }
  discardChanged()
  toggle()
  emit('cancel')
}

const cancelLatestChange = () => {
  discardChanged()
  isOpenConfirm.value = false
  changeMenu(nextTab.value)
}

const saveLatestChange = () => {
  saveChanged()
  isOpenConfirm.value = false
  emit('update-device')
  changeMenu(nextTab.value)
}
</script>

<template>
  <Popup
    title="Device Setting"
    width="640"
    :open="modelValue"
    class="device-setting-popup"
    @close="cancel"
  >
    <Popup
      title="Confirm your change"
      :open="isOpenConfirm"
      class="confirm-setting-changed"
      :can-close="false"
    >
      <div class="content">{{ content }}</div>
      <template #footer>
        <div class="footer-setting">
          <button type="button" class="btn btn-tertiary" @click="cancelLatestChange">Cancel</button>
          <button type="button" class="btn btn-primary" @click="saveLatestChange">Confirm</button>
        </div>
      </template>
    </Popup>
    <div class="setting-body">
      <div class="setting-menu">
        <div
          v-for="(menu, index) in menus"
          :key="`menu-${index}`"
          class="menu flex items-center"
          :class="{ active: menu.value === currentTab }"
          @click="changeMenu(menu.value)"
        >
          <SvgIcon :icon="menu.icon" :inherit-fill="true" height="20" class="mr-1"></SvgIcon>
          <span>{{ menu.name }}</span>
          <BetaTag v-if="menu.isBeta" class="ml-auto" />
        </div>
      </div>
      <div class="setting-content">
        <BackgroundSetting v-if="currentTab === 'video'" :engine="engine" />
        <DeviceSetting v-if="currentTab === 'setting'" :engine="engine" />
        <VideoQualitySetting v-if="currentTab === 'quality'" />
      </div>
    </div>
    <template #footer>
      <div class="footer-setting">
        <template v-if="isChanged || enableChanged">
          <button type="button" class="btn btn-tertiary" @click="cancel">Cancel</button>
          <button type="button" class="btn btn-primary" @click="applyDevice">Save</button>
        </template>
        <template v-else>
          <button type="button" class="btn btn-tertiary" @click="cancel">Close</button>
        </template>
      </div>
    </template>
  </Popup>
</template>

<style lang="scss" scoped>
.confirm-setting-changed {
  .content {
    color: #4a4a4a;
    font-size: 1rem;
  }
}
.setting-body {
  display: flex;
  position: relative;
  height: 100%;
  min-height: 50vh;
  overflow: hidden;
  .setting-menu {
    width: 30%;
    background: #f4f4f4;
    border-right: 1px solid #ececec;
    color: #4a4a4a;
    font-size: 16px;
    .menu {
      padding: 0.75rem;
      cursor: pointer;
      flex-wrap: wrap;
      &.active {
        background: var(--primary-color);
        color: white;
        :deep(.beta-icon) {
          background: white;
          color: $warning;
        }
      }
    }
  }
  .setting-content {
    width: 70%;
    padding: 1rem;
    overflow: hidden;
    overflow-y: auto;
  }
}
.btn {
  font-size: 1.1rem;
  @include font-weight(bold);
}
</style>

<style lang="scss">
.device-setting-popup.popup .popup-body {
  padding: 0 !important;
  overflow: hidden;
  overflow-y: hidden;
}
</style>
