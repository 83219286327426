<script setup lang="ts">
import PreviewTwilio from '@/components/checkup/PreviewTwilio.vue'
import PreviewVideo from '@/components/checkup/PreviewVideo.vue'
import PreviewZoom from '@/components/checkup/PreviewZoom.vue'
import { useDeviceStore } from '@/stores'
import { Engine } from '@/types/enums/room.enum'
import { computed, onMounted, onUnmounted, PropType, watch } from 'vue'

defineProps({
  engine: {
    type: String as PropType<Engine>,
    default: Engine.TWILIO,
  },
})
// Store
const device = useDeviceStore()

// Computed
const audioLevelCss = computed(() => Math.max(0, 100 - device.audioLevel * 300))

onMounted(() => {
  device.maskSelectedVideo = device.selectedVideo
  device.maskSelectedAudio = device.selectedAudio
  device.maskSelectedSpeaker = device.selectedSpeaker
  if (device.maskMicrophoneOn) {
    device.getAudioLevelZoom()
  }
})

onUnmounted(() => {
  device.stopAudioLevelZoom()
})

watch(
  () => device.maskMicrophoneOn,
  async () => {
    if (device.maskMicrophoneOn) {
      if (device.localAudioTrack) {
        await device.localAudioTrack.unmute()
      }
      device.getAudioLevelZoom()
    } else {
      if (device.localAudioTrack) {
        await device.localAudioTrack.mute()
      }
      device.stopAudioLevelZoom()
    }
  },
)
</script>

<template>
  <div class="device-setting">
    <template v-if="device.isReady">
      <PreviewTwilio
        v-if="engine === Engine.TWILIO"
        :can-set-background="false"
        :is-auto-state="false"
      />
      <PreviewZoom v-else-if="engine === Engine.ZOOM" :is-auto-state="false" />
      <PreviewVideo v-else />
    </template>
    <div class="audio-bar">
      <div class="audio-level"></div>
      <div class="background"></div>
    </div>
    <SelectOption
      v-model="device.maskSelectedVideo"
      :label="$t('camera')"
      :options="device.videoInputDevices"
    />
    <SelectOption
      v-model="device.maskSelectedAudio"
      :label="$t('microphone')"
      :options="device.audioInputDevices"
    />
    <SelectOption
      v-if="device.canSetSpeaker"
      v-model="device.maskSelectedSpeaker"
      :label="$t('speaker')"
      :options="device.audioOutputDevices"
    />
    <template v-else>
      <p class="text-sm font-medium mt-3 text-black">{{ $t('speaker') }}</p>
      <p class="text-base">System Default Audio Output</p>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.audio-bar {
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
  height: 4px;
  flex: 1;
  div {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }

  .audio-level {
    background: #14b053;
    right: calc(v-bind(audioLevelCss) * 1%);
    transition: right 0.1s linear;
  }

  .background {
    right: 0;
    background: #e1e3ea38;
  }
}
</style>
