import { onMounted, ref, toRaw, watch } from 'vue'
import { IParticipantTrack } from '@/types/interfaces/participant.interface'
import { useDeviceStore } from '@/stores'

export function useTrack(track: IParticipantTrack) {
  const device = useDeviceStore()
  const trackRef = ref<HTMLMediaElement | undefined>(undefined)

  const attachTrack = () => {
    const mediaHtml = toRaw(track).attach()
    trackRef.value?.appendChild(mediaHtml)
  }

  const initTrack = () => {
    attachTrack()
  }

  const setAudioOutput = async () => {
    const element: any = trackRef.value?.children?.item(0)
    const canSetSpeaker = !!Audio.prototype.setSinkId
    if (element?.tagName === 'AUDIO' && canSetSpeaker) {
      try {
        await element?.setSinkId(device.selectedSpeaker)
      } catch (err) {
        console.log('setAudioOutput', err)
      }
    }
  }

  watch(
    () => device.selectedSpeaker,
    () => {
      setAudioOutput()
    },
  )

  onMounted(() => {
    initTrack()
  })
  return { trackRef, setAudioOutput }
}
