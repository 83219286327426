import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import * as Sentry from '@sentry/browser'
import * as AuthService from './useAuth'
import { inject, InjectionKey } from 'vue'
import { BACKEND_BASE_URL } from '.'

export const ApiSymbol: InjectionKey<AxiosInstance> = Symbol()

const HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
}

export interface ApiResponse extends AxiosResponse {
  error?: AxiosError
  message?: any
  code?: number
}
export function initApi(): AxiosInstance {
  // Here we set the base URL for all requests made to the api
  const api = axios.create({
    baseURL: BACKEND_BASE_URL,
    headers: HEADERS,
  })

  api.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      const token = AuthService.getToken()
      if (token && config?.headers) {
        config.headers.Authorization = token
      }

      return config
    },
    (error) => {
      console.error(error.response)
      return Promise.reject(error)
    },
  )

  api.interceptors.response.use(
    (res) => res,
    (error) => {
      const response = error.response ? error.response?.data : error.toJSON()
      const code = response.code
      const message = error.response ? response.message : { message: response.message }
      Sentry.setExtra('error_path', error.config.url)
      Sentry.setExtra('error', response)
      Sentry.captureException(error)
      const err = <ApiResponse>{
        error,
        message,
        code,
      }
      if (code === 401) {
        AuthService.removeToken()
      }
      return err
    },
  )
  return api
}

export default function useApi(): AxiosInstance {
  // Here we set the base URL for all requests made to the api
  const api = inject<AxiosInstance>(ApiSymbol)

  if (!api) {
    throw new Error('Api not properly injected in app')
  }
  return api
}
