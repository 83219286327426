<script setup lang="ts">
import { computed, PropType } from 'vue'
import Checkbox from '@/components/common/Checkbox.vue'

interface ReportProblemFormEmits {
  (e: 'update:modelValue', value: string[]): void
  (e: 'update:other', value: string): void
}

const props = defineProps({
  modelValue: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
  other: {
    type: String,
    default: '',
  },
})

const emit = defineEmits<ReportProblemFormEmits>()
const rePortProblemValue = new Map<number, string>([
  [1, "[01] I can't see other(s)"],
  [2, "[02] Other(s) can't see me"],
  [3, "[03] I can't hear other(s)"],
  [4, "[04] Other(s) can't hear me"],
  [5, "[05] My camera or mic doesn't work"],
  [6, '[06] Unstable connection'],
])
const model = computed({
  get: () => props.modelValue,
  set: (value: string[]) => emit('update:modelValue', value),
})
const modelOther = computed({
  get: () => props.other,
  set: (value) => emit('update:other', value),
})
</script>

<template>
  <div>
    <div class="mt-5 mb-3 font-semibold report-topic">
      <SvgIcon class="mr-2" icon="eye" :inherit-fill="true" height="26"></SvgIcon>
      <span>Video</span>
    </div>
    <div class="grid grid-cols-2">
      <Checkbox
        :id="rePortProblemValue.get(1)"
        v-model="model"
        :value="rePortProblemValue.get(1)"
        :label="`I can't see other(s)`"
      ></Checkbox>
      <Checkbox
        :id="rePortProblemValue.get(2)"
        v-model="model"
        :value="rePortProblemValue.get(2)"
        :label="`Other(s) can't see me`"
      ></Checkbox>
    </div>

    <div class="mt-8 mb-3 font-semibold report-topic">
      <SvgIcon class="mr-2" icon="hearing" :inherit-fill="true" height="26"></SvgIcon>
      <span>Audio</span>
    </div>
    <div class="grid grid-cols-2">
      <Checkbox
        :id="rePortProblemValue.get(3)"
        v-model="model"
        :value="rePortProblemValue.get(3)"
        :label="`I can't hear other(s)`"
      ></Checkbox>
      <Checkbox
        :id="rePortProblemValue.get(4)"
        v-model="model"
        :value="rePortProblemValue.get(4)"
        :label="`Other(s) can't hear me`"
      ></Checkbox>
    </div>

    <div class="mt-8">
      <Checkbox
        :id="rePortProblemValue.get(5)"
        v-model="model"
        :value="rePortProblemValue.get(5)"
        :label="`My camera or mic doesn't work`"
      ></Checkbox>
      <Checkbox
        :id="rePortProblemValue.get(6)"
        v-model="model"
        :value="rePortProblemValue.get(6)"
        :label="`Unstable connection`"
      ></Checkbox>
    </div>

    <div class="font-semibold mt-8">
      <div class="mb-1">Other</div>
      <textarea
        v-model="modelOther"
        class="rounded border border-zinc-300 w-full p-3"
        rows="2"
        placeholder="Please specify if any"
      ></textarea>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.report-topic {
  padding-bottom: 6px;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
}

.report-topic > span {
  line-height: 26px;
}
</style>
