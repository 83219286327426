import { createI18n } from 'vue-i18n'
import CommonState from '@/services/locale-state'

const loadLocaleMessages = () => {
  const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages: any = {}
  locales.keys().forEach((key: string) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

export const createClientI18n = () => {
  return createI18n({
    messages: loadLocaleMessages(),
    locale: CommonState.getLocale() || 'en',
    global: true,
    allowComposition: true,
    fallbackLocale: 'en',
  })
}
