import { IHelpStoreState } from '@/services/help-state'

const WAITING_MIN = process.env.BRANCH === 'dev' ? 1 : 5
const WAITING_TIME = WAITING_MIN * 60 * 1000

export const checkTimeLimitToShowFeedback = (
  helpList: IHelpStoreState[],
  callback: (...args: any) => void,
) => {
  const currentTime = Date.now()
  const timeout = helpList[0].timestamp + WAITING_TIME - currentTime
  if (timeout <= 0) {
    callback()
  } else {
    setTimeout(() => {
      callback()
    }, timeout)
  }
}
