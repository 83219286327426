<script setup lang="ts">
import DiagnosticSection from '@/components/video-diagnostic/DiagnosticSection.vue'
import TipsQualityBox from '@/components/video-diagnostic/TipsQualityBox.vue'
import { getQualityScore } from '@/composables/video-diagnostic/useGetQualityScore'
import { useVideoDiagnostic } from '@/composables/video-diagnostic/useVideoDiagnostic'
import CommonState from '@/services/locale-state'
import { Stage, Topic, QualityScore } from '@/types/enums/video-diagnostic.enum'
import { IUpdateTopic } from '@/types/interfaces/video-diagnostic.interface'
import { watch, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { videoInputTestPassed } from '@/composables/video-diagnostic/useCameraTest'
import { audioTestPassed } from '@/composables/video-diagnostic/useAudioTest'
import Video from 'twilio-video'

interface ResultEmits {
  (e: 'nextStage', value: number): void
  (e: 'updateTopic', value: IUpdateTopic): void
}

const router = useRouter()
const route = useRoute()
const { sendReport, isLoading } = useVideoDiagnostic()

const props = defineProps({
  stage: {
    type: Number,
    required: true,
  },
})

const supportTel = computed(() =>
  CommonState.getCountry() === 'vn' ? '0906.830.230' : '02-026-6683',
)

const emit = defineEmits<ResultEmits>()

watch(
  () => props.stage,
  async (stage, prevStage) => {
    if (prevStage === Stage.RESULTS && stage === Stage.QUALITY) {
      emit('updateTopic', { topic: Topic.RESULTS, active: false })
    }
    if (stage === Stage.RESULTS) {
      emit('updateTopic', { topic: Topic.RESULTS, active: true })
    }
  },
)

const { totalQualityScore } = getQualityScore()

const qualityScorePassed = computed(
  () =>
    totalQualityScore.value === QualityScore.Excellent ||
    totalQualityScore.value === QualityScore.Good,
)
const testsPassed = computed(
  () =>
    (totalQualityScore.value === QualityScore.Excellent ||
      totalQualityScore.value === QualityScore.Good) &&
    videoInputTestPassed.value &&
    audioTestPassed.value &&
    Video.isSupported,
)
const qualityScore = computed(() => QualityScore[totalQualityScore.value].toLowerCase())

const refreshPage = () => {
  router.go(0)
}

const backToFirstPage = () => {
  router.go(-1)
}
</script>

<template>
  <DiagnosticSection
    :id="Stage.RESULTS"
    :class="stage === Stage.RESULTS ? 'block' : 'hidden md:block opacity-0 pointer-events-none'"
    class="result-container"
  >
    <template #default>
      <div class="row-span-2 text-sm sm:text-base lg:text-sm xl:text-base">
        <h1 class="font-bold text-xl sm:text-4xl lg:text-xl xl:text-4xl">
          {{
            testsPassed
              ? $t('video-diagnostic.all-test-passed')
              : $t('video-diagnostic.some-test-failed')
          }}
        </h1>
        <p v-if="testsPassed" class="py-4 sm:py-10 lg:py-4 xl:py-10">
          {{ $t('video-diagnostic.test-past-detail') }}
        </p>
        <p v-else class="py-4 sm:py-10 lg:py-4 xl:py-10">
          <span class="font-bold">{{ $t('video-diagnostic.some-test-failed') }} </span>

          {{ $t('video-diagnostic.test-failed-detail') }}
          <span v-if="route.query.exitPath">
            {{ $t('video-diagnostic.test-failed-detail2', { tel: supportTel }) }}
          </span>
        </p>
        <TipsQualityBox v-if="!testsPassed" />
        <!-- <div v-if="route.query.exitPath">
          <strong>{{ $t('video-diagnostic.report-comment-header') }}</strong>
          <textarea
            v-model="note"
            class="w-full p-6 mt-2"
            rows="6"
            :placeholder="$t('video-diagnostic.report-comment-placeholder')"
          ></textarea>
        </div> -->
        <div class="flex py-6">
          <button
            v-if="route.query.exitPath"
            class="btn-submit"
            type="button"
            :disabled="isLoading"
            @click="sendReport"
          >
            <div class="flex">
              <Loading class="mr-2" :is-loading="isLoading" />
              {{ $t('video-diagnostic.finish') }}
            </div>
          </button>
          <button
            v-else
            class="text-white border-2 rounded-md border-white bg-primary-color py-2 px-4 mr-4"
            type="button"
            @click="backToFirstPage"
          >
            {{ $t('video-diagnostic.back') }}
          </button>
          <button class="bg-white border py-2 px-4 rounded-md" type="button" @click="refreshPage">
            {{ $t('video-diagnostic.restart-test') }}
          </button>
        </div>
      </div>

      <div class="row-span-2 text-sm sm:text-base lg:text-sm xl:text-base">
        <div class="max-w-sm border-b-2 mb-6">
          <h2 class="font-bold text-xl">
            <SvgIcon
              :class="videoInputTestPassed ? 'text-green-500' : ''"
              class="icon-container mr-2"
              :icon="videoInputTestPassed ? 'correct-circle' : 'error-sign'"
              :inherit-fill="true"
              height="16"
            />
            {{ $t('video-diagnostic.section-camera.header') }}
          </h2>
          <p
            class="py-4"
            v-html="
              videoInputTestPassed
                ? $t('video-diagnostic.section-camera.passed-message')
                : $t('video-diagnostic.section-camera.failed-message')
            "
          ></p>
        </div>
        <div class="max-w-sm border-b-2 mb-6">
          <h2 class="font-bold text-xl">
            <SvgIcon
              :class="audioTestPassed ? 'text-green-500' : ''"
              class="icon-container mr-2"
              :icon="audioTestPassed ? 'correct-circle' : 'error-sign'"
              :inherit-fill="true"
              height="16"
            />
            {{ $t('video-diagnostic.section-speaker-and-mic.header') }}
          </h2>
          <p
            class="py-4"
            v-html="
              audioTestPassed
                ? $t('video-diagnostic.section-speaker-and-mic.passed-message')
                : $t('video-diagnostic.section-speaker-and-mic.failed-message')
            "
          ></p>
        </div>
        <div class="max-w-sm border-b-2 mb-6">
          <h2 class="font-bold text-xl">
            <SvgIcon
              :class="Video.isSupported ? 'text-green-500' : ''"
              class="icon-container mr-2"
              :icon="Video.isSupported ? 'correct-circle' : 'error-sign'"
              :inherit-fill="true"
              height="16"
            />
            {{ $t('video-diagnostic.section-browser.header') }}
          </h2>
          <p
            class="py-4"
            v-html="
              Video.isSupported
                ? $t('video-diagnostic.section-browser.passed-message')
                : $t('video-diagnostic.section-browser.failed-message')
            "
          ></p>
        </div>
        <div class="max-w-sm border-b-2 mb-6">
          <h2 class="font-bold text-xl">
            <SvgIcon
              class="icon-container text-green-500 mr-2"
              :icon="'correct-circle'"
              :inherit-fill="true"
              height="16"
            />
            {{ $t('video-diagnostic.connectivity-result') }}
          </h2>
          <p class="py-4" v-html="$t('video-diagnostic.connectivity-detail')"></p>
        </div>
        <div class="max-w-sm border-b-2 mb-6">
          <h2 class="font-bold text-xl">
            <SvgIcon
              :class="qualityScorePassed ? 'text-green-500' : ''"
              class="icon-container mr-2"
              :icon="qualityScorePassed ? 'correct-circle' : 'error-sign'"
              :inherit-fill="true"
              height="16"
            />
            {{ $t('video-diagnostic.quality-performance') }}
          </h2>
          <p
            v-if="qualityScorePassed"
            class="py-4"
            v-html="
              $t('video-diagnostic.quality-performance-detail-passed', {
                qualityScore: qualityScore,
              })
            "
          ></p>
          <p
            v-else
            class="py-4"
            v-html="
              $t('video-diagnostic.quality-performance-detail-failed', {
                qualityScore: qualityScore,
              })
            "
          ></p>
        </div>
      </div>
    </template>
  </DiagnosticSection>
</template>

<style lang="scss" scoped>
.bg-primary-color {
  background-color: #f67519;
}

button.bg-white {
  &:hover {
    background-color: #32323209;
    transition: 0.4s;
  }
  &:disabled {
    background-color: inherit;
    color: rgba(0, 0, 0, 0.26);
  }
}

.h-fit {
  height: fit-content;
}

.result-container {
  padding-bottom: 10rem;
}

@include media-breakpoint-down-custom(1080px) {
  .result-container {
    overflow-y: auto;
    padding: 6rem 5rem;
  }
}

@include media-breakpoint-down-custom(640px) {
  .result-container {
    overflow-y: auto;
    padding: 2rem 1.5rem;
  }
}

.btn-submit {
  @apply text-white border-2 rounded-md border-white py-2 px-4 mr-4;
  @extend .bg-primary-color;
  &:disabled {
    @apply bg-gray-400;
  }
}
::v-deep(.icon-container svg) {
  display: inline-block !important;
  margin-bottom: 0.7rem;
  width: 16px;
  height: 16px;
}
</style>
