import { currentResolution } from '@/composables/twilio/useTwilioState'
import { videoQuality } from '@/data/video'
import {
  clearLocalTracks,
  detectPermissions,
  getDevicesForZoom,
  getDevices,
} from '@/helpers/device.helper'
import {
  getAudioInput,
  getAudioOutput,
  getDeviceEnableState,
  getVideoInput,
  setAudioInput,
  setAudioOutput,
  setDeviceEnableState,
  setVideoInput,
} from '@/services/device-state'
import { ISelectOption } from '@/types/interfaces/common.interface'
import { IUserMediaError } from '@/types/interfaces/track.interface'
import { IVideoQuality } from '@/types/interfaces/video.interface'
import { defineStore } from 'pinia'
import ZoomVideo, { LocalVideoTrack, LocalAudioTrack, MobileVideoFacingMode } from '@zoom/videosdk'
import { Engine } from '@/types/enums/room.enum'
import { isAndroidBrowser, isIOSMobile, isIPad } from '@/helpers/zoom-device.helper'
import { IVideoInputOption } from '@/types/interfaces/video-diagnostic.interface'
import { useBackgroundStore } from './background.store'
import { isSupported } from '@twilio/video-processors'
import useGChatLog from '@/composables/api/useGChatLog'
import { LogDeviceProblemAction } from '@/types/enums/log.enum'

interface State {
  audioInputDevices: ISelectOption[]
  audioOutputDevices: ISelectOption[]
  videoInputDevices: IVideoInputOption[]
  localTracks?: MediaStream
  haveAudio: boolean
  haveVideo: boolean
  allowedCamera: boolean
  allowedMicrophone: boolean
  permissionError?: IUserMediaError
  videoErrorMsg?: string
  micErrorMsg?: string
  speakerErrorMsg?: string
  canSetSpeaker: boolean
  cameraOn: boolean
  microphoneOn: boolean
  isReady: boolean
  isProcessing: boolean
  audioLevel: number
  resolution: string
  // real used
  selectedVideo: string
  selectedAudio: string
  selectedSpeaker: string
  selectedVideoQuality: IVideoQuality | undefined
  currentVideoQuality: IVideoQuality | undefined

  // before submit change
  maskSelectedVideo: string
  maskSelectedAudio: string
  maskSelectedSpeaker: string
  maskCameraOn: boolean
  maskMicrophoneOn: boolean
  someDeviceIsGone: boolean

  engine?: Engine

  // zoom
  localVideoTrack?: LocalVideoTrack
  localAudioTrack?: LocalAudioTrack
  localTrackZoomRef?: HTMLVideoElement
  localTrackCanvasZoomRef?: HTMLCanvasElement
  audioLevelZoomInterval?: any
  previewZoomMode: 'video' | 'canvas'
  isDeviceSupportVirtualBackground: boolean
}
// main is the name of the store. It is unique across your application
// and will appear in devtools
export const useDeviceStore = defineStore('device', {
  // a function that returns a fresh state
  state: (): State => ({
    audioInputDevices: [],
    audioOutputDevices: [],
    videoInputDevices: [],
    localTracks: undefined,
    allowedCamera: true,
    allowedMicrophone: true,
    permissionError: undefined,
    videoErrorMsg: '',
    micErrorMsg: '',
    speakerErrorMsg: '',
    canSetSpeaker: !!Audio.prototype.setSinkId,
    cameraOn: getDeviceEnableState().video,
    microphoneOn: getDeviceEnableState().audio,
    selectedVideo: getVideoInput() || '',
    selectedAudio: getAudioInput() || '',
    selectedSpeaker: getAudioOutput() || '',
    maskSelectedVideo: getVideoInput() || '',
    maskSelectedAudio: getAudioInput() || '',
    maskSelectedSpeaker: getAudioOutput() || '',
    maskCameraOn: getDeviceEnableState().video,
    maskMicrophoneOn: getDeviceEnableState().audio,
    isReady: false,
    isProcessing: false,
    audioLevel: 0,
    haveAudio: true,
    haveVideo: true,
    someDeviceIsGone: false,
    selectedVideoQuality: videoQuality.get(currentResolution.value),
    currentVideoQuality: videoQuality.get(currentResolution.value),
    resolution: currentResolution.value,
    engine: undefined,
    localVideoTrack: undefined,
    localAudioTrack: undefined,
    localTrackZoomRef: undefined,
    audioLevelZoomInterval: undefined,
    previewZoomMode: 'video',
    isDeviceSupportVirtualBackground: false,
  }),
  // optional getters
  getters: {
    userMediaConstraints(state): MediaStreamConstraints {
      return {
        audio: state.haveAudio ? { deviceId: state.selectedAudio || getAudioInput() || '' } : false,
        // comment for unused video quality twilio for now (15/05/2024)
        // video: state.haveVideo
        //   ? { ...state.currentVideoQuality, deviceId: state.selectedVideo || getVideoInput() || '' }
        //   : false,
        video: state.haveVideo ? { deviceId: state.selectedVideo || getVideoInput() || '' } : false,
      }
    },
    videoChanged(state) {
      return state.selectedVideo !== state.maskSelectedVideo
    },
    audioChanged(state) {
      return state.selectedAudio !== state.maskSelectedAudio
    },
    speakerChanged(state) {
      return state.selectedSpeaker !== state.maskSelectedSpeaker
    },
    cameraOnChanged(state) {
      return state.cameraOn !== state.maskCameraOn
    },
    microphoneOnChanged(state) {
      return state.microphoneOn !== state.maskMicrophoneOn
    },
    videoQualityChanged(state) {
      return state.resolution !== currentResolution.value
    },
    canChangeBackground(state) {
      return state.isDeviceSupportVirtualBackground && state.allowedCamera && !state.videoErrorMsg
    },
  },
  // optional actions
  actions: {
    async initialize() {
      await this.applyUserMedia()
      if (this.engine === Engine.ZOOM) {
        this.cameraOn = this.maskCameraOn = true
        this.microphoneOn = this.maskMicrophoneOn = true
        this.setStateEnableToLocal()
        await this.setDeviceOptionsForZoom()
      } else {
        this.isDeviceSupportVirtualBackground = isSupported
        await this.setDeviceOptions()
      }
      this.setDefaultDeviceState()
      this.subscribeDeviceChange()
      this.isReady = true
    },
    clearLocalTracks() {
      clearLocalTracks(this.localTracks)
    },
    async applyUserMedia() {
      this.initStateFromLocal()
      await this.clearLocalTracks()
      const [permissionCamera, permissionAudio, { data, error }] = await Promise.all([
        detectPermissions({ video: this.userMediaConstraints.video }),
        detectPermissions({ audio: this.userMediaConstraints.audio }),
        detectPermissions(this.userMediaConstraints),
      ])
      this.allowedCamera = !permissionCamera.error
      this.allowedMicrophone = !permissionAudio.error
      this.permissionError =
        permissionCamera.error && permissionAudio.error
          ? error
          : permissionCamera.error || permissionAudio.error
      if (this.engine !== Engine.ZOOM) {
        this.localTracks = data
      }
    },
    async onDeviceChanged() {
      await this.applyUserMedia()
      if (this.engine === Engine.ZOOM) {
        await this.setDeviceOptionsForZoom()
        if (isIPad()) {
          await this.stopVideoTrackZoom()
          await this.startVideoTrackZoom()
        }
      } else {
        await this.setDeviceOptions()
      }
      this.setDefaultDeviceState()
    },

    // Subscribe device change [ex. new device active, disconnect device]
    subscribeDeviceChange() {
      navigator.mediaDevices.addEventListener('devicechange', this.onDeviceChanged)
    },
    unsubscribeDeviceChange() {
      navigator.mediaDevices.removeEventListener('devicechange', this.onDeviceChanged)
    },

    // Enable mic & camera
    async toggleMicrophone(isSetLocalStorage?: boolean) {
      this.microphoneOn = !this.microphoneOn
      this.maskMicrophoneOn = this.microphoneOn
      if (isSetLocalStorage || isSetLocalStorage === undefined) {
        this.setStateEnableToLocal()
      }
      if (this.localTracks) {
        this.isProcessing = true
        if (this.maskMicrophoneOn) this.localTracks.getAudioTracks()[0].enabled = true
        else this.localTracks.getAudioTracks()[0].enabled = false
        this.isProcessing = false
      }

      if (this.localAudioTrack) {
        this.isProcessing = true
        if (this.maskMicrophoneOn) await this.localAudioTrack.unmute()
        else await this.localAudioTrack.mute()
        this.isProcessing = false
      }
    },
    async toggleCamera(isSetLocalStorage?: boolean) {
      this.maskCameraOn = !this.maskCameraOn
      this.cameraOn = this.maskCameraOn
      if (isSetLocalStorage || isSetLocalStorage === undefined) {
        this.setStateEnableToLocal()
      }
      if (this.localTracks) {
        if (this.maskCameraOn) this.localTracks.getVideoTracks()[0].enabled = true
        else this.localTracks.getVideoTracks()[0].enabled = false
      }

      if (this.localVideoTrack && this.localTrackZoomRef) {
        this.isProcessing = true
        if (this.maskCameraOn) {
          await this.localVideoTrack.start(this.localTrackZoomRef)
          await this.localVideoTrack.switchCamera(this.maskSelectedVideo)
          this.previewZoomMode = 'video'
          await this.setPreviewWithZoomBackground()
        } else {
          await this.localVideoTrack.stop()
        }
        this.isProcessing = false
      }
    },

    // State LocalStorage
    initStateFromLocal() {
      const enable = getDeviceEnableState()
      this.cameraOn = enable.video
      this.microphoneOn = enable.audio
    },
    setStateEnableToLocal() {
      setDeviceEnableState({ video: this.cameraOn, audio: this.microphoneOn })
    },
    setVideoDevice(deviceId: string) {
      this.selectedVideo = deviceId
      this.maskSelectedVideo = deviceId
      setVideoInput(deviceId)
    },
    setMicrophoneDevice(deviceId: string) {
      this.selectedAudio = deviceId
      this.maskSelectedAudio = deviceId
      setAudioInput(deviceId)
    },
    setSpeakerDevice(deviceId: string) {
      this.selectedSpeaker = deviceId
      this.maskSelectedSpeaker = deviceId
      setAudioOutput(deviceId)
    },
    setSelectedVideoQuality(resolution: string) {
      this.selectedVideoQuality = videoQuality.get(resolution)
      this.resolution = resolution
    },
    setCurrentVideoQuality() {
      this.currentVideoQuality = this.selectedVideoQuality
      currentResolution.value = this.resolution
    },
    async logDeviceError(messages: string) {
      const { sendMessageToChatByGroup } = useGChatLog()
      sendMessageToChatByGroup(LogDeviceProblemAction.GET_DEVICE_OPTION, messages)
    },

    // init device
    async setDeviceOptions() {
      const data = await getDevices()
      this.audioInputDevices = data.audioInputDevices
      this.audioOutputDevices = data.audioOutputDevices
      this.videoInputDevices = data.videoInputDevices
      this.haveAudio = data.haveAudio
      this.haveVideo = data.haveVideo
      if (!this.haveVideo || !this.haveAudio) {
        this.logDeviceError(JSON.stringify(data.error || this.permissionError))
      }
      if (!this.allowedCamera || !this.haveVideo) {
        this.setVideoError(this.permissionError?.message || 'Cannot detect camera')
      }
      if (!this.allowedMicrophone || !this.haveAudio) {
        this.setMicError(this.permissionError?.message || 'Cannot detect microphone')
      }
    },
    async setDeviceOptionsForZoom() {
      const data = await getDevicesForZoom()
      this.audioInputDevices = data.audioInputDevices
      this.audioOutputDevices = data.audioOutputDevices
      if (data.error) {
        this.permissionError = data.error
      }
      this.videoInputDevices = data.videoInputDevices
      if (isIOSMobile()) {
        const frontCamera = [
          { label: 'Front-facing', value: '', videoMode: MobileVideoFacingMode.User },
        ]
        this.videoInputDevices = [...frontCamera]
      } else if (isAndroidBrowser()) {
        const frontCameras = data.videoInputDevices
          .filter((v) => v.label.toLowerCase().includes('front'))
          .map((v) => ({ ...v, videoMode: MobileVideoFacingMode.User }))
        const frontCamera = frontCameras.shift()
        if (frontCamera) {
          this.videoInputDevices = [frontCamera]
        }
      } else {
        this.videoInputDevices = data.videoInputDevices
      }
      this.haveAudio = data.haveAudio
      this.haveVideo = data.haveVideo
      this.allowedCamera = data.haveVideo
      this.allowedMicrophone = data.haveAudio

      if (!this.haveVideo || !this.haveAudio) {
        this.logDeviceError(JSON.stringify(data.error || this.permissionError))
      }
      if (!this.allowedCamera || !this.haveVideo) {
        this.setVideoError(this.permissionError?.message || 'Cannot detect camera')
      }
      if (!this.allowedMicrophone || !this.haveAudio) {
        this.setMicError(this.permissionError?.message || 'Cannot detect microphone')
      }
    },
    setDefaultDeviceState() {
      const audioDeviceNotFound = !this.audioInputDevices.some(
        (device) => device.value === this.selectedAudio,
      )
      const videoDeviceNotFound = !this.videoInputDevices.some(
        (device) => device.value === this.selectedVideo,
      )
      const speakerDeviceNotFound = !this.audioOutputDevices.some(
        (device) => device.value === this.selectedSpeaker,
      )

      // check latest device is gone
      const microphoneLeft = this.selectedAudio && audioDeviceNotFound
      const videoLeft = this.selectedVideo && videoDeviceNotFound
      const speakerLeft = this.selectedSpeaker && speakerDeviceNotFound
      this.someDeviceIsGone = !!(microphoneLeft || videoLeft || speakerLeft)
      if (audioDeviceNotFound) {
        const defaultValue = this.audioInputDevices.length ? this.audioInputDevices[0].value : ''
        this.setMicrophoneDevice(defaultValue)
      }
      if (this.audioOutputDevices.length && speakerDeviceNotFound) {
        const defaultValue = this.audioOutputDevices.length ? this.audioOutputDevices[0].value : ''
        this.setSpeakerDevice(defaultValue)
      }
      if (videoDeviceNotFound) {
        const defaultValue = this.videoInputDevices.length ? this.videoInputDevices[0].value : ''
        this.setVideoDevice(defaultValue)
      }
    },
    setLocalTrackZoom(localTrackRef?: HTMLVideoElement) {
      this.localTrackZoomRef = localTrackRef
    },
    setLocalTrackCanvasZoom(canvasRef?: HTMLCanvasElement) {
      this.localTrackCanvasZoomRef = canvasRef
    },
    setEngine(engine: Engine) {
      this.engine = engine
    },
    async startPreviewZoom() {
      if (this.haveVideo) {
        await this.startVideoTrackZoom()
      }
      if (this.haveAudio) {
        await this.startAudioTrackZoom()
      }
    },
    async startVideoTrackZoom() {
      this.localVideoTrack = ZoomVideo.createLocalVideoTrack(this.maskSelectedVideo)
      if (this.maskCameraOn && this.localTrackZoomRef) {
        this.isProcessing = true
        await this.localVideoTrack.start(this.localTrackZoomRef)
        this.previewZoomMode = 'video'
        await this.setPreviewWithZoomBackground()
        this.isProcessing = false
      }
    },
    async switchCameraZoom() {
      if (this.maskCameraOn && this.localTrackZoomRef && this.localVideoTrack) {
        this.isProcessing = true
        await this.localVideoTrack.switchCamera(this.maskSelectedVideo)
        this.isProcessing = false
      }
    },
    async stopVideoTrackZoom() {
      if (this.localVideoTrack) {
        this.isProcessing = true
        await this.localVideoTrack.stop()
        this.isProcessing = false
      }
    },
    getAudioLevelZoom() {
      this.audioLevelZoomInterval = setInterval(() => {
        if (this.localAudioTrack) {
          this.audioLevel = this.localAudioTrack.getCurrentVolume()
        }
      }, 100)
    },
    stopAudioLevelZoom() {
      this.audioLevel = 0
      clearInterval(this.audioLevelZoomInterval)
    },
    async startAudioTrackZoom() {
      this.localAudioTrack = ZoomVideo.createLocalAudioTrack(this.maskSelectedAudio)
      this.isProcessing = true
      await this.localAudioTrack.start()
      if (this.maskMicrophoneOn) await this.localAudioTrack.unmute()
      this.isProcessing = false
    },
    async setPreviewWithZoomBackground() {
      if (!this.canChangeBackground) return
      if (!this.localVideoTrack) return
      this.previewZoomMode = 'video'
      const backgroundStore = useBackgroundStore()
      const virtualBackground = backgroundStore.virtualBackgroundZoom
      if (virtualBackground?.imageUrl && this.localTrackCanvasZoomRef) {
        if (this.maskCameraOn) await this.localVideoTrack?.stop()
        await this.localVideoTrack?.start(this.localTrackCanvasZoomRef, virtualBackground)
      }
      if (virtualBackground?.imageUrl) this.previewZoomMode = 'canvas'
    },
    setVideoError(value?: string) {
      this.videoErrorMsg = value
    },
    setMicError(value?: string) {
      this.micErrorMsg = value
    },
    setSpeakerError(value?: string) {
      this.speakerErrorMsg = value
    },
  },
})
