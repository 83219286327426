import {
  IBitrateTestReport,
  IPreflightTestReport,
  IResults,
  ITestDevice,
  ITestResult,
  ITwilioStatus,
} from '@/types/interfaces/video-diagnostic.interface'
import {
  videoInputTestPassed,
  videoInputTestReport,
} from '@/composables/video-diagnostic/useCameraTest'
import {
  audioInputTestReport,
  audioOutputTestReport,
  audioTestPassed,
} from '@/composables/video-diagnostic/useAudioTest'
import { bitrateTest } from '@/composables/video-diagnostic/useBitrateTest'
import { preflightTest } from '@/composables/video-diagnostic/usePreflightTest'
import { twilioStatus } from '@/composables/video-diagnostic/useTwilioStatus'
import { ResultStatus, QualityScore } from '@/types/enums/video-diagnostic.enum'
import { getQualityScore } from '@/composables/video-diagnostic/useGetQualityScore'

const getVideoInputTestReport = (): ITestDevice => ({
  duration: videoInputTestReport.value?.testTiming.duration,
  errors: videoInputTestReport.value?.errors,
  pass: videoInputTestPassed.value,
})

const getAudioInputTestReport = (): ITestDevice => ({
  duration: audioInputTestReport.value?.testTiming.duration,
  errors: audioInputTestReport.value?.errors,
  pass: audioTestPassed.value,
})

const getAudioOutputTestReport = (): ITestDevice => ({
  duration: audioOutputTestReport.value?.testTiming.duration,
  errors: audioOutputTestReport.value?.errors,
  pass: audioTestPassed.value,
})

const getBitrateTestReport = (): IBitrateTestReport => ({
  averageBitrate: bitrateTest.report?.averageBitrate,
  duration: bitrateTest.report?.testTiming.duration,
  errors: bitrateTest.report?.errors,
})

const getPreflightTestReport = (): IPreflightTestReport => ({
  errors: preflightTest.error,
  duration: preflightTest.report?.testTiming.duration,
  stats: preflightTest.report?.stats,
  progressEvents: preflightTest.report?.progressEvents,
  networkDuration: {
    connect: preflightTest.report?.networkTiming.connect?.duration,
    dtls: preflightTest.report?.networkTiming.dtls?.duration,
    ice: preflightTest.report?.networkTiming.ice?.duration,
    media: preflightTest.report?.networkTiming.media?.duration,
    peerConnection: preflightTest.report?.networkTiming.peerConnection?.duration,
  },
})

const getTwilioStatus = (): ITwilioStatus => ({
  goRooms: twilioStatus['Go Rooms'],
})

const getResults = (): IResults => {
  const { latency, jitter, packetLoss, bitrate, totalQualityScore } = getQualityScore()
  return {
    videoInputResult:
      videoInputTestReport.value?.errors.length || !videoInputTestPassed.value
        ? ResultStatus.FAIL
        : ResultStatus.PASS,
    audioInputResult:
      audioInputTestReport.value?.errors.length || !audioTestPassed.value
        ? ResultStatus.FAIL
        : ResultStatus.PASS,
    audioOutputResult:
      audioOutputTestReport.value?.errors?.length || !audioTestPassed.value
        ? ResultStatus.FAIL
        : ResultStatus.PASS,
    connectivityResult:
      preflightTest.error !== null ||
      !preflightTest.signalingGatewayReachable ||
      !preflightTest.turnServersReachable
        ? ResultStatus.FAIL
        : ResultStatus.PASS,
    qualityResult: {
      bitrate: QualityScore[bitrate.value.qualityScore],
      jitter: QualityScore[jitter.value.qualityScore],
      latency: QualityScore[latency.value.qualityScore],
      packetLoss: QualityScore[packetLoss.value.qualityScore],
      totalQualityScore: QualityScore[totalQualityScore.value],
    },
  }
}

export const getReport = (): ITestResult => ({
  videoInputTestReport: getVideoInputTestReport(),
  audioInputTestReport: getAudioInputTestReport(),
  audioOutputTestReport: getAudioOutputTestReport(),
  bitrateTestReport: getBitrateTestReport(),
  preflightTestReport: getPreflightTestReport(),
  twilioStatus: getTwilioStatus(),
  results: getResults(),
})
