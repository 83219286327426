<template>
  <div class="spinner" :style="{ width: `${size}px`, height: `${size}px` }">
    <div class="double-bounce1"></div>
    <div class="double-bounce2"></div>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 40,
    },
  },
}
</script>

<style lang="scss" scoped>
.spinner {
  width: 40px;
  height: 40px;

  position: relative;
  margin: 24px auto;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgb(80, 80, 80);
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    transform: scale3d(0, 0, 0);
  }
  50% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale3d(0, 0, 0);
  }
  50% {
    transform: scale3d(1, 1, 1);
  }
}
</style>
