<script setup lang="ts">
import DiagnosticSection from '@/components/video-diagnostic/DiagnosticSection.vue'
import { useAudioTest } from '@/composables/zoom-diagnostic/useAudioTest'
import { useDevices } from '@/composables/video-diagnostic/useDevices'
import { Stage } from '@/types/enums/zoom-diagnostic.enum'
import { watch, computed } from 'vue'

interface AudioTestEmits {
  (e: 'nextStage', value: number): void
}

const props = defineProps({
  stage: {
    type: Number,
    required: true,
  },
})

const emit = defineEmits<AudioTestEmits>()

const { audioInputDevices, audioOutputDevices } = useDevices()
const {
  countPlay,
  readAudioInput,
  playAudio,
  clearAudioDeviceTest,
  setAudioTestResult,
  isAudioOutputTestRunning,
  isAudioInputTestRunning,
  inputLevel,
  isRecording,
  enableRecording,
  selectedAudio,
  selectedSpeaker,
  playbackURI,
  timeCounter,
} = useAudioTest()

let stopWatchIsAudioInputTestRunning

const volumeLevel = computed(() => inputLevel.value)
const audioLevelCss = computed(() => Math.max(0, 100 - volumeLevel.value * 1000))
const disableAll = computed(() => isRecording.value || isAudioOutputTestRunning.value)
const noAudioRedirect = !Audio.prototype.setSinkId

watch(
  () => props.stage,
  async (stage, prevStage) => {
    if (prevStage === Stage.AUDIO_TEST) {
      if (stopWatchIsAudioInputTestRunning) {
        stopWatchIsAudioInputTestRunning()
      }
      clearAudioDeviceTest()
    }
    if (stage === Stage.AUDIO_TEST) {
      readAudioInput()
      stopWatchIsAudioInputTestRunning = watch(
        () => isAudioInputTestRunning.value,
        (value, prevValue) => {
          if (prevValue === true && value === false) {
            enableRecording.value = false
            readAudioInput()
          }
        },
      )
    }
    if (stage === Stage.RESULTS) {
      readAudioInput()
      watch(
        () => isAudioInputTestRunning.value,
        (value, prevValue) => {
          if (prevValue === true && value === false) {
            enableRecording.value = false
            readAudioInput()
          }
        },
      )
    }
  },
)

watch(
  () => audioInputDevices.value,
  () => {
    initSelectedAudioAndSpeaker()
  },
  { deep: true },
)

const handleRecordClick = () => {
  enableRecording.value = true
  readAudioInput()
}

const nextStage = (testResult: boolean) => {
  setAudioTestResult(testResult)
  emit('nextStage', Stage.CONNECTIVITY)
}

const initSelectedAudioAndSpeaker = () => {
  if (!selectedAudio.value) {
    selectedAudio.value = audioInputDevices.value[0].value
  }
  if (!selectedSpeaker.value) {
    selectedSpeaker.value = audioOutputDevices.value[0].value
  }
}
</script>

<template>
  <DiagnosticSection
    :id="Stage.AUDIO_TEST"
    :class="
      stage === Stage.AUDIO_TEST || stage === Stage.RESULTS
        ? 'block'
        : 'hidden md:block opacity-30 pointer-events-none'
    "
  >
    <template #default>
      <div class="row-span-1 text-sm sm:text-base lg:text-sm xl:text-base">
        <h1 class="font-bold text-xl sm:text-4xl lg:text-xl xl:text-4xl">
          {{ $t('zoom-diagnostic.audio-test') }}
        </h1>
        <p class="py-4 sm:py-10 lg:py-4 xl:py-10">
          {{ $t('zoom-diagnostic.audio-test-detail') }}
        </p>
      </div>

      <div
        class="row-span-2 p-6 max-w-sm h-fit bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700"
      >
        <div class="flex justify-between font-bold mb-3">
          <p class="text-sm leading-8">{{ $t('zoom-diagnostic.audio') }}</p>
          <div class="flex">
            <button
              class="audio-record-btn mr-2"
              :class="[!playbackURI && 'active', isRecording && 'recording']"
              type="button"
              :disabled="disableAll"
              @click="handleRecordClick"
            >
              <SvgIcon
                v-if="isRecording"
                class="mr-2"
                :icon="'record'"
                :inherit-fill="true"
                height="16"
              />
              <span>{{
                isRecording
                  ? $tc('zoom-diagnostic.n-second', timeCounter)
                  : $t('zoom-diagnostic.record')
              }}</span>
            </button>
            <button
              class="audio-record-btn"
              :class="[!!playbackURI && 'active']"
              type="button"
              :disabled="!playbackURI || disableAll"
              @click="playAudio"
            >
              <SvgIcon
                v-if="isAudioOutputTestRunning"
                class="mr-2"
                :icon="'speaker'"
                :inherit-fill="true"
                height="16"
              />
              <span>
                {{
                  isAudioOutputTestRunning
                    ? $t('zoom-diagnostic.audio-playing')
                    : $tc('zoom-diagnostic.play-back', countPlay)
                }}
              </span>
            </button>
          </div>
        </div>
        <p class="text-sm font-medium mt-3 text-black">{{ $t('zoom-diagnostic.speaker') }}</p>
        <p v-if="noAudioRedirect">System Default Audio Output</p>
        <SelectOption
          v-else
          v-model="selectedSpeaker"
          :options="audioOutputDevices"
          :disabled="isAudioOutputTestRunning || isRecording"
          class="w-full"
        />
        <p class="text-sm font-bold mt-3">{{ $t('zoom-diagnostic.microphone') }}</p>
        <SelectOption
          v-model="selectedAudio"
          :options="audioInputDevices"
          :disabled="isAudioOutputTestRunning || isRecording"
          class="w-full"
          @update:model-value="readAudioInput"
        />
        <div class="flex">
          <SvgIcon
            v-if="isAudioOutputTestRunning"
            class="mr-2"
            :icon="'speaker'"
            :inherit-fill="true"
            height="24"
          />
          <SvgIcon v-else class="mr-2" :icon="'mic'" :inherit-fill="true" height="24" />
          <div class="audio-bar">
            <div class="audio-level"></div>
            <div class="background"></div>
          </div>
        </div>
      </div>

      <div class="row-span-1 text-sm sm:text-base lg:text-sm xl:text-base">
        <p class="py-6 xl:py-10 font-bold">{{ $t('zoom-diagnostic.audio-sound-good') }}</p>
        <div :class="{ 'opacity-30 pointer-events-none': stage === Stage.RESULTS }" class="flex">
          <button
            class="text-white border-2 rounded-md border-white bg-primary-color py-2 px-4"
            type="button"
            @click="nextStage(true)"
          >
            {{ $t('zoom-diagnostic.test-pass') }}
          </button>
          <button
            class="skip-button rounded-md py-2 px-4 ml-4"
            type="button"
            @click="nextStage(false)"
          >
            {{ $t('zoom-diagnostic.test-fail') }}
          </button>
        </div>
      </div>
    </template>
  </DiagnosticSection>
</template>

<style lang="scss" scoped>
.bg-primary-color {
  background-color: #f67519;
}

.skip-button {
  color: #f67519;
  background-color: transparent;
  &:hover {
    background-color: #f675192a;
    transition: 0.4s;
  }
}

button.bg-white {
  &:hover {
    background-color: #32323209;
    transition: 0.4s;
  }
  &:disabled {
    background-color: inherit;
    color: rgba(0, 0, 0, 0.26);
  }
}

.audio-bar {
  position: relative;
  margin-top: 0.6rem;
  height: 4px;
  flex: 1;
  div {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }

  .audio-level {
    background: #14b053;
    right: calc(v-bind(audioLevelCss) * 1%);
    transition: right 0.1s linear;
  }

  .background {
    right: 0;
    background: #e1e3ea38;
  }
}

.h-fit {
  height: fit-content;
}
button.audio-record-btn {
  display: inline-flex;
  @apply bg-white border py-2 px-3 rounded-md text-xs;
  &:hover:not(:disabled) {
    border-color: #f67519;
    color: #f67519;
    background: rgba(#f67519, 0.15);
  }
  &.active {
    border-color: #f67519;
    color: #f67519;
    &:disabled {
      color: #f67519;
    }
  }
  &.recording {
    color: $danger;
    border-color: $danger;
    &:disabled {
      color: $danger;
    }
  }
}
</style>
