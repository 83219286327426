<script setup lang="ts">
import { getToken } from '@/composables/api/useAuth'
import { useRouter } from 'vue-router'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  exitPath: {
    type: String,
    default: '',
  },
})
const router = useRouter()
const leave = async () => {
  if (getToken()) {
    router.go(-1)
  } else {
    window.location.href = props.exitPath
  }
}
</script>

<template>
  <Popup :title="$t('zoom-diagnostic.disconnect.title')" :open="modelValue" :can-close="false">
    <div class="text-base leave-container">
      {{ $t('zoom-diagnostic.disconnect.message') }}
      <span v-if="!getToken()">{{ exitPath }}</span>
    </div>
    <template #footer>
      <div class="footer-setting">
        <button type="button" class="btn btn-leave bg-red-500" @click="leave">
          {{ $t('zoom-diagnostic.disconnect.leave') }}
        </button>
      </div>
    </template>
  </Popup>
</template>

<style lang="scss" scoped>
.leave-container {
  color: #4a4a4a;
  padding: 1rem;
}

.footer-setting {
  .btn {
    width: 100%;
    font-size: 1.1rem;
    &.btn-leave {
      color: white;
      margin-bottom: 1rem;
    }
  }

  @include media-breakpoint-up-custom(700px) {
    .btn {
      width: 50%;
      &.btn-leave {
        order: 1;
        margin-bottom: 0;
        margin-left: 1rem;
      }
    }
  }
}
</style>
