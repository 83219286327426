import useRoomApi from '@/composables/api/useSpaceApi'
import {
  ICreateCheckupDevices,
  IResults,
  IVideoDiagnosticInterface,
} from '@/types/interfaces/video-diagnostic.interface'
import { reactive, toRefs } from 'vue'
import useClientApi from '../api/useClientApi'
import { getReport } from '@/composables/video-diagnostic/useReport'
import { detectDevice } from '@/helpers/device.helper'
import { ResultStatus } from '@/types/enums/video-diagnostic.enum'
import { useRoute } from 'vue-router'

export const useVideoDiagnostic = () => {
  const state = reactive<IVideoDiagnosticInterface>({
    token: '',
    tokenInstance: undefined,
    note: '',
    debounce: null,
    isLoading: false,
  })

  const { getTokenVideoDiagnostic, createCheckupDevices } = useRoomApi()
  const { getClientIp } = useClientApi()
  const route = useRoute()

  const getToken = async () => {
    const res = await getTokenVideoDiagnostic()
    if (!res.data) return
    state.token = res.data.token
    state.tokenInstance = res.data.tokenInstance
  }

  const getStatus = (payload: IResults): ResultStatus => {
    if (
      payload.videoInputResult === ResultStatus.PASS &&
      payload.audioInputResult === ResultStatus.PASS &&
      payload.audioOutputResult === ResultStatus.PASS
    ) {
      return ResultStatus.PASS
    } else {
      return ResultStatus.FAIL
    }
  }

  const sendReport = async () => {
    clearTimeout(state.debounce)
    state.debounce = setTimeout(async () => {
      state.isLoading = true
      let res = await getClientIp()
      const report = getReport()
      const payload: ICreateCheckupDevices = {
        agent: {
          agent: navigator.userAgent,
          ip: res.data.ip,
        },
        detail: report,
        deviceName: detectDevice(),
        status: getStatus(report.results),
        studentId: parseInt(`${route?.query?.studentId}`),
        type: 'twilio',
        version: 'space_tiger',
        note: state.note ? state.note : undefined,
      }
      res = await createCheckupDevices(payload)
      state.isLoading = true
      window.location.href = `${route?.query?.exitPath}`
    }, 600)
  }

  return { ...toRefs(state), getToken, sendReport }
}
