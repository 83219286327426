import { ClassConstructor, plainToInstance } from 'class-transformer'

export const transformPlain = <T>(tClass: ClassConstructor<T>, data: unknown): T => {
  return plainToInstance(tClass, data, {
    excludeExtraneousValues: true,
  })
}

export const transformPlainArr = <T>(tClass: ClassConstructor<T>, data: unknown[]): T[] => {
  return plainToInstance(tClass, data, {
    excludeExtraneousValues: true,
  })
}

export const transformJson = <T>(tClass: ClassConstructor<T>, data?: string): T => {
  let plain: T
  try {
    plain = data ? JSON.parse(data) : {}
  } catch (error) {
    plain = {} as T
  }
  return plainToInstance(tClass, plain, {
    excludeExtraneousValues: true,
  })
}

export const catchJson = (text: string) => {
  try {
    return JSON.parse(text)
  } catch (error) {
    return {}
  }
}
