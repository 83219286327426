<script setup lang="ts">
import ControlButton from '@/components/room/ControlButton.vue'
import DeviceButtonOption from '@/components/room/DeviceButtonOption.vue'
import useNotify from '@/composables/useNotify'
import { useDeviceStore } from '@/stores'
import { IParticipantCapabilities } from '@/types/interfaces/participant.interface'
import { PropType, ref } from 'vue'

export type DeviceChange = 'mic' | 'cam' | 'audio'
export interface IChangeSetting {
  type: DeviceChange
  deviceId: string
}

interface IShowMessage {
  mic: boolean
  cam: boolean
  audio: boolean
}

interface RoomControlEmits {
  (e: 'toggle-cam', value: boolean): void
  (e: 'toggle-mic', value: boolean): void
  (e: 'toggle-doc', value: boolean): void
  (e: 'toggle-whiteboard', value: boolean): void
  (e: 'toggle-screen-share', value: boolean): void
  (e: 'toggle-chat', value: boolean): void
  (e: 'toggle-sticker', value: boolean): void
  (e: 'toggle-game', value: boolean): void
  (e: 'toggle-help', value: boolean): void
  (e: 'switch-device', value: IChangeSetting): void
}

const props = defineProps({
  nCompartments: {
    type: Number,
    default: 1,
  },
  userPermissions: {
    type: Object as PropType<IParticipantCapabilities>,
    required: true,
  },
  isSmall: {
    type: Boolean,
    default: false,
  },
  cameraEnable: {
    type: Boolean,
    default: false,
  },
  microphoneEnable: {
    type: Boolean,
    default: false,
  },
  documentEnable: {
    type: Boolean,
    default: false,
  },
  whiteboardEnable: {
    type: Boolean,
    default: false,
  },
  screenShareEnable: {
    type: Boolean,
    default: false,
  },
  stickerEnable: {
    type: Boolean,
    default: false,
  },
  chatEnable: {
    type: Boolean,
    default: false,
  },
  gameEnable: {
    type: Boolean,
    default: false,
  },
  screenShareShow: {
    type: Boolean,
    default: true,
  },
  chatNotifyCount: {
    type: Number,
    default: 0,
  },
})
const emit = defineEmits<RoomControlEmits>()
const deviceStore = useDeviceStore()
const notification = useNotify()

const showWarning = ref<IShowMessage>({ mic: false, cam: false, audio: false })

const triggerShowWarning = (type: DeviceChange, withMessage?: string) => {
  if (withMessage) return (showWarning.value[type] = true)
  else showWarning.value[type] = false
}

const onDeviceChange = (deviceId: string, type: DeviceChange) => {
  emit('switch-device', { type, deviceId })
}

const toggleCamera = () => {
  if (deviceStore.allowedCamera) {
    emit('toggle-cam', !props.cameraEnable)
  } else {
    notification.warning({
      text: deviceStore.videoErrorMsg || 'Please allow camera',
      component: 'svg-icon',
      icon: 'warning',
    })
  }
}
const toggleMicrophone = () => {
  if (deviceStore.allowedMicrophone) {
    emit('toggle-mic', !props.microphoneEnable)
  } else {
    notification.warning({
      text: deviceStore.micErrorMsg || 'Please allow microphone',
      component: 'svg-icon',
      icon: 'warning',
    })
  }
}
</script>

<template>
  <div class="room-footer" :class="{ '-small': isSmall }">
    <div class="left">
      <div
        v-if="userPermissions?.publishableTracks?.cam"
        :class="{ 'ext-gap': !deviceStore.videoErrorMsg && deviceStore.videoInputDevices.length }"
      >
        <Popper
          :show="showWarning.cam"
          :hover="true"
          :arrow="true"
          :content="deviceStore.videoErrorMsg"
          @mouseover="triggerShowWarning('cam', deviceStore.videoErrorMsg)"
          @mouseleave="showWarning.cam = false"
        >
          <DeviceButtonOption
            :value="deviceStore.maskSelectedVideo"
            :is-enable="cameraEnable"
            :options="deviceStore.videoErrorMsg ? [] : deviceStore.videoInputDevices"
            @change-device="onDeviceChange($event, 'cam')"
          >
            <ControlButton
              name="Cam"
              icon="cam"
              icon-disabled="cam-disabled"
              :is-ready="true"
              :disabled="!cameraEnable || !deviceStore.allowedCamera"
              is-danger-disabled
              :is-warning="!!deviceStore.videoErrorMsg"
              @click="toggleCamera"
            /> </DeviceButtonOption
        ></Popper>
      </div>
      <div
        v-if="userPermissions?.publishableTracks?.mic"
        :class="{ 'ext-gap': !deviceStore.micErrorMsg && deviceStore.audioInputDevices.length }"
      >
        <Popper
          :show="showWarning.mic"
          :hover="true"
          :arrow="true"
          :content="deviceStore.micErrorMsg"
          @mouseover="triggerShowWarning('mic', deviceStore.micErrorMsg)"
          @mouseleave="showWarning.mic = false"
        >
          <DeviceButtonOption
            :value="deviceStore.maskSelectedAudio"
            :is-enable="microphoneEnable"
            :options="deviceStore.micErrorMsg ? [] : deviceStore.audioInputDevices"
            @change-device="onDeviceChange($event, 'mic')"
          >
            <ControlButton
              name="Mic"
              icon="mic"
              icon-disabled="mic-disabled"
              :disabled="!microphoneEnable || !deviceStore.allowedMicrophone"
              is-danger-disabled
              :is-warning="!!deviceStore.micErrorMsg"
              @click="toggleMicrophone"
            />
          </DeviceButtonOption>
        </Popper>
      </div>
      <div
        v-if="deviceStore.canSetSpeaker"
        :class="{
          'ext-gap': !deviceStore.speakerErrorMsg && deviceStore.audioOutputDevices.length,
        }"
      >
        <Popper
          :show="showWarning.audio"
          :hover="true"
          :arrow="true"
          :content="deviceStore.speakerErrorMsg"
          @mouseover="triggerShowWarning('audio', deviceStore.speakerErrorMsg)"
          @mouseleave="showWarning.audio = false"
        >
          <DeviceButtonOption
            :value="deviceStore.maskSelectedSpeaker"
            :options="deviceStore.speakerErrorMsg ? [] : deviceStore.audioOutputDevices"
            @change-device="onDeviceChange($event, 'audio')"
          >
            <ControlButton
              name="Audio"
              icon="speaker"
              :disabled="false"
              :is-warning="!!deviceStore.speakerErrorMsg"
              is-danger-disabled
            /> </DeviceButtonOption
        ></Popper>
      </div>
      <ControlButton
        v-if="userPermissions?.doc"
        name="Doc"
        icon="doc"
        :disabled="!documentEnable"
        @click="emit('toggle-doc', !documentEnable)"
      />
      <ControlButton
        v-if="userPermissions?.publishableTracks?.screenShare"
        v-show="screenShareShow"
        name="Screen"
        icon="screen"
        :disabled="!screenShareEnable"
        @click="emit('toggle-screen-share', !screenShareEnable)"
      />
      <ControlButton
        v-if="userPermissions?.whiteBoard"
        name="Board"
        icon="board"
        :disabled="!whiteboardEnable"
        @click="emit('toggle-whiteboard', !whiteboardEnable)"
      />
    </div>
    <div class="right">
      <ControlButton
        v-if="userPermissions?.engder"
        name="Game"
        icon="game"
        :disabled="!gameEnable"
        :is-ready="nCompartments == 1"
        @click="emit('toggle-game', !gameEnable)"
      />
      <ControlButton
        v-if="userPermissions?.sticker"
        name="Sticker"
        icon="sticker"
        :disabled="!stickerEnable"
        @click="emit('toggle-sticker', !stickerEnable)"
      />

      <div class="chat-wrapper">
        <ControlButton
          v-if="userPermissions?.chat"
          name="Chat"
          icon="chat"
          :disabled="!chatEnable"
          :badge="chatNotifyCount > 0 ? `${chatNotifyCount}` : ''"
          @click="emit('toggle-chat', !chatEnable)"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(.popper) {
  font-size: 0.8rem;
}

.room-footer {
  margin-top: auto;
}

.room-footer {
  display: flex;
  overflow-x: auto;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  @include font-weight(regular);
  font-size: 1.3rem;

  &::-webkit-scrollbar {
    display: none;
  }

  .left,
  .right {
    display: flex;
  }

  .left {
    > * {
      margin-right: 8px;
      &.ext-gap {
        margin-right: 20px;
      }
    }
  }

  .right {
    > *:not(:first-child) {
      margin-left: 8px;
    }
  }

  .chat-wrapper {
    .message-preview-position {
      position: absolute;
      right: 10px;
      bottom: calc(var(--control-height, 66px) + 20px);
      z-index: $z-index-message-preview;

      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
}

.room-footer {
  &.-rounded-minimal .left,
  &.-rounded-minimal .right {
    .control-item {
      width: 44px;
      height: 44px;
      border-radius: 12px;

      ::v-deep(.control-text) {
        display: none !important;
      }
    }
  }

  &.-small .left,
  &.-small .right {
    .control-item {
      width: 50px;
      height: 50px;

      ::v-deep(.control-text) {
        display: none !important;
      }
    }
  }
}
</style>
