<script setup lang="ts">
defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
})

const leave = () => {
  window.location.href = 'https://app.globish.co.th/'
}
</script>

<template>
  <Popup :title="$t('zoom-diagnostic.access-denied.title')" :open="modelValue" :can-close="false">
    <div class="text-base leave-container">
      {{ $t('zoom-diagnostic.access-denied.message') }}
    </div>
    <template #footer>
      <div class="footer-setting">
        <button type="button" class="btn btn-leave bg-red-500" @click="leave">
          {{ $t('zoom-diagnostic.access-denied.leave') }}
        </button>
      </div>
    </template>
  </Popup>
</template>

<style lang="scss" scoped>
.leave-container {
  color: #4a4a4a;
  padding: 1rem;
}

.footer-setting {
  .btn {
    width: 100%;
    font-size: 1.1rem;
    &.btn-leave {
      color: white;
      margin-bottom: 1rem;
    }
  }

  @include media-breakpoint-up-custom(700px) {
    .btn {
      width: 50%;
      &.btn-leave {
        order: 1;
        margin-bottom: 0;
        margin-left: 1rem;
      }
    }
  }
}
</style>
