import * as RoomLayoutState from '../room/useRoomLayout'
import { computed, reactive, ref, toRefs, watch, watchEffect } from 'vue'
import useAuth from '../api/useAuth'
import { useDeviceStore, useRoomStore } from '@/stores'
import { Engine } from '@/types/enums/room.enum'
import {
  IEventScreenShare,
  IParticipantDisconnectedSocket,
  IRoomByEngineProps,
} from '@/types/interfaces/room.interface'
import AuthState from '@/services/auth-state'
import { ROOM_SPACE_URL } from '../api'
import { IParticipantInfo } from '@/types/interfaces/participant.interface'
import { useWindowSize } from '@vueuse/core'
import { engineAfter } from './useRoomState'
import useRoomNotify from './useRoomNotify'
import useSocketEvent, { participantsInSocket } from './useSocketEvent'

interface IUseRoomByEngine {
  forceFocusParticipantId?: string
  popoutCompartmentId?: string
  focusCompartmentId?: string
  allParticipantInRoom: IParticipantInfo[]
}

export default function useRoomByEngine() {
  /**
   * Composables
   */

  const { width } = useWindowSize()
  const { getAccessInfo } = useAuth()
  const device = useDeviceStore()
  const roomNotify = useRoomNotify()
  const { socket } = useSocketEvent()
  const roomStore = useRoomStore()

  /**
   * State
   */
  const isLoading = ref(false)
  const isOpenPopup = ref(false)
  const state = reactive<IUseRoomByEngine>({
    forceFocusParticipantId: undefined,
    popoutCompartmentId: undefined,
    focusCompartmentId: undefined,
    allParticipantInRoom: [],
  })

  /**
   * Computed
   */

  const isIgnoreFocus = computed(() => roomStore.currentEngine === Engine.ZOOM)
  const isIgnorePopout = computed(() => roomStore.currentEngine === Engine.ZOOM)

  const nonFocusParticipants = computed(() =>
    state.allParticipantInRoom.filter(
      (participant) =>
        !participant.isInvisible &&
        (isIgnoreFocus.value || state.focusCompartmentId !== participant.id) &&
        (isIgnorePopout.value || participant.id !== state.popoutCompartmentId),
    ),
  )

  const focusParticipant = computed(() =>
    state.allParticipantInRoom.find(
      (participant) => !participant.isInvisible && state.focusCompartmentId === participant.id,
    ),
  )
  const popoutParticipant = computed(() =>
    state.allParticipantInRoom.find(
      (participant) => !participant.isInvisible && participant.id === state.popoutCompartmentId,
    ),
  )
  const isForceFocusParticipant = computed(() => width.value < 700)

  const canPinParticipant = computed(() => state.allParticipantInRoom.length > 1)
  const canPopoutParticipant = computed(
    () => nonFocusParticipants.value.length > 1 && width.value > 700,
  )

  const roomProps = computed(
    () =>
      ({
        nCompartments: nonFocusParticipants.value.length,
        isFocused: !!focusParticipant.value,
        roomInfo: roomStore.room,
        localParticipant: roomStore.localParticipantInfo,
        participantsInfo: roomStore.participants,
        isLoading: isLoading.value,
        isCoachRole: !!roomStore.isCoachRole,
        isStudentRole: !!roomStore.isStudentRole,
        token: roomStore.token,
        allParticipantInRoom: state.allParticipantInRoom,
        canPinParticipant: canPinParticipant.value,
        canPopoutParticipant: canPopoutParticipant.value,
        nonFocusParticipants: nonFocusParticipants.value,
        focusParticipant: focusParticipant.value,
        popoutParticipant: popoutParticipant.value,
        forceFocusParticipantId: state.forceFocusParticipantId,
        isWhereby: roomStore.currentEngine === Engine.WHEREBY,
        engine: roomStore.currentEngine,
        isStartShareScreenWithVideoElement: false,
        mediaStream: undefined,
        canMoveRoomByCoach: state.allParticipantInRoom.length > 1,
        isTestRoom: roomStore.requestTestDiagnostic,
      } as IRoomByEngineProps),
  )
  watch(
    () => [
      device.permissionError,
      device.someDeviceIsGone,
      roomStore.localParticipantInfo?.isInvisible,
    ],
    () => {
      if (!roomStore.localParticipant?.id) return
      if (roomStore.currentEngine === Engine.WHEREBY) return
      if (
        !roomStore.localParticipantInfo?.isInvisible &&
        (device.permissionError || device.someDeviceIsGone)
      ) {
        openAlertDevice()
      }
    },
    { deep: true },
  )

  watch(
    () => [device.allowedCamera, device.allowedMicrophone],
    () => {
      openAlertDevice()
    },
  )

  /**
   * Method
   */
  const openAlertDevice = () => {
    isOpenPopup.value = true
  }

  const connectRoomByEngine = async (engine: Engine) => {
    if (!roomStore.token || !roomStore.room || !roomStore.localParticipant) return
    if (roomStore.localParticipantInfo && !roomStore.localParticipantInfo?.isInvisible) {
      state.allParticipantInRoom.push(roomStore.localParticipantInfo)
    }
    AuthState.addCountRoomTab()
    switch (engine) {
      case Engine.TWILIO:
        device.clearLocalTracks()
        return

      case Engine.TOKBOX:
        window.location.href = `${ROOM_SPACE_URL}/join?token=${AuthState.getRoomToken()}&tiger`
        return

      case Engine.ZOOM:
        break

      default:
        break
    }
  }

  const addParticipantInRoom = (email: string) => {
    const participantExist = state.allParticipantInRoom.find(
      (participant) => participant.email === email,
    )
    if (participantExist) return
    const participantConnected = roomStore.participants.find(
      (participant) => participant.email === email,
    )
    if (!participantConnected || participantConnected.isInvisible) return
    state.allParticipantInRoom.push(participantConnected)
    return participantConnected
  }

  const onParticipantConnect = (email: string) => {
    const participantConnected = addParticipantInRoom(email)
    if (participantConnected && !participantConnected.isInvisible) {
      roomNotify.roomParticipantJoined(participantConnected)
    }
  }

  const onParticipantDisconnect = (email: string) => {
    const participantIndex = state.allParticipantInRoom.findIndex(
      (participant) => participant.email === email,
    )
    if (participantIndex === -1) return
    const participantDisconnected = state.allParticipantInRoom[participantIndex]
    if (participantDisconnected.email === roomStore.localParticipantInfo?.email) return
    state.allParticipantInRoom.splice(participantIndex, 1)
    if (participantDisconnected && !participantDisconnected.isInvisible) {
      roomNotify.roomParticipantDisconnect(participantDisconnected)
    }
  }

  const onParticipantShareScreen = (value: IEventScreenShare) => {
    const participantShared = roomStore.participants.find(
      (participant) => participant.email === value.email || participant.name === value.email,
    )
    if (!participantShared) return
    roomNotify.toggleScreenShare(value.isEnable, participantShared)
  }

  const onEngineDisconnect = () => {
    socket.emit('engineDisconnect', {
      currentEngine: roomStore.currentEngine,
      engineAfter: engineAfter.value,
    })
  }

  const removeFocusCompartment = () => {
    state.focusCompartmentId = undefined
  }

  const unpopoutCompartment = () => {
    state.popoutCompartmentId = undefined
  }

  const addFocusCompartment = (compartmentId?: string) => {
    if (state.popoutCompartmentId === compartmentId) {
      unpopoutCompartment()
    }
    state.focusCompartmentId = compartmentId
    if (nonFocusParticipants.value.length === 0) {
      unpopoutCompartment()
    }
  }

  const popoutCompartment = (compartmentId?: string) => {
    if (state.focusCompartmentId === compartmentId) {
      removeFocusCompartment()
    }
    state.popoutCompartmentId = compartmentId
    if (nonFocusParticipants.value.length === 0) {
      unpopoutCompartment()
    }
  }

  const joinRoom = async () => {
    isLoading.value = true
    if (!socket.connected) {
      socket.connect()
    }
    const { roomId, participantId, zoomUserId } = getAccessInfo()
    if (roomId && participantId) {
      // if (roomStore.currentEngine !== Engine.WHEREBY) {
      await roomStore.connect()
      // }
    }
    await connectRoomByEngine(roomStore.currentEngine)
    socket.emit('updateParticipantForTimer', {
      userAgent: navigator.userAgent,
      participantId: roomStore.localParticipant?.email,
      invisible: roomStore.localParticipantInfo?.isInvisible,
      isCoachRole: roomStore.localParticipantInfo?.isCoachRole,
      zoomUserId,
    })
    socket.emit('updateTestDeviceState', {
      roomId,
      email: roomStore.localParticipant?.email,
      invisible: roomStore.localParticipantInfo?.isInvisible,
      isInRoom: true,
    })
    socket.on('participantDisconnected', (data: IParticipantDisconnectedSocket) => {
      const index = participantsInSocket.value.findIndex(
        (pa) => pa.participantId === data.participantId,
      )
      if (index !== -1) {
        participantsInSocket.value.splice(index, 1)
      }
      onParticipantDisconnect(data.participantId)
    })
    isLoading.value = false
  }

  watchEffect(() => {
    if (isForceFocusParticipant.value) {
      unpopoutCompartment()
    }
    if (state.allParticipantInRoom.length > 1) {
      if (isForceFocusParticipant.value && state.focusCompartmentId === undefined) {
        state.forceFocusParticipantId =
          state.allParticipantInRoom[state.allParticipantInRoom.length - 1].id
        addFocusCompartment(state.forceFocusParticipantId)
      } else if (
        isForceFocusParticipant.value &&
        state.focusCompartmentId !== state.forceFocusParticipantId
      ) {
        state.forceFocusParticipantId = undefined
      }

      if (
        !isForceFocusParticipant.value &&
        state.forceFocusParticipantId === state.focusCompartmentId
      ) {
        removeFocusCompartment()
        state.forceFocusParticipantId = undefined
      }
    } else {
      removeFocusCompartment()
      unpopoutCompartment()
      state.forceFocusParticipantId = undefined
    }

    if (RoomLayoutState.haveToolMainCompartment.value) {
      removeFocusCompartment()
      state.forceFocusParticipantId = undefined
    }
  })

  watchEffect(() => {
    if (
      RoomLayoutState.documentEnable.value ||
      RoomLayoutState.screenShareEnable.value ||
      RoomLayoutState.whiteboardEnable.value ||
      RoomLayoutState.gameEnable.value
    ) {
      removeFocusCompartment()
    }
  })

  return {
    ...toRefs(state),
    roomProps,
    isLoading,
    isOpenPopup,

    // Computed
    focusParticipant,
    nonFocusParticipants,
    popoutParticipant,
    canPinParticipant,
    canPopoutParticipant,

    // Methods
    addParticipantInRoom,
    joinRoom,
    openAlertDevice,
    onParticipantConnect,
    onParticipantDisconnect,
    onEngineDisconnect,
    onParticipantShareScreen,
    addFocusCompartment,
    popoutCompartment,
  }
}
