import { canUseEngineZoom, detectUserAgent, validBrowserWithDevice } from '@/helpers/detect-device'
import { computed, ref } from 'vue'
import TwilioVideo from 'twilio-video'
import { Engine } from '@/types/enums/room.enum'
import { IResult } from 'ua-parser-js'
import { isIOSMobile } from '@/helpers/zoom-device.helper'
import AuthState from '@/services/auth-state'

const useBrowserSupport = (engine: Engine, roomUrl?: string) => {
  const currentDevice = ref<IResult>(detectUserAgent())
  const isOpen = ref<boolean>(false)
  const isCopied = ref<boolean>(false)
  const defaultRoomUrl = ref<string>(location.origin + '/?token=' + AuthState.getRoomToken())
  const redirectLink = computed(() => roomUrl || defaultRoomUrl.value)
  const linkWithoutHttp = computed(() => redirectLink.value.replace(/^https?:\/\//, ''))
  const isMobile = computed(() => {
    const deviceCanUse = ['mobile', 'tablet'] || isIOSMobile(currentDevice.value?.ua)
    return deviceCanUse.includes(currentDevice.value?.device.type || '')
  })
  const isSupported = computed(() => {
    if (engine === Engine.TWILIO)
      return validBrowserWithDevice(currentDevice.value.ua) || TwilioVideo.isSupported
    else if (engine === Engine.ZOOM) return canUseEngineZoom()
    return true
  })

  const isSuggestSafari = computed(() => {
    if (engine === Engine.ZOOM) {
      return false
    } else {
      return (
        currentDevice.value?.os.name === 'iOS' &&
        isMobile.value &&
        currentDevice.value?.browser.name?.includes('Safari')
      )
    }
  })

  const isSuggestChromeOnIOS = computed(() => {
    if (engine === Engine.ZOOM) {
      return (
        isIOSMobile(currentDevice.value?.ua) ||
        currentDevice.value?.browser.name?.includes('Safari')
      )
    } else {
      return false
    }
  })

  const chromeLink = computed(() =>
    isMobile.value ? `googlechrome://${linkWithoutHttp.value}` : 'https://www.google.com/chrome',
  )

  /**
   * Methods
   */
  const open = () => {
    isOpen.value = true
  }

  const close = () => {
    isOpen.value = false
    sessionStorage.setItem('ignore_browser', 'true')
  }

  const detectSupportToOpen = () => {
    const sessionValue = sessionStorage.getItem('ignore_browser')
    if (!isSupported.value && (!sessionValue || sessionValue !== 'true')) {
      open()
    }
  }

  const copyRoomUrl = () => {
    // Copy the text inside the text field
    navigator.clipboard.writeText(redirectLink.value)
    isCopied.value = true
  }

  return {
    currentDevice,
    isCopied,
    isMobile,
    isSuggestChromeOnIOS,
    isSuggestSafari,
    isSupported,
    isOpen,
    close,
    detectSupportToOpen,
    open,
    redirectLink,
    linkWithoutHttp,
    copyRoomUrl,
    chromeLink,
  }
}

export default useBrowserSupport
