<script setup lang="ts">
import { ref, watch, onMounted } from 'vue'
import moment from 'moment-timezone'
import { COACH_ATTEND_MINUTE } from '@/data/room'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  isAttend: {
    type: Boolean,
    default: false,
  },
  startTime: {
    type: [String, Date],
    default: '',
  },
  exitPath: {
    type: String,
    default: '',
  },
})

const isOpen = ref(false)
const { t } = useI18n()

const close = () => {
  isOpen.value = false
}

const confirm = () => {
  close()
  if (props.exitPath) {
    window.location.href = props.exitPath
  }
}

/**
 * Check class time after 10 min when coach attend is show coach absent popup
 */
const checkTime = (start: moment.Moment) => {
  const currentTime = moment()
  const isBetween = currentTime.isSameOrAfter(start)
  return isBetween
}

const countdown = () => {
  /**
   * if coach attend do nothing
   */
  if (props.isAttend) return
  const timeAbsent = moment(props.startTime).add(COACH_ATTEND_MINUTE.ABSENT, 'minute')
  const isBefore = moment().isBefore(timeAbsent)
  /**
   * if student attend before time of coach absent
   * isBefore is true and countdown to show message
   */
  if (isBefore) {
    /**
     * if student attend before time of coach absent
     * ex. classtime 10.00 timeAbsent -> 10.10
     */
    const setCountdown = setInterval(() => {
      if (checkTime(timeAbsent)) {
        isOpen.value = !props.isAttend
      }
      if (isOpen.value) {
        clearInterval(setCountdown)
      }
    }, 1000)
  } else {
    isOpen.value = true
  }
}

watch(
  () => props.isAttend,
  () => {
    if (props.isAttend) {
      close()
    }
  },
)

onMounted(() => {
  countdown()
})
</script>

<template>
  <Popup v-if="startTime" :title="t('coach-attend.absent.title')" :open="isOpen" @close="close">
    <span class="text-base text-content py-3">{{ t('coach-attend.absent.content') }}</span>
    <template #footer>
      <div class="flex justify-center">
        <button
          type="button"
          class="btn btn-primary text-white text-base w-full ml-2"
          @click="confirm"
        >
          {{ $t('coach-attend.absent.confirm') }}
        </button>
      </div>
    </template>
  </Popup>
</template>

<style lang="scss" scoped>
.text-content {
  color: #4a4a4a;
}
</style>
