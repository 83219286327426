<script setup lang="ts">
import { DocumentData } from 'firebase/firestore'
import { computed, defineAsyncComponent, PropType, ref } from 'vue'
import * as moment from 'moment-timezone'
import SvgIcon from '../common/SvgIcon.vue'
import { downloadFile } from '@/helpers/link.helper'

const FileMessage = defineAsyncComponent(() => import('@/components/chatbox/FileMessage.vue'))
const ImageMessage = defineAsyncComponent(() => import('@/components/chatbox/ImageMessage.vue'))
const TextMessage = defineAsyncComponent(() => import('@/components/chatbox/TextMessage.vue'))

const props = defineProps({
  msg: {
    type: Object as PropType<DocumentData>,
    default: () => {
      return {}
    },
  },
  isMySelf: {
    type: Boolean,
    default: false,
  },
  isSending: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['unsend'])

const isHoverMessage = ref(false)
const messageType = computed(() => {
  if (props.msg.type === 'file') return 'file'
  else if (props.msg.type === 'image') return 'image'
  else return 'text'
})
const messageComponent = computed(() => {
  if (messageType.value === 'file') return FileMessage
  else if (messageType.value === 'image') return ImageMessage
  else return TextMessage
})

const timeFormat = (seconds: number) => {
  // const tz = currentUser.value?.timezone || 'Asia/Bangkok'
  const tz = moment.tz.guess()
  return moment.tz(seconds * 1000, tz).format('h:mm A')
}

const unsendMessage = () => {
  emit('unsend')
}

const downloadImage = () => downloadFile(props.msg.message, props.msg.file_url)
</script>

<template>
  <div class="message" :class="[isMySelf && 'my-self']" @mouseleave="isHoverMessage = false">
    <component
      :is="messageComponent"
      :url="msg.file_url"
      :message="msg.message"
      class="content"
      :class="[messageType === 'image' && 'img-content', isSending && '-loading']"
      @mouseover="isHoverMessage = true"
    ></component>

    <div class="message-detail" :class="[isHoverMessage && isMySelf && 'hidden']">
      <div
        v-if="messageType === 'image'"
        class="download-img _pointer-on-hover"
        @click="downloadImage"
      >
        <SvgIcon icon="download" height="20" inherit-fill />
      </div>
      <div v-if="isMySelf && msg.read_by && msg.read_by.length > 0">
        Read {{ msg.read_by.length }}
      </div>
      <div v-if="msg.sent_at?.seconds">{{ timeFormat(msg.sent_at?.seconds) }}</div>
    </div>
    <div v-if="isMySelf" class="unsend-msg" :class="[isHoverMessage && 'show-unsend']">
      <div
        v-if="messageType === 'image'"
        class="download-img _pointer-on-hover"
        @click="downloadImage"
      >
        <SvgIcon icon="download" height="20" inherit-fill />
      </div>
      <p @click="unsendMessage">Unsend</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.message {
  width: 100%;
  display: flex;
  margin-bottom: 5px;
  position: relative;

  .content {
    max-width: 70%;
    background-color: var(--bg-chat-msg);
    border-radius: 12px;
    border-top-left-radius: 0;
    font-size: 0.9rem;
    color: var(--text-chat);

    display: inline-block;
    max-width: 70%;
    padding: 10px 1rem;
    /*-ms-word-wrap: normal;*/
    word-wrap: break-word;
    &.img-content {
      border-radius: 12px !important;
      padding: 0;
    }
  }

  .message-detail,
  .unsend-msg {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    font-size: 0.75rem;
    color: #6a6a6a;
    padding: 0 5px;
  }
  .unsend-msg {
    display: none;
    cursor: pointer;
    &.show-unsend {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      p:hover {
        text-decoration: underline;
      }
    }
  }
  .message-detail.hidden {
    display: none;
  }
  .download-img {
    margin: 8px 0;
  }
  &.my-self {
    flex-direction: row-reverse;
    justify-content: flex-start;
    .content {
      border-radius: 12px;
      border-top-right-radius: 0;
      background-color: var(--primary-color);
      color: white;
      cursor: pointer;
    }
    .show-unsend,
    .message-detail {
      align-items: flex-end;
    }

    .-loading {
      animation: shimmer 2s infinite linear;
      background: linear-gradient(
          -60deg,
          var(--bg-chat-msg) 30%,
          var(--loading-msg),
          var(--bg-chat-msg) 70%
        )
        right/300% 100%;
      background-size: 100px 100%;

      @keyframes fullView {
        100% {
          width: 100%;
        }
      }

      @keyframes shimmer {
        0% {
          background-position: -100px 0;
        }
        100% {
          background-position: 100px 0;
        }
      }
    }
  }
}
</style>

<style lang="scss">
:root {
  --loading-msg: #f9f9f9;
}
body.dark {
  --loading-msg: #4a4a4a;
}
</style>
