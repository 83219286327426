import { Engine } from '@/types/enums/room.enum'
import { ITab } from '@/types/interfaces/common.interface'
import { computed, ref } from 'vue'

export const screenShareEnable = ref<boolean>(false)
export const screenShareShow = ref<boolean>(true)
export const documentEnable = ref<boolean>(false)
export const whiteboardEnable = ref<boolean>(false)
export const gameEnable = ref<boolean>(false)
export const chatEnable = ref<boolean>(false)
export const stickerEnable = ref<boolean>(false)
export const helpEnable = ref<boolean>(false)

export const focusCompartmentId = ref<string | undefined>(undefined)
export const popoutCompartmentId = ref<string | undefined>(undefined)
export const currentTab = ref<string>('')

export const isOpenDeviceSetting = ref<boolean>(false)
export const isOpenReportProblem = ref<boolean>(false)
export const isOpenChangeVideoEngine = ref<boolean>(false)
export const changeToEngine = ref<Engine>()
export const forceDisconnect = ref<boolean>(false)

export const haveToolMainCompartment = computed(
  () =>
    documentEnable.value || screenShareEnable.value || whiteboardEnable.value || gameEnable.value,
)
export const haveMainCompartment = computed(
  () => !!focusCompartmentId.value || haveToolMainCompartment.value,
)
const documentTab: ITab = { label: 'Document', value: 'document' }
const screenShareTab: ITab = { label: 'Screenshare', value: 'screenShare' }
const whiteboardTab: ITab = { label: 'Whiteboard', value: 'whiteboard' }
const engderTab: ITab = { label: 'Game', value: 'game' }

export const mainTabs = computed<ITab[]>(() => {
  const tabs: ITab[] = []
  if (documentEnable.value) tabs.push(documentTab)
  if (screenShareEnable.value) tabs.push(screenShareTab)
  if (whiteboardEnable.value) tabs.push(whiteboardTab)
  if (gameEnable.value) tabs.push(engderTab)
  return tabs
})

/**
 *
 * Methods
 */

export const changeCurrentTab = (tabValue?: string) => {
  currentTab.value = tabValue || ''
  if (!currentTab.value) {
    currentTab.value = mainTabs.value.length ? mainTabs.value[0].value : ''
  }
}
