<script setup lang="ts">
import { ref, computed } from 'vue'
import ReportProblemForm from '@/components/room/ReportProblemForm.vue'
import useSpaceApi from '@/composables/api/useSpaceApi'

interface ReportProblemPopupEmits {
  (e: 'update:modelValue', value: boolean): void
}

defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits<ReportProblemPopupEmits>()
const { saveLogsReport } = useSpaceApi()
const other = ref('')
const checkedIssues = ref([])
const reportProblemPayload = computed(() => [
  ...checkedIssues.value,
  ...(other.value.trim() !== '' ? [`[99] ${other.value.trim()}`] : []),
])
const disabled = computed(() => (reportProblemPayload.value.length === 0 ? true : false))
const submit = async () => {
  emit('update:modelValue', false)
  await saveLogsReport(reportProblemPayload.value)
  checkedIssues.value = []
}
const close = () => {
  emit('update:modelValue', false)
  checkedIssues.value = []
}
</script>

<template>
  <Popup title="Report Problem" :open="modelValue" width="500" @close="close">
    <div class="text-base">
      <span>
        Please select all issues that apply to your case. To help us diagnose the problem, the
        current room's state will be collected and sent along with this report.
      </span>
      <ReportProblemForm v-model="checkedIssues" v-model:other="other"></ReportProblemForm>
    </div>
    <template #footer>
      <div class="footer-setting">
        <button :disabled="disabled" type="button" class="btn btn-primary ml-3" @click="submit">
          Submit
        </button>

        <button type="button" class="btn btn-tertiary" @click="close">Cancel</button>
      </div>
    </template>
  </Popup>
</template>

<style lang="scss" scoped>
.text-base {
  color: #4a4a4a;
}
.btn {
  font-size: 1.1rem;
}
</style>
