import { ref } from 'vue'
import { spaceSocket } from './socket'
import { IParticipantConnectedSocket, IRoomStateSocket } from '@/types/interfaces/room.interface'
import AuthState from '@/services/auth-state'
import { forceDisconnect } from './useRoomLayout'
import { multipleSessions, multipleTabs } from '../twilio/useTwilioState'
import { useRouter } from 'vue-router'
import { useRoomStore } from '@/stores'
import useAuth from '../api/useAuth'
import UAParser from 'ua-parser-js'
import { SocketHelpEmit } from './useHelpSystem'
import { SolveAction } from '@/types/enums/help.enum'
import useGChatLog from '../api/useGChatLog'

export const socketDisconnect = ref<boolean>(false)
export const isCoachAttend = ref<boolean>(false)
export const roomSocketState = ref<IRoomStateSocket>()
export const participantsInSocket = ref<IParticipantConnectedSocket[]>([])

export default function useSocketEvent() {
  const { getAccessInfo } = useAuth()
  const router = useRouter()
  const roomStore = useRoomStore()
  const { sendSOSForChangeRoom } = useGChatLog()
  const joinRoomSocket = () => {
    const { roomId, participantId, zoomUserId } = getAccessInfo()
    const result = new UAParser().getResult()
    spaceSocket.emit('joinRoom', {
      roomId,
      participantId,
      zoomUserId,
      isMobile: ['mobile', 'tablet'].includes(result.device.type || ''),
    })
  }

  const sendHelpRequest = (event: SocketHelpEmit) => {
    if (event.solution === SolveAction.MOVE_ROOM) {
      spaceSocket?.emit('helpAction', event)
    }
    sendSOSForChangeRoom(event.mainAction)
  }
  spaceSocket.on('connect', () => {
    joinRoomSocket()
  })
  spaceSocket.on('connect_error', (err) => {
    console.error(`connect_error due to ${err?.message}`)
  })

  spaceSocket.on('disconnect', () => {
    socketDisconnect.value = true
  })

  // for first time join room
  spaceSocket?.on('roomState', (data: IRoomStateSocket) => {
    const coachAttend = data.attendanceRecords?.find((attendanceRec) => {
      return attendanceRec.action === 'join' && attendanceRec.isCoachRole
    })
    isCoachAttend.value = !!coachAttend
    roomSocketState.value = data
    participantsInSocket.value =
      data.participants?.map((pa) => ({
        userAgent: '',
        participantId: pa.participantId,
        isCoachRole: pa.isCoachRole,
        zoomUserId: pa.zoomUserId,
      })) || []
  })

  spaceSocket?.on('participantConnected', (data: IParticipantConnectedSocket) => {
    const { userAgent, isCoachRole: isCoachConnected, participantId } = data
    const localParticipantEmail = roomStore.localParticipant.email
    const isSameUser = participantId === localParticipantEmail
    const index = participantsInSocket.value?.findIndex((p) => p.participantId === participantId)
    if (index >= 0) {
      participantsInSocket.value[index] = data
    } else {
      participantsInSocket.value.push(data)
    }
    if (isSameUser) {
      forceDisconnect.value = true
      const nTab = AuthState.getCountRoomTab() || '1'
      if (navigator.userAgent === userAgent && Number(nTab) > 1) {
        multipleTabs.value = true
        AuthState.removeCountRoomTab()
        router.replace({ path: 'end' })
      } else {
        multipleSessions.value = true
        roomStore.isMultipleSessionDetected = true
      }
      spaceSocket?.disconnect()
    } else {
      // for check coach attend after participant connect
      if (!isCoachAttend.value) {
        isCoachAttend.value = !!isCoachConnected
      }
    }
  })

  return { joinRoomSocket, socket: spaceSocket, sendHelpRequest }
}
