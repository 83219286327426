<script setup lang="ts"></script>

<template>
  <Toast />
  <router-view />
</template>

<style lang="scss">
#app {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
