export enum LogDeviceProblemAction {
  START_AUDIO_ZOOM = 'start-audio-zoom',
  START_VIDEO_ZOOM = 'start-video-zoom',
  STOP_VIDEO_ZOOM = 'stop-video-zoom',
  GET_DEVICE_OPTION = 'get-devices',
  SWITCH_SPEAKER = 'switch-speaker',
  SWITCH_MIC = 'switch-microphone',
  SWITCH_CAM = 'switch-camera',
  STOP_AUDIO = 'stop-audio',
  UNMUTE_AUDIO = 'unmute-audio',
  AUDIO_PROBLEM = 'audio-problem',
  CHANGE_BACKGROUND = 'start-video-background',
}

export enum GChatGroup {
  DEVICE_PROBLEM = 'device-problem',
  SUPPORT_SOS = 'support-sos',
}
