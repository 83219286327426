import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

export const roomGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  if (!to?.query?.token) {
    return next({ name: 'join-fail' })
  }
  return next()
}
