const mediaAction = {
  joinRoom: 'https://space-public-storage.s3-ap-southeast-1.amazonaws.com/sounds/join.mp3',
  newMessage: 'https://space-public-storage.s3-ap-southeast-1.amazonaws.com/sounds/message.mp3',
}
export default function useSoundNotify() {
  return {
    playMedia(url: string, volume: number) {
      const audio = new Audio(url)
      audio.volume = volume
      audio.play()
    },
    joinRoom(volume?: number) {
      this.playMedia(mediaAction.joinRoom, volume || 0.3)
    },
    newMessage(volume?: number) {
      this.playMedia(mediaAction.newMessage, volume || 0.2)
    },
  }
}
