<script setup lang="ts">
import useBrowserSupport from '@/composables/useBrowserSupport'
import { Engine } from '@/types/enums/room.enum'
import { PropType } from 'vue'
const props = defineProps({
  roomUrl: {
    type: String,
    default: '',
  },
  engine: {
    type: String as PropType<Engine>,
    default: Engine.TWILIO,
  },
})

const { isSuggestSafari, chromeLink } = useBrowserSupport(props.engine, props.roomUrl)
</script>

<template>
  <div class="browser-support-card">
    <p class="mt-3">
      It seems that this browser or its version doesn't support video chat functionality. Although
      you can join this room, other participants may not see or hear you. In contrast, you may not
      see or hear other connected participants, either.
      <br /><br />
      For a better experience, please continue with the suggested browser below and make sure that
      you update it to the latest version.
    </p>
    <div class="row mt-5">
      <div v-if="isSuggestSafari" class="w-full">
        <strong>For iPhone and iPad</strong>
        <div class="flex items-center mt-2">
          <img src="@/assets/svg/icon-safari.svg" width="44" height="44" />
          <span class="ml-2 flex items-center">Safari</span>
        </div>
      </div>
      <div v-else class="w-full">
        <strong>For your device suggestion</strong>
        <a :href="chromeLink" target="_blank">
          <div class="flex items-center mt-2">
            <img src="@/assets/svg/icon-chrome.svg" width="44" height="44" />
            <span class="ml-2 flex items-center"
              >Google Chrome
              <SvgIcon
                class="external-link-icon"
                icon="up-right"
                :inherit-fill="true"
                width="20"
              ></SvgIcon>
            </span>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>
