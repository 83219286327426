import { testVideoInputDevice, VideoInputTest } from '@twilio/rtc-diagnostics'
import { ref, reactive, toRefs } from 'vue'

interface IUseCameraTestState {
  videoElementRef?: HTMLVideoElement
  videoInputDeviceTest?: VideoInputTest
  selectedVideo?: string
}

export const videoInputTestReport = ref<any>(undefined)
export const videoInputTestPassed = ref<boolean>(true)

export const useCameraTest = () => {
  const state = reactive<IUseCameraTestState>({
    videoElementRef: undefined,
    videoInputDeviceTest: undefined,
    selectedVideo: undefined,
  })

  const clearVideoDeviceTest = () => {
    state.videoInputDeviceTest?.stop()
  }

  const videoInputTest = () => {
    if (state.videoInputDeviceTest) {
      clearVideoDeviceTest()
    }

    state.videoInputDeviceTest = testVideoInputDevice({
      element: state.videoElementRef,
      deviceId: state.selectedVideo,
    })
    state.videoInputDeviceTest.on(VideoInputTest.Events.Error, (error) => {
      console.error(error)
      clearVideoDeviceTest()
    })
    state.videoInputDeviceTest.on(VideoInputTest.Events.End, (testReport) => {
      videoInputTestReport.value = testReport
    })
  }

  const setVideoInputTestResult = (result: boolean) => {
    videoInputTestPassed.value = result
  }

  return {
    ...toRefs(state),
    clearVideoDeviceTest,
    videoInputTest,
    setVideoInputTestResult,
  }
}
