<script setup lang="ts">
import DiagnosticSection from '@/components/video-diagnostic/DiagnosticSection.vue'
import { Stage, QualityScore } from '@/types/enums/video-diagnostic.enum'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

interface ExcellentQualityTestEmits {
  (e: 'nextStage'): void
  (e: 'updateToggle'): void
}

const props = defineProps({
  stage: {
    type: Number,
    required: true,
  },
  totalQualityScore: {
    type: Number,
    required: true,
  },
})
const i18n = useI18n()

const emit = defineEmits<ExcellentQualityTestEmits>()

const qualityScore = computed(() =>
  props.totalQualityScore === QualityScore.Excellent ? 'excellent' : 'good',
)

const displayQualityScore = computed(() => {
  const color = qualityScore.value === 'excellent' ? 'text-blue-500' : 'text-green-500'
  return `<span class="${color}">${i18n.t(
    `video-diagnostic.quality-score.${qualityScore.value}`,
  )}</span>`
})
</script>

<template>
  <DiagnosticSection
    :id="Stage.QUALITY"
    :class="
      stage === Stage.QUALITY || stage === Stage.RESULTS
        ? 'block'
        : 'hidden md:block opacity-30 pointer-events-none'
    "
  >
    <template #default>
      <div class="row-span-1 text-sm sm:text-base lg:text-sm xl:text-base">
        <h1 class="font-bold text-xl sm:text-4xl lg:text-xl xl:text-4xl">
          <p
            v-html="$t('video-diagnostic.expected-quality', { qualityScore: displayQualityScore })"
          ></p>
          <!-- <span :class="displayQualityScore(qualityScore)">{{ qualityScore }}</span> -->
        </h1>
        <div class="py-4 sm:py-10 lg:py-4 xl:py-10">
          <span
            v-html="
              $t('video-diagnostic.expected-quality-detail', { qualityScore: displayQualityScore })
            "
          >
          </span>
          <SvgIcon
            :icon="'info'"
            class="cursor-pointer text-gray-400 hover:text-black pl-1"
            :inherit-fill="true"
            height="15"
            @click="emit('updateToggle')"
          />
        </div>
      </div>

      <div class="flex justify-center w-full row-span-2 mx-auto sm:mx-0">
        <div class="w-fit row-span-2 mx-auto sm:mx-0 text-sm sm:text-base lg:text-sm xl:text-base">
          <img class="m-auto" src="@/assets/video-diagnostic/Success.png" alt="" />
        </div>
      </div>

      <div class="row-span-1 text-sm sm:text-base lg:text-sm xl:text-base mt-6">
        <button
          :class="{ 'opacity-30 pointer-events-none': stage === Stage.RESULTS }"
          class="text-white border-2 rounded-md border-white bg-primary-color py-2 px-4"
          type="button"
          @click="emit('nextStage')"
        >
          {{ $t('video-diagnostic.finish-up') }}
        </button>
      </div>
    </template>
  </DiagnosticSection>
</template>

<style lang="scss" scoped>
.bg-primary-color {
  background-color: #f67519;
}

button.bg-white {
  &:hover {
    background-color: #32323209;
    transition: 0.4s;
  }
  &:disabled {
    background-color: inherit;
    color: rgba(0, 0, 0, 0.26);
  }
}

.w-fit {
  width: fit-content;
}
</style>
