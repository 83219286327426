import { IVideoQuality } from '@/types/interfaces/video.interface'
import { isMobile } from '@/composables/twilio/useTwilioState'
import { computed } from 'vue'
import { MobileVideoFacingMode } from '@zoom/videosdk'

export const listVideoResolution = computed<string[]>(() =>
  isMobile.value
    ? ['240p', '360p', '480p', '720p', 'Auto']
    : ['240p', '360p', '480p', '720p', 'Auto'],
)

const mobileQuality = new Map<string, IVideoQuality>([
  ['240p', { height: { min: 480, ideal: 480 }, width: { min: 240, ideal: 240 }, frameRate: 24 }],
  ['360p', { height: { min: 720, ideal: 720 }, width: { min: 360, ideal: 360 }, frameRate: 24 }],
  ['480p', { height: { min: 960, ideal: 960 }, width: { min: 480, ideal: 480 }, frameRate: 24 }],
  ['720p', { height: { min: 1440, ideal: 1440 }, width: { min: 720, ideal: 720 }, frameRate: 24 }],
  ['Auto', { height: { min: 480, ideal: 480 }, width: { min: 240, ideal: 240 }, frameRate: 24 }],
])

const desktopQuality = new Map<string, IVideoQuality>([
  ['240p', { height: { min: 240, ideal: 240 }, width: { min: 426, ideal: 426 }, frameRate: 24 }],
  ['360p', { height: { min: 360, ideal: 360 }, width: { min: 640, ideal: 640 }, frameRate: 24 }],
  ['480p', { height: { min: 480, ideal: 480 }, width: { min: 854, ideal: 854 }, frameRate: 24 }],
  ['720p', { height: { min: 720, ideal: 720 }, width: { min: 1280, ideal: 1280 }, frameRate: 24 }],
  ['Auto', { height: { min: 240, ideal: 240 }, width: { min: 426, ideal: 426 }, frameRate: 24 }],
])

export const videoQuality = isMobile.value ? mobileQuality : desktopQuality

export const ZOOM_VIDEO_MOBILE_DEVICES = [
  { value: MobileVideoFacingMode.User, label: 'Front-facing' },
  { value: MobileVideoFacingMode.Environment, label: 'Rear-facing' },
]
