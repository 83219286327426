<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { useBackgroundStore, useDeviceStore } from '@/stores'
import PreviewWrapper from '@/components/checkup/PreviewWrapper.vue'
import { Engine } from '@/types/enums/room.enum'
import BackgroundGallery from '@/components/checkup/BackgroundGallery.vue'
import { covertVideoBgToBackgroundType } from '@/helpers/background.helper'
import useSpaceApi from '@/composables/api/useSpaceApi'

const props = defineProps({
  canSetBackground: {
    type: Boolean,
    default: false,
  },
  isAutoState: {
    type: Boolean,
    default: false,
  },
  canOpenSetting: {
    type: Boolean,
    default: false,
  },
  mirrorCanvas: {
    type: Boolean,
    default: false,
  },
})

const device = useDeviceStore()
const backgroundStore = useBackgroundStore()
const { saveLogsChangeBackground } = useSpaceApi()
const localTrackRef = ref<HTMLVideoElement>()
const localTrackCanvasRef = ref<HTMLCanvasElement>()
const isInit = ref<boolean>(false)
const displayVideo = ref<boolean>(false)

const mediaConstrains = computed(() => {
  return {
    haveAudio: device.haveAudio,
    haveVideo: device.haveVideo,
    cameraOn: device.maskCameraOn,
    microphoneOn: device.maskMicrophoneOn,
    maskSelectedVideo: device.maskSelectedVideo,
    maskSelectedAudio: device.maskSelectedAudio,
  }
})

const init = async () => {
  device.setLocalTrackZoom(localTrackRef.value)
  device.setLocalTrackCanvasZoom(localTrackCanvasRef.value)
  await device.startPreviewZoom()
  displayVideo.value = device.maskCameraOn
  isInit.value = true
}
const toggleCamera = async () => {
  if (props.isAutoState) {
    await device.toggleCamera(true)
  } else {
    device.maskCameraOn = !device.maskCameraOn
    if (device.maskCameraOn) {
      await device.startVideoTrackZoom()
    } else {
      await device.stopVideoTrackZoom()
    }
  }
  displayVideo.value = device.maskCameraOn
}

const updateBackground = async () => {
  if (!device.localTrackZoomRef) return
  device.isProcessing = true
  backgroundStore.setStateBackground(backgroundStore.selectedBackground)
  // Log Change Background
  const { backgroundType, imagePath } = covertVideoBgToBackgroundType(
    backgroundStore.currentBackground,
  )
  saveLogsChangeBackground({ engine: Engine.ZOOM, backgroundType, imagePath })
  if (displayVideo.value) {
    await device.localVideoTrack?.stop()
  }
  await device.localVideoTrack?.start(device.localTrackZoomRef)
  await device.setPreviewWithZoomBackground()
  displayVideo.value = device.maskCameraOn
  device.isProcessing = false
}

const toggleMicrophone = async () => {
  if (props.isAutoState) {
    await device.toggleMicrophone(true)
  } else {
    device.maskMicrophoneOn = !device.maskMicrophoneOn
  }
}

watch(
  () => mediaConstrains.value.maskSelectedVideo,
  async () => {
    if (!isInit.value) return
    await device.switchCameraZoom()
  },
)

watch(
  () => mediaConstrains.value.maskSelectedAudio,
  () => {
    if (!isInit.value) return
    device.startAudioTrackZoom()
  },
)

onMounted(async () => {
  init()
})
</script>

<template>
  <div>
    <PreviewWrapper
      :is-ready="device.isReady"
      :is-processing="device.isProcessing"
      :camera-enabled="device.maskCameraOn"
      :is-camera-found="device.haveVideo"
      :allowed-camera="device.allowedCamera"
      :microphone-enabled="device.maskMicrophoneOn"
      :is-microphone-found="device.haveAudio"
      :allowed-microphone="device.allowedMicrophone"
      :can-set-background="false"
      @toggle-camera="toggleCamera"
      @toggle-microphone="toggleMicrophone"
    >
      <div class="video-track --me">
        <LoadingProcess :loading="device.isProcessing" />

        <video
          v-show="!device.isProcessing && displayVideo && device.previewZoomMode === 'video'"
          ref="localTrackRef"
          playsinline
        ></video>
        <canvas
          v-show="!device.isProcessing && displayVideo && device.previewZoomMode === 'canvas'"
          ref="localTrackCanvasRef"
          width="1280"
          height="720"
          class="video-canvas"
          :class="[mirrorCanvas && 'mirror-canvas']"
        ></canvas>
      </div>
    </PreviewWrapper>
    <BackgroundGallery
      v-if="canSetBackground && device.canChangeBackground"
      :disabled="device.isProcessing"
      @select-background="updateBackground"
    />
  </div>
</template>

<style lang="scss" scoped>
::v-deep(.video-button > .control-item:first-child) {
  margin-right: 12px;
}
::v-deep(.video-button > .control-item:hover) {
  background-color: rgba(255, 255, 255, 0.7) !important;
}
::v-deep(.video-button > .control-item.-disabled) {
  background: $danger !important;
  border-color: $danger !important;
}
::v-deep(.video-button > .control-item.-disabled:hover) {
  background-color: #f7475bc9 !important;
}
::v-deep(.video-button > div > .control-item.-disabled) {
  background: #f7475b8a !important;
  color: #ffffff9f !important;
}
::v-deep(.video-button > div > .control-item.-disabled:hover) {
  background-color: #f7475b8a !important;
}

.display-none {
  display: none;
}
.video-canvas {
  display: block;
  max-width: 100%;
  &.mirror-canvas {
    transform: scaleX(-1);
  }
}
</style>
