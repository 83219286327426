/**
 * Read more
 * https://cli.vuejs.org/guide/mode-and-env.html#modes
 */
export const TOKEN_KEY = 'room_access_token'
export const DOMAIN =
  process.env.NODE_ENV == 'production' ? (process.env.DOMAIN as string) : 'localhost'
export const BACKEND_BASE_URL = (process.env.API_SPACE_URL as string) || 'http://localhost:3000'
export const ROOM_SPACE_URL = (process.env.ROOM_SPACE_URL as string) || 'https://room.globish.dev'
export const API_STUDENT_URL = (process.env.API_STUDENT_URL as string) || 'https://api3.globish.dev'
export const WH_AUTH_USER = process.env.WH_AUTH_USER as string
export const WH_AUTH_PASS = process.env.WH_AUTH_PASS as string
export const VERSION = process.env.VERSION || 'v'
/**
 * export firebase env
 */
export const FIREBASE_API_KEY = process.env.FIREBASE_API_KEY as string
export const FIREBASE_AUTH_DOMAIN = process.env.FIREBASE_AUTH_DOMAIN as string
export const CLOUD_FIRESTORE_PROJECT_ID = process.env.CLOUD_FIRESTORE_PROJECT_ID as string
export const STORAGE_BUCKET = process.env.STORAGE_BUCKET as string
export const MESSAGING_SENDER_ID = process.env.MESSAGING_SENDER_ID as string
export const FIREBASE_APP_ID = process.env.APP_ID as string
export const MEASUREMENT_ID = process.env.MEASUREMENT_ID as string
