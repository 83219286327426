import { computed, onMounted, reactive, Ref, toRefs, watch } from 'vue'
// Import the functions you need from the SDKs you need
import { FirebaseApp, initializeApp } from 'firebase/app'
import {
  addDoc,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  DocumentData,
  Firestore,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
  initializeFirestore,
} from 'firebase/firestore'
import { FirebaseStorage, getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'
import {
  CLOUD_FIRESTORE_PROJECT_ID,
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  MEASUREMENT_ID,
  MESSAGING_SENDER_ID,
  STORAGE_BUCKET,
} from './api'
import { chatEnable } from './room/useRoomLayout'
import { IPreviewMessage } from '@/types/interfaces/common.interface'
import moment from 'moment-timezone'
import { useRoomLayoutStore } from '@/stores/room-layout.store'

type TParticipantRole = 'observer' | 'watcher' | 'coach' | 'student' | 'system'
export type TMessageType = 'file' | 'image' | 'text'

interface IuseFirestore {
  storage?: FirebaseStorage
  app?: FirebaseApp
  db?: Firestore
  messages: Array<DocumentData>
  newMessages?: IPreviewMessage
  chatboxRef?: Ref<Element>
  currentUser?: IParticipant
  roomRefId: string
  isSending: boolean
  isUploading: boolean
}

interface ISetRoom {
  name: string
  externalRoomId?: string
  spaceRoomId: string
}

export interface IParticipant {
  name: string
  email: string
  avatar: string
  role: TParticipantRole
}

interface IAddParticipant {
  roomRefId: string
  participant: IParticipant
}

interface IMessage {
  sendBy: IParticipant
  message: string
  fileURL: string
  type: TMessageType
}

export interface ISendMessage {
  roomRefId: string
  message: IMessage
}

interface IUpdateMessage {
  messageRefId: string
  roomRefId: string
  email: string
}

interface IDeleteMessage {
  roomRefId: string
  messageRefId: string
}

interface IUploadFileURL {
  roomRefId: string
  user: IParticipant
  file: File
}

interface IListeningMessage {
  roomRefId: string
  currentUser: IParticipant
}

export default function useFirestore() {
  /**
   * useFirestore state
   */
  const state = reactive<IuseFirestore>({
    roomRefId: '',
    messages: [],
    newMessages: undefined,
    chatboxRef: undefined,
    app: undefined,
    db: undefined,
    storage: undefined,
    currentUser: undefined,
    isSending: false,
    isUploading: false,
  })

  const roomLayoutStore = useRoomLayoutStore()

  const unreadMessages = computed(() => {
    const unread = state.messages.filter((msg) => {
      return (
        msg.send_by?.email !== state.currentUser?.email &&
        !msg.read_by.includes(state.currentUser?.email)
      )
    })
    return unread
  })

  const chatActive = computed(() => roomLayoutStore.chatEnable || chatEnable.value)

  /**
   * *****************************
   *            methods
   * *****************************
   */

  const setRoomIfNotExist = async ({ spaceRoomId, externalRoomId, name }: ISetRoom) => {
    /**  DO NOTHING IF NOT FOUND FIRESTORE DB */
    if (!state.db) return
    /**
     * find crated space room or class id
     * for this chat room
     */
    const roomsCollectionRef = collection(state.db, 'rooms')
    const querySnapshot = await getDocs(
      query(roomsCollectionRef, where('id', '==', spaceRoomId), orderBy('created_at', 'desc')),
    )
    /**
     * Add a new document in collection "rooms"
     * IF NOT EXIST!!!
     */
    if (querySnapshot.docs.length === 0) {
      try {
        await setDoc(doc(state.db, 'rooms', spaceRoomId), {
          id: spaceRoomId,
          name,
          created_at: serverTimestamp(),
          external_room_id: externalRoomId,
        })
      } catch (error) {
        console.warn(error)
      }
    }
    // set room ref state after every things is done
    state.roomRefId = spaceRoomId
  }

  const addParticipant = async ({ participant, roomRefId }: IAddParticipant) => {
    state.currentUser = participant
    /** DO NOTHING IF NOT FOUND FIRESTORE DB */
    if (!state.db) return
    /** find created participant if exist */
    const userCollectionRef = collection(state.db, 'rooms', roomRefId, 'participants')
    const querySnapshot = await getDocs(
      query(userCollectionRef, where('email', '==', participant.email)),
    )
    /**
     * if not found participant exist in document
     * create new participant with new value
     */
    if (querySnapshot.docs.length === 0) {
      await addDoc(userCollectionRef, participant)
    }
  }

  const getImageURL = (path: string) => {
    /** DO NOTHING IF NOT FOUND FIRESTORE STORAGE */
    if (!state.storage) return
    /**
     * get image url
     */
    try {
      return getDownloadURL(ref(state.storage, path))
    } catch (error) {
      console.error(error)
    }
  }

  const uploadFileURL = async ({ roomRefId, user, file }: IUploadFileURL) => {
    /** DO NOTHING IF NOT FOUND FIRESTORE STORAGE */
    if (!state.storage) return

    try {
      const isImageType = file['type'].split('/')[0] === 'image'

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...fileName] = file.name.split('.').reverse()
      const path = `${isImageType ? 'images' : 'files'}/${fileName
        .reverse()
        .join('')}-${new Date().valueOf()}`
      /**
       * upload image file into storage
       */
      const storageRef = ref(state.storage, path)
      await uploadBytes(storageRef, file)
      /**
       * get image url if exist then send new message as url type
       */
      const url = await getImageURL(path)
      if (url) {
        await sendMessage({
          roomRefId,
          message: {
            message: file.name,
            fileURL: url,
            type: isImageType ? 'image' : 'file',
            sendBy: user,
          },
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const sendMessage = async ({ roomRefId, message }: ISendMessage) => {
    /** DO NOTHING IF NOT FOUND FIRESTORE DB */
    if (!state.db) return
    /**
     * push new message into firestore
     */
    state.isSending = true
    const messageCollectionRef = collection(state.db, 'rooms', roomRefId, 'messages')
    await addDoc(messageCollectionRef, {
      message: message.message,
      type: message.type,
      file_url: message.fileURL,
      sent_at: serverTimestamp(),
      send_by: message.sendBy,
      read_by: [],
    })
    state.isSending = false
  }

  const updateMessage = async ({ roomRefId, messageRefId, email }: IUpdateMessage) => {
    /** DO NOTHING IF NOT FOUND FIRESTORE DB */
    if (!state.db) return
    try {
      const docRef = doc(state.db, 'rooms', roomRefId, 'messages', messageRefId)
      await updateDoc(docRef, {
        read_by: arrayUnion(email),
      })
    } catch (error) {
      console.error(error)
    }
  }

  const deleteMessage = async ({ messageRefId, roomRefId }: IDeleteMessage) => {
    /** DO NOTHING IF NOT FOUND FIRESTORE DB */
    if (!state.db) return
    /**
     * delete message
     */
    try {
      const docRef = doc(state.db, 'rooms', roomRefId, 'messages', messageRefId)
      await deleteDoc(docRef)
    } catch (error) {
      console.error(error)
    }
  }

  const userReadMessages = () => {
    /**
     * update user unread message
     */
    if (chatActive.value) {
      if (unreadMessages.value.length > 0 && state.roomRefId) {
        unreadMessages.value.forEach(async ({ id }) => {
          await updateMessage({
            roomRefId: state.roomRefId,
            messageRefId: id,
            email: state.currentUser?.email || '',
          })
        })
      }
    }
  }

  const onSnapshotMessage = async ({ currentUser, roomRefId }: IListeningMessage) => {
    /** DO NOTHING IF NOT FOUND FIRESTORE DB */
    if (!state.db) return
    /**
     * listening current message alltime
     */
    const roomCollectionRef = collection(state.db, 'rooms', roomRefId, 'messages')
    let lastScrollTop = 0
    const timeInit = moment().unix()
    onSnapshot(query(roomCollectionRef, orderBy('sent_at', 'asc')), (snapshot) => {
      /**
       * set new message box
       */
      state.messages = []
      snapshot.forEach((doc) => {
        state.messages.push({
          ...doc.data(),
          id: doc.id,
        })
      })
      snapshot.docChanges().forEach((change) => {
        // show preview message condition: message from another and chat box is closed
        if (
          change.type === 'added' &&
          change.doc.data().send_by.email !== state.currentUser?.email &&
          !chatActive.value &&
          moment(timeInit).diff(change.doc.data().sent_at?.seconds) < 0
        ) {
          state.newMessages = {
            message: change.doc.data().message as string,
            participant: change.doc.data().send_by,
            id: change.doc.id,
            type: change.doc.data().type,
          }
        }
      })
      /**
       * Update user read message
       */
      userReadMessages()
      /**
       * scroll down follow a new message
       */
      if (state.messages.length > 0) {
        setTimeout(() => {
          const lastMessage = state.messages[state.messages.length - 1]
          const isOnwedLastMsg = lastMessage.email === currentUser.email
          const isScrolling =
            Math.floor(lastScrollTop) <= Math.floor(state.chatboxRef?.scrollTop || 0)
          /**
           * scrolling when user is owned last message or
           * user is on the bottom of chat box
           */
          if (state.chatboxRef && (isScrolling || isOnwedLastMsg)) {
            state.chatboxRef.scrollTop = state.chatboxRef.scrollHeight
            lastScrollTop = Math.floor(state.chatboxRef.scrollTop)
          }
        }, 100)
      }
    })
  }

  watch(
    () => chatActive.value,
    () => {
      userReadMessages()
      if (chatActive.value) {
        state.newMessages = undefined
      }
    },
  )

  onMounted(() => {
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    const firebaseConfig = {
      apiKey: FIREBASE_API_KEY,
      authDomain: FIREBASE_AUTH_DOMAIN,
      projectId: CLOUD_FIRESTORE_PROJECT_ID,
      storageBucket: STORAGE_BUCKET,
      messagingSenderId: MESSAGING_SENDER_ID,
      appId: FIREBASE_APP_ID,
      measurementId: MEASUREMENT_ID,
    }

    // Initialize Firebase App
    state.app = initializeApp(firebaseConfig)

    /**
     * get storage and firestore db
     */
    state.storage = getStorage(state.app)
    state.db = initializeFirestore(state.app, { experimentalForceLongPolling: true })
  })

  return {
    ...toRefs(state),
    setRoomIfNotExist,
    addParticipant,
    sendMessage,
    onSnapshotMessage,
    uploadFileURL,
    deleteMessage,
    unreadMessages,
  }
}
