import useRoomApi from '@/composables/api/useSpaceApi'
import {
  ICreateCheckupDevicesZoom,
  IVideoDiagnosticInterface,
} from '@/types/interfaces/video-diagnostic.interface'
import { reactive, toRefs } from 'vue'
import useClientApi from '../api/useClientApi'
import { getReport } from '@/composables/zoom-diagnostic/useReport'
import { detectDevice } from '@/helpers/device.helper'
import { ResultStatus } from '@/types/enums/video-diagnostic.enum'
import { useRoute } from 'vue-router'
import { videoInputTestPassed } from '@/composables/zoom-diagnostic/useCameraTest'
import { audioTestPassed } from '@/composables/zoom-diagnostic/useAudioTest'
import { connectionFailed } from '@/composables/zoom-diagnostic/useConnection'

export const useVideoDiagnostic = () => {
  const state = reactive<IVideoDiagnosticInterface>({
    token: '',
    tokenInstance: undefined,
    note: '',
    debounce: null,
    isLoading: false,
  })

  const { createCheckupDevices } = useRoomApi()
  const { getClientIp } = useClientApi()
  const route = useRoute()

  const getStatus = (): ResultStatus => {
    if (videoInputTestPassed.value && audioTestPassed.value && !connectionFailed.value) {
      return ResultStatus.PASS
    } else {
      return ResultStatus.FAIL
    }
  }

  const sendReport = async () => {
    clearTimeout(state.debounce)
    state.debounce = setTimeout(async () => {
      state.isLoading = true
      let res = await getClientIp()
      const report = getReport()
      const payload: ICreateCheckupDevicesZoom = {
        agent: {
          agent: navigator.userAgent,
          ip: res.data.ip,
        },
        detail: report,
        deviceName: detectDevice(),
        status: getStatus(),
        studentId: parseInt(`${route?.query?.studentId}`),
        type: 'zoom',
        version: 'space_tiger',
        note: state.note ? state.note : undefined,
      }
      res = await createCheckupDevices(payload)
      state.isLoading = true
      window.location.href = `${route?.query?.exitPath}`
    }, 600)
  }

  return { ...toRefs(state), sendReport }
}
