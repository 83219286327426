const CommonState = {
  getLocale() {
    return localStorage.getItem('locale')
  },
  setLocale(locale: string) {
    return localStorage.setItem('locale', locale)
  },
  getCountry() {
    return localStorage.getItem('country')
  },
  setCountry(country: string) {
    return localStorage.setItem('country', country)
  },
}

export default CommonState
