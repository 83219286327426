import moment from 'moment'
import { ISpecialSticker } from '../types/interfaces/sticker.interface'

interface IGetSpecialSticket {
  date: moment.Moment
  stickerList: ISpecialSticker
}

/**
 * Returns 1 if the current date falls within the date range defined by the startMonth and endMonth
 * properties of the given stickerList, -1 if the properties are undefined or null.
 */
export const sortSpecialStickerDate = ({ date, stickerList }: IGetSpecialSticket): number => {
  // Check if the sticker list has startMonth and endMonth values
  if (!stickerList?.startMonth && !stickerList?.endMonth) {
    return -1
  }
  if (stickerList?.startMonth && stickerList?.endMonth) {
    // Calculate the start date of the range
    const dateRangeStart = moment({
      year: date.year(),
      month: stickerList.startMonth - 1,
    }).startOf('month')

    // Calculate the end date of the range
    const dateRangeEnd =
      stickerList.endMonth < stickerList.startMonth
        ? moment({ year: date.year() + 1, month: stickerList.endMonth - 1 }).endOf('month')
        : moment({ year: date.year(), month: stickerList.endMonth - 1 }).endOf('month')

    if (date.isBetween(dateRangeStart, dateRangeEnd, 'day', '[]')) {
      return -1
    }
    return 1
  }
  return 1
}
