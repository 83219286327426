<script setup lang="ts">
import PreviewWrapper from '@/components/checkup/PreviewWrapper.vue'
import BackgroundPopup from '@/components/checkup/BackgroundPopup.vue'
import useTwilioBackground from '@/composables/twilio/useTwilioBackground'
import DeviceSettingPopup from '@/components/checkup/DeviceSettingPopup.vue'
import { setLocalTrackElement } from '@/helpers/twilio.helper'
import { createPreviewLocalTracks } from '@/helpers/twilio.helper'
import { useBackgroundStore, useDeviceStore } from '@/stores'
import { LocalTrack } from 'twilio-video'
import { onMounted, ref, watch, toRaw, computed, onUnmounted } from 'vue'
import { Engine } from '../../types/enums/room.enum'

const props = defineProps({
  canSetBackground: {
    type: Boolean,
    default: false,
  },
  isAutoState: {
    type: Boolean,
    default: false,
  },
  canOpenSetting: {
    type: Boolean,
    default: false,
  },
})

const popupOpened = ref<boolean>(false)
const settingPopupOpened = ref<boolean>(false)
const localTracks = ref<LocalTrack[]>([])
const localTrackRef = ref<HTMLVideoElement>()
const currentTab = ref<string>('quality')

const userMediaConstraints = computed(() => {
  return {
    audio: device.haveAudio ? { deviceId: device.maskSelectedAudio } : false,
    video: device.haveVideo
      ? {
          ...device.currentVideoQuality,
          deviceId: device.maskSelectedVideo,
        }
      : false,
  }
})
const device = useDeviceStore()
const background = useBackgroundStore()
const { setImageBackground, removeProcessor } = useTwilioBackground()

const setLocalTrack = () => {
  const option = {
    haveAudio: device.haveAudio,
    haveVideo: device.haveVideo,
    cameraOn: device.maskCameraOn,
    microphoneOn: device.maskMicrophoneOn,
    height: device.currentVideoQuality?.height,
    width: device.currentVideoQuality?.width,
  }
  setLocalTrackElement(
    { localTrackRef: localTrackRef.value, localTwilioTracks: toRaw(localTracks.value), option },
    (level) => {
      device.audioLevel = device.maskMicrophoneOn ? level : 0
    },
  )
  toRaw(localTracks.value).forEach((track) => {
    if (track.kind === 'video') {
      setImageBackground(track, background.currentBackground)
    }
  })
}

const init = async () => {
  if (localTracks.value.length) {
    toRaw(localTracks.value).forEach((track) => {
      if (track.kind === 'video') {
        track.stop()
      }
    })
    localTracks.value = []
  }
  localTracks.value = await createPreviewLocalTracks(userMediaConstraints.value)
  setLocalTrack()
}

const toggleCamera = async () => {
  if (props.isAutoState) {
    await device.toggleCamera(true)
  } else {
    device.maskCameraOn = !device.maskCameraOn
  }
  toRaw(localTracks.value).forEach((track) => {
    if (track.kind === 'video') {
      track.enable(device.maskCameraOn)
      if (track.isEnabled) {
        setImageBackground(track, background.currentBackground)
      } else {
        removeProcessor(track)
      }
    }
  })
}

const toggleMicrophone = async () => {
  if (props.isAutoState) {
    await device.toggleMicrophone(true)
  } else {
    device.maskMicrophoneOn = !device.maskMicrophoneOn
  }
  toRaw(localTracks.value).forEach((track) => {
    if (track.kind === 'audio') {
      track.enable(device.maskMicrophoneOn)
    }
  })
}

watch(
  () => userMediaConstraints.value,
  () => init(),
  { deep: true },
)

watch(
  () => [device.maskCameraOn, device.maskMicrophoneOn],
  () => setLocalTrack(),
  { deep: true },
)

onMounted(() => {
  init()
})
onUnmounted(() => {
  if (localTracks.value.length) {
    toRaw(localTracks.value).forEach((track) => {
      if (track.kind === 'video') {
        track.stop()
      }
    })
    localTracks.value = []
  }
})
</script>

<template>
  <PreviewWrapper
    :is-ready="device.isReady"
    :camera-enabled="device.maskCameraOn"
    :is-camera-found="device.haveVideo"
    :allowed-camera="device.allowedCamera"
    :microphone-enabled="device.maskMicrophoneOn"
    :is-microphone-found="device.haveAudio"
    :allowed-microphone="device.allowedMicrophone"
    :can-set-background="canSetBackground && device.canChangeBackground"
    @toggle-camera="toggleCamera"
    @toggle-microphone="toggleMicrophone"
    @open-background-setting="popupOpened = true"
    @open-setting="settingPopupOpened = true"
  >
    <div
      ref="localTrackRef"
      class="video-track --me"
      :class="[!device.maskCameraOn && 'video-display-none']"
    ></div>
    <BackgroundPopup v-model="popupOpened" :engine="Engine.TWILIO" @apply-background="init" />
    <DeviceSettingPopup
      v-model="settingPopupOpened"
      v-model:currentTab="currentTab"
      :option="['quality']"
    ></DeviceSettingPopup>
  </PreviewWrapper>
</template>

<style lang="scss" scoped>
.video-container {
  --track-radius: 10px;
  position: relative;
  height: 100%;
  width: 100%;
  height: 35vh;
  border-radius: var(--track-radius);
  margin-top: 1rem;
  background: #272727;
  @include media-breakpoint-up-custom(768px) {
    width: 40vw;
    height: 30vh;
    min-height: 320px;
    max-height: 480px;
    max-width: 720px;
    margin-top: 1.5rem;
  }
  @include media-breakpoint-up-custom(1000px) {
    min-height: 380px;
  }

  .track-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--track-radius);
    overflow: hidden;

    .video-button {
      position: absolute;
      top: 90%;
      left: 50%;
      transform: translate(-50%, -50%);
      .control-btn {
        border-radius: 50%;
        width: 36px !important;
        height: 36px !important;
        background: transparent;
        border: solid 1px;
        color: white !important;
        transition-duration: 0.4s;
        @include media-breakpoint-up-custom(768px) {
          width: 48px !important;
          height: 48px !important;
        }
      }
      > div {
        position: relative;
        .icon-container {
          position: absolute;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 14px;
          height: 14px;
          border-radius: 50%;
          border: solid 1px #f67519;
          top: -5%;
          right: -5%;
          background-color: #f67519;
          color: #ffffff;
          @include media-breakpoint-up-custom(768px) {
            width: 20px;
            height: 20px;
          }
        }
      }
      > div:first-child {
        margin-right: 12px;
      }
    }
    .camera-notify {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 18px;
    }

    .microphone-notify-container {
      position: absolute;
      top: 10%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 12px;
      color: #ffffff;
      .microphone-notify {
        width: auto;
        height: 42px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 12px 24px;
        background: #1d1d1d;
        border-radius: 24px;

        transform: translateX(-80px);
        opacity: 0;
        transition-property: transform, opacity;
        transition-duration: 0.5s;
        transition-timing-function: ease;
      }
      .microphone-notify-active {
        transform: translateX(0);
        opacity: 1;
        transition-delay: 0ms;
      }
    }

    .text-off {
      width: auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      justify-content: center;
    }
  }
}
::v-deep(.video-button > .control-item:first-child) {
  margin-right: 12px;
}
::v-deep(.video-button > .control-item:hover) {
  background-color: rgba(255, 255, 255, 0.7) !important;
}
::v-deep(.video-button > .control-item.-disabled) {
  background: $danger !important;
  border-color: $danger !important;
}
::v-deep(.video-button > .control-item.-disabled:hover) {
  background-color: #f7475bc9 !important;
}
::v-deep(.video-button > div > .control-item.-disabled) {
  background: #f7475b8a !important;
  color: #ffffff9f !important;
}
::v-deep(.video-button > div > .control-item.-disabled:hover) {
  background-color: #f7475b8a !important;
}
::v-deep(.video-display-none > video) {
  display: none;
}
</style>
