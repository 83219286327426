<script setup lang="ts">
import DiagnosticSection from '@/components/video-diagnostic/DiagnosticSection.vue'
import { useCameraTest } from '@/composables/zoom-diagnostic/useCameraTest'
import { useDevices } from '@/composables/video-diagnostic/useDevices'
import { Stage } from '@/types/enums/zoom-diagnostic.enum'
import { watch } from 'vue'

interface CameraTestEmits {
  (e: 'nextStage', value: number): void
}

const props = defineProps({
  stage: {
    type: Number,
    required: true,
  },
})

const emit = defineEmits<CameraTestEmits>()

const {
  stopVideoTrackZoom,
  videoInputTest,
  setVideoInputTestResult,
  videoElementRef,
  selectedVideo,
} = useCameraTest()
const { videoInputDevices } = useDevices()

watch(
  () => props.stage,
  async (stage, prevStage) => {
    if (prevStage === Stage.CAMERA_TEST) {
      stopVideoTrackZoom()
    }
    if (stage === Stage.CAMERA_TEST) {
      videoInputTest()
    }
    if (stage === Stage.RESULTS) {
      videoInputTest()
    }
  },
)

watch(videoInputDevices, () => {
  initSelectedVideo()
})

const nextStage = (testResult: boolean) => {
  setVideoInputTestResult(testResult)
  emit('nextStage', Stage.AUDIO_TEST)
}

const initSelectedVideo = () => {
  if (!selectedVideo.value) {
    selectedVideo.value = videoInputDevices.value[0].value
  }
}
</script>

<template>
  <DiagnosticSection
    :id="Stage.CAMERA_TEST"
    :class="
      [Stage.GET_STARTED, Stage.CHECK_PERMISSIONS, Stage.PERMISSION_ERROR].includes(stage)
        ? 'opacity-0'
        : stage === Stage.CAMERA_TEST || stage === Stage.RESULTS
        ? 'block'
        : 'hidden md:block opacity-30 pointer-events-none'
    "
  >
    <template #default>
      <div class="row-span-1 text-sm sm:text-base lg:text-sm xl:text-base">
        <h1 class="font-bold text-xl sm:text-4xl lg:text-xl xl:text-4xl">
          {{ $t('zoom-diagnostic.check-video-camera') }}
        </h1>
        <p class="py-4 sm:py-10 lg:py-4 xl:py-10">
          {{ $t('zoom-diagnostic.check-video-camera-detail') }}
        </p>
      </div>

      <div
        class="row-span-2 p-6 max-w-sm h-fit bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700"
      >
        <p class="text-center font-bold mb-3">{{ $t('zoom-diagnostic.video-preview') }}</p>
        <div class="video-container">
          <div class="aspectRatioContainer">
            <video ref="videoElementRef" playsInline />
          </div>
        </div>
        <p class="font-bold mt-3">{{ $t('zoom-diagnostic.camera') }}</p>
        <SelectOption
          v-model="selectedVideo"
          :options="videoInputDevices"
          class="w-full"
          @update:model-value="videoInputTest"
        />
      </div>

      <div class="row-span-1 text-sm sm:text-base lg:text-sm xl:text-base">
        <p class="py-6 font-bold">{{ $t('zoom-diagnostic.video-look-ok') }}</p>
        <div :class="{ 'opacity-30 pointer-events-none': stage === Stage.RESULTS }" class="flex">
          <button
            class="bg-primary-color text-white border-2 rounded-md border-white py-2 px-4"
            type="button"
            @click="nextStage(true)"
          >
            {{ $t('zoom-diagnostic.test-pass') }}
          </button>
          <button
            class="bg-primary-color skip-button rounded-md py-2 px-4 ml-4"
            type="button"
            @click="nextStage(false)"
          >
            {{ $t('zoom-diagnostic.test-fail') }}
          </button>
        </div>
      </div>
    </template>
  </DiagnosticSection>
</template>

<style lang="scss" scoped>
.bg-primary-color {
  background-color: #f67519;
}

.skip-button {
  color: #f67519;
  background-color: transparent;
  &:hover {
    background-color: #f675192a;
    transition: 0.4s;
  }
}

.video-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aspectRatioContainer {
  position: relative;
  display: flex;
  width: 80%;
  padding: 1em;
  margin: '1em 0';
  &::after {
    content: '';
    padding-top: 56.25%;
  }
  & video {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: scaleX(-1);
  }
}

.h-fit {
  height: fit-content;
}
</style>
