<script setup lang="ts">
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import { useSlots } from '@vue/runtime-dom'
import { computed } from 'vue'

interface PopupEmits {
  (e: 'close', value: boolean): void
}
const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: '',
  },
  width: {
    type: String,
    default: '410',
  },
  canClose: {
    type: Boolean,
    default: true,
  },
})
const maxWidth = computed(() => `${props.width}px`)
const emit = defineEmits<PopupEmits>()

const slots = useSlots()

const close = () => {
  if (props.canClose) {
    emit('close', false)
  }
}
</script>
<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="popup fixed inset-0" @close="close">
      <div class="popup-wrapper">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div class="popup-box" :style="{ maxWidth }">
            <div class="title-section flex justify-between">
              <div class="flex items-center">
                <div v-if="slots['icon']" class="title-icon">
                  <!-- <ExclamationIcon class="h-6 w-6 text-red-600" aria-hidden="true" /> -->
                  <slot name="icon" />
                </div>
                <slot v-if="$slots.title" name="title" />
                <DialogTitle
                  v-else-if="title"
                  as="h3"
                  class="text-lg leading-6 font-medium text-gray-900"
                >
                  {{ title }}
                </DialogTitle>
              </div>
              <SvgIcon
                v-if="canClose"
                class="cursor-pointer text-gray-900"
                :icon="'close'"
                :inherit-fill="true"
                height="24"
                @click="close"
              />
            </div>
            <div class="popup-body">
              <slot />
            </div>
            <div v-if="slots['footer']" class="popup-footer">
              <slot name="footer" />
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style lang="scss">
.popup {
  z-index: $z-index-popup;
  .popup-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $z-index-popup;
    background: rgba(0, 0, 0, 0.65);
    @include media-breakpoint-up-custom(700px) {
      align-items: center;
      background: rgba(0, 0, 0, 0.65);
    }
  }
  .popup-box {
    @apply inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full;

    width: 100%;
    bottom: 0;
    z-index: 9;
    max-height: 90%;
    border-radius: $popup-br $popup-br 0 0;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 10px;
    margin-bottom: 0;
    @include primary-box-shadow;
    max-width: 410px;
    @include media-breakpoint-up-custom(700px) {
      border-radius: $popup-br;
      // max-width: 410px;
    }
    .title-section {
      position: relative;
      background: #fcfcfc;
      // border-radius: 1.4rem 1.4rem 0 0;
      // padding: 1rem;
      padding: 17px 15px 13px 15px;
      border-bottom: 1px solid #f4f4f4;
      @include font-weight(regular);
      font-size: 1.18rem;
      > .title-icon {
        @apply mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10;
      }
    }
  }
  .popup-body {
    background: $pure-white;
    padding: 1rem;
    overflow: hidden;
    overflow-y: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 90vh;
    @include media-breakpoint-up-custom(700px) {
      border-top: 1px solid #f4f4f4;
      margin-top: -1px;
    }
  }
  .popup-footer {
    padding: 15px;
    background: #fcfcfc;
    border-top: 1px solid #f3f3f3;
  }
}
</style>
