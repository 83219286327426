<script setup lang="ts">
const emit = defineEmits(['on-close', 'confirm-solution'])
defineProps({
  coachMessage: {
    type: String,
    default: undefined,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
})

const close = () => {
  emit('on-close', false)
}
const coachConfirmSolved = () => {
  emit('confirm-solution')
}
</script>

<template>
  <Popup title="Notify" :open="!!coachMessage" @close="close">
    <Loading
      class="curriculum-popup-container"
      background-color="#ffffffac"
      is-fullscreen
      :is-loading="isLoading"
      size="36"
    />
    <span class="text-base curriculum-popup-container">{{ coachMessage }}</span>
    <template #footer>
      <div class="flex">
        <button
          type="button"
          class="btn btn-tertiary text-white text-base w-full"
          :disabled="isLoading"
          @click="close"
        >
          {{ $t('help-popup.cancel-btn') }}
        </button>
        <button
          type="button"
          class="btn btn-primary text-white text-base w-full ml-2"
          :disabled="isLoading"
          @click="coachConfirmSolved"
        >
          {{ $t('help-popup.confirm-btn') }}
        </button>
      </div>
    </template>
  </Popup>
</template>

<style lang="scss" scoped>
.curriculum-popup-container {
  color: #4a4a4a;
}
</style>
