import { ZoomRoomConnectingState } from '@/types/enums/room.enum'
import { ExecutedFailure, Stream, VideoQuality } from '@zoom/videosdk'
import UAParser from 'ua-parser-js'
import promiseHandler from './promise-handler'

export const isIPad = () => {
  return /MacIntel/i.test(navigator.platform) && navigator?.maxTouchPoints > 2
}
export const isIOSMobile = (ua?: string) => {
  const { userAgent } = navigator
  const result = new UAParser(ua || userAgent).getResult()
  const isIOS = /iPad|iPhone|iPod/i.test(result.ua)
  return (
    isIOS ||
    isIPad() ||
    (['iOS', 'macOS'].includes(result.os.name || '') &&
      ['mobile', 'tablet'].includes(result.device.type || ''))
  )
}

export const isAndroidBrowser = (ua?: string) => {
  const { userAgent } = navigator
  const result = new UAParser(ua || userAgent).getResult()
  return /android/i.test(result.ua) || result.os.name === 'Android'
}
export const isAndroidOrIOSBrowser = (ua?: string) => {
  const { userAgent } = navigator
  const result = new UAParser(ua || userAgent).getResult()
  return isAndroidBrowser(result.ua) || isIOSMobile(result.ua)
}

export const mappingErrorResponse = (error: ExecutedFailure) => {
  const { type, reason } = error
  switch (type) {
    case 'INVALID_OPERATION':
    case 'OPERATION_TIMEOUT':
    case 'INTERNAL_ERROR':
      return {
        state: ZoomRoomConnectingState.NOT_INITIALIZE,
        message: reason
          ? `cannot initialize ${reason}`
          : `cannot initialize: ${JSON.stringify(error)}`,
      }

    default:
      return {
        state: ZoomRoomConnectingState.CONNECTING_FAILED,
        message: reason ? `connect fail: ${reason}` : `connect fail: ${JSON.stringify(error)}`,
      }
  }
}

interface IRenderPayload {
  stream: typeof Stream
  canvas: HTMLCanvasElement
  isRendered?: boolean
  videoQuality?: VideoQuality
  option?: {
    width: number
    height: number
    userId: number
  }
}

export const renderAndAdjustCanvas = async (payload: IRenderPayload) => {
  const { canvas, stream, isRendered, option } = payload
  const width = option?.width || 300
  const height = option?.height || 150
  const userId = option?.userId || 0
  const videoQuality = payload.videoQuality || VideoQuality.Video_360P
  let renderErr

  const [, error] = await promiseHandler(stream.updateVideoCanvasDimension(canvas, width, height))
  if (error) {
    console.warn('cannot update video canvas dimension', error)
    return
  }
  if (isRendered) {
    const [, err] = await promiseHandler(
      stream?.adjustRenderedVideoPosition(canvas, userId, width, height, 0, 0),
    )
    renderErr = err
  } else {
    const [, err] = await promiseHandler(
      stream?.renderVideo(canvas, userId, width, height, 0, 0, videoQuality),
    )
    renderErr = err
  }
  if (renderErr) {
    console.warn(renderErr)
  }
  return !renderErr
}
