<script setup lang="ts">
import { computed, ref } from 'vue'
import useSpaceApi from '@/composables/api/useSpaceApi'
import useNotify from '@/composables/useNotify'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  classId: {
    type: Number,
    required: true,
  },
  isFromLessonBtn: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['update:modelValue', 'success', 'leave'])
const notification = useNotify()
const { saveLesson } = useSpaceApi()
const lessonNo = ref(0)
const lessonName = ref('')
const disabled = computed(() => (lessonNo.value && lessonName.value ? false : true))
const submit = async () => {
  const res = await saveLesson({
    lessonNumber: lessonNo.value,
    lessonName: lessonName.value,
    classId: props.classId,
  })
  if (res.status === 201) {
    notification.success({ text: 'Submit Lesson Success!' })
    emit('success')
    close()
  } else {
    notification.warning({ text: 'Could not submit the lesson. Please try again.' })
  }
}
const close = () => {
  emit('update:modelValue', !props.modelValue)
}
</script>

<template>
  <Popup title="Which lesson did you teach?" :open="modelValue" @close="close">
    <div class="text-base form-container">
      <label>Lesson No.</label>
      <input v-model="lessonNo" type="number" class="mb-3" />
      <label>Lesson Name</label>
      <input v-model="lessonName" type="type" />
    </div>
    <template #footer>
      <div class="footer-setting">
        <button :disabled="disabled" type="button" class="btn btn-primary" @click="submit">
          Submit
        </button>
        <button type="button" class="btn btn-tertiary" @click="close">Cancel</button>
      </div>
      <div v-if="!isFromLessonBtn" class="flex justify-center mt-3">
        <button type="button" class="btn btn-link" @click="emit('leave')">
          Or leave this room
        </button>
      </div>
    </template>
  </Popup>
</template>

<style lang="scss" scoped>
label {
  font-size: 1rem;
  @include font-weight(regular);
}
input {
  border: 1.5px solid $gs-4;
  padding: 8px;
  border-radius: 3px;
  width: 100%;
  outline-color: var(--primary-color);
}

input:focus {
  border-color: rgba(var(--primary-color-rgb), 0.4);
  box-shadow: 0 0 0 0.15rem rgba(var(--primary-color-rgb), 0.25);
}

input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.form-container {
  color: #4a4a4a;
  padding: 1rem 0;
}
.btn {
  font-size: 1.1rem;
  &.btn-link {
    text-decoration: underline;
    color: $gs-3;
    font-size: 1rem;
    @include font-weight(regular);
    &:hover {
      text-decoration: underline !important;
      color: $gs-2;
    }
  }
}
.footer-setting {
  .btn {
    width: 100%;
  }

  @include media-breakpoint-up-custom(700px) {
    .btn {
      width: 50%;
    }
  }
}
</style>
