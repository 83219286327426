import { ConnectionChangePayload, ConnectionState, ReconnectReason } from '@zoom/videosdk'

export const detectConnectionChanged = (connectionPayload: ConnectionChangePayload) => {
  const { state, reason, subsessionName } = connectionPayload
  switch (state) {
    // type ClosedReason = 'kicked by host' | 'ended by host' | 'expeled by host';
    case ConnectionState.Closed: {
      if (reason === 'ended by host') {
        return {
          title: 'Meeting ended',
          content: 'This meeting has been ended by host',
          status: ConnectionState.Closed,
        }
      } else {
        return {
          title: 'Meeting ended',
          content: `This meeting has been ended by ${reason}`,
          status: ConnectionState.Closed,
        }
      }
    }
    case ConnectionState.Reconnecting: {
      if (reason === ReconnectReason.Failover) {
        return {
          title: 'Reconnect session',
          content: 'This meeting has been disconnected',
          status: ConnectionState.Reconnecting,
        }
      } else if (
        reason === ReconnectReason.JoinSubsession ||
        reason === ReconnectReason.MoveToSubsession
      ) {
        return {
          title: 'Reconnect session',
          content: `Joining to ${subsessionName}`,
          status: ConnectionState.Reconnecting,
        }
      } else if (reason === ReconnectReason.BackToMainSession) {
        return {
          title: 'Reconnect session',
          content: 'Return to main session',
          status: ConnectionState.Reconnecting,
        }
      } else {
        return {
          title: 'Reconnect session',
          content: 'This meeting has been ended by host',
          status: ConnectionState.Reconnecting,
        }
      }
    }

    case ConnectionState.Connected: {
      return {
        title: 'Join success',
        content: 'This meeting has been success',
        status: ConnectionState.Connected,
      }
    }
    default: {
      return {
        title: 'Meeting fail',
        content: 'This meeting has been fail',
        status: ConnectionState.Fail,
      }
    }
  }
}
