import { reactive, toRefs } from 'vue'
import useDocApi, {
  AnnotationInfo,
  AuthorInfo,
  DocumentInfo,
  RefreshWriteboard,
} from '../api/useDocApi'
import WebViewer from '@pdftron/webviewer'
import { initDocumentSocket } from '@/composables/document/useDocumentSocket'
import { Socket } from 'socket.io-client'
import { DocEvent, PDFCollaboration } from './collaboration'
import useTheme from '@/composables/useTheme'
import _ from 'lodash'
import { localParticipantInfo, participantsInfo } from '@/composables/room/useRoomState'
import { IFollowMyPage } from '@/types/interfaces/room.interface'
import { findParticipantInfoByEmail } from '@/helpers/participant.helper'
import useRoomNotify from '../room/useRoomNotify'
import { IParticipantInfo } from '@/types/interfaces/participant.interface'

const PDFTRON_LICENSE_PREFIX =
  'Globish Academia (Thailand) Co.,Ltd.:OEM:Globish Space::B+:AMS(20241231):'
export const pdftronLicense = `${PDFTRON_LICENSE_PREFIX}${process.env.PDFTRON_LICENSE_KEY}`
const WEBVIEWER_SERVER_URL = process.env.WEBVIEWER_SERVER_URL
// const INIT_WEB_VIEWER_TIMEOUT = 45000 // 45s

/**
 * **************
 *      type
 * **************
 */
export type ControlBy = 'owner' | 'other' | 'none'

/**
 * *******************
 *      interface
 * *******************
 */

export interface IInitWebViewerParams {
  documentId: string
  email: string
  name?: string
  canToggleWritable?: boolean
  canToggleFollowing?: boolean
  canManagePermission?: boolean
  participantId?: string
  isWhiteboard?: boolean
  isRenderAllAnnotation?: boolean
}

export interface IWritableAuthor extends AuthorInfo {
  readonly: boolean
}

export interface IUseWEbViewerState {
  pdfCollaboration: PDFCollaboration
  viewerRef: HTMLElement
  toggleFollowMe: boolean
  toggleWritable: boolean
  socket: Socket
  documentInfo?: DocumentInfo
  authorInfo?: AuthorInfo
  controlBy: ControlBy
  currentPage: number
  annotPages: number[]
  activeUsers: IWritableAuthor[]
  refreshWhiteboardData?: RefreshWriteboard
  isLoading: boolean
  isLoadingAllAnnots: boolean
  isConnected: boolean
  isOnInitWebViewer: boolean
}

export interface IDocumentState {
  id: string
  users: string[]
  authors?: IWritableAuthor[]
  controlBy?: string
  followPage?: number
  // optional for whiteboard
  authorId?: string
  wbControl?: string
}

export default function useWebViewer() {
  /**
   * use web viewer state & variable
   */
  const state = reactive<IUseWEbViewerState>({
    pdfCollaboration: {} as PDFCollaboration,
    viewerRef: {} as HTMLElement,
    toggleFollowMe: false,
    toggleWritable: false,
    socket: {} as Socket,
    documentInfo: undefined,
    authorInfo: undefined,
    controlBy: 'none',
    currentPage: 0,
    annotPages: [],
    activeUsers: [],
    refreshWhiteboardData: undefined,
    isLoading: true,
    isLoadingAllAnnots: false,
    isConnected: true,
    isOnInitWebViewer: false,
  })
  /**
   * use composables
   */
  const { getDocumentById, getAuthorByEmail } = useDocApi()
  const { getAnnotations, getAllAnnotations: getAllAnnotationsApi } = useDocApi()
  const { isDark } = useTheme()
  const roomNotify = useRoomNotify()

  /**
   * *****************
   *      methods
   * *****************
   */
  // const isInitWebViewerTooLong = () => {
  //   if (state.isOnInitWebViewer) {
  //     state.isConnected = false
  //   }
  // }

  const onToggleFollowMe = (value: boolean) => {
    /**
     * emit socket event when value is "true"
     * set documentId authorId and currentPage
     */
    if (state.socket?.id && value) {
      state.socket.emit('toggleFollowPage', {
        documentId: state.documentInfo?.id,
        authorId: state.authorInfo?.id,
        page: state.currentPage,
      })
    }
    /**
     * emit socket event when value is "false"
     * set authorId and page to null
     */
    if (state.socket?.id && !value) {
      state.socket.emit('toggleFollowPage', {
        documentId: state.documentInfo?.id,
        authorId: null,
        page: null,
      })
    }
    /**
     * emit socket event notify follow my page
     */
    if (state.socket?.id) {
      state.socket.emit('notifyToggleFollowPage', {
        documentId: state.documentInfo?.id,
        isOpen: value,
        by: localParticipantInfo.value?.email,
      })
    }
  }
  const toggleReadonly = (email: string) => {
    state.activeUsers = state.activeUsers.map((item) => {
      /**
       * if matched active users with toggle readonly email
       * then set readonly state and emit socket
       */
      if (item.email === email) {
        /**
         * emit socket event when update author readonly
         * and set it into document state
         */
        state.socket.emit('toggleWritableDoc', {
          documentId: state.documentInfo?.id,
          authorId: item.id,
          readonly: !item.readonly,
        })
      }
      return { ...item }
    })
  }

  const getAllAnnotations = async () => {
    state.isLoadingAllAnnots = true
    const maxPage = state.pdfCollaboration.getPageCount()
    state.annotPages = _.range(1, maxPage + 1)
    let currentPage = 1
    let totalPage = 1

    const timer = setInterval(async () => {
      if (!state.documentInfo?.id) return
      const { data, status } = await getAllAnnotationsApi({
        docId: state.documentInfo?.id,
        currentPage,
        perPage: 25,
      })
      if (status === 200 && data) {
        const annotHistories = data.data
        totalPage = data.totalPage
        annotHistories.map((d) => state.pdfCollaboration.onAnnotationCreated(d))
      }
      currentPage += 1
      if (currentPage > totalPage) {
        state.isLoadingAllAnnots = false
        clearInterval(timer)
      }
    }, 50)
  }

  /**
   * ************************************
   *      initial WebViewer function
   * ************************************
   */
  const initWebViewer = async ({
    email,
    name,
    documentId,
    participantId,
    isWhiteboard = false,
    isRenderAllAnnotation = false,
    canManagePermission = false,
  }: IInitWebViewerParams) => {
    state.isConnected = true
    state.isOnInitWebViewer = true
    // setTimeout(isInitWebViewerTooLong, INIT_WEB_VIEWER_TIMEOUT)
    /**
     * Set important information before init PDFTron
     */
    if (!isWhiteboard) {
      /*`
       * fetch document and author info
       */
      const [authorInfo, documentInfo] = await Promise.all([
        getAuthorByEmail(email),
        getDocumentById(documentId),
      ])
      state.documentInfo = documentInfo
      state.authorInfo = authorInfo
    } else {
      state.documentInfo = {
        id: documentId,
        name: 'whiteboard',
        docUrl: 'https://d1bnvx5vhcnf8w.cloudfront.net/curriculum/blank_page.pdf',
      }
      state.authorInfo = {
        id: participantId || email,
        name: name || 'Annonymous',
        email,
      }
    }
    /**
     * init document socket
     */
    state.socket = initDocumentSocket()
    /**
     * init PDFTron webviewer
     *
     * This process.env.BASE_URL seem to be in WebViewer lib
     * set instance of WebViewer
     */
    const instance = await WebViewer(
      {
        initialDoc: decodeURI(state.documentInfo?.docUrl || ''),
        path: `${process.env.BASE_URL}webviewer`,
        fullAPI: true,
        webviewerServerURL: WEBVIEWER_SERVER_URL,
        licenseKey: pdftronLicense,
      },
      state.viewerRef,
    )
    /**
     * new PDF collaboration
     */
    state.pdfCollaboration = new PDFCollaboration(
      instance,
      state.documentInfo?.id || 'default-document-id',
      state.authorInfo?.id || 'default-author-id',
      state.authorInfo?.name || 'default-author-name',
      isWhiteboard,
    )
    state.pdfCollaboration.setTheme(isDark.value ? 'dark' : 'light')
    // state.pdfCollaboration.removeHeaderItems()
    /**
     * *************************************
     *      listening pdf collaboration
     * *************************************
     */

    state.pdfCollaboration.on(DocEvent.ADD, (data) => {
      /**
       * emit an created annotation
       */
      state.socket.emit('createAnnotation', data)
    })
    state.pdfCollaboration.on(DocEvent.MODIFY, (data) => {
      /**
       * emit an updated annotation
       */
      state.socket.emit('modifyAnnotation', data)
    })
    state.pdfCollaboration.on(DocEvent.DELETE, (data) => {
      /**
       * emit an deleted annotation
       */
      state.socket.emit('deleteAnnotation', data)
    })
    state.pdfCollaboration.on(DocEvent.LOADED, async () => {
      state.isLoading = false

      if (isRenderAllAnnotation) {
        getAllAnnotations()
      }
      /**
       * redraw previous annotations when new user has joined.
       */
      if (
        state.authorInfo?.id === state.refreshWhiteboardData?.authorId &&
        state.refreshWhiteboardData?.authorId &&
        state.refreshWhiteboardData?.xfdf
      ) {
        await state.pdfCollaboration.onAnnotationCreated({
          author: { name: name || 'Anonymous', email, id: state.refreshWhiteboardData.authorId },
          xfdfId: 'xfdf-id',
          xfdf: state.refreshWhiteboardData.xfdf,
          id: NaN,
        })
      }
    })
    state.pdfCollaboration.on(DocEvent.PAGE_UPDATED, async (page) => {
      // set current page into state
      state.currentPage = page
      /**
       * Handle Follow Page
       *  emit socket event when control by state is "owner"
       *  and toggle follow me is true
       */
      if (state.controlBy === 'owner' && state.toggleFollowMe) {
        // emit socket toggleFollowPage for enable
        state.socket.emit('toggleFollowPage', {
          documentId: state.documentInfo?.id,
          authorId: state.authorInfo?.id,
          page,
        })
      }
      if (isRenderAllAnnotation) return
      /**
       * condition to fetch annotations by currentPage, upPage and downPage
       */
      const topPage = page - 1
      const bottomPage = page + 1

      /**
       * Code below are used for prevent fetching
       * duplicated annotations from each page
       */

      // display annotation at top of current page
      if (topPage > 0 && !state.annotPages.includes(topPage)) {
        state.annotPages.push(topPage)
        const annotHistories = await getAnnotations(documentId, topPage)
        annotHistories.map((data) => state.pdfCollaboration.onAnnotationCreated(data))
      }
      // display annotation at current page
      if (!state.annotPages.includes(page)) {
        state.annotPages.push(page)
        const annotHistories = await getAnnotations(documentId, page)
        annotHistories.map((data) => state.pdfCollaboration.onAnnotationCreated(data))
      }
      // display annotation at bottom of current page
      if (!state.annotPages.includes(bottomPage)) {
        state.annotPages.push(bottomPage)
        const annotHistories = await getAnnotations(documentId, bottomPage)
        annotHistories.map((data) => state.pdfCollaboration.onAnnotationCreated(data))
      }
    })

    state.socket.on('notifyToggleFollowPage', (data: IFollowMyPage) => {
      const { isOpen, by } = data
      let participant: IParticipantInfo | undefined
      if (participantsInfo.value) {
        participant = findParticipantInfoByEmail(by, participantsInfo.value)
      }
      if (participant) {
        roomNotify.toggleFollowMyPage(isOpen, participant)
      }
    })
    /**
     * ********************************
     *      listening socket event
     * ********************************
     */

    /**
     * when an annotation was created by another author
     */
    state.socket.on('annotationCreated', async (data: AnnotationInfo) => {
      await state.pdfCollaboration.onAnnotationCreated(data)
    })
    /**
     * when an annotation was updated by another author
     */
    state.socket.on('annotationUpdated', async (data: AnnotationInfo) => {
      await state.pdfCollaboration.onAnnotationUpdated(data)
    })
    /**
     * when an annotation was deleted by another author
     */
    state.socket.on('annotationDeleted', async (annotationId: string) => {
      await state.pdfCollaboration.onAnnotationDeleted(annotationId)
    })
    /**
     * when document state has changed
     */
    state.socket.on('updateDocState', async (data: IDocumentState) => {
      /**
       * **********************************
       *      REFRESH WRITEBOARD LOGIC
       * **********************************
       */

      if (isWhiteboard && state.authorInfo?.id === data.wbControl) {
        const xfdf = await state.pdfCollaboration.getAnnotationsCommand()
        if (xfdf) {
          /**
           * @NOTE data.authorId is joined-user
           */
          state.socket.emit('refreshWhiteboard', {
            documentId,
            authorId: data.authorId,
            xfdf,
          })
        }
      }

      /**
       * ********************************
       *      TOGGLE WRITABLE LOGIC
       * ********************************
       */

      // set active authors form document state
      state.activeUsers = data?.authors || []
      // set new readonly permission for each user
      state.activeUsers.forEach((user) => {
        if (user.email === email) {
          state.pdfCollaboration.setReadOnly(user.readonly)
        }
      })

      /**
       * *************************************
       *      TOGGLE FOLLOWING PAGE LOGIC
       * *************************************
       */

      state.controlBy =
        data?.controlBy === state.authorInfo?.id ? 'owner' : data?.controlBy ? 'other' : 'none'
      state.toggleFollowMe = !!data?.controlBy
      /**
       * if document state is controlled by someone
       * set other author's page to current page
       */
      if (state.controlBy === 'other' && data?.controlBy && data?.followPage) {
        state.pdfCollaboration.setCurrentPage(data.followPage)
      }
    })
    state.socket.on('refreshWhiteboard', async (data: RefreshWriteboard) => {
      state.refreshWhiteboardData = data
    })
    /**
     * Clear Whiteboard
     */
    const clearWhiteboard = () => {
      state.socket.emit('cleanWhiteboard', documentId)
    }
    if (isWhiteboard && canManagePermission) {
      state.pdfCollaboration.elementClearAll(clearWhiteboard)
    }

    state.socket.on('cleanWhiteboard', () => {
      state.pdfCollaboration.clearAnnotations()
    })

    /**
     * ********************************
     *      emitting socket event
     * ********************************
     */

    /**
     * join document socket
     */
    state.socket.emit('joinDocument', {
      documentId: state.documentInfo?.id || 'default-document-id',
      authorId: state.authorInfo?.id || 'default-author-id',
    })

    // const { documentViewer } = instance.Core
    // documentViewer.addEventListener(instance.UI.Events.DOCUMENT_LOADED, () => {
    //   state.isOnInitWebViewer = false
    // })
  }

  return {
    ...toRefs(state),
    onToggleFollowMe,
    toggleReadonly,
    initWebViewer,
    getAllAnnotations,
  }
}
