<script setup lang="ts">
import DiagnosticSection from '@/components/video-diagnostic/DiagnosticSection.vue'
import { useCheckPermissions } from '@/composables/video-diagnostic/useCheckPermissions'
import { Stage, Topic } from '@/types/enums/video-diagnostic.enum'
import { IUpdateTopic } from '@/types/interfaces/video-diagnostic.interface'
import { permissions } from '@/composables/video-diagnostic/useCheckPermissions'
import { watch, computed } from 'vue'
import UAParser from 'ua-parser-js'

interface CheckPermissionsEmits {
  (e: 'updateTopic', value: IUpdateTopic): void
  (e: 'nextStage', value: number): void
}

const props = defineProps({
  stage: {
    type: Number,
    required: true,
  },
})

const emit = defineEmits<CheckPermissionsEmits>()

const allowPermissionImg = computed(() => {
  const userAgentParser = new UAParser()
  const userAgentInfo = userAgentParser.getResult()
  const browser = userAgentInfo.browser

  if (browser.name?.includes('Chrome')) {
    return require('../../assets/video-diagnostic/allow-permission-chrome.png')
  }
  if (browser.name?.includes('Firefox')) {
    return require('../../assets/video-diagnostic/allow-permission-firefox.png')
  }
  if (browser.name?.includes('Edge')) {
    return require('../../assets/video-diagnostic/allow-permission-edge.png')
  }
  if (browser.name?.includes('Safari')) {
    return require('../../assets/video-diagnostic/allow-permission-safari.png')
  }
  return require('../../assets/video-diagnostic/allow-permission-default.png')
})

watch(
  () => props.stage,
  (stage, prevStage) => {
    if (stage === Stage.CHECK_PERMISSIONS) {
      emit('updateTopic', { topic: Topic.DEVICE_SETUP, active: true })
    }
    if (prevStage === Stage.CHECK_PERMISSIONS && stage === Stage.GET_STARTED) {
      emit('updateTopic', { topic: Topic.DEVICE_SETUP, active: false })
    }
  },
)

const checkPermissionsEmit = async () => {
  try {
    await useCheckPermissions()
    permissions.value = true
    emit('nextStage', Stage.CAMERA_TEST)
    // setTimeout(() => {
    //   permissions.value = true
    //   emit('nextStage', Stage.CAMERA_TEST)
    // }, 500)
  } catch (err) {
    emit('nextStage', Stage.PERMISSION_ERROR)
    // setTimeout(() => {
    //   emit('nextStage', Stage.PERMISSION_ERROR)
    // }, 500)
  }
}
</script>

<template>
  <DiagnosticSection
    :id="Stage.CHECK_PERMISSIONS"
    :class="
      stage === Stage.GET_STARTED
        ? 'opacity-0'
        : stage === Stage.CHECK_PERMISSIONS || stage === Stage.RESULTS
        ? 'block'
        : 'hidden md:block opacity-30 pointer-events-none'
    "
  >
    <template #default>
      <div class="row-span-2 text-sm sm:text-base lg:text-sm xl:text-base pt-10">
        <h1 class="font-bold text-xl sm:text-4xl lg:text-xl xl:text-4xl">
          {{ $t('video-diagnostic.check-permissions') }}
        </h1>
        <p class="py-4 sm:py-10">
          {{ $t('video-diagnostic.check-permissions-detail') }}
        </p>
        <p class="py-4 sm:py-10 font-bold">{{ $t('video-diagnostic.allow-permissions') }}</p>
        <button
          :class="{ 'opacity-30 pointer-events-none': stage === Stage.RESULTS }"
          class="text-white border-2 rounded-md border-white py-2 px-4"
          type="button"
          @click="checkPermissionsEmit"
        >
          {{ $t('video-diagnostic.request-permissions-button') }}
        </button>
      </div>

      <div
        class="row-span-2 mt-10 p-6 max-w-sm h-fit bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700"
      >
        <p class="font-bold mb-3">{{ $t('video-diagnostic.grant-permissions') }}</p>
        <img :src="allowPermissionImg" alt="allow permission img for globish video diagnostic" />
      </div>
    </template>
  </DiagnosticSection>
</template>

<style lang="scss" scoped>
button {
  background-color: #f67519;
}

.h-fit {
  height: fit-content;
}
</style>
