import { Engine, VideoRoomConnectionState } from '@/types/enums/room.enum'
import { IParticipantInfo } from '@/types/interfaces/participant.interface'
import { ref } from 'vue'

export const localParticipantInfo = ref<IParticipantInfo | undefined>(undefined)
export const participantsInfo = ref<IParticipantInfo[] | undefined>(undefined)
export const roomConnectState = ref<VideoRoomConnectionState>(
  VideoRoomConnectionState.NotInitialised,
)
export const roomConnectErrorMessage = ref<string>('')
export const engineAfter = ref<Engine>(Engine.TWILIO)
