<script setup lang="ts">
import PreviewWrapper from '@/components/checkup/PreviewWrapper.vue'
import { setLocalTrackElement } from '@/helpers/device.helper'
import { useDeviceStore } from '@/stores'
import { onMounted, ref, watch } from 'vue'

const localTrackRef = ref<HTMLVideoElement>()
const device = useDeviceStore()
const setLocalTrack = () => {
  const option = {
    haveAudio: device.haveAudio,
    haveVideo: device.haveVideo,
    cameraOn: device.cameraOn,
    microphoneOn: device.microphoneOn,
  }
  setLocalTrackElement(
    {
      localTrackRef: localTrackRef.value,
      localTracks: device.localTracks,
      option,
    },
    (level) => {
      device.audioLevel = level
    },
  )
}
watch(
  () => device.localTracks,
  () => setLocalTrack(),
  { deep: true },
)
onMounted(() => {
  setLocalTrack()
})
</script>

<template>
  <PreviewWrapper
    :is-ready="device.isReady"
    :camera-enabled="device.cameraOn"
    :is-camera-found="device.haveVideo"
    :allowed-camera="device.allowedCamera"
    :microphone-enabled="device.microphoneOn"
    :is-microphone-found="device.haveAudio"
    :allowed-microphone="device.allowedMicrophone"
    @toggle-camera="device.toggleCamera"
    @toggle-microphone="device.toggleMicrophone"
  >
    <div ref="localTrackRef" class="video-track --me"></div>
  </PreviewWrapper>
</template>

<style lang="scss" scoped>
.video-container {
  --track-radius: 10px;
  position: relative;
  height: 100%;
  width: 100%;
  height: 35vh;
  border-radius: var(--track-radius);
  margin-top: 1rem;
  background: #272727;
  @include media-breakpoint-up-custom(768px) {
    width: 40vw;
    height: 30vh;
    min-height: 320px;
    max-height: 480px;
    max-width: 720px;
    margin-top: 1.5rem;
  }
  @include media-breakpoint-up-custom(1000px) {
    min-height: 380px;
  }

  .track-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--track-radius);
    overflow: hidden;

    .video-button {
      position: absolute;
      top: 90%;
      left: 50%;
      transform: translate(-50%, -50%);
      .control-btn {
        border-radius: 50%;
        width: 36px !important;
        height: 36px !important;
        background: transparent;
        border: solid 1px;
        color: white !important;
        transition-duration: 0.4s;
        @include media-breakpoint-up-custom(768px) {
          width: 48px !important;
          height: 48px !important;
        }
      }
      > div {
        position: relative;
        .icon-container {
          position: absolute;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 14px;
          height: 14px;
          border-radius: 50%;
          border: solid 1px #f67519;
          top: -5%;
          right: -5%;
          background-color: #f67519;
          color: #ffffff;
          @include media-breakpoint-up-custom(768px) {
            width: 20px;
            height: 20px;
          }
        }
      }
      > div:first-child {
        margin-right: 12px;
      }
    }
    .camera-notify {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 18px;
    }

    .microphone-notify-container {
      position: absolute;
      top: 10%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 12px;
      color: #ffffff;
      .microphone-notify {
        width: auto;
        height: 42px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 12px 24px;
        background: #1d1d1d;
        border-radius: 24px;

        transform: translateX(-80px);
        opacity: 0;
        transition-property: transform, opacity;
        transition-duration: 0.5s;
        transition-timing-function: ease;
      }
      .microphone-notify-active {
        transform: translateX(0);
        opacity: 1;
        transition-delay: 0ms;
      }
    }

    .text-off {
      width: auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      justify-content: center;
    }
  }
}
::v-deep(.video-button > .control-item:first-child) {
  margin-right: 12px;
}
::v-deep(.video-button > .control-item:hover) {
  background-color: rgba(255, 255, 255, 0.7) !important;
}
::v-deep(.video-button > .control-item.-disabled) {
  background: $danger !important;
  border-color: $danger !important;
}
::v-deep(.video-button > .control-item.-disabled:hover) {
  background-color: #f7475bc9 !important;
}
::v-deep(.video-button > div > .control-item.-disabled) {
  background: #f7475b8a !important;
  color: #ffffff9f !important;
}
::v-deep(.video-button > div > .control-item.-disabled:hover) {
  background-color: #f7475b8a !important;
}
</style>
