import { isAndroidBrowser, isIOSMobile } from '../helpers/zoom-device.helper'
import { HelpAction, SolveAction } from '../types/enums/help.enum'
import { IHelpItem } from '../types/interfaces/help.interface'

export const studentHelpList = (isMainEngine?: boolean): IHelpItem[] => {
  const roomName =
    isMainEngine || isMainEngine === undefined
      ? { th: 'สำรอง', en: 'second', vn: 'thứ hai' }
      : { th: 'หลัก', en: 'main', vn: 'chủ yếu' }
  const isMobile = isIOSMobile() || isAndroidBrowser()
  return [
    {
      action: HelpAction.CANNOT_SEE_COACH,
      icon: 'eye',
      header: {
        th: 'ไม่เห็นโค้ชในห้องเรียน',
        en: 'You cannot see the coach in the classroom.',
        vn: 'Tôi không thể nhìn thấy giáo viên trong lớp học.',
      },
      content: {
        th: `หากท่านกำลังมีปัญหา ไม่เห็นโค้ชในห้องเรียน กรุณากดปุ่ม ส่งคำขอย้ายห้อง เพื่อส่งคำร้องขอไปยังโค้ชสำหรับการย้ายไปยังห้องเรียน${roomName.th}`,
        en: `If you are having trouble seeing the coach in the classroom, please press the "Request Room Change" button to send a request to the coach for moving to a ${roomName.en} classroom.`,
        vn: `Nếu bạn không nhìn thấy giáo viên trong lớp học, vui lòng nhấn nút "Yêu cầu đổi phòng học" để yêu cầu giáo viên chuyển sang ${roomName.vn}`,
      },
      choices: [
        {
          label: {
            th: 'ส่งคำขอย้ายห้องไปยังโค้ช',
            en: 'Request Room Change.',
            vn: 'Yêu cầu đổi phòng học',
          },
          icon: 'change-room',
          solveBy: SolveAction.MOVE_ROOM,
        },
      ],
    },
    {
      action: HelpAction.COACH_CANT_HEAR_STUDENT,
      icon: 'hearing',
      header: {
        th: 'โค้ชไม่ได้ยินเสียง​ของท่าน',
        en: 'The coach cannot hear your voice.',
        vn: 'Giáo viên không nghe được tiếng của tôi',
      },
      content: {
        th: `หากท่านกำลังมีปัญหา ไม่เห็นโค้ชในห้องเรียน กรุณากดปุ่ม ส่งคำขอย้ายห้อง เพื่อส่งคำร้องขอไปยังโค้ชสำหรับการย้ายไปยังห้องเรียน${roomName.th}`,
        en: `If you are having trouble seeing the coach in the classroom, please press the "Request Room Change" button to send a request to the coach for moving to a ${roomName.en} classroom.`,
        vn: `Nếu giáo viên không nghe được giọng của bạn, vui lòng nhấn nút “Yêu cầu đổi phòng học” để gửi yêu cầu xin chuyển sang phòng học phụ đến giáo viên ${roomName.vn} lớp học`,
      },
      choices: [
        {
          label: {
            th: 'ส่งคำขอย้ายห้องไปยังโค้ช',
            en: 'Request Room Change.',
            vn: 'Yêu cầu đổi phòng học',
          },
          icon: 'change-room',
          solveBy: SolveAction.MOVE_ROOM,
        },
      ],
    },
    {
      action: HelpAction.VIDEO_PROBLEM,
      icon: 'cam',
      header: {
        th: 'ภาพกระตุก ค้าง หรือ ภาพและเสียงไม่ตรงกัน',
        en: 'The screen freezes, glitches, or the screen and voice do not match.',
        vn: 'Màn hình bị treo, trục trặc hay màn hình và giọng nói không khớp',
      },
      content: {
        th: 'Globish แนะนำให้ท่าน',
        en: 'Globish recommends that you.',
        vn: 'Globish khuyến nghị bạn',
      },
      choices: [
        {
          label: {
            th: 'ปิดกล้องเพื่อให้การเรียนของท่าน\nมีประสิทธิภาพมากขึ้น',
            en: 'Turn off the camera for a better learning experience.',
            vn: 'Tắt camera để học ổn định hơn \n(vui lòng báo với giáo viên)',
          },
          icon: 'cam-disabled',
          solveBy: SolveAction.MUTE_CAMERA,
        },
        {
          label: {
            th: 'ส่งคำขอย้ายห้องไปยังโค้ช',
            en: 'Request Room Change.',
            vn: 'Yêu cầu đổi phòng học',
          },
          icon: 'change-room',
          solveBy: SolveAction.MOVE_ROOM,
        },
      ],
    },
    {
      action: HelpAction.STUDENT_CANT_HEAR_COACH,
      icon: 'hearing',
      header: {
        th: 'ไม่ได้ยินเสียงโค้ชในห้องเรียน',
        en: 'You cannot hear the coach’s voice.',
        vn: 'Tôi không thể nghe được tiếng của giáo viên',
      },
      content: {
        th: 'Globish แนะนำให้ท่าน',
        en: 'Globish recommends that you',
        vn: ' Globish khuyến nghị bạn',
      },
      choices: [
        {
          label: {
            th: 'รีโหลดและเข้าห้องเรียนอีกครั้ง',
            en: 'Reload and re-enter the classroom.',
            vn: 'Tải lại và vào lại lớp học',
          },
          icon: 'reload',
          solveBy: SolveAction.RELOAD_ROOM,
        },
        {
          label: {
            th: 'ส่งคำขอย้ายห้องไปยังโค้ช',
            en: 'Request Room Change.',
            vn: 'Yêu cầu đổi phòng học',
          },
          icon: 'change-room',
          solveBy: SolveAction.MOVE_ROOM,
        },
      ],
    },
    {
      action: HelpAction.BLUETOOTH_PROBLEM,
      icon: 'headset',
      header: {
        th: 'หูฟัง Bluetooth เชื่อมต่อแล้วไม่ได้ยินหรือเสียงไม่ชัด',
        en: 'Your Bluetooth headphones are connected, but there is no sound or the sound is unclear.',
        vn: 'Tai nghe Bluetooth của tôi đã được kết nối nhưng không có âm thanh hoặc âm thanh không rõ ràng',
      },
      content: {
        th: 'กรุณาปิดการเชื่อมต่อหูฟัง bluetooth (Globish แนะนำให้ท่านเปิดลำโพง หรือ ใช้หูฟังแบบมีสาย) หากท่านทำการปิดการเชื่อมต่อเรียบร้อยแล้ว',
        en: 'Please disconnect the bluetooth headphones. (Globish recommends that you turn on the speakers or use wired headphones instead) If you have already disconnected them',
        vn: 'Hãy ngắt kết nối tại nghe bluetooth. (Globish khuyến nghị bạn bật tai nghe máy tính hoặc sử dụng tại nghe tai nghe chụp tai có dây để học tập). Nếu bạn đã ngắt kết nối, hãy làm các điều sau',
      },
      choices: [
        {
          label: {
            th: 'รีโหลดและเข้าห้องเรียนอีกครั้ง',
            en: 'Reload and re-enter the classroom.',
            vn: 'Tải lại và vào lại lớp họ',
          },
          icon: 'reload',
          solveBy: SolveAction.RELOAD_ROOM,
        },
        {
          label: {
            th: 'ส่งคำขอย้ายห้องไปยังโค้ช',
            en: 'Request Room Change.',
            vn: 'Yêu cầu đổi phòng học',
          },
          icon: 'change-room',
          solveBy: SolveAction.MOVE_ROOM,
        },
      ],
    },
    {
      action: HelpAction.CANNOT_SEE_DOC,
      icon: 'doc',
      header: {
        th: 'ไม่เห็นเอกสาร / เลื่อนเอกสารไม่ได้',
        en: 'You cannot see the document. / You cannot scroll the document.',
        vn: 'Tôi không nhìn thấy tài liệu. / Tôi không thể kéo tài liệu.',
      },
      content: {
        th: 'หากท่านกำลังมีปัญหาในส่วนของการเปิดเอกสาร กรุณากดปุ่ม ส่งคำขอแชร์เอกสาร เพื่อส่งคำร้องขอไปยังโค้ชสำหรับการแชร์หน้าจอเอกสาร',
        en: 'If you are having trouble opening the document, please press the "Request Document Share" button to send a request to the coach for screen sharing.',
        vn: 'Nếu gặp khó khăn khi mở tài liệu, vui lòng nhấn nút “Yêu cầu chia sẻ tài liệu” để gửi yêu cầu chia sẻ màn hình đến giáo viên',
      },
      choices: isMobile
        ? [
            {
              label: {
                th: 'ส่งคำขอแชร์เอกสาร',
                en: 'Request Document Share',
                vn: 'Yêu cầu chia sẻ tài liệu',
              },
              solveBy: SolveAction.SHARE_SCREEN,
              icon: 'doc',
            },
          ]
        : [
            {
              label: {
                th: 'ส่งคำขอแชร์เอกสาร',
                en: 'Request Document Share',
                vn: 'Yêu cầu chia sẻ tài liệu',
              },
              solveBy: SolveAction.SHARE_SCREEN,
              icon: 'doc',
            },
            {
              label: {
                th: 'เอกสาร ต้นฉบับ',
                en: 'Original Material',
                vn: 'Tải tài liệu gốc',
              },
              solveBy: SolveAction.OPEN_ORIGINAL_MATERIAL,
              icon: 'doc',
            },
          ],
    },
  ]
}

export const coachHelpList = (isMainEngine?: boolean): IHelpItem[] => {
  const roomName =
    isMainEngine || isMainEngine === undefined
      ? { th: 'สำรอง', en: 'second' }
      : { th: 'หลัก', en: 'main' }
  const isMobile = isIOSMobile() || isAndroidBrowser()
  return [
    {
      action: HelpAction.CANNOT_SEE_DOC,
      icon: 'eye',
      header: {
        th: 'Can’t open the material/ Material error',
        en: 'Can’t open the material/ Material error',
      },
      content: {
        th: 'หากท่านกำลังมีปัญหาในส่วนของการเปิดเอกสาร กรุณากดปุ่ม  Share your screen เพื่อให้ระบบทำการสอบถามสำหรับการเลือกหน้าจอที่โค้ชต้องการแชร์ให้กับนักเรียน หรือ สามารถกดปุ่ม original material ในกรณีที่โค้ชไม่สามารถทำการเปิดหรือแชร์เอกสารให้กับนักเรียนได้',
        en: 'If you can\'t open a document, click "Share your screen" to select the screen for sharing with students. If the coach can\'t open or share the document, click "Original material" instead.',
      },
      choices: isMobile
        ? [
            {
              label: {
                th: 'Share your screen',
                en: 'Share your screen',
              },
              solveBy: SolveAction.SHARE_SCREEN,
              icon: 'doc',
            },
          ]
        : [
            {
              label: {
                th: 'Share your screen',
                en: 'Share your screen',
              },
              solveBy: SolveAction.SHARE_SCREEN,
              icon: 'doc',
            },
            {
              label: {
                th: 'Original Material',
                en: 'Original Material',
              },
              solveBy: SolveAction.OPEN_ORIGINAL_MATERIAL,
              icon: 'doc',
            },
          ],
    },
    {
      action: HelpAction.COACH_CANT_HEAR_STUDENT,
      icon: 'eye',
      header: {
        th: 'Can’t hear the student',
        en: 'Can’t hear the student',
      },
      content: {
        th: `หากท่านกำลังมีปัญหา “ฉันไม่สามารถได้ยินนักเรียน” กรุณากดปุ่ม 'ย้ายไปยังห้องเรียน${roomName.th}' เพื่อระบบจะทำการย้ายห้องเรียนของท่านไปยังห้องเรียน${roomName.th}`,
        en: `If you can’t hear the student, please click the “Move to the ${roomName.en} room” button to switch to the ${roomName.en} room.`,
      },
      choices: [
        {
          label: {
            th: `Move to the ${roomName.en} room`,
            en: `Move to the ${roomName.en} room`,
          },
          solveBy: SolveAction.MOVE_ROOM,
          icon: 'change-room',
        },
      ],
    },
    {
      action: HelpAction.CANNOT_SEE_STUDENT,
      icon: 'eye',
      header: {
        th: 'Can’t see the student',
        en: 'Can’t see the student',
      },
      content: {
        th: `หากท่านกำลังมีปัญหา “ไม่สามารถเห็นนักเรียน” กรุณากดปุ่ม 'ย้ายไปยังห้องเรียน${roomName.th}' เพื่อระบบจะทำการย้ายห้องเรียนของท่านไปยังห้องเรียน${roomName.th}`,
        en: `If you can’t see the student, please click the “Move to the ${roomName.en} room” button to switch to the ${roomName.en} room.`,
      },
      choices: [
        {
          label: {
            th: `Move to the ${roomName.en} room`,
            en: `Move to the ${roomName.en} room`,
          },
          solveBy: SolveAction.MOVE_ROOM,
          icon: 'change-room',
        },
      ],
    },
    {
      action: HelpAction.CANNOT_SEE_COACH,
      icon: 'eye',
      header: {
        th: 'Student can’t see me',
        en: 'Student can’t see me',
      },
      content: {
        th: `หากท่านกำลังมีปัญหา “นักเรียนไม่สามารถมองเห็นฉัน” กรุณากดปุ่ม 'ย้ายไปยังห้องเรียน${roomName.th}' เพื่อระบบจะทำการย้ายห้องเรียนของท่านไปยังห้องเรียน${roomName.th}`,
        en: `If the student can’t see you, please click the “Move to the ${roomName.en} room” button to switch to the ${roomName.en} room.`,
      },
      choices: [
        {
          label: {
            th: `Move to the ${roomName.en} room`,
            en: `Move to the ${roomName.en} room`,
          },
          solveBy: SolveAction.MOVE_ROOM,
          icon: 'change-room',
        },
      ],
    },
    {
      action: HelpAction.STUDENT_CANT_HEAR_COACH,
      icon: 'eye',
      header: {
        th: 'Student can’t hear me',
        en: 'Student can’t hear me',
      },
      content: {
        th: `หากท่านกำลังมีปัญหา “นักเรียนไม่สามารถได้ยินฉัน” ท่านสามารถกดปุ่ม 'reload the classroom' เพื่อทำการโหลดห้องเรียนนี้ใหม่ หรือ กดปุ่ม 'ย้ายไปยังห้องเรียน${roomName.th}' เพื่อระบบจะทำการย้ายห้องเรียนของท่านไปยังห้องเรียน${roomName.th}`,
        en: `If you’re experiencing the "Student can't hear me" issue, click "Reload classroom" to reload the current class or “Move to the ${roomName.en} room” to move to the ${roomName.en} room.`,
      },
      choices: [
        {
          label: {
            th: 'Reload classroom',
            en: 'Reload classroom',
          },
          solveBy: SolveAction.RELOAD_ROOM,
          icon: 'reload',
        },
        {
          label: {
            th: `Move to the ${roomName.en} room`,
            en: `Move to the ${roomName.en} room`,
          },
          solveBy: SolveAction.MOVE_ROOM,
          icon: 'change-room',
        },
      ],
    },
    {
      action: HelpAction.VIDEO_PROBLEM,
      icon: 'eye',
      header: {
        th: 'Poor camera quality/ Poor connection',
        en: 'Poor camera quality/ Poor connection',
      },
      content: {
        th: `หากท่านกำลังมีปัญหาเกี่ยวกับสัญญาณอินเทอร์เน็ตหรือภาพ /เสียงกระตุก ท่านสามารถกดปุ่ม Turn your camera off for a stable connection เพื่อทำการปิดกล้อง หรือ กดปุ่ม 'ย้ายไปยังห้องเรียน${roomName.th}' เพื่อให้ระบบทำการย้ายห้องเรียนของท่านไปยังห้องเรียน${roomName.th}`,
        en: `If you are experiencing internet connection issues or lagging audio or video, you can click 'Turn your camera off for a stable connection' or “Move to the ${roomName.en} room” to switch to ${roomName.en} room for better stability.`,
      },
      choices: [
        {
          label: {
            th: 'Turn your camera off for a stable connection',
            en: 'Turn your camera off for a stable connection',
          },
          solveBy: SolveAction.MUTE_CAMERA,
          icon: 'cam-disabled',
        },
        {
          label: {
            th: `Move to the ${roomName.en} room`,
            en: `Move to the ${roomName.en} room`,
          },
          solveBy: SolveAction.MOVE_ROOM,
          icon: 'change-room',
        },
      ],
    },
  ]
}

interface IGenerateMessageOption {
  solveBy: SolveAction
  userName: string
  moveTo: string
  isNeedSupport: boolean
}
export const messageChatToCoach = ({
  solveBy,
  userName,
  isNeedSupport,
  moveTo,
}: IGenerateMessageOption) => {
  switch (solveBy) {
    case SolveAction.RELOAD_ROOM:
      return `${userName} is experiencing technical difficulties and reloading the room. Please wait for their return soon.`
    case SolveAction.MOVE_ROOM:
      if (isNeedSupport) {
        return `${userName} is experiencing technical difficulties and has requested that you move to the New Room. Please press the “Move to the ${moveTo} room” button.`
      } else {
        return `${userName} is experiencing technical difficulties and send request to support for move room.`
      }
    case SolveAction.SHARE_SCREEN:
      return `${userName} is experiencing difficulties opening the document and has requested that you share your screen. Please press the “Share Screen” button to share the document with them.`
    case SolveAction.MUTE_CAMERA:
      return `${userName} is experiencing connectivity issues and the camera is turning off.`
    default:
      return ''
  }
}

export const messageChatToStudent = ({
  solveBy,
  userName,
  isNeedSupport,
  moveTo,
}: IGenerateMessageOption) => {
  switch (solveBy) {
    case SolveAction.RELOAD_ROOM:
      return `${userName} is reloading this classroom due to hearing issues. Please wait for a moment until the issue is resolved.`
    case SolveAction.MOVE_ROOM:
      if (isNeedSupport) {
        return `${userName} is experiencing issues hearing the student. ${userName} will move you to the ${moveTo} room. Please click the “Join ${moveTo} room” to switch to the new room.`
      } else {
        return `${userName} is experiencing issues hearing the student. Student Support will move you to the ${moveTo} room. Please click the “Join ${moveTo} room” to switch to the new room.`
      }
    case SolveAction.SHARE_SCREEN:
      return `${userName} is experiencing issues opening the document and will share their screen instead. Students, please wait in the classroom for a moment.`
    case SolveAction.MUTE_CAMERA:
      return `${userName}'s camera has been turned off to improve internet stability.`
    default:
      return ''
  }
}
