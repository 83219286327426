import axios from 'axios'
import { UAParser } from 'ua-parser-js'
import { useRoomStore } from '@/stores'
import { GChatGroup, LogDeviceProblemAction } from '@/types/enums/log.enum'
import moment from 'moment-timezone'
import promiseHandler from '@/helpers/promise-handler'
import { IGGChatOptionLog } from '@/types/interfaces/log.interface'
import { HelpAction } from '@/types/enums/help.enum'

export default function useGChatLog() {
  const roomStore = useRoomStore()

  const sendMessage = (group: GChatGroup, option: IGGChatOptionLog) => {
    let urlWebHook = ''
    switch (group) {
      case GChatGroup.DEVICE_PROBLEM:
        urlWebHook = process.env.GGC_DEVICE_PROBLEM || ''
        break
      case GChatGroup.SUPPORT_SOS:
        urlWebHook = process.env.GGC_SOS || ''
        break

      default:
        break
    }
    if (!urlWebHook) return
    let bodyJson = { text: option.content }
    if (option.threadKey) {
      bodyJson = Object.assign(bodyJson, { thread: { threadKey: option.threadKey } })
      urlWebHook += '&messageReplyOption=REPLY_MESSAGE_OR_FAIL'
    }
    const body = JSON.stringify(bodyJson)
    axios.post(urlWebHook, body, {
      headers: { 'Content-Type': 'application/json; charset=UTF-8' },
    })
  }

  const sendMessageToChatByGroup = (action: LogDeviceProblemAction, message?: string) => {
    if (!roomStore.room) return
    const result = new UAParser().getResult()
    const defaultContent = [
      `💡External ID: ${roomStore.room.externalRoomId ? roomStore.room.externalRoomId : '-'}`,
      `💡Room ID: ${roomStore.room.id} - ${roomStore.releaseVersion}`,
      `⏰Class Time: ${moment(roomStore.room.startTime)
        .tz('Asia/Bangkok')
        .format('D MMM YYYY HH:mm')}`,
      `🙋Participant: ${roomStore.localParticipantInfo?.name} - (${roomStore.localParticipantInfo?.email})`,
      `📹Current Engine: ${roomStore.currentEngine}`,
      `🔍Browser: ${result.browser.name} - V${result.browser.version}`,
      `🔍userAgent: ${result.ua}`,
      `👊Action: ${action}`,
    ]
    const content = defaultContent.concat(message ? [`💔Error: ${message}`] : []).join('\n')
    sendMessage(GChatGroup.DEVICE_PROBLEM, { content, threadKey: roomStore.room.id })
  }

  const sendSOSForChangeRoom = (action?: string) => {
    const isPrivateClass = !!Number(roomStore.room.externalRoomId)
    const privateClassId = roomStore.room.externalRoomId
    const whitehouseBaseURL = process.env.WH_APP_URL
    const whPrivateClass = isPrivateClass
      ? `${whitehouseBaseURL}/classrooms/private-classes?search=${privateClassId}`
      : '-'
    const roleType = roomStore.localParticipantInfo?.isCoachRole
      ? ' Coach'
      : roomStore.localParticipantInfo?.isStudentRole
      ? ' Student'
      : roomStore.localParticipantInfo?.isInvisible
      ? ' Observe'
      : ''
    const displayAction =
      action && (Object.values(HelpAction) as string[]).includes(action)
        ? action?.toUpperCase()
        : action || '-'
    const contents = [
      `💡Class ID: ${privateClassId || '-'}`,
      `🙋From${roleType}: ${roomStore.localParticipantInfo?.name}`,
      `🆘Help Action: ${displayAction}`,
      `💡Room ID: ${roomStore.room.id}`,
      `🔎 WH Link: ${whPrivateClass}`,
    ]
    sendMessage(GChatGroup.SUPPORT_SOS, {
      content: isPrivateClass ? contents.join('\n') : contents.slice(0, -1).join('\n'),
    })
  }

  const catchMediaErrorToChat = async <T>(action: LogDeviceProblemAction, p?: Promise<T>) => {
    if (!p) return
    const [, error] = await promiseHandler(p)
    if (error) {
      sendMessageToChatByGroup(action, JSON.stringify(error))
    }
    return error
  }

  return { catchMediaErrorToChat, sendMessageToChatByGroup, sendSOSForChangeRoom }
}
