import { createApp, h, provide } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import VueGtag from 'vue-gtag'
import './scss/main.scss'

import registerGlobalComponent from './plugins/global-components'

import { createClientI18n } from './plugins/i18n'
import { ApiSymbol, initApi } from './composables/api/useApi'

const api = initApi()
const pinia = createPinia()
const i18n = createClientI18n()

const app = createApp({
  setup() {
    provide(ApiSymbol, api)
    document.title = 'Globish Tiger'
  },
  render: () => h(App),
})
app.use(pinia)
app.use(router)
app.use(i18n)
if (process.env.BRANCH === 'master') {
  app.use(
    VueGtag,
    {
      config: { id: process.env.GTAG_ID as string },
      enabled: true,
    },
    router,
  )
}
registerGlobalComponent(app)

if (process.env.NODE_ENV === 'production') {
  app.config.compilerOptions.isCustomElement = (tag) => tag.startsWith('video-player')
}
app.mount('#app')
