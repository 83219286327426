<template>
  <InlineSvg
    class="svg-container svg-class"
    :src="svgPath"
    :width="width"
    :height="height"
    :fill="inheritFill ? 'currentColor' : undefined"
    :transform-source="transform"
  ></InlineSvg>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
export default {
  components: {
    InlineSvg,
  },
  props: {
    icon: {
      type: String,
      default: null,
    },
    inheritFill: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: null,
    },
    height: {
      type: String,
      default: null,
    },
  },
  computed: {
    svgPath() {
      return require(`@/assets/svg/icon-${this.icon}.svg`)
    },
  },
  methods: {
    transform(svgEl) {
      if (this.inheritFill) {
        this.recursivelyRemoveFill(svgEl)
      }
      return svgEl
    },
    recursivelyRemoveFill(el) {
      if (!el) {
        return
      }
      el.setAttribute('fill', 'currentColor')
      ;[].forEach.call(el.children, (child) => {
        this.recursivelyRemoveFill(child)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.svg-container {
  display: inline;
}
.svg-class {
  vertical-align: middle;
}
</style>
