<script setup lang="ts">
import SupportedBrowser from '@/components/video-diagnostic/SupportedBrowser.vue'
import UnSupportedBrowser from '@/components/video-diagnostic/UnSupportedBrowser.vue'
import { Stage, Topic } from '@/types/enums/video-diagnostic.enum'
import { IUpdateTopic } from '@/types/interfaces/video-diagnostic.interface'
import { watch } from 'vue'
import Video from 'twilio-video'

interface BrowserTestEmits {
  (e: 'nextStage', value: number): void
  (e: 'updateTopic', value: IUpdateTopic): void
  (e: 'supportedBrowser', value: boolean): void
}

const props = defineProps({
  stage: {
    type: Number,
    required: true,
  },
})

const emit = defineEmits<BrowserTestEmits>()

watch(
  () => props.stage,
  (stage, prevStage) => {
    if (stage === Stage.BROWSER_TEST) {
      emit('updateTopic', { topic: Topic.CONNECTIVITY, active: true })
      emit('supportedBrowser', Video.isSupported)
    }
    if (prevStage === Stage.BROWSER_TEST && stage === Stage.AUDIO_TEST) {
      emit('updateTopic', { topic: Topic.CONNECTIVITY, active: false })
      emit('supportedBrowser', true)
    }
  },
)
</script>

<template>
  <SupportedBrowser
    v-if="Video.isSupported"
    :stage="stage"
    @next-stage="emit('nextStage', Stage.CONNECTIVITY)"
  ></SupportedBrowser>
  <UnSupportedBrowser v-else :stage="stage"></UnSupportedBrowser>
</template>

<style lang="scss" scoped></style>
