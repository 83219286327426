import useWebViewer from '@/composables/document/useDocWebViewer'
import { IRoom } from '../../types/interfaces/room.interface'
import { ISpaceParticipants } from '../../types/interfaces/participant.interface'

interface UseWhiteboardParameters {
  roomInfo: IRoom
  localParticipant: ISpaceParticipants
  canManagePermission?: boolean
}

export default function useWhiteboard({
  roomInfo,
  localParticipant,
  canManagePermission,
}: UseWhiteboardParameters) {
  /**
   * use web viewer
   */
  const { initWebViewer, viewerRef, socket } = useWebViewer()

  const { id: roomId } = roomInfo
  const { id: participantId, email, name } = localParticipant
  const initWhiteboard = async () => {
    /**
     * init whiteboard socket
     */
    await initWebViewer({
      email,
      participantId,
      name,
      isWhiteboard: true,
      documentId: `wb-${roomId}`,
      canManagePermission,
    })
  }
  const clearBoard = async () => {
    socket.value.emit('cleanWhiteboard', `wb-${roomId}`)
  }

  return {
    viewerRef,
    socket,
    clearBoard,
    initWhiteboard,
  }
}
