import { IParticipantInfo } from '@/types/interfaces/participant.interface'
import useNotify from '../useNotify'
import useSoundNotify from '../useSoundNotify'
import { onImageError } from '@/helpers/favicon'

export default function useRoomNotify() {
  const notification = useNotify()
  const soundNotify = useSoundNotify()

  const notifyWithAvatar = (avatar?: string) => {
    return {
      component: 'img',
      src: avatar || '',
      onerror: onImageError,
    }
  }

  const toggleFollowMyPage = (value: boolean, { name, avatar }: IParticipantInfo) => {
    if (value) {
      notification.success({
        title: name,
        text: 'has enabled follow my page',
        ...notifyWithAvatar(avatar),
      })
    } else {
      notification.fail({
        title: name,
        text: 'has disabled follow my page',
        ...notifyWithAvatar(avatar),
      })
    }
  }

  const toggleWhiteboard = (value: boolean, { name, avatar }: IParticipantInfo) => {
    if (value) {
      notification.success({
        title: name,
        text: 'has opened the whiteboard',
        ...notifyWithAvatar(avatar),
      })
    } else {
      notification.fail({
        title: name,
        text: 'has closed the whiteboard',
        ...notifyWithAvatar(avatar),
      })
    }
  }

  const toggleScreenShare = (value: boolean, { name, avatar }: IParticipantInfo) => {
    if (value) {
      notification.success({
        title: name,
        text: 'Sharing Screen',
        ...notifyWithAvatar(avatar),
      })
    } else {
      notification.fail({
        title: name,
        text: 'Stop Sharing Screen',
        ...notifyWithAvatar(avatar),
      })
    }
  }

  const notSupportScreenSharing = () => {
    notification.warning({
      text: 'This browser does not support screen sharing',
      component: 'svg-icon',
      icon: 'laptop-alert',
    })
  }

  const roomParticipantJoined = ({ name, avatar }: IParticipantInfo) => {
    notification.success({
      title: name,
      text: 'has joined this room',

      ...notifyWithAvatar(avatar),
    })
    soundNotify.joinRoom()
  }
  const roomParticipantDisconnect = ({ name, avatar }: IParticipantInfo) => {
    notification.fail({
      title: name,
      text: 'has left this room',
      ...notifyWithAvatar(avatar),
    })
  }

  return {
    toggleWhiteboard,
    toggleScreenShare,
    notSupportScreenSharing,
    roomParticipantJoined,
    roomParticipantDisconnect,
    toggleFollowMyPage,
  }
}
