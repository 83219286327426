import { IBitrateTest } from '@/types/interfaces/video-diagnostic.interface'
import { testMediaConnectionBitrate, MediaConnectionBitrateTest } from '@twilio/rtc-diagnostics'
import { reactive, ref } from 'vue'

export const bitrateTestInProgress = ref(false)
export const bitrateTestFinished = ref(false)
export const bitrateTest = reactive<IBitrateTest>({
  bitrate: null,
  report: null,
  error: null,
})

export const useBitrateTest = (tokenInstance: any) => {
  const mediaConnectionBitrateTest = testMediaConnectionBitrate({
    iceServers: tokenInstance?.iceServers,
  })

  mediaConnectionBitrateTest.on(MediaConnectionBitrateTest.Events.Bitrate, (bitrate) => {
    bitrateTest.bitrate = bitrate
  })

  mediaConnectionBitrateTest.on(MediaConnectionBitrateTest.Events.Error, (error) => {
    bitrateTest.error = error
    bitrateTestFinished.value = true
    bitrateTestInProgress.value = false
  })

  mediaConnectionBitrateTest.on(MediaConnectionBitrateTest.Events.End, (report) => {
    bitrateTest.report = report
    bitrateTestFinished.value = true
    bitrateTestInProgress.value = false
  })

  setTimeout(() => {
    mediaConnectionBitrateTest.stop()
  }, 15000)
}
