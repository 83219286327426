import { IParticipantTrack, PublicationTracks } from '@/types/interfaces/participant.interface'

export const attach = (stream: MediaStream): HTMLMediaElement => {
  const videoEl = document.createElement('video')
  videoEl.srcObject = stream
  videoEl.setAttribute('playsinline', '')
  videoEl.autoplay = true
  videoEl.muted = true
  return videoEl
}

export const filterMediaTrack = (alltracks: PublicationTracks) => {
  const tracks: IParticipantTrack[] = []
  alltracks.forEach((publication) => {
    if (publication.track) {
      if (publication.track.kind !== 'data') {
        tracks.push(publication.track)
      }
    }
  })
  return tracks
}
