import { BrowserDetect, OperatingSystem } from '@/types/enums/device.enum'
import { IResult, UAParser } from 'ua-parser-js'
import { isIPad } from './zoom-device.helper'

export const detectDevice = (userAgent = navigator.userAgent, platform = navigator.platform) => {
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
  const iosPlatforms = ['iPhone', 'iPad', 'iPod']
  if (macosPlatforms.indexOf(platform) !== -1) {
    return 'Mac'
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    return 'IOS'
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    return 'PC'
  } else if (/Android/.test(userAgent)) {
    return 'Android'
  } else if (/Linux/.test(platform)) {
    return 'Linux'
  }
  return ''
}

export const detectBrowser = (userAgent: string) => {
  if (userAgent.indexOf('Chrome') !== -1) {
    return BrowserDetect.CHROME
  } else if (userAgent.indexOf('Firefox') !== -1) {
    return BrowserDetect.FIREFOX
  } else if (userAgent.indexOf('MSIE') !== -1) {
    return BrowserDetect.IE
  } else if (userAgent.indexOf('Edge') !== -1) {
    return BrowserDetect.EDGE
  } else if (userAgent.indexOf('Safari') !== -1) {
    return BrowserDetect.SAFARI
  } else if (userAgent.indexOf('Opera') !== -1) {
    return BrowserDetect.OPERA
  } else {
    return BrowserDetect.OTHERS
  }
}

export const detectUserAgent = (userAgent?: string): IResult => {
  const parser = new UAParser()
  return parser.setUA(userAgent || navigator.userAgent).getResult()
}

export const validBrowserWithDevice = (userAgent: string) => {
  const result = detectUserAgent(userAgent)
  const suggestionBrowser = [
    'Safari',
    'Mobile Safari',
    'Chrome',
    'Mobile Chrome',
    'Firefox',
    'Mobile Firefox',
  ]
  // use iphone, ipad with safari
  if (result.os.name === 'iOS') {
    return ['Safari', 'Mobile Safari'].includes(result.browser.name || '')
  } else if (result.os.name === 'Android') {
    return ['Chrome', 'Mobile Chrome'].includes(result.browser.name || '')
  } else return suggestionBrowser.includes(result.browser.name || '')
}

export const deviceMapForReport = () => {
  const parser = new UAParser()
  const os = parser.setUA(navigator.userAgent).getOS()
  if (os.name?.includes('Android')) {
    return OperatingSystem.ANDROID
  } else if (os.name?.includes('iOS')) {
    return OperatingSystem.IOS
  } else if (os.name?.includes('Windows')) {
    return OperatingSystem.WINDOWS
  } else if (os.name?.includes('Mac OS')) {
    return OperatingSystem.MAC_OS
  } else {
    return OperatingSystem.UNKNOWN
  }
}

export const canUseEngineZoom = () => {
  const parser = new UAParser()
  const result = parser.getResult()

  const allowedBrowser = [
    'Chrome',
    'Mobile Chrome',
    'Firefox',
    'Mobile Firefox',
    'Safari',
    'Mobile Safari',
    'Brave',
    'Edge',
  ]

  const ignoreBrowsers = ['Line']
  if (ignoreBrowsers.includes(result.browser.name || '')) return false

  const canUseWithBrowser = allowedBrowser.includes(result.browser.name || '')

  if (['mobile', 'tablet'].includes(result.device.type || '') || isIPad()) {
    if (result.os.name?.includes('Android')) {
      return result.engine.name?.includes('Blink')
    } else if (result.os.name?.includes('iOS')) {
      return result.engine.name?.includes('WebKit')
    } else {
      return canUseWithBrowser
    }
  } else {
    return canUseWithBrowser
  }
}
