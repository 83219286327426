<script setup lang="ts"></script>

<template>
  <div class="w-full h-full flex items-center justify-center end">
    <div class="max-w-xs text-center text-base">
      <SvgIcon icon="closed-eye" :inherit-fill="true" height="48"></SvgIcon>
      <div class="mt-3">{{ $t('end-session-page') }}</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
::v-deep(.svg-class) {
  margin: auto;
}

.end {
  background: var(--room-body-color);
}
</style>
