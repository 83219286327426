<script setup lang="ts">
import { computed, onMounted, PropType, onUnmounted } from 'vue'
import useWhiteboard from '@/composables/whiteboard/useWhiteboard'
/**
 * Interface
 */
import { IRoom } from '@/types/interfaces/room.interface'
import { IParticipantInfo } from '@/types/interfaces/participant.interface'

const props = defineProps({
  roomInfo: {
    type: Object as PropType<IRoom>,
    required: true,
  },
  localParticipant: {
    type: Object as PropType<IParticipantInfo>,
    required: true,
  },
  openBy: {
    type: String,
    default: '',
  },
})

const { initWhiteboard, viewerRef, clearBoard, socket } = useWhiteboard({
  roomInfo: props.roomInfo,
  localParticipant: props.localParticipant,
  canManagePermission:
    props.localParticipant?.capabilities?.whiteBoard &&
    props.openBy === props.localParticipant.email,
})

onUnmounted(() => {
  /**
   * clear whiteboard and disconnect socket
   * before close thie component
   */
  clearBoard()
  socket.value.disconnect()
})

/**
 * init whiteboard
 */
onMounted(async () => {
  await initWhiteboard()
})
</script>

<template>
  <div class="whiteboard">
    <div class="doc-wrapper">
      <div id="viewer-wb" ref="viewerRef"></div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.whiteboard {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .doc-wrapper {
    width: 100%;
    height: 100%;
    #viewer-wb {
      height: 100%;
      width: 100%;
    }
  }
}
</style>
