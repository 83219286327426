// Ref: https://gist.github.com/mathiasbynens/428626

import { AVATAR_DEFAULT } from '@/data/room'

// document.head || (document.head = document.getElementsByTagName("head")[0]);

export const changeFavicon = (src: string) => {
  const link = document.createElement('link')
  const oldLink = document.getElementById('dynamic-favicon')
  link.id = 'dynamic-favicon'
  link.rel = 'shortcut icon'
  link.href = src
  if (oldLink) {
    document.head.removeChild(oldLink)
  }
  document.head.appendChild(link)
}

export const onImageError = (e: Event) => {
  const target = e.target as HTMLImageElement
  target.src = AVATAR_DEFAULT
}
