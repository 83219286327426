<script setup lang="ts">
import { watch, computed, onBeforeMount, onMounted, ref } from 'vue'
import { clamp, useDraggable, useElementBounding, useWindowSize } from '@vueuse/core'
/**
 * Import Components
 */

import BrowserSupportPopup from '@/components/checkup/BrowserSupportPopup.vue'
import Chatbox from '@/components/chatbox/Chatbox.vue'
import ChangeVideoEnginePopup from '@/components/room/ChangeVideoEnginePopup.vue'
import CompartmentGrid from '@/components/room/CompartmentGrid.vue'
import ConfirmProblemCancelPopup from '@/components/room/ConfirmProblemCancelPopup.vue'
import ProblemCancelPopup from '@/components/room/ProblemCancelPopup.vue'
import ProblemCancelLeavePopup from '@/components/room/ProblemCancelLeavePopup.vue'
import DocViewer from '@/components/document/DocViewer.vue'
import FeedbackHelpRequest from '@/components/room/FeedbackHelpRequest.vue'
import NavBar from '@/components/room/RoomNavbar.vue'
import ReportProblemPopup from '@/components/room/ReportProblemPopup.vue'
import RoomControl, { IChangeSetting } from '@/components/room/RoomControl.vue'
import MessagePreview from '../components/chatbox/MessagePreview.vue'
import NotifyVideoEngineChangePopup from '@/components/room/NotifyVideoEngineChangePopup.vue'
import Sticker from '@/components/sticker/Sticker.vue'
import Whiteboard from '@/components/whiteboard/Whiteboard.vue'
import Engder from '@/components/game/Engder.vue'
import CoachAbsentPopup from '@/components/room/CoachAbsentPopup.vue'
import CoachLatePopup from '@/components/room/CoachLatePopup.vue'
import WherebyNavbar from '@/components/room/WherebyNavbar.vue'
import ScreenTrack from '@/components/room/ScreenTrack.vue'
import MultipleSessionsDetectedPopup from '@/components/room/MultipleSessionsDetectedPopup.vue'
import HelpRequestPopup from '@/components/room/HelpRequestPopup.vue'
import CoachMessagePopup from '@/components/room/CoachMessagePopup.vue'
import CoachWelcomePopup from '@/components/checkup/CoachMessagePopup.vue'
import StudentWelcomePopup from '@/components/room/StudentWelcomePopup.vue'
import HelpCollapse from '../components/help-collapse/HelpCollapse.vue'
import PdfViewer from '@/components/document/PdfViewer.vue'

/**
 * Composables
 */
import {
  engineAfter,
  roomConnectErrorMessage,
  roomConnectState,
} from '@/composables/room/useRoomState'
import { screenTrack, multipleSessions } from '@/composables/twilio/useTwilioState'
import {
  // State
  screenShareEnable,
  whiteboardEnable,
  stickerEnable,
  chatEnable,
  documentEnable,
  gameEnable,
  helpEnable,
  currentTab,
  isOpenDeviceSetting,
  isOpenReportProblem,
  isOpenChangeVideoEngine,
  haveMainCompartment,
  mainTabs,
  screenShareShow,

  // Methods
  changeCurrentTab,
  changeToEngine,
} from '@/composables/room/useRoomLayout'
import useRoomNotify from '@/composables/room/useRoomNotify'
import { ROOM_SPACE_URL } from '@/composables/api'
/**
 * Interface
 */
import {
  EngineChangeSocketEmit,
  IWhiteboardSocketState,
  IRequestClassCancelSocket,
  IRoomLayoutProps,
} from '@/types/interfaces/room.interface'
import { Engine, VideoRoomConnectionState } from '@/types/enums/room.enum'
import { IPreviewMessage } from '@/types/interfaces/common.interface'
import { findParticipantInfoByEmail } from '@/helpers/participant.helper'
import useSoundNotify from '@/composables/useSoundNotify'
import { useDeviceStore, useRoomStore } from '@/stores'

import {
  HelpAction,
  HelpResolved,
  HelpRole,
  SolveAction,
  SubmitType,
} from '../types/enums/help.enum'
import useFirestore, { ISendMessage } from '../composables/useFirestore'
import useSpaceApi from '@/composables/api/useSpaceApi'
import useAuth from '@/composables/api/useAuth'
import {
  getHelpClickState,
  getOpenHelp,
  IHelpStoreState,
  setHelpClickState,
  setOpenHelp,
} from '@/services/help-state'
import { checkTimeLimitToShowFeedback } from '@/helpers/help-request.helper'
import { deviceMapForReport } from '@/helpers/detect-device'
import useSocketEvent, { isCoachAttend, roomSocketState } from '@/composables/room/useSocketEvent'
import { SocketHelpEmit } from '@/composables/room/useHelpSystem'
import useGChatLog from '@/composables/api/useGChatLog'
import { messageChatToCoach, messageChatToStudent } from '@/data/mock-help-menu'
import { detectSpaceRoomVersion } from '@/helpers/layout.helper'

const { sendMessage } = useFirestore()
const { getAccessInfo } = useAuth()
const { toggleEngine, saveLogsHelpRequest, saveLogsHelpResolve, submitProblemCancel } =
  useSpaceApi()

interface RoomLayoutEmits {
  (e: 'toggle-camera', value: boolean): void
  (e: 'toggle-microphone', value: boolean): void
  (e: 'toggle-sticker', value: boolean): void
  (e: 'toggle-chat', value: boolean): void
  (e: 'toggle-document', value: boolean): void
  (e: 'toggle-game', value: boolean): void
  (e: 'toggle-original-material', value: boolean): void
  (e: 'toggle-screen-share', value: boolean): void
  (e: 'toggle-whiteboard', value: boolean): void
  (e: 'disconnect'): void
  (e: 'confirm-welcome'): void
  (e: 'change-cam', value: string): void
  (e: 'change-mic', value: string): void
  (e: 'change-audio', value: string): void
}

const props = defineProps<IRoomLayoutProps>()

const chatNotifyCount = ref<number>(0)
const newMessage = ref<IPreviewMessage | undefined>(undefined)
const { socket, sendHelpRequest } = useSocketEvent()
const soundNotify = useSoundNotify()
const device = useDeviceStore()
const popoutRef = ref<HTMLElement | null>(null)
const { width: popoutWidth, height: popoutHeight } = useElementBounding(popoutRef)
const { width: windowWidth, height: windowHeight } = useWindowSize()

const { x, y } = useDraggable(popoutRef, {
  initialValue: { x: 30, y: 87 },
})

const roomNotify = useRoomNotify()
const roomStore = useRoomStore()
const { sendSOSForChangeRoom } = useGChatLog()
const emit = defineEmits<RoomLayoutEmits>()

const openBy = ref<string>('')

const isOpenWelcomePopup = ref<boolean>(false)
const isHelpRequestLoading = ref<boolean>(false)
const helpActionType = ref<HelpAction | undefined>(undefined)
const helpSolvedBy = ref<SolveAction | undefined>(undefined)
const coachMessage = ref<string | undefined>(undefined)
const coachSolution = ref<SolveAction | undefined>(undefined)
const mainActionType = ref<HelpAction | undefined>(undefined)
const sendBy = ref<string>()

const originalMaterialEnable = ref<boolean>(false)
const canShowFeedback = ref<boolean>(false)
const showProblemCancelDetail = ref<boolean>(false)
const showConfirmProblemCancel = ref<boolean>(false)
const clickedHelpActions = ref<IHelpStoreState[]>([])
const showStudentMessageCancelClass = ref<boolean>(false)
const participantRequestCancelName = ref<string>('')

// const
const HELP_REQUEST_EVENT = [SolveAction.SHARE_SCREEN, SolveAction.MOVE_ROOM]

/**
 * Computed
 */
const restrictedX = computed(() => clamp(30, x.value, windowWidth.value - 30 - popoutWidth.value))
const restrictedY = computed(() =>
  clamp(87, y.value, windowHeight.value - 100 - popoutHeight.value),
)

const moveTo = computed(() =>
  changeToEngine.value === Engine.WHEREBY
    ? 'whereby'
    : props.roomInfo.isMainEngine
    ? 'secondary'
    : 'main',
)

// when student send requested to coach
const sendMessageToChat = computed(() => {
  if (!helpSolvedBy.value) return ''
  const generateOption = {
    solveBy: helpSolvedBy.value,
    isNeedSupport: false,
    moveTo: moveTo.value,
    userName: props.localParticipant.name,
  }
  if (props.isCoachRole) {
    return messageChatToStudent(generateOption)
  } else {
    return messageChatToCoach(generateOption)
  }
})

const showComponentNightTime = computed(
  () =>
    !props.localParticipant.isCoachRole &&
    roomStore.isNightTime &&
    !!parseInt(props.roomInfo.externalRoomId || '') &&
    props.roomInfo.metaData?.country === 'th',
)

const showFeedbackHelp = computed(() => showComponentNightTime.value && isCoachAttend.value)

const showMainLayout = computed(
  () => (props.isFocused || haveMainCompartment.value) && !props.isLoading,
)

const toggleCamera = () => {
  device.toggleCamera()
  emit('toggle-camera', device.cameraOn)
}

const toggleMicrophone = () => {
  device.toggleMicrophone()
  emit('toggle-microphone', device.microphoneOn)
}

const toggleDocument = () => {
  localStorage.removeItem('persist:viewer')
  documentEnable.value = !documentEnable.value
  changeCurrentTab(documentEnable.value ? 'document' : '')
  emit('toggle-document', documentEnable.value)
}

const toggleScreenShare = () => {
  emit('toggle-screen-share', !screenShareEnable.value)
}

const toggleGame = () => {
  gameEnable.value = !gameEnable.value
  changeCurrentTab(gameEnable.value ? 'game' : '')
  emit('toggle-game', gameEnable.value)
}

const toggleWhiteboard = () => {
  emit('toggle-whiteboard', whiteboardEnable.value)
  if (socket) {
    socket.emit('docWhiteboardControl', {
      roomId: props.roomInfo.id,
      isOpen: !whiteboardEnable.value,
      by: props.localParticipant?.email,
    })
  }
}

const toggleOriginalMaterial = () => {
  originalMaterialEnable.value = !originalMaterialEnable.value
}
/**
 * HELP FUNCTION
 */
const toggleHelp = () => {
  chatEnable.value = false
  helpEnable.value = !helpEnable.value
}
const openPopupHelpRequest = (helpRequest: any) => {
  helpActionType.value = helpRequest.action
  helpSolvedBy.value = helpRequest.solvedBy
}

const clearHelpRequest = () => {
  helpActionType.value = undefined
  helpSolvedBy.value = undefined
}

const sendLogsHelpRequest = async (submitType: SubmitType, helpRole: HelpRole) => {
  const helpRequestPayload = {
    solution: helpSolvedBy.value,
    topic: helpActionType.value,
  }
  if (helpRequestPayload.solution && helpRequestPayload.topic) {
    await saveLogsHelpRequest({
      solution: helpRequestPayload.solution,
      topic: helpRequestPayload.topic,
      submitType,
      role: helpRole,
    })
  }
}

const mockAndSendMessage = async (message?: string) => {
  if (!message) return
  const mockMessage: ISendMessage = {
    roomRefId: props.roomInfo.id,
    message: {
      sendBy: {
        name: 'System Support',
        email: 'engineer@globish.co.th',
        avatar: 'https://d1bnvx5vhcnf8w.cloudfront.net/logo/globish-logo.png',
        role: 'system',
      },
      message,
      fileURL: '',
      type: 'text',
    },
  }
  await sendMessage(mockMessage)
}

const confirmSolution = async () => {
  if (!helpSolvedBy.value) return
  isHelpRequestLoading.value = true
  if (SubmitType.CONFIRM && isCoachAttend.value && helpActionType.value && helpSolvedBy.value) {
    const helpSession = {
      topic: helpActionType.value,
      solveBy: helpSolvedBy.value,
    }
    clickedHelpActions.value = setHelpClickState(helpSession)
  }
  await sendLogsHelpRequest(SubmitType.CONFIRM, HelpRole.SENDER)

  // SEND MESSAGE
  const message = sendMessageToChat.value || ''
  mockAndSendMessage(message)
  // student request to coach
  if (
    props.isStudentRole &&
    helpActionType.value &&
    helpSolvedBy.value &&
    HELP_REQUEST_EVENT.includes(helpSolvedBy.value)
  ) {
    sendHelpRequest({
      solution: helpSolvedBy.value,
      by: props.localParticipant.email,
      message,
      mainAction: helpActionType.value,
    })
  }
  if (props.isCoachRole && helpSolvedBy.value === SolveAction.SHARE_SCREEN) {
    // share screen for coac
    emit('toggle-screen-share', true)
  }

  if (props.isCoachRole && helpSolvedBy.value === SolveAction.MOVE_ROOM) {
    // move room for coach
    await sendSOSForChangeRoom(helpActionType.value)
    await switchEngine()
  }

  if (helpSolvedBy.value === SolveAction.RELOAD_ROOM) {
    // reload room
    window.location.reload()
  } else if (helpSolvedBy.value === SolveAction.MUTE_CAMERA) {
    // Mute camera
    if (device.cameraOn) {
      device.toggleCamera()
      emit('toggle-camera', false)
    }
    // do nothing if camera already mute
  } else if (helpSolvedBy.value === SolveAction.OPEN_ORIGINAL_MATERIAL) {
    if (!documentEnable.value) {
      toggleDocument()
    }
    if (!originalMaterialEnable.value) {
      toggleOriginalMaterial()
    }
  }

  clearHelpRequest()
  isHelpRequestLoading.value = false
}
const switchEngine = async () => {
  const { queryToken } = getAccessInfo()
  try {
    const { status, data } = await toggleEngine([
      mainActionType.value || 'help menu request to move room',
    ])
    if (status === 200) {
      const spaceVersion = detectSpaceRoomVersion(data.currentEngine)
      const dataEmit: EngineChangeSocketEmit = {
        currentEngine: data.currentEngine,
        version: spaceVersion,
      }
      engineAfter.value = data.currentEngine
      socket.emit('engineChanged', dataEmit)
      if (spaceVersion === 'space5') {
        window.location.href = `${ROOM_SPACE_URL}/join?token=${queryToken}&tiger`
      } else {
        window.location.reload()
      }
    }
  } catch (err) {
    console.log(err)
    alert('Cannot toggle engine')
  }
}

const coachConfirmSolution = async () => {
  isHelpRequestLoading.value = true
  if (coachSolution.value && mainActionType.value) {
    await saveLogsHelpRequest({
      solution: coachSolution.value,
      topic: mainActionType.value,
      submitType: SubmitType.CONFIRM,
      role: HelpRole.RECEIVER,
    })
  }

  if (coachSolution.value === SolveAction.MOVE_ROOM) {
    await switchEngine()
  } else if (coachSolution.value === SolveAction.SHARE_SCREEN) {
    emit('toggle-screen-share', true)
  }
  coachMessage.value = undefined
  isHelpRequestLoading.value = false
}

const cancelSolved = async (defaultRole?: HelpRole) => {
  isHelpRequestLoading.value = true
  if (defaultRole) {
    await sendLogsHelpRequest(SubmitType.CANCEL, defaultRole)
  } else {
    await sendLogsHelpRequest(
      SubmitType.CANCEL,
      props.isCoachRole ? HelpRole.RECEIVER : HelpRole.SENDER,
    )
  }
  isHelpRequestLoading.value = false

  helpSolvedBy.value = undefined
  helpActionType.value = undefined
  // coach
  coachSolution.value = undefined
  coachMessage.value = undefined
  mainActionType.value = undefined
  sendBy.value = undefined
}

const changeSetting = (event: IChangeSetting) => {
  switch (event.type) {
    case 'cam':
      device.maskSelectedVideo = event.deviceId
      emit('change-cam', event.deviceId)
      break
    case 'audio':
      device.maskSelectedSpeaker = event.deviceId
      emit('change-audio', event.deviceId)
      break
    case 'mic':
      device.maskSelectedAudio = event.deviceId
      emit('change-mic', event.deviceId)
      break

    default:
      break
  }
}

socket?.on('helpRequest', (data: SocketHelpEmit) => {
  if (props.isCoachRole) {
    const { solution, by, message, mainAction } = data

    if (solution === SolveAction.MOVE_ROOM || solution === SolveAction.SHARE_SCREEN) {
      coachMessage.value = message
      coachSolution.value = solution
      // For coach log
      mainActionType.value = mainAction
      sendBy.value = by
    }
  }
})

socket?.on('whiteboardState', (data: IWhiteboardSocketState) => {
  const { isOpen, by } = data
  openBy.value = by
  /**
   * show room notify when the whiteboard has any action
   */
  const participant = findParticipantInfoByEmail(by, props.participantsInfo)
  if (participant && isOpen !== whiteboardEnable.value) {
    roomNotify.toggleWhiteboard(isOpen, participant)
    whiteboardEnable.value = isOpen
  }
  /**
   * reset whiteboard state if disconnected
   */
  if (!by && !isOpen) {
    whiteboardEnable.value = isOpen
  }
  changeCurrentTab(isOpen ? 'whiteboard' : '')
})

socket?.on('classCancel', (data: IRequestClassCancelSocket) => {
  if (data.sendBy !== props.localParticipant.email) {
    showStudentMessageCancelClass.value = true
    participantRequestCancelName.value = data.participantName
  }
})

const toggleChat = () => {
  helpEnable.value = false
  chatEnable.value = !chatEnable.value
  emit('toggle-chat', chatEnable.value)
}

const toggleSticker = () => {
  stickerEnable.value = !stickerEnable.value
  emit('toggle-sticker', stickerEnable.value)
}

const updateUnreadMessages = (count: number) => {
  chatNotifyCount.value = count
}

const updatePreviewMessages = (messages?: IPreviewMessage) => {
  soundNotify.newMessage()
  newMessage.value = messages
}

/**
 * Computed
 */
const showEngder = computed(
  () => props.roomInfo?.metaData?.engderDeckGroup && props.localParticipant?.capabilities?.engder,
)

const userPermission = computed(() => {
  return {
    ...props.localParticipant.capabilities,
    publishableTracks: {
      ...props.localParticipant.capabilities?.publishableTracks,
      cam: props.localParticipant.capabilities?.publishableTracks.cam,
      mic: props.localParticipant.capabilities?.publishableTracks.mic,
    },
  }
})

const setDefaultState = () => {
  whiteboardEnable.value = false
}

const onSuccessHelp = () => {
  canShowFeedback.value = false
  saveLogsHelpResolve({
    currentEngine: props.roomInfo.currentEngine,
    helpResolve: HelpResolved.YES,
  })
  setOpenHelp()
}

const onOpenConfirmProblemCancel = () => {
  showConfirmProblemCancel.value = true
}

const submitParticipantHaveProblem = () => {
  canShowFeedback.value = false
  showProblemCancelDetail.value = true
  saveLogsHelpResolve({ currentEngine: props.roomInfo.currentEngine, helpResolve: HelpResolved.NO })
  setOpenHelp()
}

const submitCancelClassWithProblem = async (comment?: string) => {
  const classId = parseInt(props.roomInfo.externalRoomId)
  if (!classId) return
  const payload = {
    classId,
    device: deviceMapForReport(),
    helpActions: clickedHelpActions.value.map(({ topic }) => topic) as HelpAction[],
    detail: comment ? comment : undefined,
  }
  const message = `Student ${props.localParticipant.name} requested to cancel this class due to some issues. You can leave the class and mark the attendance report as a problem within class.`
  await mockAndSendMessage(message)
  socket?.emit('requestClassCancel', {
    sendBy: props.localParticipant.email,
    participantName: props.localParticipant.name,
  })
  await submitProblemCancel(payload)
  window.location.href = props.roomInfo.exitPath
}

const openDeviceSetting = () => {
  isOpenDeviceSetting.value = true

  // // Mute camera for engine zoom
  // if (device.cameraOn && props.roomInfo.currentEngine === Engine.ZOOM) {
  //   device.toggleCamera()
  //   emit('toggle-camera', false)
  // }
}

watch(
  () => props.nCompartments,
  () => {
    if (props.nCompartments > 1) {
      gameEnable.value = false
      changeCurrentTab('')
    }
  },
)

watch(
  () => clickedHelpActions.value.length,
  (value) => {
    if (props.isCoachRole) return
    if (value > 0 && getOpenHelp() !== 'yes') {
      checkTimeLimitToShowFeedback(clickedHelpActions.value, () => {
        if (getOpenHelp() !== 'yes') {
          canShowFeedback.value = true
        }
      })
    }
  },
)
onBeforeMount(() => {
  /**
   * Check if the student has Endger and no one is join room except student
   * then auto open engder game
   */
  if (
    props.roomInfo?.metaData?.engderDeckGroup &&
    props.localParticipant?.capabilities?.engder &&
    props.nCompartments === 1
  ) {
    toggleGame()
  }
})

onMounted(() => {
  setDefaultState()
  clickedHelpActions.value = getHelpClickState()
  /**
   * call socket event "checkWhiteboardState" for
   * set whiteboardState value if exist
   */
  socket.emit('checkWhiteboardState', props.roomInfo.id)
  isOpenWelcomePopup.value = true
  // close auto close welcome
  // setTimeout(() => {
  //   if (isOpenWelcomePopup.value) {
  //     isOpenWelcomePopup.value = false
  //     emit('confirm-welcome')
  //   }
  // }, WELCOME_AUTO_CLOSE_TIME)
})
</script>

<template>
  <div class="room-wrapper">
    <CoachWelcomePopup
      v-if="isCoachRole && roomStore.localParticipantInfo"
      v-model="isOpenWelcomePopup"
      :room-duration="roomStore.roomDuration"
      :coach="roomStore.localParticipantInfo.name"
      @join-room="emit('confirm-welcome')"
      @close="emit('confirm-welcome')"
    />
    <StudentWelcomePopup
      v-if="
        roomStore.localParticipantInfo &&
        (!isCoachRole || roomStore.localParticipantInfo.isInvisible)
      "
      v-model="isOpenWelcomePopup"
      :is-invisible="roomStore.localParticipantInfo.isInvisible"
      @join-room="emit('confirm-welcome')"
      @close="emit('confirm-welcome')"
    />
    <BrowserSupportPopup v-if="roomInfo?.currentEngine" :engine="roomInfo.currentEngine" />
    <MultipleSessionsDetectedPopup v-model="multipleSessions"></MultipleSessionsDetectedPopup>
    <NotifyVideoEngineChangePopup :is-main-engine="roomInfo.isMainEngine" />
    <ChangeVideoEnginePopup
      v-model="isOpenChangeVideoEngine"
      :move-to="moveTo"
    ></ChangeVideoEnginePopup>
    <ReportProblemPopup v-model="isOpenReportProblem"></ReportProblemPopup>
    <HelpRequestPopup
      :is-loading="isHelpRequestLoading"
      :help-request-type="helpActionType"
      :help-solved-by="helpSolvedBy"
      :is-coach-role="isCoachRole"
      :is-main-engine="roomInfo.isMainEngine"
      @confirm-solution="confirmSolution"
      @on-close="cancelSolved(HelpRole.SENDER)"
    ></HelpRequestPopup>
    <CoachMessagePopup
      v-if="isCoachRole"
      :coach-message="coachMessage"
      :is-loading="isHelpRequestLoading"
      @on-close="cancelSolved"
      @confirm-solution="coachConfirmSolution"
    ></CoachMessagePopup>
    <template v-if="showComponentNightTime && roomSocketState">
      <CoachAbsentPopup
        v-if="!isCoachRole"
        :is-attend="isCoachAttend"
        :start-time="roomInfo.startTime"
        :exit-path="roomInfo.exitPath"
      />
      <CoachLatePopup
        v-if="!isCoachRole"
        :is-attend="isCoachAttend"
        :start-time="roomInfo.startTime"
      />
    </template>
    <Sticker :is-enable="stickerEnable" @close="toggleSticker" />
    <ProblemCancelLeavePopup
      v-if="isCoachRole"
      v-model="showStudentMessageCancelClass"
      :student-name="participantRequestCancelName"
      :exit-path="roomInfo.exitPath"
    />
    <template v-if="showFeedbackHelp">
      <FeedbackHelpRequest
        v-if="canShowFeedback"
        @not-working="submitParticipantHaveProblem"
        @complete="onSuccessHelp"
      />
      <ProblemCancelPopup v-model="showProblemCancelDetail" @confirm="onOpenConfirmProblemCancel" />
      <ConfirmProblemCancelPopup
        v-model="showConfirmProblemCancel"
        @confirm="submitCancelClassWithProblem"
      />
    </template>
    <div
      class="room"
      :class="{
        '-with-focus': showMainLayout,
        '-with-chat': chatEnable,
        '-with-help': helpEnable,
        '-hidden-control': isWhereby,
      }"
    >
      <!-- for hel menu -->
      <div v-if="helpEnable" class="help">
        <HelpCollapse
          :is-coach-role="isCoachRole"
          :is-student-role="isStudentRole"
          :current-engine="roomInfo.currentEngine"
          :is-main-engine="roomInfo.isMainEngine"
          :engine-whereby="localParticipant.capabilities.engineWhereby"
          :engine-control="localParticipant.capabilities.engineControl"
          :country="roomInfo?.metaData?.country"
          :help-enable="helpEnable"
          :can-move-room-by-coach="canMoveRoomByCoach"
          @help-solve-confirm="openPopupHelpRequest"
          @on-close="toggleHelp"
        />
      </div>
      <template v-if="!isHiddenNavbar">
        <WherebyNavbar
          v-if="isWhereby"
          :user-permissions="userPermission"
          :document-enable="documentEnable"
          :whiteboard-enable="whiteboardEnable"
          :chat-enable="chatEnable"
          :sticker-enable="stickerEnable"
          :game-enable="gameEnable"
          :n-compartments="nCompartments"
          :exit-path="roomInfo.exitPath"
          :have-track-lesson="roomInfo.metaData?.haveTrackLesson"
          :chat-notify-count="chatNotifyCount"
          :show-help="true"
          class="navbar"
          @toggle-doc="toggleDocument"
          @toggle-whiteboard="toggleWhiteboard"
          @toggle-chat="toggleChat"
          @toggle-sticker="toggleSticker"
          @toggle-game="toggleGame"
          @toggle-help="toggleHelp"
        />
        <NavBar
          v-else
          :avatar="localParticipant?.avatar"
          :logo-path="roomInfo.logoPath"
          :user-permissions="localParticipant?.capabilities"
          :is-main-engine="roomInfo.isMainEngine"
          :support-tel="roomInfo.supportTel"
          :support-email="roomInfo.supportEmail"
          :current-engine="roomInfo.currentEngine"
          :exit-path="roomInfo.exitPath"
          :is-recording="isCoachRole || !!roomInfo.record"
          :have-track-lesson="roomInfo.metaData?.haveTrackLesson"
          :help-enable="helpEnable"
          :is-whereby="isWhereby"
          :show-help="true"
          class="navbar"
          @toggle-help="toggleHelp"
          @open-device-setting="openDeviceSetting"
        >
          <slot name="custom-navbar" />
        </NavBar>
      </template>

      <div v-show="showMainLayout" class="main">
        <slot name="main" />
        <Tabs v-show="mainTabs.length" v-model="currentTab" :tabs="mainTabs" class="main-tabs">
          <template #document>
            <VisualCompartment class="h-full">
              <PdfViewer
                v-if="originalMaterialEnable"
                :doc-path="roomInfo.docPath"
                :is-coach-role="isCoachRole"
                @toggle-original-material="toggleOriginalMaterial"
              />
              <DocViewer
                v-else
                :document-id="roomInfo.docId"
                :participants="participantsInfo"
                :participant-info="localParticipant"
                :has-doc-path="!!roomInfo.docPath"
                :is-coach-role="isCoachRole"
                @toggle-original-material="toggleOriginalMaterial"
              />
            </VisualCompartment>
          </template>
          <template #screenShare>
            <VisualCompartment class="h-full">
              <ScreenTrack v-if="screenTrack" :track="screenTrack"></ScreenTrack>
            </VisualCompartment>
          </template>
          <template #whiteboard>
            <VisualCompartment class="h-full">
              <Whiteboard
                :room-info="roomInfo"
                :local-participant="localParticipant"
                :open-by="openBy"
              />
            </VisualCompartment>
          </template>
          <template #game>
            <VisualCompartment class="h-full">
              <Engder
                v-if="showEngder"
                :level="roomInfo?.metaData?.engderDeckGroup"
                :email="localParticipant.email"
                :type="roomInfo?.metaData?.engderType"
                :class-id="roomInfo?.externalRoomId"
              />
            </VisualCompartment>
          </template>
        </Tabs>
      </div>

      <div v-if="isLoading" class="compartments">
        <VisualCompartment class="compartments -connecting">
          <DoubleBounceLoader :size="40"></DoubleBounceLoader>
          <span>{{ $t('joining') }}...<br />{{ $t('connect-video') }}</span>
          <slot name="loading" />
        </VisualCompartment>
      </div>

      <CompartmentGrid
        v-else-if="!isLoading && roomConnectState !== VideoRoomConnectionState.ConnectFailed"
        :n-compartments="nCompartments"
        :is-focused="showMainLayout"
        class="compartments"
      >
        <!-- for display participant with slot -->
        <slot />
      </CompartmentGrid>
      <div v-else class="compartments">
        <VisualCompartment class="compartments -connecting-fail">
          <div class="box-error">
            <p class="mb-3">{{ roomConnectErrorMessage }}</p>

            <button class="btn btn-primary btn-reload" @click="() => $router.go(0)">
              <SvgIcon
                class="_pointer-on-hover ml-1 mr-3"
                icon="reload"
                :inherit-fill="true"
                height="21"
              ></SvgIcon>
              <span>{{ $t('reload-room') }}</span>
            </button>
          </div>
        </VisualCompartment>
      </div>

      <RoomControl
        v-if="!isWhereby"
        :n-compartments="nCompartments"
        :user-permissions="userPermission"
        :is-small="showMainLayout"
        :camera-enable="device.cameraOn"
        :microphone-enable="device.microphoneOn"
        :document-enable="documentEnable"
        :screen-share-enable="screenShareEnable"
        :whiteboard-enable="whiteboardEnable"
        :chat-enable="chatEnable"
        :sticker-enable="stickerEnable"
        :game-enable="gameEnable"
        :screen-share-show="screenShareShow"
        :chat-notify-count="chatNotifyCount"
        class="room-control"
        @toggle-cam="toggleCamera"
        @toggle-mic="toggleMicrophone"
        @toggle-doc="toggleDocument"
        @toggle-screen-share="toggleScreenShare"
        @toggle-whiteboard="toggleWhiteboard"
        @toggle-chat="toggleChat"
        @toggle-sticker="toggleSticker"
        @toggle-game="toggleGame"
        @switch-device="changeSetting"
      />

      <div
        ref="popoutRef"
        class="popout"
        :style="{
          top: `${restrictedY}px`,
          left: `${restrictedX}px`,
        }"
      >
        <slot name="popout"></slot>
      </div>
      <div v-if="localParticipant?.capabilities?.chat" class="message-preview-position">
        <MessagePreview :new-message="newMessage" @click="chatEnable = true" />
      </div>
      <!-- Chatbox Popup -->
      <div v-if="localParticipant?.capabilities?.chat" class="chat">
        <Chatbox
          :enable-chat="chatEnable"
          :room="roomInfo"
          :local-participant="localParticipant"
          @unread-updated="updateUnreadMessages"
          @new-messages="updatePreviewMessages"
          @close="chatEnable = false"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.room-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  overflow: hidden;
  background: var(--room-body-color);
}
.room {
  --nav-height: 60px;
  --chat-width: 340px;
  --help-width: 480px;
  --control-height: 70px;

  @include media-breakpoint-up-custom(700px) {
    --nav-height: 72px;
    --control-height: 80px;
  }
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 0.3fr minmax(0, calc(100vh - var(--nav-height) - var(--control-height))) 0.1fr;
  grid-template-areas:
    'navbar navbar navbar navbar navbar'
    'compartments compartments compartments compartments compartments'
    'room-control room-control room-control room-control room-control';

  .navbar {
    grid-area: navbar;
    height: var(--nav-height);
    padding: 0 15px;
  }

  .compartments {
    grid-area: compartments;
    padding: 0 15px;
  }

  .main {
    grid-area: main;

    padding: 0 15px 10px 15px;

    > ::v-deep(.compartment) {
      width: 100%;
      height: 100%;
    }
    @include media-breakpoint-up-custom(700px) {
      padding: 0;
    }
  }
  .popout {
    user-select: none;
    touch-action: none;
    position: fixed;
    z-index: 999;
    width: 185px;
    height: 120px;
    border-radius: 6px;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    overflow: hidden;
    cursor: pointer;
    &:empty {
      display: none;
    }
    &:hover,
    &:focus {
      box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.5), 0 2px 4px 0 rgba(0, 0, 0, 0.7);
    }
    ::v-deep(.avatar) {
      width: 50px !important;
      height: 50px !important;
    }

    ::v-deep(.compartment) {
      margin: 0;
      width: 100%;
      height: 100%;
    }
  }

  .room-control {
    grid-area: room-control;
    height: var(--control-height);
    width: 100%;
    padding: 10px 15px;
  }
  .message-preview-position {
    position: absolute;
    right: 22px;
    bottom: calc(var(--control-height) + 10px);
    z-index: $z-index-message-preview;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .chat {
    transform-origin: bottom;
    opacity: 0;
    transition-property: transform opacity;
    transition-duration: 0.35s;
    transition-timing-function: $primary-tc;
    transform: scale3d(1, 1, 1) translate3d(0, 120px, 0);

    @include media-breakpoint-up-custom(700px) {
      transform: scale3d(1, 1, 1) translate3d(0, 120px, 0);
    }

    @include media-breakpoint-up-custom(1200px) {
      display: none;
      grid-area: chat;
      opacity: 1;
      transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    }
  }
  .help {
    transform-origin: bottom;
    opacity: 0;
    transition-property: transform opacity;
    transition-duration: 0.35s;
    transition-timing-function: $primary-tc;
    transform: scale3d(1, 1, 1) translate3d(0, 120px, 0);

    @include media-breakpoint-up-custom(700px) {
      transform: scale3d(1, 1, 1) translate3d(0, 120px, 0);
    }

    @include media-breakpoint-up-custom(1200px) {
      display: none;
      grid-area: help;
      opacity: 1;
      transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    }
  }

  &.-hidden-control {
    --control-height: 0px;
    grid-template-areas:
      'navbar navbar navbar navbar navbar'
      'compartments compartments compartments compartments compartments'
      'compartments compartments compartments compartments compartments';
  }

  &.-with-focus {
    display: grid;
    grid-auto-flow: row;
    grid-template-rows:
      0.3fr minmax(0, calc(100vh - var(--nav-height) - var(--control-height) - 120px))
      120px 0.1fr;
    grid-template-areas:
      'navbar navbar navbar navbar navbar'
      'main main main main main'
      'compartments compartments compartments compartments compartments'
      'room-control room-control room-control room-control room-control';
    &.-hidden-control {
      grid-template-areas:
        'navbar navbar navbar navbar navbar'
        'main main main main main'
        'compartments compartments compartments compartments compartments'
        'compartments compartments compartments compartments compartments';
    }

    @include media-breakpoint-up-custom(700px) {
      grid-template-rows: 0.3fr minmax(0, calc(100vh - var(--nav-height) - var(--control-height))) 0.1fr;
      grid-template-areas:
        'navbar navbar navbar navbar navbar'
        'main main main compartments compartments'
        'main main main room-control room-control';
      &.-hidden-control {
        grid-template-areas:
          'navbar navbar navbar navbar navbar'
          'main main main compartments compartments'
          'main main main compartments compartments';
      }
    }
  }

  &.-with-chat {
    z-index: $z-index-chat;
    transform: scale3d(0.98, 0.98, 1);
    background: rgba(255, 255, 255, 0.1);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;

    @include media-breakpoint-up-custom(700px) {
      transform: scale3d(1, 1, 1);
      background: rgba(255, 255, 255, 0);
      border-radius: 0;
    }

    @include media-breakpoint-up-custom(1200px) {
      grid-template-columns: 1fr 1fr 1fr 1fr minmax(280px, 1.5fr);

      grid-template-areas:
        'navbar navbar navbar navbar navbar'
        'compartments compartments compartments compartments chat'
        'room-control room-control room-control room-control chat';

      &.-hidden-control {
        grid-template-areas:
          'navbar navbar navbar navbar navbar'
          'compartments compartments compartments compartments chat'
          'compartments compartments compartments compartments chat';
      }
      &.-with-focus {
        grid-template-columns: 1.5fr 1fr 1fr 1fr minmax(280px, 1.25fr);
        grid-template-areas:
          'navbar navbar navbar navbar navbar'
          'main main compartments compartments chat'
          'main main room-control room-control chat';

        &.-hidden-control {
          grid-template-areas:
            'navbar navbar navbar navbar navbar'
            'main main compartments compartments chat'
            'main main compartments compartments chat';
        }
      }
    }
    .chat {
      position: fixed;
      display: block;
      width: 100%;
      height: 96%;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: $z-index-chat;
      opacity: 1;
      transform: scale3d(1.02, 1.02, 1) translate3d(0, 0, 0);

      @include media-breakpoint-up-custom(700px) {
        width: 380px;
        height: 92%;
        left: unset;
        transform: none; // Important! Otherwise, a modal cannot be fixed to the viewport
      }

      @include media-breakpoint-up-custom(1200px) {
        display: block;
        position: relative;
        width: unset;
        height: unset;
        left: unset;
        bottom: unset;
        right: unset;
      }
    }
  }

  &.-with-help {
    transform: scale3d(0.98, 0.98, 1);
    background: rgba(255, 255, 255, 0.1);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;

    @include media-breakpoint-up-custom(700px) {
      transform: scale3d(1, 1, 1);
      background: rgba(255, 255, 255, 0);
      border-radius: 0;
    }

    @include media-breakpoint-up-custom(1200px) {
      grid-template-columns: minmax(280px, 2fr) 1fr 1fr 1fr 1fr;
      grid-template-areas:
        'navbar navbar navbar navbar navbar'
        'help compartments compartments compartments compartments'
        'help room-control room-control room-control room-control';

      &.-with-focus {
        grid-template-columns: minmax(280px, 2fr) 1.25fr 1fr 1fr 1fr;
        grid-template-areas:
          'navbar navbar navbar navbar navbar'
          'help main main compartments compartments'
          'help main main room-control room-control';

        &.-hidden-control {
          grid-template-areas:
            'navbar navbar navbar navbar navbar'
            'help main main compartments compartments'
            'help main main compartments compartments';
        }
        .main {
          padding-left: 15px;
        }
      }
      &.-hidden-control {
        grid-template-areas:
          'navbar navbar navbar navbar navbar'
          'help compartments compartments compartments compartments'
          'help compartments compartments compartments compartments';
      }
    }
    .help {
      position: fixed;
      display: block;
      width: 100%;
      height: 96%;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: $z-index-chat;
      opacity: 1;
      transform: scale3d(1.02, 1.02, 1) translate3d(0, 0, 0);

      @include media-breakpoint-up-custom(700px) {
        width: 380px;
        height: 92%;
        left: unset;
        transform: none; // Important! Otherwise, a modal cannot be fixed to the viewport
      }

      @include media-breakpoint-up-custom(1200px) {
        display: block;
        position: relative;
        width: unset;
        height: unset;
        left: unset;
        bottom: unset;
        right: unset;
      }
    }
  }
}
.compartment {
  &.-connecting-fail {
    height: 100%;
    .box-error {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
      > p {
        max-width: 60vw;
        text-align: center;
      }
    }
    .btn-reload {
      display: flex;
      align-items: center;
      @include font-size(1.2rem);
    }
  }

  &.-connecting {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    @include font-weight(normal);
    text-align: center;
  }
}
</style>

<style lang="scss">
body:not(.dark) .main-tabs.tab-component {
  background: rgba(var(--control-btn-color-rgb), 0.3);
  color: rgba(var(--control-btn-color-rgb), 1);

  .tab-headers {
    background: rgba(#ffffff, 0.03);

    .tab-header {
      color: rgba($gs-2, 0.6);
      background: rgba(#ffffff, 0);

      &.active {
        background: rgba(#ffffff, 0.8);
        color: rgba($gs-2, 1);
      }

      &:hover:not(.active) {
        background: rgba(#ffffff, 0.1);
        color: rgba($gs-2, 0.85);
      }
    }
  }
}
</style>
