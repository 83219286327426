<script setup lang="ts">
import { ref } from 'vue'
import useSpaceApi from '@/composables/api/useSpaceApi'
import useAuth from '@/composables/api/useAuth'
const props = defineProps({
  exitPath: {
    type: String,
    default: '',
  },
})
const { saveLogsLeaveButton } = useSpaceApi()
const { getAccessInfo } = useAuth()
const isOpenedPopup = ref(false)
const feedback = async () => {
  const { roomId, participantId } = getAccessInfo()
  isOpenedPopup.value = false
  await saveLogsLeaveButton(roomId || '', participantId || '')
  window.location.href = props.exitPath
}
</script>

<template>
  <div>
    <Popup :title="`${$t('feedback')}`" :open="isOpenedPopup" @close="isOpenedPopup = false">
      <div class="text-base feedback-container">
        {{ $t('feedback-message', { exitPath }) }}
      </div>
      <template #footer>
        <div class="footer-setting">
          <button type="button" class="btn btn-primary" @click="feedback">
            {{ $t('confirm-btn') }}
          </button>
          <button type="button" class="btn btn-tertiary" @click="isOpenedPopup = false">
            {{ $t('cancel-btn') }}
          </button>
        </div>
      </template>
    </Popup>

    <button class="btn-action-nav nav-menu-item" @click="isOpenedPopup = true">
      <SvgIcon
        class="_pointer-on-hover mr-1"
        icon="required"
        :inherit-fill="true"
        height="26"
      ></SvgIcon>
      <span>{{ $t('feedback-button') }}</span>
    </button>
  </div>
</template>

<style lang="scss" scoped>
.feedback-container {
  color: #4a4a4a;
}
.btn {
  font-size: 1.1rem;
  @include font-weight(bold);
}
</style>
