<script setup lang="ts">
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  exitPath: {
    type: String,
    default: '',
  },
  studentName: {
    type: String,
    default: '',
  },
})
const emit = defineEmits(['update:modelValue'])

const close = () => {
  emit('update:modelValue', false)
  if (props.exitPath) {
    window.location.href = props.exitPath
  }
}
</script>

<template>
  <Popup :title="'Alert'" :open="modelValue" @close="close">
    <span class="text-base text-content py-3">
      {{ $t('feedback-help-request.message-student-cancel', { studentName }) }}</span
    >
    <template #footer>
      <div class="flex justify-center">
        <button
          type="button"
          class="btn btn-primary text-white text-base w-full ml-2"
          @click="close"
        >
          {{ $t('leave-button') }}
        </button>
      </div>
    </template>
  </Popup>
</template>

<style lang="scss" scoped>
.text-content {
  color: #4a4a4a;
}
</style>
