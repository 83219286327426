<script setup lang="ts">
import { computed, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { HelpAction, SolveAction } from '../../types/enums/help.enum'
import { useRoomStore } from '@/stores'

const emit = defineEmits(['on-close', 'confirm-solution'])
const props = defineProps({
  helpRequaseType: {
    type: String as PropType<HelpAction>,
    default: undefined,
  },
  helpSolvedBy: {
    type: String as PropType<SolveAction>,
    default: undefined,
  },
  isMainEngine: {
    type: Boolean,
    default: false,
  },
  isCoachRole: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
})
const i18n = useI18n()
const roomStore = useRoomStore()
const isRequestOriginalMaterial = computed(
  () => props.helpSolvedBy === SolveAction.OPEN_ORIGINAL_MATERIAL,
)
const message = computed(() => {
  switch (props.helpSolvedBy) {
    case SolveAction.SHARE_SCREEN:
      return props.isCoachRole
        ? i18n.t('help-popup.coach-message.share-screen')
        : i18n.t('help-popup.student-message.share-screen')
    case SolveAction.MOVE_ROOM:
      if (roomStore.isNightTime) {
        return props.isCoachRole
          ? i18n.t('help-popup.coach-message.move-room', {
              room: props.isMainEngine ? i18n.t('second-room') : i18n.t('main-room'),
            })
          : i18n.t('help-popup.student-message.move-room')
      } else {
        return props.isCoachRole
          ? i18n.t('help-popup.coach-message.move-room-operation-time', {
              room: props.isMainEngine ? i18n.t('second-room') : i18n.t('main-room'),
            })
          : i18n.t('help-popup.student-message.move-room-operation-time')
      }
    case SolveAction.RELOAD_ROOM:
      return props.isCoachRole
        ? i18n.t('help-popup.coach-message.reload-room')
        : i18n.t('help-popup.student-message.reload-room')
    case SolveAction.MUTE_CAMERA:
      return props.isCoachRole
        ? i18n.t('help-popup.coach-message.mute-camera')
        : i18n.t('help-popup.student-message.mute-camera')
    case SolveAction.OPEN_ORIGINAL_MATERIAL:
      return i18n.t('help-popup.coach-message.open-original-material')
    default:
      return ''
  }
})

const close = () => {
  emit('on-close', false)
}
const confirmSolved = () => {
  emit('confirm-solution')
}
</script>

<template>
  <Popup :title="i18n.t('help-popup.notify')" :open="!!helpSolvedBy" @close="close">
    <Loading
      class="curriculum-popup-container"
      background-color="#ffffffac"
      is-fullscreen
      :is-loading="isLoading"
      size="36"
    />
    <span class="text-base curriculum-popup-container py-3">{{ message }}</span>
    <blockquote v-if="isRequestOriginalMaterial" class="curriculum-mark-down-container">
      <ol class="curriculum-mark-down">
        <li
          v-for="(msg, i) in i18n.tm('help-popup.coach-message.original-material-markdown')"
          :key="`og-${i}`"
          class="text-sm mb-2"
        >
          {{ msg }}
        </li>
      </ol>
    </blockquote>
    <template #footer>
      <div class="flex">
        <button
          type="button"
          :disabled="isLoading"
          class="btn btn-tertiary text-white text-base w-full"
          @click="close"
        >
          {{ $t('help-popup.cancel-btn') }}
        </button>
        <button
          type="button"
          class="btn btn-primary text-white text-base w-full ml-2"
          :disabled="isLoading"
          @click="confirmSolved"
        >
          {{ $t('help-popup.confirm-btn') }}
        </button>
      </div>
    </template>
  </Popup>
</template>

<style lang="scss" scoped>
.curriculum-popup-container {
  color: #4a4a4a;
}
.curriculum-mark-down-container {
  border-left: 6px;
  border-color: var(--primary-color);
  border-style: solid;
  border-radius: 3px 7px 7px 3px;
  background: $body-background;
}
.curriculum-mark-down {
  list-style: auto;
  padding: 1rem;
  margin-left: 20px;
}
</style>
