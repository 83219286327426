import { computed, reactive, toRefs } from 'vue'

import { useRoute, useRouter } from 'vue-router'
import useAuth from './api/useAuth'
import { ApiResponse } from './api/useApi'
import useSpaceApi from './api/useSpaceApi'
import { IJoiningParticipants, IParticipantInfo } from '@/types/interfaces/participant.interface'
import { Engine } from '@/types/enums/room.enum'
import { isMobileDevice } from '@/helpers/device.helper'
import { isMobile } from './twilio/useTwilioState'
import { useRoomStore, useZoomStore } from '@/stores'
import useSocketEvent from './room/useSocketEvent'
import { catchJson } from '@/helpers/transform.helper'

interface UseTestPageState {
  authResponse: ApiResponse
  roomTitle: string
  joiningParticipants: IJoiningParticipants[]
  localParticipantInfo?: IParticipantInfo
  initLoading: boolean
  isOpenCoachMessage: boolean
}

export default function useTestPage() {
  const state = reactive<UseTestPageState>({
    authResponse: {} as ApiResponse,
    roomTitle: 'Globish Tiger Room',
    joiningParticipants: [],
    localParticipantInfo: {} as IParticipantInfo,
    initLoading: false,
    isOpenCoachMessage: false,
  })

  const route = useRoute()
  const router = useRouter()
  const { socket } = useSocketEvent()
  const { loginWithToken } = useAuth()
  const roomStore = useRoomStore()
  const zoomStore = useZoomStore()
  const { getParticipantsAlreadyInRoom } = useSpaceApi()

  /**
   * Store
   */
  const engine = computed(() => roomStore.currentEngine)
  const alreadyParticipants = computed(() =>
    roomStore.participants
      .filter((p) => state.joiningParticipants.some((participant) => participant.email === p.email))
      .map((p) => {
        return { ...p, label: p.name }
      }),
  )

  const setDefaultState = () => {
    state.joiningParticipants = []
  }

  const onInit = async () => {
    isMobile.value = isMobileDevice()
    setDefaultState()
    const token = route?.query?.token as string
    const engine = 'whereby' in route?.query ? Engine.WHEREBY : undefined
    state.authResponse = await loginWithToken(token)
    if (!state.authResponse.data?.accessToken) {
      const errorData = catchJson(state.authResponse.message?.error)
      const timeAttend = errorData?.now
      const message = state.authResponse.message?.message
      router.push({
        name: 'join-fail',
        query: {
          from: location.href,
          code: state.authResponse.message?.statusCode,
          timeAttend,
          message,
          error: state.authResponse.message?.error,
          token,
        },
      })
      return
    }
    await roomStore.getRoomInitialInfo(engine)
    state.roomTitle = roomStore.room.name
    state.localParticipantInfo = roomStore.localParticipantInfo
    if (roomStore.currentEngine === Engine.ZOOM) {
      // zoom cannot set bg on first time init
      localStorage.setItem('video_bg', 'none')
    }
    if (!socket.connected) {
      socket.connect()
    }
    const { data } = await getParticipantsAlreadyInRoom(roomStore.room.id)
    if (data) {
      const participantsAlreadyInRoom = data.filter((it) => it !== roomStore.localParticipant.email)
      state.joiningParticipants = participantsAlreadyInRoom.map((email) => ({ email }))
    }

    socket.emit('joinTestDevice', {
      roomId: roomStore.room.id,
      email: roomStore.localParticipant.email,
    })
    socket.on('updateTestDeviceState', (data) => {
      if (data.isInRoom) {
        const newSocketId = state.joiningParticipants.find(
          (participant) => participant.socketId !== data.socketId,
        )
        if (newSocketId) {
          const index = state.joiningParticipants.findIndex(
            (participant) => participant.email === data.email,
          )
          if (index !== -1) {
            state.joiningParticipants.splice(index, 1)
          }
        }
        const alreadyInRoom = state.joiningParticipants.find(
          (participant) => participant.email === data.email,
        )
        if (!alreadyInRoom) {
          state.joiningParticipants.push({ email: data.email, socketId: data.socketId })
        }
      } else {
        const alreadySocketId = state.joiningParticipants.find(
          (participant) => participant.socketId === data.socketId,
        )
        const alreadyInRoom = state.joiningParticipants.find(
          (participant) => participant.email === data.email,
        )
        if (alreadySocketId || !alreadyInRoom?.socketId) {
          const index = state.joiningParticipants.findIndex(
            (participant) => participant.email === data.email,
          )
          if (index !== -1) {
            state.joiningParticipants.splice(index, 1)
          }
        }
      }
    })
    state.initLoading = true
  }

  return {
    ...toRefs(state),
    alreadyParticipants,
    engine,
    onInit,
  }
}
