import { IBackgroundOption } from '@/types/interfaces/background.interface'

export const backgroundList: IBackgroundOption[] = [
  { name: 'None', path: 'prohibited', value: 'none' },
  { name: 'Blur Background', path: 'video-background', type: 'blur', value: 'blur' },
  {
    name: 'Globish Background 1',
    type: 'image',
    path: '/img/bg/globish-bg-color-orange.png',
    src: require('../../public/img/bg/globish-bg-color-orange.png'),
    value: 'globish-bg-color-orange',
  },
  {
    name: 'Globish Background 2',
    type: 'image',
    path: '/img/bg/globish-bg-color-warm.png',
    src: require('../../public/img/bg/globish-bg-color-warm.png'),
    value: 'globish-bg-color-warm',
  },
  {
    name: 'Globish Background 3',
    type: 'image',
    path: '/img/bg/globish-bg-color-black.png',
    src: require('../../public/img/bg/globish-bg-color-black.png'),
    value: 'globish-bg-color-black',
  },

  // can add data image background here and add image in public folder
]
