import { VideoInputTest } from '@twilio/rtc-diagnostics'
import ZoomVideo, { LocalVideoTrack } from '@zoom/videosdk'
import { ref, reactive, toRefs } from 'vue'

interface IUseCameraTestState {
  videoElementRef?: HTMLVideoElement
  videoInputDeviceTest?: VideoInputTest
  selectedVideo?: string
  localVideoTrack?: LocalVideoTrack
}

export const videoInputTestReport = ref<any>(undefined)
export const videoInputTestPassed = ref<boolean>(true)

export const useCameraTest = () => {
  const state = reactive<IUseCameraTestState>({
    videoElementRef: undefined,
    videoInputDeviceTest: undefined,
    selectedVideo: undefined,
    localVideoTrack: undefined,
  })

  const videoInputTest = () => {
    stopVideoTrackZoom()
    startVideoTrackZoom()
  }

  const startVideoTrackZoom = async () => {
    state.localVideoTrack = ZoomVideo.createLocalVideoTrack(state.selectedVideo)
    if (state.videoElementRef) await state.localVideoTrack.start(state.videoElementRef)
  }

  const stopVideoTrackZoom = async () => {
    if (state.localVideoTrack) {
      await state.localVideoTrack.stop()
      state.localVideoTrack = undefined
    }
  }

  const setVideoInputTestResult = (result: boolean) => {
    videoInputTestPassed.value = result
  }

  return {
    ...toRefs(state),
    videoInputTest,
    setVideoInputTestResult,
    stopVideoTrackZoom,
    startVideoTrackZoom,
  }
}
