<script setup lang="ts">
import ConnectionSuccess from '@/components/video-diagnostic/ConnectionSuccess.vue'
import ConnectionFailed from '@/components/video-diagnostic/ConnectionFailed.vue'
import LoadingBox from '@/components/video-diagnostic/Loading.vue'
import { Stage } from '@/types/enums/video-diagnostic.enum'
import {
  preflightTest,
  preflightTestFinished,
  preflightTestInProgress,
} from '@/composables/video-diagnostic/usePreflightTest'
import { bitrateTestInProgress } from '@/composables/video-diagnostic/useBitrateTest'
import { computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'

interface ConnectivityEmits {
  (e: 'nextStage', value: number): void
  (e: 'inprogress'): void
}

defineProps({
  stage: {
    type: Number,
    required: true,
  },
})

const i18n = useI18n()

const emit = defineEmits<ConnectivityEmits>()

const header = computed(() => i18n.t('video-diagnostic.hang-tight'))
const message = computed(() => i18n.t('video-diagnostic.hang-tight-detail'))

const inprogress = computed(() => preflightTestInProgress.value || bitrateTestInProgress.value)
const connectionFailed = computed(
  () =>
    preflightTestFinished.value &&
    (preflightTest.error !== null ||
      !preflightTest.signalingGatewayReachable ||
      !preflightTest.turnServersReachable),
)

watch(inprogress, (value) => {
  if (value === false) {
    emit('inprogress')
  }
})
</script>

<template>
  <LoadingBox v-if="inprogress" :stage="stage" :header="header" :message="message"></LoadingBox>
  <ConnectionSuccess
    v-else-if="!connectionFailed"
    :stage="stage"
    @next-stage="emit('nextStage', Stage.QUALITY)"
  ></ConnectionSuccess>
  <ConnectionFailed
    v-else
    :stage="stage"
    @next-stage="emit('nextStage', Stage.QUALITY)"
  ></ConnectionFailed>
</template>

<style lang="scss" scoped>
thead > tr {
  background-color: #32323209;
}

::v-deep(svg) {
  display: inline-block !important;
  margin-bottom: 0.4rem;
}
</style>
