<script setup lang="ts">
import useBrowserSupport from '@/composables/useBrowserSupport'
import { Engine } from '@/types/enums/room.enum'
import { PropType, computed, onMounted } from 'vue'
import BrowserSupportCard from '@/components/checkup/BrowserSupportCard.vue'
const props = defineProps({
  roomUrl: {
    type: String,
    default: '',
  },
  engine: {
    type: String as PropType<Engine>,
    default: Engine.TWILIO,
  },
})

const forceChangeBrowser = computed(() => props.engine === Engine.ZOOM)
const { isCopied, isOpen, detectSupportToOpen, copyRoomUrl, close } = useBrowserSupport(
  props.engine,
  props.roomUrl,
)

onMounted(() => {
  detectSupportToOpen()
})
</script>

<template>
  <Popup
    title="Incompatible browser"
    :can-close="!forceChangeBrowser"
    :open="isOpen"
    @close="close"
  >
    <div class="text-base text-content">
      <div>
        <BrowserSupportCard :engine="engine" :room-url="roomUrl" />
      </div>
    </div>
    <template #footer>
      <button
        v-if="!forceChangeBrowser"
        type="button"
        class="btn btn-tertiary text-base w-full"
        @click="close"
      >
        Continue at your own risk
      </button>
      <button
        type="button"
        class="btn btn-primary text-base w-full mt-3 copy-btn"
        :class="{ '-copied': isCopied }"
        @click="copyRoomUrl"
      >
        <span v-show="isCopied" class="flex justify-center copy-feedback">
          <SvgIcon class="mr-1" icon="success" :inherit-fill="true" width="25"></SvgIcon>
          Copied
        </span>
        <span v-show="!isCopied">Copy this room link</span>
      </button>
    </template>
  </Popup>
</template>

<style lang="scss" scoped>
.text-content {
  color: #4a4a4a;
}

.external-link-icon {
  color: $gs-3;
}

a:hover {
  text-decoration: underline;
}
a:focus-visible {
  outline: none;
}
.copy-btn.-copied {
  background: $gs-2 !important;
  .copy-feedback {
    color: rgba(white, 0.9) !important;
  }
}
</style>
