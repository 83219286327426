<script setup lang="ts">
import { computed } from 'vue'
import Tabs from '../common/Tabs.vue'
import useSticker from '@/composables/room/useSticker'
import { ITab } from '@/types/interfaces/common.interface'

interface StickerEmits {
  (e: 'close'): void
}

defineProps({
  isEnable: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits<StickerEmits>()

const {
  categories,
  activeCategory,
  showingSticker,
  stickerPathToShow,
  showingFullScreen,
  stickers,
  sendSticker,
} = useSticker()

const categoriesTabs = computed<ITab[]>(() =>
  categories.value.map((cat) => {
    return { label: cat, value: cat }
  }),
)
const close = () => {
  emit('close')
}
const selectSticker = async (stickerUrl: string) => {
  await sendSticker(stickerUrl)
  close()
}
</script>

<template>
  <div>
    <transition name="fade-scale">
      <div v-show="isEnable" class="sticker-popup">
        <div class="close-button _pointer-on-hover" @click="close">
          <SvgIcon class="close-icon" icon="close" :inherit-fill="true" height="32"></SvgIcon>
        </div>
        <div class="scrollable">
          <div class="sticker-list">
            <Tabs v-model="activeCategory" :tabs="categoriesTabs" />
            <div class="grid grid-cols-3 md:grid-cols-4 m-0 pt-2">
              <div
                v-for="(sticker, index) in stickers[activeCategory]"
                :key="index"
                class="sticker-wrapper _pointer-on-hover _prevent-text-select"
                @click="selectSticker(sticker.images.original.url)"
              >
                <div class="sticker">
                  <img :src="sticker.images.preview_gif.url" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div v-if="showingSticker" class="layout">
      <div class="sticker" :class="{ fullscreen: showingFullScreen }">
        <img v-if="stickerPathToShow" :src="stickerPathToShow" alt="" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.layout {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.35);
  z-index: $z-index-popup;
  pointer-events: none;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: calc(100% - 30px);

    @include media-breakpoint-up-custom(430px) {
      max-width: 400px;
    }
  }
  .sticker {
    &.fullscreen {
      img {
        max-width: none !important;
        height: 100% !important;
      }
    }
  }
}
.sticker-popup {
  @extend .layout;
  z-index: $z-index-popup + 1;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, 0.75);
  pointer-events: unset;

  &::-webkit-scrollbar {
    display: none;
  }

  .close-button {
    position: fixed;
    top: 0;
    right: 0;
    padding: 15px;
    color: $gs-5;
    z-index: 2;

    &:hover {
      color: #fff;
    }

    @include media-breakpoint-up-custom(700px) {
      top: 15px;
      right: 15px;
    }
  }

  .scrollable {
    z-index: 1;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;

    .sticker-list {
      z-index: 1;
      padding: 60px 30px 15px 30px;
      max-width: 1000px;
      width: 100%;
      .tab-header {
        text-transform: capitalize;
      }
      .sticker-wrapper {
        padding: 20px;
        overflow: hidden;
        border-radius: 12px;
        transition: background 0.35s $primary-tc;

        &:hover {
          background: rgba(#fff, 0.06);
        }
      }

      .sticker {
        position: relative;
        display: inline-block;
        width: 100%;
        padding-bottom: 100%;

        img {
          max-width: 100%;
        }
      }
    }
  }
  .tab-component {
    height: auto;
  }
}
</style>
