<script setup lang="ts">
import { onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'
import moment from 'moment-timezone'
import { IRoom } from '@/types/interfaces/room.interface'
import { catchJson } from '@/helpers/transform.helper'

const route = useRoute()

const code = computed(() => route.query.code)
const link = computed(() => route.query.from as string)
const message = computed(() => route.query.message)
const timeAttend = computed(() => route.query.timeAttend as string)

const errorQuery = computed(() => catchJson((route.query.error as string) || '{}'))
const roomDetail = computed(() => errorQuery.value?.room as IRoom)

const isTimeOut = computed(() => {
  const attendAfterMin = roomDetail.value?.attendAfter > 0 ? roomDetail.value?.attendAfter : 0
  const endAttendTime = moment(roomDetail.value?.endTime).add(attendAfterMin, 'm')
  return route.query.code == 'ECONNABORTED' || moment().diff(endAttendTime) > 0
})
const attendTime = computed(() => {
  if (roomDetail.value?.startTime) {
    const attendBeforeMin =
      Number(roomDetail.value?.attendBefore) > 0 ? Number(roomDetail.value?.attendBefore) : 0
    const timeForAttend = moment(roomDetail.value?.startTime).subtract(attendBeforeMin, 'm')
    if (moment().diff(moment(timeAttend.value)) < 0) return timeForAttend.format('LLL')
  }
  return 0
})
onMounted(() => {
  /**
   * Don't For get to uncomment this code
   */
  // await axios.post('https://www.globish.co.th/webhook/space/event', {
  //   action: 'warning',
  //   message: message,
  //   code: code,
  //   link: link,
  // })
})
</script>

<template>
  <div class="join">
    <svg-icon class="mb-2" icon="sad-cloud" :inherit-fill="true" height="64"></svg-icon>
    <div style="max-width: 320px">
      <div v-if="code">{{ $t('join-fail-status') }} {{ code }}</div>
      <div v-if="message">{{ message }}</div>
      <div v-else>
        {{ $t('join-fail') }}
      </div>
      <div v-if="attendTime || isTimeOut" class="mt-3">
        <div v-show="!isTimeOut">
          {{ $t('join-fail-time-out') }} <br />
          <div class="font-weight-bold">{{ attendTime }}</div>
        </div>
        <a v-show="link" class="btn btn-primary mt-3" :href="link">
          <svg-icon
            key="reload"
            icon="reload"
            :inherit-fill="true"
            width="24"
            class="mr-1"
          ></svg-icon>
          <span>{{ $t('join-fail-reload-button') }}</span></a
        >
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.join {
  background: var(--room-body-color);
  position: relative;
  width: 100%;
  height: 100%;
  // color: rgba(#fff, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include font-size(1.6rem);
  @include font-weight(normal);
  text-align: center;
}
.btn.btn-primary {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $gs-2;
  color: $gs-6;
  opacity: 1;
  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active {
    background: $gs-2;
    color: $gs-6;
    opacity: 0.9;
  }
}
</style>
