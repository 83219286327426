import { CustomEventEmitter } from '@/helpers/custom-event-emitter'
import {
  calculateImageSize,
  convertBase64,
  getBytes,
  imageToCanvas,
  convertBase64ImgToAnnotation,
} from '@/helpers/input-image.helper'
import { Core, UI, WebViewerInstance } from '@pdftron/webviewer'
import { AnnotationInfo } from '../api/useDocApi'

interface InsertedAnnotation extends Core.Annotations.Annotation {
  canvas?: HTMLCanvasElement
  image?: HTMLImageElement
}

export enum DocEvent {
  ADD = 'add',
  MODIFY = 'modify',
  DELETE = 'delete',
  LOADED = 'loaded',
  PAGE_UPDATED = 'pageUpdated',
}

export class PDFCollaboration extends CustomEventEmitter<DocEvent> {
  private docViewer: Core.DocumentViewer
  private annotManager: Core.AnnotationManager
  private toggleFollowMe = false
  /**
   * variable for insert image logic
   */
  private MAX_IMAGE_SIZE = 512 // KB
  private NEW_IMAGE_WIDTH = 600 // px
  private NEW_IMAGE_HEIGHT = 360 // px

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private eventVisibility: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private eventToolbarGroup: any

  constructor(
    private webViewerInstance: WebViewerInstance,
    private documentId: string,
    private authorId: string,
    private username: string,
    private isWhiteBoard: boolean,
  ) {
    super()
    this.docViewer = webViewerInstance.Core.documentViewer
    this.annotManager = webViewerInstance.Core.annotationManager
    this.setWebViewerTools()
    this.listenEvent(this.isWhiteBoard)
  }

  setWebViewerTools() {
    const { Tools } = this.webViewerInstance.Core
    const instance = this.webViewerInstance
    // const style = this.webViewerInstance.UI.iframeWindow.document.documentElement.style;
    // style.setProperty(`--primary-button`, 'red');
    // style.setProperty(`--primary-button-hover`, 'yellow');
    // Disabled tab
    const headerSettingTab = [
      'viewControlsButton',
      'thumbRotateCounterClockwise',
      'thumbDelete',
      'thumbRotateClockwise',
      'documentControl',
      // "thumbMultiDelete",
      // "thumbMultiRotate",
      // "thumbExtract",
      'signaturePanelButton',
      'selectToolButton',
      'fullscreenButton',
      'printButton',
      // 'themeChangeButton',
      'languageButton',
    ]
    const headerAnnotationGroup = [
      'toolbarGroup-Edit',
      'toolbarGroup-FillAndSign',
      'toolbarGroup-Forms',
      'toolbarGroup-Measure',
    ]

    const elementSpecial = [
      'miscToolGroupButton',
      'signatureToolGroupButton',
      'textSquigglyToolGroupButton',
      'strikeoutToolGroupButton',
      'rubberStampToolGroupButton',
      'fileAttachmentToolGroupButton',
      'calloutToolGroupButton',
    ]

    if (!this.isWhiteBoard) {
      const disableForPDF = ['toolbarGroup-Insert']
      headerAnnotationGroup.push(...disableForPDF)
    } else {
      const disableForWhiteboard = ['searchButton', 'toggleNotesButton']
      headerAnnotationGroup.push(...disableForWhiteboard)
    }
    const elementInComments = ['noteState']
    const pageManipulations = ['pageManipulationOverlayButton']
    this.webViewerInstance.UI.disableElements([
      ...headerSettingTab,
      ...headerAnnotationGroup,
      ...elementInComments,
      ...elementSpecial,
      ...pageManipulations,
    ])
    //  return annotation instanceof instance.Annotations.FreeHandAnnotation;
    this.webViewerInstance.UI.disableReplyForAnnotations(
      (annotation: Core.Annotations.Annotation) =>
        annotation instanceof instance.Core.Annotations.Annotation,
    )

    this.webViewerInstance.UI.disableTools([
      Tools.ToolNames.SIGNATURE,
      Tools.ToolNames.STAMP,
      Tools.ToolNames.CALLOUT,
      Tools.ToolNames.CALLOUT2,
      Tools.ToolNames.CALLOUT3,
      Tools.ToolNames.CALLOUT4,
      Tools.ToolNames.FILEATTACHMENT,
      Tools.ToolNames.CROP,
      Tools.ToolNames.SQUIGGLY,
      Tools.ToolNames.SQUIGGLY2,
      Tools.ToolNames.SQUIGGLY3,
      Tools.ToolNames.SQUIGGLY4,
      Tools.ToolNames.STRIKEOUT,
      Tools.ToolNames.STRIKEOUT2,
      Tools.ToolNames.STRIKEOUT3,
      Tools.ToolNames.STRIKEOUT4,
      Tools.ToolNames.STAMP,
      Tools.ToolNames.STICKY,
      Tools.ToolNames.STICKY2,
      Tools.ToolNames.STICKY3,
      Tools.ToolNames.STICKY4,
    ]) // hides DOM element + disables shortcut

    // re-enable every tool when no parameter is passed in
    // webViewerInstance.value.enableTools();

    this.webViewerInstance.UI.disableToolDefaultStyleUpdateFromAnnotationPopup()
    this.webViewerInstance.UI.disableFeatures([instance.UI.Feature.LocalStorage])

    this.webViewerInstance.UI.setHeaderItems((header) => {
      this.setCustomToolbarGroup(header)
    })
  }

  removeHeaderItems() {
    this.webViewerInstance.UI.setHeaderItems((header) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const newItems: any[] = []
      const items = header.getItems()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      items.map((it: any) => {
        if (it.title !== 'component.searchPanel' && it.title !== 'component.notesPanel') {
          newItems.push(it)
        }
      })
      header.update(newItems)
    })
  }

  elementClearAll(callback: { (): void; (): void }) {
    this.webViewerInstance.UI.setHeaderItems((header) => {
      const items = header.getItems()
      items.splice(10, 0, {
        type: 'actionButton',
        toolName: 'CleanAllTool',
        dataElement: 'cleanToolButton',
        title: 'Clean Whiteboard',
        img: 'icon-delete-line',
        onClick: () => callback(),
      })
      header.update(items)
    })
  }

  listenEvent(isAdmin: boolean) {
    this.onDocumentLoaded()
    this.setPermission(isAdmin)
    this.onAnnotationChanged()

    const { ToolbarGroup } = this.webViewerInstance.UI

    this.webViewerInstance.UI.addEventListener('toolbarGroupChanged', (event) => {
      // set event toolbar group
      this.eventToolbarGroup = event

      if (event.detail === ToolbarGroup.INSERT) {
        setTimeout(() => {
          const $ = this.webViewerInstance.UI.iframeWindow.document
          const [, button] = $.querySelectorAll<HTMLElement>(
            '[data-element="stampToolGroupButton"]',
          )
          button.click()
        }, 250)
      }
    })

    let timeoutIDs: NodeJS.Timeout[] = []
    this.webViewerInstance.UI.addEventListener('visibilityChanged', async (event) => {
      this.eventVisibility = event

      if (event.detail.element === 'toolsOverlay') {
        const timeoutID = setTimeout(() => {
          if (this.eventToolbarGroup?.detail === ToolbarGroup.INSERT) {
            // check insert image icon is active
            if (event.detail.isVisible) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const input = document.createElement('input')
              input.style.display = 'none'
              input.className = 'stamp-create-tool-file-uploader'
              input.accept = '.jpg,.jpeg,.png'
              input.type = 'file'
              // add event listener
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              input.addEventListener('change', async (e: any) => {
                const base64Img = await convertBase64(e.target.files[0])
                await convertBase64ImgToAnnotation({
                  webViewerInstance: this.webViewerInstance,
                  annotManager: this.annotManager,
                  base64Img,
                })
                // deactivate icon image button
                const $ = this.webViewerInstance.UI.iframeWindow.document
                const [, button] = $.querySelectorAll<HTMLElement>(
                  '[data-element="stampToolGroupButton"]',
                )
                button.click()
              })
              // open file uploader
              input.click()
            }
          }
          clearTimeout(timeoutID)
          timeoutIDs = []
        }, 500)

        timeoutIDs.push(timeoutID)
        /**
         * handle duplicate timeout
         *  implement only last item every 500 ms
         */
        if (timeoutIDs.length > 1) {
          while (timeoutIDs.length > 1) {
            const [id, ...etc] = timeoutIDs
            timeoutIDs = etc
            clearTimeout(id)
          }
        }
      }
    })
  }

  // set admin user by @isAdmin boolean
  setAdminUser(isAdmin: boolean) {
    if (isAdmin) {
      this.annotManager.promoteUserToAdmin()
    } else {
      this.annotManager.demoteUserFromAdmin()
    }
  }

  // log debugging for check the current user is admin
  getAdminUser() {
    // console.log('=====get admin user::', this.annotManager.getIsAdminUser())
  }

  // set read/write permission for user
  setPermission(isAdmin: boolean) {
    this.annotManager.setPermissionCheckCallback(
      (_author, annotation) => annotation.Author === this.username || isAdmin,
    )
  }

  // set UI theme for Web Viewer
  setTheme(theme: 'dark' | 'light') {
    this.webViewerInstance.UI.setTheme(theme)
  }

  // set readonly permission for user
  setReadOnly(readonly: boolean) {
    if (readonly) {
      this.annotManager.enableReadOnlyMode()
    } else {
      this.annotManager.disableReadOnlyMode()
    }
  }

  // set user current page
  setCurrentPage(page: number) {
    this.docViewer.setCurrentPage(page, true)
  }

  // get all annotations
  async getAnnotationsCommand() {
    const annotList = this.annotManager.getAnnotationsList()
    if (annotList.length > 0) {
      const xmlString = await this.annotManager.exportAnnotations({ annotList })
      return this.convertToXfdf(xmlString, DocEvent.ADD)
    }

    return undefined
  }

  // clear all annotations
  async clearAnnotations() {
    let annotCommand = await this.getAnnotationsCommand()
    const annotIds: string[] = []
    if (annotCommand) {
      let start = annotCommand.indexOf('name="') + 'name="'.length
      let end = annotCommand.indexOf('" title')
      while (end >= 0) {
        // push annotation id and cut unneccessary string
        annotIds.push(annotCommand.substring(start, end))
        annotCommand = annotCommand.substring(end + '" title'.length, annotCommand.length)
        // set new start and end for substring
        start = annotCommand.indexOf('name="') + 'name="'.length
        end = annotCommand.indexOf('" title')
      }
    }
    await Promise.all(annotIds.map((id) => this.onAnnotationDeleted(id)))
  }

  // wrapper function to convert xfdf fragments to full xfdf strings
  convertToXfdf(xmlString: string, action: DocEvent) {
    const changedAnnotation = xmlString.substring(
      xmlString.indexOf('<annots>') + '<annots>'.length,
      xmlString.indexOf('</annots>'),
    )
    let xfdfString =
      '<?xml version="1.0" encoding="UTF-8" ?><xfdf xmlns="http://ns.adobe.com/xfdf/" xml:space="preserve"><fields />'
    if (action === 'add') {
      xfdfString += `<add>${changedAnnotation}</add><modify /><delete />`
    } else if (action === 'modify') {
      xfdfString += `<add /><modify>${changedAnnotation}</modify><delete />`
    } else if (action === 'delete') {
      xfdfString += `<add /><modify /><delete>${changedAnnotation}</delete>`
    }
    xfdfString += '</xfdf>'
    return xfdfString
  }

  convertImageSize(annotation: InsertedAnnotation, type: DocEvent) {
    if (annotation.image) {
      const imgSize = getBytes(annotation.image.src) / 1024
      const resizeImgParams = {
        image: annotation.image,
        newWidth: this.NEW_IMAGE_WIDTH,
        newHeight: this.NEW_IMAGE_HEIGHT,
      }
      /**
       * change image size if it more than max available image size
       */
      if (imgSize > this.MAX_IMAGE_SIZE && type === DocEvent.ADD) {
        const [newWidth, newHeight] = calculateImageSize(resizeImgParams)
        const canvas = imageToCanvas(resizeImgParams)
        annotation.image.src = canvas.toDataURL()
        annotation.canvas = canvas
        annotation.Height = newHeight
        annotation.Width = newWidth
      }
    }
    return annotation
  }

  public onDocumentLoaded() {
    this.docViewer.addEventListener(this.webViewerInstance.UI.Events.DOCUMENT_LOADED, async () => {
      this.webViewerInstance.UI.setFitMode(this.webViewerInstance.UI.FitMode.FitWidth)
      // set current username when document is loaded
      this.annotManager.setCurrentUser(this.username)
      // set default tool is pan
      this.webViewerInstance.UI.setToolMode(this.webViewerInstance.Core.Tools.ToolNames.PAN)
      if (!this.isWhiteBoard) {
        /**
         * get user document previous page and
         * set it for document user current page
         */
        const localPage = localStorage.getItem(`${this.documentId}-${this.authorId}`)
        const page = localPage ? parseInt(localPage) : 1
        /**
         * set to second page (page = 2) for toggle event "pageNumberUpdated"
         * if localPage is first page (page = 1), and then set local currentPage
         */
        if (page === 1) {
          this.docViewer.setCurrentPage(2, true)
        }
        this.docViewer.setCurrentPage(page, true)
      }
      this.emitEvent(DocEvent.LOADED)
    })
    this.docViewer.addEventListener('pageNumberUpdated', async (updatedPage: number) => {
      /**
       * set user document page into local storage
       * and keep user current page
       */
      localStorage.setItem(`${this.documentId}-${this.authorId}`, `${updatedPage}`)
      /**
       * emit toggle following
       */
      this.emitEvent(DocEvent.PAGE_UPDATED, updatedPage)
    })
  }

  getPageCount() {
    return this.docViewer.getPageCount()
  }
  /**
   * listening document event "annotationChanged"
   * @annotationChanged check event type "add", "modify" and "delete"
   * then emit socket event by type
   */
  async onAnnotationChanged() {
    this.annotManager.addEventListener(
      'annotationChanged',
      async (annotations: Core.Annotations.Annotation[], type, { imported }) => {
        if (imported) {
          return
        }
        annotations.forEach(async (annotation: Core.Annotations.Annotation) => {
          const annot = this.convertImageSize(annotation, type)

          const xfdfString = await this.annotManager.exportAnnotations({ annotList: [annot] })
          const xfdf = this.convertToXfdf(xfdfString, type)

          // const authorId = annotation.Author;
          if (type === DocEvent.ADD) {
            /**
             * event emitter for socket event "createAnnotation"
             */
            this.emitEvent(DocEvent.ADD, {
              xfdfId: annotation.Id,
              documentId: this.documentId,
              authorId: this.authorId,
              xfdf,
            })
          } else if (type === DocEvent.MODIFY) {
            /**
             * event emitter for socket event "modifyAnnotation"
             */
            this.emitEvent(DocEvent.MODIFY, {
              xfdfId: annotation.Id,
              documentId: this.documentId,
              authorId: this.authorId,
              xfdf,
            })
          } else if (type === DocEvent.DELETE) {
            /**
             * event emitter for socket event "deleteAnnotation"
             */
            this.emitEvent(DocEvent.DELETE, {
              xfdfId: annotation.Id,
              documentId: this.documentId,
            })
          }
        })
      },
    )
  }

  /**
   * redraw annotation after created for other user
   * when someone create an annotation
   */
  async onAnnotationCreated(data: AnnotationInfo) {
    // Import the annotation based on xfdf command
    const annotations = await this.annotManager.importAnnotationCommand(data.xfdf)
    const annotation = annotations[0]
    if (annotation) {
      await annotation.resourcesLoaded()
      // Set a custom field authorId to be used in client-side permission check
      if (data?.author?.name) {
        annotation.Author = data?.author?.name
      } else {
        /**
         * get author's name from xfdf string
         *
         * *** NOTE author's name ...title="[author's name]" subject="...
         */
        annotation.Author = data.xfdf.substring(
          data.xfdf.indexOf('title="') + 'title="'.length,
          data.xfdf.indexOf('" subject'),
        )
      }
      this.annotManager.redrawAnnotation(annotation)
    }
  }

  // redrawAnnotation(annot: Core.Annotations.Annotation) {
  //   return this.annotManager.redrawAnnotation(annot)
  // }

  /**
   * redraw annotation after updated for other user
   * when someone modify an annotation
   */
  async onAnnotationUpdated(data: AnnotationInfo) {
    // Import the annotation based on xfdf command
    const annotations = await this.annotManager.importAnnotationCommand(data.xfdf)
    const annotation = annotations[0]
    if (annotation) {
      await annotation.resourcesLoaded()
      // Set a custom field authorId to be used in client-side permission check
      this.annotManager.redrawAnnotation(annotation)
    }
  }

  /**
   * remove annotation after deleted for other user
   * when someone remove an annotation
   */
  async onAnnotationDeleted(annotationId: string) {
    const command = `<delete><id>${annotationId}</id></delete>`
    await this.annotManager.importAnnotationCommand(command)
  }

  setCustomToolbarGroup(header: UI.Header) {
    const { documentViewer } = this.webViewerInstance.Core
    const annotHistoryManager = documentViewer.getAnnotationHistoryManager()
    const toolsOverlay = header.getHeader('toolbarGroup-Annotate').get('toolsOverlay')
    const toolInsertImage = this.getToolInsertImage()
    header.getHeader('toolbarGroup-Edit').update([
      { type: 'spacer' },
      { ...toolInsertImage },
      {
        type: 'toolGroupButton',
        toolGroup: 'highlightTools',
        dataElement: 'highlightToolGroupButton',
        title: 'annotation.highlight',
      },
      {
        type: 'toolGroupButton',
        toolGroup: 'underlineTools',
        dataElement: 'underlineToolGroupButton',
        title: 'annotation.underline',
      },
      {
        type: 'toolGroupButton',
        toolGroup: 'squigglyTools',
        dataElement: 'squigglyToolGroupButton',
        title: 'annotation.squiggly',
      },
      {
        type: 'toolGroupButton',
        toolGroup: 'stickyTools',
        dataElement: 'stickyToolGroupButton',
        title: 'annotation.stickyNote',
      },
      {
        type: 'toolGroupButton',
        toolGroup: 'freeTextTools',
        dataElement: 'freeTextToolGroupButton',
        title: 'annotation.freetext',
      },
      {
        type: 'toolGroupButton',
        toolGroup: 'freeHandTools',
        dataElement: 'freeHandToolGroupButton',
        title: 'annotation.freehand',
      },
      {
        type: 'toolGroupButton',
        toolGroup: 'freeHandHighlightTools',
        dataElement: 'freeHandHighlightToolGroupButton',
        title: 'annotation.freeHandHighlight',
      },
      {
        type: 'toolGroupButton',
        toolGroup: 'rectangleTools',
        dataElement: 'shapeToolGroupButton',
        title: 'annotation.rectangle',
      },
      {
        type: 'toolGroupButton',
        toolGroup: 'ellipseTools',
        dataElement: 'ellipseToolGroupButton',
        title: 'annotation.ellipse',
      },
      {
        type: 'toolGroupButton',
        toolGroup: 'polygonTools',
        dataElement: 'polygonToolGroupButton',
        title: 'annotation.polygon',
      },
      {
        type: 'toolGroupButton',
        toolGroup: 'cloudTools',
        dataElement: 'polygonCloudToolGroupButton',
        title: 'annotation.polygonCloud',
      },
      {
        type: 'toolGroupButton',
        toolGroup: 'lineTools',
        dataElement: 'lineToolGroupButton',
        title: 'annotation.line',
      },
      {
        type: 'toolGroupButton',
        toolGroup: 'polyLineTools',
        dataElement: 'polyLineToolGroupButton',
        title: 'annotation.polyline',
      },
      {
        type: 'toolGroupButton',
        toolGroup: 'arrowTools',
        dataElement: 'arrowToolGroupButton',
        title: 'annotation.arrow',
      },
      { type: 'divider' },
      { ...toolsOverlay },
      // Undo Button
      {
        type: 'actionButton',
        style: { marginLeft: '0px' },
        dataElement: 'undoButton',
        title: 'action.undo',
        img: 'icon-operation-undo',
        hideOnClick: true,
        shouldPassActiveDocumentViewerKeyToOnClickHandler: true,
        onClick: () => {
          annotHistoryManager.undo()
        },
        isNotClickableSelector: () => !annotHistoryManager.canUndo(),
      },
      // Redo Button
      {
        type: 'actionButton',
        dataElement: 'redoButton',
        title: 'action.redo',
        img: 'icon-operation-redo',
        hideOnClick: true,
        shouldPassActiveDocumentViewerKeyToOnClickHandler: true,
        onClick: () => {
          annotHistoryManager.redo()
        },
        isNotClickableSelector: () => !annotHistoryManager.canRedo(),
      },
      { type: 'toolButton', toolName: 'AnnotationEraserTool' },
      { type: 'spacer', hidden: ['tablet', 'mobile', 'small-mobile'] },
    ])
  }

  getToolInsertImage() {
    const instance = this.webViewerInstance
    const { annotationManager } = this.webViewerInstance.Core
    return {
      type: 'actionButton',
      dataElement: 'insertImageButton',
      title: 'annotation.image',
      img: 'icon-tool-image-line',
      onClick: () => {
        const input = document.createElement('input')
        input.style.display = 'none'
        input.className = 'stamp-create-tool-file-uploader'
        input.accept = '.jpg,.jpeg,.png'
        input.type = 'file'
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        input.addEventListener('change', async (e: any) => {
          const base64Img = await convertBase64(e.target.files[0])
          await convertBase64ImgToAnnotation({
            webViewerInstance: instance,
            annotManager: annotationManager,
            base64Img,
          })
          // deactivate icon image button
          const $ = instance.UI.iframeWindow.document
          const [, button] = $.querySelectorAll<HTMLElement>(
            '[data-element="stampToolGroupButton"]',
          )
          button.click()
        })
        input.click()
      },
    }
  }
}
