<template>
  <footer id="footer" class="footer">
    <div class="content">
      <div class="flex flex-row flex-wrap">
        <div class="link-group col col-12 col-md-3 mb-2">
          <span class="heading mb-1">{{ $t('footer.product') }}</span>
          <ul>
            <li>
              <a :href="`${wwwGlobish}/courses`">{{ $t('footer.courses') }}</a>
            </li>
            <li v-if="isCountryTH">
              <a :href="`${wwwGlobish}/event`">{{ $t('footer.event') }}</a>
            </li>
          </ul>
        </div>
        <div class="link-group col col-12 col-md-3 mb-2">
          <span class="heading mb-1">{{ $t('footer.account') }}</span>
          <ul>
            <li>
              <a :href="`${appGlobish}/payment/history`">{{ $t('footer.payment_history') }}</a>
            </li>
          </ul>
        </div>
        <div class="link-group col col-12 col-md-2 mb-2">
          <span class="heading mb-1">{{ $t('footer.support') }}</span>
          <ul>
            <li v-if="isCountryTH">
              <a :href="`${appGlobish}/help-history?where=footer`">{{ $t('footer.help') }}</a>
            </li>
            <li>
              <a :href="`${appGlobish}/faq`">{{ $t('footer.faq') }}</a>
            </li>

            <li>
              <a :href="`${appGlobish}/privacy-policy`">{{ $t('footer.privacy_policy') }}</a>
            </li>

            <li>
              <a :href="`${appGlobish}/cookies-policy`">{{ $t('footer.cookies_policy') }}</a>
            </li>
          </ul>
        </div>
        <div class="flex">
          <LanguageDropdown bg-color="transparent" text-color="#ffffff" />
        </div>
      </div>
      <div class="bottom-group">
        <div class="logo-and-copyright mt-5">
          <div class="link-logo">
            <a :href="`${appGlobish}/home`">
              <img :src="logoGlobish" alt="globish logo" height="42" width="149" />
            </a>
          </div>
          <div class="copyright">Copyright © 2020 Globish Academia (Thailand) Co., Ltd.</div>
        </div>
        <div class="support-tel mt-5">
          <div class="header-thai">{{ $t('footer.contact_ss') }}</div>
          <div class="header-thai">{{ `( ${$t('footer.operating_time')} )` }}</div>
          <div class="body-thai">
            <a :href="`tel:${supportTel}`"> {{ $t('footer.contact_tel') }} {{ supportTel }} </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import CommonState from '@/services/locale-state'

export default {
  data() {
    const logoGlobish = 'https://app.globish.co.th/img/globish-logo-white@3x.6bbed892.png'
    return {
      logoGlobish,
      appGlobish: 'https://app.globish.co.th',
    }
  },
  computed: {
    isCountryTH() {
      // default country is "th" when common state doesn't have value
      return (CommonState.getCountry() || 'th') === 'th'
    },
    supportTel() {
      return this.isCountryTH ? '02-026-6683' : '0906.830.230'
    },
    wwwGlobish() {
      return this.isCountryTH ? 'https://www.globish.co.th' : 'https://www.globish-academia.com'
    },
  },
}
</script>

<style lang="scss" scoped>
.footer {
  font-family: var(--sans-serif-font-family);
  background: #222222;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 15px 40px 15px;
  font-size: 10px;
  z-index: 1;

  .content {
    max-width: 1200px;
    width: 100%;
  }

  .link-group {
    flex-basis: 33%;
    color: rgba(255, 255, 255, 1);

    .heading {
      @include font-size(1.6em);
      @include font-weight(bold);
    }
    ul {
      @include font-size(1.5em);
      list-style: none;
      padding: 0;

      li {
        opacity: 0.7;
        cursor: pointer;
        max-width: fit-content;
        transition: all 0.35s $primary-tc;

        &:hover {
          opacity: 1;
        }
      }

      li.link-app {
        opacity: 1;

        a {
          opacity: 0.7;
          transition: all 0.35s $primary-tc;

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    @media screen and (max-width: 480px) {
      flex-basis: 50%;
    }

    @media screen and (min-width: 768px) {
      flex-basis: 25%;
    }
  }

  .bottom-group {
    font-size: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .logo-and-copyright {
      @include font-size(1.4em);
      flex-basis: 100%;
      width: 100%;

      .link-logo {
        max-width: fit-content;
        a {
          img {
            color: white;
          }
        }
      }

      .copyright {
        color: #a5a5a5;
        margin-top: 10px;
      }
    }

    .support-tel {
      flex-basis: 100%;
      width: 100%;

      .header-thai {
        color: rgba(255, 255, 255, 1);
        @include font-size(1.6em);
        @include font-weight(bold);
      }

      .body-thai {
        color: rgba(255, 255, 255, 1);
        @include font-size(1.5em);
        list-style: none;
        padding: 0;
        opacity: 0.7;
        cursor: pointer;
        max-width: fit-content;
        transition: all 0.35s $primary-tc;

        &:hover {
          opacity: 1;
        }
      }
    }

    @media screen and (min-width: 768px) {
      .logo-and-copyright {
        flex-basis: 66.67%;
        width: auto;
      }
      .support-tel {
        flex-basis: 33.33%;
        width: auto;
      }
    }
  }
}
</style>
