import ZoomVideo from '@zoom/videosdk'
import { ref } from 'vue'

export const connectionProgress = ref(true)
export const connectionFailed = ref(false)
export const zoomDiagnosticDisconnect = ref(false)

interface IUseConnection {
  token: string
  roomName: string
  name: string
}

const setZoomConnectTimeout = () => {
  const EXPIRE_MINIUTE = process.env.BRANCH === 'dev' ? 1 : 10
  const expireTimeMinute = EXPIRE_MINIUTE * 60 * 1000 // convert to millisecond
  const item = {
    value: new Date().getTime(),
    expiry: new Date().getTime() + expireTimeMinute,
  }
  localStorage.setItem('zoom_connect_timeout', JSON.stringify(item))
}

export const useConnection = async ({ name, roomName, token }: IUseConnection) => {
  const client = ZoomVideo.createClient()
  client.init('en-US', 'CDN')
  try {
    await client.join(roomName, token, name)
    const itemStr = localStorage.getItem('zoom_connect_timeout')
    if (!itemStr) {
      setZoomConnectTimeout()
    }
  } catch (err) {
    connectionFailed.value = true
  }
  setTimeout(() => {
    connectionProgress.value = false
  }, 15000)
  const itemStr = localStorage.getItem('zoom_connect_timeout')
  if (itemStr) {
    const item = JSON.parse(itemStr)
    const expire = item.expiry - new Date().getTime()
    if (expire > 0) {
      setTimeout(() => {
        localStorage.removeItem('zoom_connect_timeout')
        zoomDiagnosticDisconnect.value = true
        client.leave()
      }, expire)
    } else {
      setZoomConnectTimeout()
      const itemStr = localStorage.getItem('zoom_connect_timeout')
      if (itemStr) {
        const item = JSON.parse(itemStr)
        const expire = item.expiry - new Date().getTime()
        setTimeout(() => {
          localStorage.removeItem('zoom_connect_timeout')
          zoomDiagnosticDisconnect.value = true
          client.leave()
        }, expire)
      }
    }
  }
}
