<script setup lang="ts">
import DiagnosticSection from '@/components/video-diagnostic/DiagnosticSection.vue'
import { Stage } from '@/types/enums/zoom-diagnostic.enum'
import ConnectionCard from '@/components/video-diagnostic/ConnectionCard.vue'

interface ConnectionSuccessEmits {
  (e: 'nextStage'): void
  (e: 'updateToggle'): void
}

defineProps({
  stage: {
    type: Number,
    required: true,
  },
})

const emit = defineEmits<ConnectionSuccessEmits>()
</script>

<template>
  <DiagnosticSection
    :id="Stage.CONNECTIVITY"
    :class="
      stage === Stage.CONNECTIVITY || stage === Stage.RESULTS
        ? 'block'
        : 'hidden md:block opacity-30 pointer-events-none'
    "
  >
    <template #default>
      <div class="row-span-1 text-sm sm:text-base lg:text-sm xl:text-base">
        <h1 class="font-bold text-xl sm:text-4xl lg:text-xl xl:text-4xl">
          {{ $t('zoom-diagnostic.connection-success') }}
        </h1>
        <p
          class="py-4 sm:py-10 lg:py-4 xl:py-10"
          v-html="$t('zoom-diagnostic.connection-success-detail')"
        ></p>
      </div>

      <div class="w-fit row-span-2 mx-auto sm:mx-0">
        <div
          class="row-span-2 my-10 p-6 max-w-sm h-fit bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 text-sm sm:text-base lg:text-sm xl:text-base"
        >
          <ConnectionCard />
        </div>
      </div>

      <div class="row-span-1 text-sm sm:text-base lg:text-sm xl:text-base mt-6">
        <button
          :class="{ 'opacity-30 pointer-events-none': stage === Stage.RESULTS }"
          class="text-white border-2 rounded-md border-white bg-primary-color py-2 px-4"
          type="button"
          @click="emit('nextStage')"
        >
          {{ $t('zoom-diagnostic.ok') }}
        </button>
      </div>
    </template>
  </DiagnosticSection>
</template>

<style lang="scss" scoped>
.bg-primary-color {
  background-color: #f67519;
}

button.bg-white {
  &:hover {
    background-color: #32323209;
    transition: 0.4s;
  }
  &:disabled {
    background-color: inherit;
    color: rgba(0, 0, 0, 0.26);
  }
}

.w-fit {
  width: fit-content;
}
</style>
