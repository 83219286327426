<script setup lang="ts">
import CommonState from '@/services/locale-state'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { locale } = useI18n()
const language = ref(CommonState.getLocale() || locale)

const onChangeLocale = (lang: string) => {
  if (lang) {
    CommonState.setLocale(lang)
    locale.value = lang
  }
}

const langOption = ref([
  { label: 'TH', icon: 'th', value: 'th' },
  { label: 'EN', icon: 'uk', value: 'en' },
  { label: 'VN', icon: 'vn', value: 'vn' },
])

const props = defineProps({
  byTheme: {
    type: Boolean,
    default: false,
  },
  bgColor: {
    type: String,
    default: '',
  },
  textColor: {
    type: String,
    default: '',
  },
})

const backgroundColor = computed(() => props.bgColor)
const color = computed(() => props.textColor)
onMounted(() => {
  if (language.value) {
    onChangeLocale(language.value)
  }
})
</script>

<template>
  <SelectOption
    v-model="locale"
    :from-lang="true"
    :by-theme="byTheme"
    :options="langOption"
    class="m-0 z-99"
    :class="backgroundColor && color && 'custom-bg-text-color'"
    @update:model-value="onChangeLocale"
  ></SelectOption>
</template>

<style lang="scss" scoped>
.select-option {
  margin-bottom: 0;

  &.custom-bg-text-color {
    ::v-deep(.relative.flex button) {
      background-color: v-bind(backgroundColor);
      color: v-bind(color);
    }
  }
}
</style>
