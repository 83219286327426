import axios, { AxiosPromise } from 'axios'

interface GetClientIpResponse {
  ip: string
}

export default function useClientApi() {
  const getClientIp = (): AxiosPromise<GetClientIpResponse> => {
    return axios('https://api.ipify.org/?format=json')
  }

  return { getClientIp }
}
