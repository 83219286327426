<script setup lang="ts">
import { useRoute } from 'vue-router'
import { computed } from 'vue'
const route = useRoute()

const code = computed(() => route.query.code)
const error = computed(() => route.query.error)
const message = computed(() => route.query.message)
</script>

<template>
  <div class="wrapper">
    <svg-icon class="mb-2" icon="sad-cloud" :inherit-fill="true" height="64"></svg-icon>
    <div style="max-width: 320px">
      <div>{{ code }} - {{ error }}</div>
      <div>{{ message }}</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
  background: var(--room-body-color);

  position: relative;
  width: 100%;
  height: 100%;
  // color: rgba(#fff, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include font-size(1.6rem);
  @include font-weight(normal);
  text-align: center;
}
</style>
