import { DOMAIN } from '@/composables/api'
import Cookies from 'js-cookie'
export interface IHelpStoreState extends IHelpState {
  timestamp: number
}

export interface IHelpState {
  topic: string
  solveBy: string
}

export const getCookieHelpOption = () => {
  const roomDuration = sessionStorage.getItem('room_datetime')
  const endTime = JSON.parse(roomDuration || '')?.endTime
  return {
    domain: DOMAIN,
    expires: endTime ? new Date(endTime) : 90,
    secure: process.env.NODE_ENV === 'production' ? true : false,
  }
}

export const getHelpClickState = () => {
  const store = Cookies.get('help_menu_actions')
  if (store) {
    return JSON.parse(store) as IHelpStoreState[]
  }
  return []
}

export const setHelpClickState = (help: IHelpState) => {
  const store = getHelpClickState()
  store.push({ ...help, timestamp: Date.now() })
  Cookies.set('help_menu_actions', JSON.stringify(store), getCookieHelpOption())
  return getHelpClickState()
}

export const setOpenHelp = () => {
  Cookies.set('feedback_alert', 'yes', getCookieHelpOption())
}

export const getOpenHelp = () => {
  return Cookies.get('feedback_alert')
}
