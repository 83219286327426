<script lang="ts" setup>
import { computed, PropType, ref, watchEffect } from 'vue'
import SpinnerLoader from './SpinnerLoader.vue'
import PulseLoader from './PulseLoader.vue'
import DotLoader from './DotLoader.vue'
const props = defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String as PropType<'spinner' | 'dot' | 'pulse'>,
    default: 'spinner',
  },
  size: {
    type: String,
    default: '24',
  },
  backgroundColor: {
    type: String,
    default: 'none',
  },
  // for overlay element
  isOverlay: {
    type: Boolean,
    default: false,
  },
  // for full page
  isFullscreen: {
    type: Boolean,
    default: false,
  },
})
const gLoadingRef = ref<HTMLElement>()
const iconLoader = computed(() => {
  switch (props.type) {
    case 'dot':
      return DotLoader
    case 'pulse':
      return PulseLoader

    default:
      return SpinnerLoader
  }
})
watchEffect(() => {
  if (props.isLoading) {
    gLoadingRef.value?.parentElement?.classList.add('g-loading-parent')
  } else {
    gLoadingRef.value?.parentElement?.classList.remove('g-loading-parent')
  }
})
</script>
<template>
  <div
    v-show="isLoading"
    ref="gLoadingRef"
    class="g-loading-container"
    :class="{
      'g-loading-container--overlay': isOverlay,
      'full-screen': isFullscreen,
    }"
  >
    <component :is="iconLoader" :size="size" />
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.g-loading-container {
  background: v-bind(backgroundColor);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  gap: 5px;

  &--overlay {
    position: absolute;
  }
  &.full-screen {
    position: fixed;
  }
}
</style>

<style>
.g-loading-parent {
  position: relative !important;
  overflow: hidden;
}
</style>
