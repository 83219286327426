import { TOKEN_KEY } from '@/composables/api'

const AuthState = {
  getRoomId() {
    return localStorage.getItem('room_id')
  },
  setRoomId(roomId: string) {
    return localStorage.setItem('room_id', roomId)
  },
  getParticipantId() {
    return localStorage.getItem('participant_id')
  },
  setParticipantId(participantId: string) {
    return localStorage.setItem('participant_id', participantId)
  },
  getRoomToken() {
    return sessionStorage.getItem('query_token')
  },
  setRoomToken(token: string) {
    return sessionStorage.setItem('query_token', token)
  },
  getRoomAccessToken() {
    return localStorage.getItem(TOKEN_KEY)
  },
  setRoomAccessToken(accessToken: string) {
    return localStorage.setItem(TOKEN_KEY, accessToken)
  },
  addCountRoomTab() {
    const count = this.getCountRoomTab() || '0'
    localStorage.setItem(`room_tab_${this.getRoomId()}`, String(parseInt(count) + 1))
  },
  resetCountRoomTab() {
    localStorage.setItem(`room_tab_${this.getRoomId()}`, '1')
  },
  removeCountRoomTab() {
    const tabs = this.getCountRoomTab() || '1'
    if (Number(tabs) > 1) {
      localStorage.setItem(`room_tab_${this.getRoomId()}`, String(Number(tabs) - 1))
    } else {
      localStorage.removeItem(`room_tab_${this.getRoomId()}`)
    }
  },
  getCountRoomTab() {
    return localStorage.getItem(`room_tab_${this.getRoomId()}`)
  },
  setZoomClientToken(token: string) {
    sessionStorage.setItem(`zoom_token_${this.getRoomId()}_${this.getParticipantId()}`, token)
    return this.getZoomClientToken()
  },
  getZoomClientToken() {
    return sessionStorage.getItem(`zoom_token_${this.getRoomId()}_${this.getParticipantId()}`) || ''
  },
  setZoomUserId(userId: number) {
    sessionStorage.setItem('zoom_user_id', String(userId))
    return this.getZoomUserId()
  },
  getZoomUserId() {
    const zoomUserId = sessionStorage.getItem('zoom_user_id')
    return zoomUserId ? Number(zoomUserId) : undefined
  },
}
export default AuthState
