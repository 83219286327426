<template>
  <div class="max-w-sm row-span-2 text-sm sm:text-base lg:text-sm xl:text-base">
    <div
      class="p-6 h-fit bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700"
    >
      <p class="font-bold mb-3">{{ $t('video-diagnostic.poor-quality-tips') }}</p>
      <ul class="list-disc p-4">
        <li class="mb-1">
          {{ $t('video-diagnostic.tips-close-tab') }}
        </li>
        <li class="mb-1">{{ $t('video-diagnostic.tips-move-closer-router') }}</li>
        <li class="mb-1">{{ $t('video-diagnostic.tips-vpn') }}</li>
        <li class="mb-1">{{ $t('video-diagnostic.tips-start-com') }}</li>
      </ul>
    </div>
  </div>
</template>
