import { IPreflightTest } from '@/types/interfaces/video-diagnostic.interface'
import { reactive, ref } from 'vue'
import { runPreflight } from 'twilio-video'

export const preflightTestInProgress = ref(false)
export const preflightTestFinished = ref(false)
export const preflightTest = reactive<IPreflightTest>({
  progress: null,
  report: null,
  error: null,
  tokenError: null,
  signalingGatewayReachable: false,
  turnServersReachable: false,
})

export const usePreflightTest = (token: string) => {
  const runPreflightTest = runPreflight(token)

  preflightTestInProgress.value = true
  preflightTestFinished.value = false

  runPreflightTest.on('progress', (progress) => {
    preflightTest.progress = progress
    // Safari does not support RTCDtlsTransport, so we use 'peerConnectionConnected' to determine if Signaling Gateway is reachable
    if (progress === 'dtlsConnected' || progress === 'peerConnectionConnected') {
      preflightTest.signalingGatewayReachable = true
    }

    if (progress === 'mediaAcquired') {
      preflightTest.turnServersReachable = true
    }
  })

  runPreflightTest.on('completed', (report) => {
    preflightTest.report = report
    preflightTest.progress = null
    preflightTestInProgress.value = false
    preflightTestFinished.value = true
  })

  runPreflightTest.on('failed', (error) => {
    preflightTest.error = error
    preflightTest.progress = null
    preflightTestInProgress.value = false
    preflightTestFinished.value = true
  })
}
