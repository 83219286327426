<script setup lang="ts">
import { getQualityScore } from '@/composables/video-diagnostic/useGetQualityScore'
import { QualityScore } from '@/types/enums/video-diagnostic.enum'
import { computed, reactive } from 'vue'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const { latency, jitter, packetLoss, bitrate } = getQualityScore()
const networkInfo = reactive({
  rtt: {
    topic: 'RTT',
    info: t('video-diagnostic.network-info.rtt'),
    score: latency,
  },
  jitter: {
    topic: 'Jitter',
    info: t('video-diagnostic.network-info.jitter'),
    score: jitter,
  },
  packetLoss: {
    topic: 'Packet loss',
    info: t('video-diagnostic.network-info.packet-lost'),
    score: packetLoss,
  },
  bitrate: {
    topic: 'Bitrate',
    info: t('video-diagnostic.network-info.bit-rate'),
    score: bitrate,
  },
})

const score = (payload) => {
  return `${QualityScore[payload.score.qualityScore]}`
}

const icon = (qualityScore) => {
  if (qualityScore === QualityScore.Excellent) {
    return 'bg-blue-500'
  }
  if (qualityScore === QualityScore.Good) {
    return 'bg-green-500'
  }
  if (qualityScore === QualityScore.Suboptimal) {
    return 'bg-yellow-500'
  }
  if (qualityScore === QualityScore.Poor) {
    return 'bg-red-500'
  }
}
const displayQualityStatus = (value) => {
  return `${t(`video-diagnostic.quality-status.${score(value)}`)}`
}
</script>

<template>
  <div class="container mx-auto px-4">
    <table class="w-full text-sm">
      <thead>
        <tr class="border-b-2">
          <th class="p-4">Network Info</th>
          <th class="p-4">Quality</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(value, key) in networkInfo" :key="key" class="border-b-2">
          <td class="p-4">
            <div class="inline-flex">
              <Popper :hover="true" :arrow="true" :content="value.info">
                <SvgIcon :icon="'info'" :inherit-fill="true" height="16" />
              </Popper>
              <p class="font-bold ml-2">{{ value.topic }}</p>
            </div>
          </td>
          <td class="p-4">
            <div class="inline-flex">
              <div
                :class="icon(value.score.qualityScore)"
                class="rounded-full p-2 w-2 h-2 mr-2"
              ></div>
              <p>
                {{ displayQualityStatus(value) }}
              </p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
