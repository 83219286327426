<script setup lang="ts">
import Footer from '@/components/common/Footer.vue'
import LoadingBox from '@/components/video-diagnostic/Loading.vue'
import ControlButton from '@/components/room/ControlButton.vue'
import GetStarted from '@/components/video-diagnostic/GetStarted.vue'
import CheckPermissions from '@/components/video-diagnostic/CheckPermissions.vue'
import PermissionError from '@/components/video-diagnostic/PermissionError.vue'
import CameraTest from '@/components/video-diagnostic/CameraTest.vue'
import AudioTest from '@/components/video-diagnostic/AudioTest.vue'
import BrowserTest from '@/components/video-diagnostic/BrowserTest.vue'
import Connectivity from '@/components/video-diagnostic/Connectivity.vue'
import Quality from '@/components/video-diagnostic/Quality.vue'
import Result from '@/components/video-diagnostic/Result.vue'
import { useTwilioStatus } from '@/composables/video-diagnostic/useTwilioStatus'
import { usePreflightTest } from '@/composables/video-diagnostic/usePreflightTest'
import { useBitrateTest } from '@/composables/video-diagnostic/useBitrateTest'
import { permissions } from '@/composables/video-diagnostic/useCheckPermissions'
import { useVideoDiagnostic } from '@/composables/video-diagnostic/useVideoDiagnostic'
import { IUpdateTopic } from '@/types/interfaces/video-diagnostic.interface'
import { Stage, Topic } from '@/types/enums/video-diagnostic.enum'
import { useRoute } from 'vue-router'
import { reactive, ref, onMounted, watch, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import CommonState from '@/services/locale-state'

const i18n = useI18n()
const route = useRoute()
const { token, tokenInstance, getToken } = useVideoDiagnostic()

const topic = reactive({
  deviceSetup: false,
  connectivity: false,
  quality: false,
  results: false,
})
const stage = ref(0)
const supportedBrowser = ref(true)
const isInProgress = ref(true)
const finishedTest = ref(false)
const isLoading = ref(false)
const header = computed(() => i18n.t('video-diagnostic.test-are-done'))
const message = computed(() => i18n.t('video-diagnostic.wait-report'))

let stopWatchStage

stopWatchStage = watch(stage, (stage) => {
  if (stage === Stage.RESULTS) {
    finishedTest.value = true
    isLoading.value = true
    setTimeout(() => {
      isLoading.value = false
      const objDiv = document.getElementById('scrollContainer')
      objDiv.scrollTop = objDiv.scrollHeight
      stopWatchStage()
    }, 1500)
  }
})

watch(isLoading, () => {
  setTimeout(() => {
    const objDiv = document.getElementById('scrollContainer')
    const footer = document.getElementById('footer')
    objDiv.scrollTop = objDiv.scrollHeight
    /**
     * scroll to result header after get the result
     */
    if (objDiv && footer && topic.results) {
      objDiv.scrollTop = objDiv.scrollHeight - (objDiv.offsetHeight + footer.offsetHeight)
    }
  }, 20)
})

const scrollDown = (id: number) => {
  stage.value = id
  const el = document.getElementById(`${id}`)
  const firstEl = document.getElementById('0')
  if (el) {
    const offset =
      id === Stage.RESULTS
        ? firstEl.offsetTop + firstEl.offsetHeight * 0.5
        : el.offsetTop + el.offsetHeight * 0.5
    const isMobile = screen.width <= 640
    if (el?.parentElement) {
      el.parentElement.style.transform = isMobile
        ? 'none'
        : `translateY(calc(50vh - ${offset}px + 50px))`
    }
  }
}

const updateTopic = (value: IUpdateTopic) => {
  switch (value.topic) {
    case Topic.DEVICE_SETUP:
      if (value.active) {
        topic.deviceSetup = true
      } else {
        topic.deviceSetup = false
      }
      break
    case Topic.CONNECTIVITY:
      if (value.active) {
        topic.connectivity = true
      } else {
        topic.connectivity = false
      }
      break
    case Topic.QUALITY:
      if (value.active) {
        topic.quality = true
      } else {
        topic.quality = false
      }
      break
    case Topic.RESULTS:
      if (value.active) {
        topic.results = true
      } else {
        topic.results = false
      }
      break
  }
}

const setLanguage = () => {
  if (route.query.language) {
    switch (route.query.language) {
      case 'th': {
        CommonState.setLocale('th')
        i18n.locale.value = 'th'
        break
      }
      case 'vn': {
        CommonState.setLocale('vn')
        i18n.locale.value = 'vn'
        break
      }
      case 'en': {
        CommonState.setLocale('en')
        i18n.locale.value = 'en'
        break
      }
    }
  }
}

const setCountry = () => {
  if (route.query.country) {
    switch (route.query.country) {
      case 'th': {
        CommonState.setCountry('th')
        break
      }
      case 'vn': {
        CommonState.setCountry('vn')
        break
      }
    }
  }
}

onMounted(async () => {
  setLanguage()
  setCountry()
  scrollDown(0)
  await getToken()
  useBitrateTest(tokenInstance.value)
  usePreflightTest(token.value)
  await useTwilioStatus()
})
</script>

<template>
  <DefaultLayout have-navbar theme="light" can-change-language>
    <div
      id="scrollContainer"
      :class="finishedTest ? 'overflow-auto' : 'overflow-hidden'"
      class="h-screen"
    >
      <section class="wizard-wrapper">
        <div class="container font-bold mx-auto px-40">
          <div class="flex flex-row text-xs xl:text-base">
            <div :class="topic.deviceSetup ? 'opacity-100' : 'opacity-30'" class="mr-10 xl:mr-20">
              {{ $t('video-diagnostic.device-software-setup') }}
              <span class="ml-4 xl:ml-10">></span>
            </div>
            <div :class="topic.connectivity ? 'opacity-100' : 'opacity-30'" class="mr-10 xl:mr-20">
              {{ $t('video-diagnostic.connectivity') }}
              <span class="ml-4 xl:ml-10">></span>
            </div>
            <div :class="topic.quality ? 'opacity-100' : 'opacity-30'" class="mr-10 xl:mr-20">
              {{ $t('video-diagnostic.quality-and-performance') }}
              <span class="ml-4 xl:ml-10">></span>
            </div>
            <div :class="topic.results ? 'opacity-100' : 'opacity-30'">
              {{ $t('video-diagnostic.get-result') }}
            </div>
          </div>
        </div>
        <div class="w- mt-10 bg-gray-200 rounded-full h-1 dark:bg-gray-700">
          <div
            class="bg-primary-color h-1 rounded-full duration-1000"
            :style="`width: ${(stage / Stage.RESULTS) * 100}%`"
          ></div>
        </div>
      </section>

      <div v-if="isLoading" class="loading-container">
        <LoadingBox :stage="Stage.CONNECTIVITY" :header="header" :message="message"></LoadingBox>
      </div>

      <div v-show="!isLoading" class="scrollContainer">
        <GetStarted :stage="stage" @next-stage="scrollDown"></GetStarted>

        <CheckPermissions
          :stage="stage"
          @next-stage="scrollDown"
          @update-topic="updateTopic"
        ></CheckPermissions>

        <PermissionError v-if="!permissions" :stage="stage"></PermissionError>

        <CameraTest :stage="stage" @next-stage="scrollDown"></CameraTest>

        <AudioTest :stage="stage" @next-stage="scrollDown"></AudioTest>

        <BrowserTest
          :stage="stage"
          @next-stage="scrollDown"
          @update-topic="updateTopic"
          @supported-browser="(value) => (supportedBrowser = value)"
        ></BrowserTest>

        <Connectivity
          v-if="supportedBrowser"
          :stage="stage"
          @inprogress="isInProgress = false"
          @next-stage="scrollDown"
        ></Connectivity>

        <Quality
          v-if="supportedBrowser && !isInProgress"
          :stage="stage"
          @next-stage="scrollDown"
          @update-topic="updateTopic"
        ></Quality>

        <Result
          v-if="supportedBrowser && !isInProgress"
          :stage="stage"
          @next-stage="scrollDown"
          @update-topic="updateTopic"
        ></Result>

        <Footer v-if="stage === Stage.RESULTS && topic.results" />
      </div>

      <section v-if="stage !== Stage.RESULTS" class="hidden md:block arrow-container">
        <ControlButton
          :class="stage === 0 ? 'opacity-0 pointer-events-none' : ''"
          class="arrow-container__button mb-4"
          icon="line-arrow-up"
          @click="
            scrollDown(
              permissions && stage === Stage.CAMERA_TEST ? Stage.CHECK_PERMISSIONS : stage - 1,
            )
          "
        />
        <ControlButton
          :class="
            [Stage.CHECK_PERMISSIONS, Stage.PERMISSION_ERROR, Stage.RESULTS].includes(stage) ||
            !supportedBrowser ||
            (isInProgress && stage === Stage.CONNECTIVITY)
              ? 'opacity-0 pointer-events-none'
              : ''
          "
          class="arrow-container__button"
          icon="line-arrow-down"
          @click="scrollDown(stage + 1)"
        />
      </section>
    </div>
  </DefaultLayout>
</template>

<style lang="scss" scoped>
.bg-primary-color {
  background-color: #f67519;
}

.arrow-container {
  position: fixed;
  top: 80%;
  right: 3%;
  &__button {
    color: black !important;
    background-color: white !important;
    border: 1px solid var(--compartment-body-color);
    &:hover {
      background-color: var(--bg-chat-msg) !important;
      transition: 0.4s;
    }
  }
  & > div {
    width: 40px !important;
    height: 40px !important;
  }
}

.loading-container {
  transform: translateY(40vh);
}

.scrollContainer {
  transition: all 1s ease;
  position: relative;
  transform: translateY(50vh);
}

@include media-breakpoint-down-custom(600px) {
  .scrollContainer {
    transition: all 0s ease;
    transform: initial;
  }
}
.wizard-wrapper {
  z-index: 1;
  @apply hidden md:block fixed w-full bg-white pt-10 text-base;
}
</style>
