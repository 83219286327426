import { PaginationParams, PaginationResponse } from '@/types/interfaces/common.interface'
import useApi from './useApi'

/**
 * ******************************************
 *        exported doc api interface
 * ******************************************
 */

export interface AuthorInfo {
  id: string
  name: string
  email: string
}

export interface AnnotationInfo {
  id: number
  xfdf: string
  xfdfId: string
  author: {
    email: string
    id: string
    name: string
  }
}
export interface RefreshWriteboard {
  authorId: string
  xfdf: string
}
export interface DocumentInfo {
  id: string
  name: string
  docUrl: string
}

interface CreateDocument {
  name: string
  docUrl: string
  authorId: string
  externalKey: string
}

interface CreateUser {
  name: string
  email: string
}

interface IGetAllAnnotations extends PaginationParams {
  docId: string
}

export default function useDocApi() {
  /**
   * use api
   */
  const api = useApi()

  /**
   * *********************
   *        methods
   * *********************
   */

  const createDocument = async (payload: CreateDocument) => {
    const { status, data } = await api.post('Documents', payload)
    if (status === 201) {
      return data
    }
  }
  const createUserDocument = async (users: CreateUser[]) => {
    const { status, data } = await api.post('Documents/Users', users)
    if (status === 201) {
      return data
    }
  }
  const getDocumentById = async (docId: string): Promise<DocumentInfo | undefined> => {
    const { status, data } = await api.get(`Documents/${docId}`)
    if (status === 200) {
      return data as DocumentInfo
    }
  }

  const getDocumentByExternalId = async (externalKey: string) => {
    const { status, data } = await api.get('Documents/ExternalKey', {
      params: { externalKey },
    })
    if (status === 200) {
      return data as DocumentInfo
    }
  }

  const getAuthorByEmail = async (email: string) => {
    const { status, data } = await api.get('Documents/Users', { params: { email } })
    if (status === 200) {
      return data as AuthorInfo
    }
  }

  const getAnnotations = async (docId: string, page: number) => {
    const { status, data } = await api.get(`Documents/${docId}/Annotations?page=${page}`)
    return status === 200 ? (data as AnnotationInfo[]) : []
  }

  const getAllAnnotations = (params: IGetAllAnnotations) => {
    return api.get<PaginationResponse<AnnotationInfo[]>>(
      `/Documents/${params.docId}/annotations/pagination`,
      {
        params,
      },
    )
  }

  return {
    createDocument,
    createUserDocument,
    getDocumentById,
    getDocumentByExternalId,
    getAuthorByEmail,
    getAnnotations,
    getAllAnnotations,
  }
}
