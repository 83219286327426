export enum AudioNumber {
  AUDIO_LEVEL_THRESHOLD = 200,
  INPUT_TEST_DURATION = 20000,
  RECORD_DURATION = 6000,
}

export enum Stage {
  GET_STARTED,
  CHECK_PERMISSIONS,
  PERMISSION_ERROR,
  CAMERA_TEST,
  AUDIO_TEST,
  BROWSER_TEST,
  CONNECTIVITY,
  QUALITY,
  RESULTS,
}

export enum Topic {
  GET_STARTED,
  DEVICE_SETUP,
  CONNECTIVITY,
  QUALITY,
  RESULTS,
}

export enum QualityScore {
  Poor,
  Suboptimal,
  Good,
  Excellent,
}

export enum ResultStatus {
  PASS = 'pass',
  FAIL = 'fail',
}
