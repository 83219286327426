import { AudioNumber } from '@/types/enums/video-diagnostic.enum'
import { ref, reactive, toRefs } from 'vue'
import ZoomVideo, { LocalAudioTrack } from '@zoom/videosdk'

interface IUseAudioTestState {
  inputLevel: number
  enableRecording: boolean
  isRecording: boolean
  isAudioInputTestRunning: boolean
  isAudioOutputTestRunning: boolean
  selectedAudio?: string
  selectedSpeaker?: string
  playbackURI: string
  timeCounter: number
  countPlay: number
  localAudioTrack?: LocalAudioTrack
  audioLevelZoomInterval?: any
}

export const audioInputTestReport = ref<any>(undefined)
export const audioOutputTestReport = ref<any>(undefined)
export const audioTestPassed = ref<boolean>(true)

export const useAudioTest = () => {
  const state = reactive<IUseAudioTestState>({
    inputLevel: 0,
    enableRecording: false,
    isRecording: false,
    isAudioInputTestRunning: false,
    isAudioOutputTestRunning: false,
    selectedAudio: undefined,
    selectedSpeaker: 'default',
    playbackURI: '',
    timeCounter: 0,
    countPlay: 0,
    localAudioTrack: undefined,
    audioLevelZoomInterval: undefined,
  })

  const countdown = (duration: number) => {
    return new Promise((resolve) => {
      state.timeCounter = duration / 1000
      const timeCount = setInterval(() => {
        state.timeCounter -= 1
        if (state.timeCounter === 0) {
          clearInterval(timeCount)
          resolve(timeCount)
        }
      }, 1000)
    })
  }

  const readAudioInput = async () => {
    if (state.enableRecording) {
      state.localAudioTrack?.testMicrophone({
        recordAndPlay: true,
        speakerId: state.selectedSpeaker,
        microphoneId: state.selectedAudio,
        maxRecordDuration: AudioNumber.RECORD_DURATION,
      })
      state.isAudioInputTestRunning = true
      state.isRecording = true
      await countdown(AudioNumber.RECORD_DURATION)
      await playAudio()
    } else {
      startAudioTrack()
      getAudioInput()
    }
  }

  const playAudio = async () => {
    state.isAudioOutputTestRunning = true
    await countdown(AudioNumber.RECORD_DURATION)
    state.isAudioOutputTestRunning = false
    state.enableRecording = false
    state.isRecording = false
  }

  const clearAudioDeviceTest = () => {
    state.enableRecording = false
    state.isRecording = false
    state.inputLevel = 0
    clearInterval(state.audioLevelZoomInterval)
  }

  const startAudioTrack = async () => {
    state.localAudioTrack = ZoomVideo.createLocalAudioTrack(state.selectedAudio)
    await state.localAudioTrack.start()
    await state.localAudioTrack.unmute()
  }

  const getAudioInput = () => {
    state.audioLevelZoomInterval = setInterval(() => {
      if (state.localAudioTrack) {
        state.inputLevel = state.localAudioTrack.getCurrentVolume()
      }
    }, 100)
  }

  const setAudioTestResult = (result: boolean) => {
    audioTestPassed.value = result
  }

  return {
    ...toRefs(state),
    readAudioInput,
    playAudio,
    clearAudioDeviceTest,
    setAudioTestResult,
    startAudioTrack,
    getAudioInput,
  }
}
