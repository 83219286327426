<script setup lang="ts">
import { onMounted, ref } from 'vue'
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  roomDuration: {
    type: Number,
    default: 25,
  },
  coach: {
    type: String,
    required: true,
  },
})

const message = ref<string>('')

const randomMessage = (coachName: string, rawDuration: number) => {
  const duration = rawDuration > 50 ? 50 : rawDuration
  const contents = [
    `We hope you have the best ${duration}-minute class! ${coachName}. We appreciate your help in entering the correct lesson name and number for this class.`,
    `Let's begin the wonderful ${duration}-minute journey! ${coachName} ☀️ Your help in entering the correct lesson name and number for this class is a good start for the wonderful student's learning journey.❤️`,
    `The best class starts here with you! ${coachName} 🏆 Thank you for checking the lesson and number of this class. Have a wonderful time with our students!🌻`,
    `Every moment is a fresh beginning! ${coachName}. Appreciate your time in checking the lesson number and name of the class. Hope your class filled with joy.`,
    'We are grateful to have an amazing coach like you with us here! Your time on checking the lesson and number of this class is well appreciated. Wish you a happy class.✨',
  ]
  const randomNumber = Math.floor(Math.random() * contents.length)
  return contents[randomNumber]
}
const emit = defineEmits(['update:modelValue', 'join-room'])
const close = () => {
  emit('update:modelValue', false)
  emit('join-room')
}
onMounted(() => {
  message.value = randomMessage(props.coach, props.roomDuration)
})
</script>

<template>
  <Popup :open="modelValue" :title="`${$t('message-from-globish')}`" width="560" @close="close">
    <div class="text-lg text-content p-4">{{ message }}</div>
    <template #footer>
      <div class="coach-popup-footer">
        <button type="button" class="btn btn-primary text-lg" @click="close">
          {{ $t('start-your-class') }}
        </button>
      </div>
    </template>
  </Popup>
</template>

<style lang="scss" scoped>
.text-content {
  color: #4a4a4a;
  text-align: center;
}
.coach-popup-footer {
  display: flex;
  justify-content: center;
  border-top: none;
}
</style>
