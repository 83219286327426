import Cookies from 'js-cookie'
import useApi, { ApiResponse, ApiSymbol } from './useApi'
import JwtDecode from 'jwt-decode'
import { TOKEN_KEY, DOMAIN } from '.'
import { provide } from 'vue'
import AuthState from '@/services/auth-state'

const COOKIE_OPTION = {
  domain: DOMAIN,
  expires: 90,
  secure: process.env.NODE_ENV === 'production' ? true : false,
}

interface JwtAuth {
  roomId: string
  participantId: string
  exp: number
}

interface LoginWithTokenResponseData {
  accessToken: string
}

export const getToken = () => {
  return Cookies.get(TOKEN_KEY)
}

export const removeToken = () => {
  Cookies.remove(TOKEN_KEY, { domain: DOMAIN })
}

export const saveAccessInfo = (accessToken: string) => {
  const decoded: JwtAuth = JwtDecode(accessToken)
  const { roomId, participantId } = decoded
  AuthState.setRoomAccessToken(accessToken)
  AuthState.setRoomId(roomId)
  AuthState.setParticipantId(participantId)
}

export default function useAuthApi() {
  const api = useApi()

  const setToken = (accessToken: string) => {
    const token = `Bearer ${accessToken}`
    const decoded: JwtAuth = JwtDecode(accessToken)
    Cookies.remove(TOKEN_KEY, { domain: DOMAIN })
    Cookies.set(TOKEN_KEY, token, {
      ...COOKIE_OPTION,
      expires: new Date(decoded.exp * 1000),
    })
  }

  const getAccessInfo = () => {
    const accessToken = AuthState.getRoomAccessToken()
    if (accessToken) {
      const decoded: JwtAuth = JwtDecode(accessToken)
      if (new Date() < new Date(decoded?.exp * 1000)) {
        return {
          roomId: AuthState.getRoomId(),
          participantId: AuthState.getParticipantId(),
          queryToken: AuthState.getRoomToken(),
          zoomUserId: AuthState.getZoomUserId(),
        }
      }
    }
    return {
      roomId: null,
      participantId: null,
    }
  }

  const loginWithToken = async (token: string): Promise<ApiResponse> => {
    const res = await api.get('/Spaces/Auth', { params: { token } })
    const { data, status } = res
    if (status === 200) {
      const { accessToken } = data as LoginWithTokenResponseData
      if (accessToken) {
        AuthState.setRoomToken(token)
        setToken(accessToken)
        saveAccessInfo(accessToken)
        provide(ApiSymbol, api)
      }
    }
    return res
  }
  return {
    loginWithToken,
    getAccessInfo,
  }
}
