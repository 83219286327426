<script setup lang="ts">
import AuthState from '@/services/auth-state'
import { useRouter } from 'vue-router'

interface MultipleSessionsDetectedPopupEmits {
  (e: 'update:modelValue', value: boolean): void
}

defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
})

const router = useRouter()
const emit = defineEmits<MultipleSessionsDetectedPopupEmits>()

const continuePage = () => {
  AuthState.removeCountRoomTab()
  router.go(0)
  emit('update:modelValue', false)
}

const endPage = () => {
  AuthState.removeCountRoomTab()
  router.replace({ path: 'end' })
  emit('update:modelValue', false)
}
</script>

<template>
  <Popup
    :title="$t('multiple-session-title')"
    :can-close="false"
    :open="modelValue"
    @close="endPage"
  >
    <div class="text-base multiple-sessions-container">
      <span>
        {{ $t('multiple-session-message') }}
      </span>
    </div>
    <template #footer>
      <button
        type="button"
        class="btn bg-gray-400 text-white text-base w-full"
        @click="continuePage"
      >
        {{ $t('continue-session-button') }}
      </button>
      <button
        type="button"
        class="btn bg-red-500 text-white text-base w-full mt-2"
        @click="endPage"
      >
        {{ $t('end-session-button') }}
      </button>
    </template>
  </Popup>
</template>

<style lang="scss" scoped>
.multiple-sessions-container {
  color: #4a4a4a;
}
</style>
