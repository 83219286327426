import { checkCoachRole, checkStudentRole } from '@/helpers/participant.helper'
import { Expose, Transform } from 'class-transformer'

export class TCapabilitiesJSON {
  @Expose()
  @Transform(({ obj }) => obj?.doc || false)
  doc!: boolean

  @Expose()
  @Transform(({ obj }) => obj?.chat || false)
  chat!: boolean

  @Expose()
  @Transform(({ obj }) => obj?.sticker || false)
  sticker!: boolean

  @Expose()
  @Transform(({ obj }) => obj?.invisible || false)
  invisible!: boolean

  @Expose()
  @Transform(({ obj }) => obj?.whiteBoard || false)
  whiteBoard!: boolean

  @Expose()
  @Transform(({ obj }) => obj?.engineControl)
  engineControl?: boolean

  @Expose()
  @Transform(({ obj }) => obj?.engineWhereby)
  engineWhereby?: boolean

  @Expose()
  @Transform(({ obj }) => obj?.engder)
  engder?: boolean

  @Expose()
  @Transform(({ obj }) => obj?.trackLesson)
  trackLesson?: boolean

  @Expose()
  @Transform(({ obj }) => obj?.followDoc)
  followDoc?: boolean

  @Expose()
  @Transform(({ obj }) => obj?.writableControlDoc)
  writableControlDoc?: boolean

  @Expose()
  @Transform(({ obj }) => obj?.feedbackClass)
  feedbackClass?: boolean

  @Expose()
  @Transform(({ obj }) => ({
    cam: obj?.publishableTracks?.cam || false,
    mic: obj?.publishableTracks?.mic || false,
    screenShare: obj?.publishableTracks?.screenShare || false,
  }))
  publishableTracks!: {
    cam: boolean
    mic: boolean
    screenShare: boolean
  }
}

export class TParticipantInfo {
  @Expose()
  id!: string

  @Expose()
  email!: string

  @Expose()
  createdAt!: string

  @Expose()
  updatedAt!: null

  @Expose()
  name!: string

  @Expose()
  avatar!: string

  @Expose()
  @Transform(({ obj }) => obj?.capabilities?.invisible || false)
  isInvisible!: boolean

  @Expose()
  @Transform(({ obj }) => checkCoachRole(obj))
  isCoachRole!: boolean

  @Expose()
  @Transform(({ obj }) => checkStudentRole(obj))
  isStudentRole!: boolean

  @Expose()
  capabilities!: TCapabilitiesJSON
}
