import { defineStore } from 'pinia'
import { computed, onBeforeMount, ref } from 'vue'
import { backgroundList } from '@/data/background'
import { IBackgroundOption } from '@/types/interfaces/background.interface'

export const useBackgroundStore = defineStore('background', () => {
  const backgroundGallery = ref<Map<string, IBackgroundOption>>(
    new Map([['none', { name: 'None', path: 'prohibited', value: 'none' }]]),
  )

  const currentBackground = ref<string>(localStorage.getItem('video_bg') || 'none')
  const selectedBackground = ref<string>(currentBackground.value)

  const currentData = computed(() => backgroundGallery.value.get(currentBackground.value))
  const selectedData = computed(() => backgroundGallery.value.get(selectedBackground.value))

  const backgroundChanged = computed(() => selectedBackground.value !== currentBackground.value)

  const virtualBackgroundZoom = computed(() => {
    if (selectedData.value?.src) return { imageUrl: `.${selectedData.value?.src}` }
    else if (selectedData.value?.type === 'blur') return { imageUrl: 'blur' }
    else return undefined
  })

  const setStateBackground = (value: string) => {
    localStorage.setItem('video_bg', value)
    currentBackground.value = localStorage.getItem('video_bg') || value
  }

  const removeStateBackground = () => {
    localStorage.setItem('video_bg', 'none')
    currentBackground.value = localStorage.getItem('video_bg') || 'none'
  }

  const loadImage = (path: string): Promise<HTMLImageElement> =>
    new Promise((resolve) => {
      const image = new Image()
      image.src = path
      image.onload = () => resolve(image)
    })

  const setInitialHTMLImage = async () => {
    if (backgroundGallery.value.size > 1) return
    await Promise.all(
      backgroundList.map(async (bg) => {
        let imageHtml: HTMLImageElement
        const data = bg
        if (bg.type === 'image' && bg.path) {
          imageHtml = await loadImage(bg.path)
          Reflect.set(data, 'imgHtml', imageHtml)
        }
        backgroundGallery.value.set(bg.value, data)
      }),
    )
  }

  onBeforeMount(() => setInitialHTMLImage())

  return {
    setInitialHTMLImage,
    backgroundGallery,
    currentBackground,
    currentData,
    selectedBackground,
    selectedData,
    backgroundChanged,
    setStateBackground,
    virtualBackgroundZoom,
    removeStateBackground,
  }
})
