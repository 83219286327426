<script setup lang="ts">
import { PropType, computed } from 'vue'
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/vue'
import { CheckIcon, ChevronDownIcon } from '@heroicons/vue/24/solid'
import { ISelectOption } from '@/types/interfaces/common.interface'

interface SelectOptionEmits {
  (e: 'update:modelValue', value: string): void
}

const props = defineProps({
  modelValue: {
    type: String,
    default: undefined,
  },
  label: {
    type: String,
    default: '',
  },
  options: {
    type: Array as PropType<ISelectOption[]>,
    default: () => [],
  },
  fromLang: {
    type: Boolean,
    default: false,
  },
  byTheme: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits<SelectOptionEmits>()
const selectedValue = computed(() =>
  props.options?.find((option) => option.value === props.modelValue),
)
const selectedLabel = computed(() => selectedValue.value?.label)
const selectedIcon = computed(() => selectedValue.value?.icon)

const updateValue = (event: ISelectOption) => {
  emit('update:modelValue', event.value)
}
</script>

<template>
  <div class="select-option" :class="[byTheme && 'by-theme']">
    <Listbox v-model="selectedValue" @update:modelValue="updateValue">
      <ListboxLabel class="block text-sm font-medium text-gray-700"> {{ label }} </ListboxLabel>
      <div class="relative" :class="[fromLang && 'flex']">
        <ListboxButton class="list-box-button" :class="[fromLang && 'from-lang']">
          <span class="flex items-center">
            <SvgIcon
              v-if="selectedIcon"
              :key="selectedIcon"
              class="mr-1 icon-box"
              :icon="selectedIcon"
              height="24"
            ></SvgIcon>
            <span :class="[fromLang ? 'ml-0' : 'ml-3', 'block truncate']">{{ selectedLabel }}</span>
          </span>
          <span class="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </ListboxButton>

        <transition
          leave-active-class="transition ease-in duration-100"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <ListboxOptions class="list-box-option" :class="[fromLang && 'top-9 md:mt-3']">
            <ListboxOption
              v-for="(option, index) in options"
              :key="`option-${index}`"
              v-slot="{ active, selected }"
              as="template"
              :value="option"
            >
              <li
                :class="[
                  active ? 'text-white bg-yellow-600' : 'list-option-text',
                  'select-none relative py-2 pl-3 pr-9',
                ]"
              >
                <div class="flex items-center">
                  <SvgIcon
                    v-if="option.icon"
                    class="mr-1 icon-box"
                    :icon="option.icon"
                    height="24"
                    aria-hidden="true"
                  ></SvgIcon>
                  <span
                    :class="[
                      selected ? 'font-semibold' : 'font-normal',
                      ' block truncate',
                      fromLang ? 'ml-0' : 'ml-3',
                    ]"
                  >
                    {{ option.label }}
                  </span>
                </div>

                <span
                  v-if="selected"
                  :class="[
                    active ? 'text-white' : 'text-yellow-600',
                    fromLang ? 'pr-2' : 'pr-4',
                    'absolute inset-y-0 right-0 flex items-center',
                  ]"
                >
                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
  </div>
</template>

<style lang="scss" scoped>
.select-option {
  color: #4a4a4a;
  margin-bottom: 1rem;
  .list-box-button {
    @apply relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left focus:outline-none focus:ring-1 focus:ring-yellow-600 focus:border-yellow-600 text-sm;
    cursor: pointer;

    &:disabled {
      opacity: 0.7;
    }
  }
  .list-box-option {
    cursor: pointer;
    @apply absolute z-10 mt-3 w-full bg-white shadow-lg max-h-56 rounded-md py-1 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm;
    .list-option-text {
      @apply text-gray-900;
    }
  }
}
.from-lang {
  padding: 7px 38px 7px 10px;
}
.icon-box {
  display: inline-flex;
  @include media-breakpoint-down-custom(700px) {
    display: none;
  }
}
</style>

<style lang="scss">
.dark .select-option.by-theme {
  .list-box-button {
    background: #1f1f1f;
    color: white;
    border-color: #4f4f4f;
  }
  .list-box-option {
    background: #2f2f2f;
    color: white;
    .list-option-text {
      @apply text-white;
    }
  }
}
</style>
