// ref: https://labs.madisoft.it/javascript-image-compression-and-resizing/
// ref: https://stackoverflow.com/questions/53228948/how-to-get-image-file-size-from-base-64-string-in-javascript

import { Core, WebViewerInstance } from '@pdftron/webviewer'

export type UnitSize = 'B' | 'KB' | 'MB' | 'GB' | 'TB' | 'PB' | 'EB' | 'ZB' | 'YB'

export interface IResizeImage {
  image: HTMLImageElement
  newWidth: number
  newHeight: number
}

export interface IConvertFileImageToAnnotation {
  base64Img: string
  annotManager: Core.AnnotationManager
  webViewerInstance: WebViewerInstance
  maxImageSize?: number // KB
  newImageWidth?: number // px
  newImageHeight?: number // px
}

/**
 * @returns a tuple of [width, heiht] in number array
 */
export const calculateImageSize = ({ image, newWidth, newHeight }: IResizeImage) => {
  let width = image.width
  let height = image.height
  // reduce width
  if (width > newWidth) {
    height = Math.round((height * newWidth) / width)
    width = newWidth
  }
  // reduce height
  if (height > newHeight) {
    width = Math.round((width * newHeight) / height)
    height = newHeight
  }
  return [width, height]
}

export const imageToCanvas = ({ image, newWidth, newHeight }: IResizeImage) => {
  // create an off-screen canvas
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  // set its dimension to target size
  canvas.width = newWidth
  canvas.height = newHeight
  // draw source image into the off-screen canvas:
  ctx?.drawImage(image, 0, 0, newWidth, newHeight)
  return canvas
}

export const resizeImage = (data: IResizeImage) => {
  const canvas = imageToCanvas(data)
  return canvas.toDataURL()
}

export const getBytes = (base64: string) => {
  let content = base64.split(',')[1]
  // remove "==" at the end of base64 data if exist
  if (content.substring(content.length, content.length - 2) === '==') {
    content = content.substring(0, content.length - 2)
  }
  return (content.length * 3) / 4
}

export const getReadableBytes = (bytes: number) => {
  const i = Math.floor(Math.log(bytes) / Math.log(1024)),
    sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i]
}

export const convertBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)

    fileReader.onload = () => {
      resolve(fileReader.result as string)
    }

    fileReader.onerror = (error) => {
      reject(error)
    }
  })
}

export const getImageDimensions = (
  image: HTMLImageElement,
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve) => {
    image.onload = function () {
      resolve({ width: image.width, height: image.height })
    }
  })
}

export const convertBase64ImgToAnnotation = async ({
  base64Img,
  annotManager,
  webViewerInstance,
  maxImageSize = 512, // KB
  newImageWidth = 600, // px
  newImageHeight = 360, // px
}: IConvertFileImageToAnnotation) => {
  const image = new Image()
  image.src = base64Img
  const { width, height } = await getImageDimensions(image)
  image.width = width
  image.height = height
  /**
   * craete stamp annotation image on whiteboard
   *
   *  ************************ NOTE ************************
   *  this solution required moving an image after uploading
   *  it onto the whiteboard to show to other users
   */
  const stampAnnot = new webViewerInstance.Core.Annotations.StampAnnotation()
  stampAnnot.PageNumber = 1
  stampAnnot.X = 0
  stampAnnot.Y = 0
  stampAnnot.Width = width
  stampAnnot.Height = height
  /**
   * change image size if it more than max available image size
   *  includes max available width and height
   */
  const imgSize = getBytes(base64Img) / 1024
  const resizeImgParams = {
    image: image,
    newWidth: newImageWidth,
    newHeight: newImageHeight,
  }
  if (imgSize > maxImageSize || image.width > newImageWidth || image.height > newImageHeight) {
    const [newWidth, newHeight] = calculateImageSize(resizeImgParams)
    stampAnnot.Width = newWidth
    stampAnnot.Height = newHeight
  }

  const keepAsSVG = false
  stampAnnot.setImageData(base64Img, keepAsSVG)
  stampAnnot.Author = annotManager.getCurrentUser()

  annotManager.addAnnotation(stampAnnot)
  annotManager.redrawAnnotation(stampAnnot)
}
