import { IParticipantInfo } from '@/types/interfaces/participant.interface'

export const findParticipantInfoByEmail = (email: string, participants: IParticipantInfo[]) => {
  return participants.find((p) => p.email === email)
}

export const findParticipantInfoById = (id: string, participants: IParticipantInfo[]) => {
  return participants.find((p) => p.id === id)
}

export const checkCoachRole = (participant: IParticipantInfo) => {
  return !!(
    !participant.isInvisible &&
    participant.capabilities?.followDoc &&
    participant.capabilities?.writableControlDoc &&
    participant.capabilities?.sticker &&
    participant.capabilities?.publishableTracks.screenShare &&
    participant.capabilities?.whiteBoard &&
    participant.capabilities?.trackLesson
  )
}

export const checkStudentRole = (participant: IParticipantInfo) => {
  return !participant.isInvisible && !!participant.capabilities?.feedbackClass
}
