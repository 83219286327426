interface IDeviceEnableState {
  video: boolean
  audio: boolean
}
export const getVideoInput = () => {
  return localStorage.getItem('video_input')
}

export const setVideoInput = (videoId: string) => {
  return localStorage.setItem('video_input', videoId)
}

export const getAudioInput = () => {
  return localStorage.getItem('audio_input')
}

export const setAudioInput = (audioId: string) => {
  return localStorage.setItem('audio_input', audioId)
}

export const getAudioOutput = () => {
  return localStorage.getItem('audio_output')
}

export const setAudioOutput = (speakerId: string) => {
  return localStorage.setItem('audio_output', speakerId)
}

export const setDeviceEnableState = (data: IDeviceEnableState) => {
  return localStorage.setItem('device_state', JSON.stringify(data))
}

export const getDeviceEnableState = (): IDeviceEnableState => {
  const data = localStorage.getItem('device_state')
  if (data) return JSON.parse(data) as IDeviceEnableState
  else {
    setDeviceEnableState({ video: true, audio: true })
    return { video: true, audio: true }
  }
}
