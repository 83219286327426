import { TwilioAPIStatus, TwilioStatus } from '@/types/interfaces/video-diagnostic.interface'
import axios from 'axios'
import { reactive } from 'vue'

export const twilioStatus = reactive<TwilioStatus>({})

export const useTwilioStatus = async () => {
  const ALLOWED_COMPONENTS = ['Go Rooms']
  const response = await axios('https://status.twilio.com/api/v2/components.json')

  response.data.components.forEach(
    ({ name, status }: { name: keyof TwilioStatus; status: TwilioAPIStatus }) => {
      if (ALLOWED_COMPONENTS.includes(name)) {
        twilioStatus[name] = status
      }
    },
  )
}
