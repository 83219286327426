<script setup lang="ts">
import ControlButton from '@/components/room/ControlButton.vue'
import { useElementSize } from '@vueuse/core'
import { computed, ref } from 'vue'
const props = defineProps({
  isReady: {
    type: Boolean,
    default: false,
  },
  isProcessing: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  // video
  cameraEnabled: {
    type: Boolean,
    default: false,
  },
  isCameraFound: {
    type: Boolean,
    default: false,
  },
  allowedCamera: {
    type: Boolean,
    default: false,
  },
  // audio
  microphoneEnabled: {
    type: Boolean,
    default: false,
  },
  isMicrophoneFound: {
    type: Boolean,
    default: false,
  },
  allowedMicrophone: {
    type: Boolean,
    default: false,
  },
  width: {
    type: String,
    default: '100%', // allow percent or px
  },
  canSetBackground: {
    type: Boolean,
    default: false,
  },
  canOpenSetting: {
    type: Boolean,
    default: false,
  },
})
function toDecimal(percent: string) {
  const parsed = parseFloat(percent)
  if (!Number.isNaN(parsed)) {
    return parseFloat(percent) / 100
  } else {
    return 0
  }
}

const VIDEO_RATIO = 9 / 16

const emit = defineEmits([
  'toggle-camera',
  'toggle-microphone',
  'open-background-setting',
  'open-setting',
])
const previewRef = ref<HTMLElement | null>(null)
const { width: wrapperWidth } = useElementSize(previewRef)
const calculateWidth = computed(() =>
  props.width.includes('%')
    ? wrapperWidth.value * toDecimal(props.width)
    : props.width.includes('px')
    ? Number(props.width.replace('px', ''))
    : wrapperWidth.value,
)
const calculateHeight = computed(() => calculateWidth.value * VIDEO_RATIO)

const widthPixel = computed(() => `${calculateWidth.value}px`)
const heightPixel = computed(() => `${calculateHeight.value}px`)

const toggleCamera = () => {
  if (props.isProcessing) return
  emit('toggle-camera')
}
const toggleMicrophone = () => {
  if (props.isProcessing) return
  emit('toggle-microphone')
}
const clickBackgroundButton = () => {
  emit('open-background-setting')
}
const clickSettingButton = () => {
  emit('open-setting')
}
</script>

<template>
  <div ref="previewRef" class="preview-wrapper">
    <div class="video-container">
      <slot />
      <div class="track-overlay">
        <div class="microphone-notify-container">
          <div class="w-full"></div>

          <div class="w-9/12 lg:w-6/12">
            <div
              v-if="isMicrophoneFound && allowedMicrophone && isReady"
              class="microphone-notify"
              :class="{ active: !microphoneEnabled }"
            >
              <SvgIcon class="mr-2" icon="mic-disabled" :inherit-fill="true" height="16" />
              <div class="text-off">{{ $t('microphone-off') }}</div>
            </div>

            <div
              v-if="(!isMicrophoneFound || !allowedMicrophone) && isReady"
              class="microphone-notify active"
            >
              <SvgIcon class="mr-2" icon="mic-disabled" :inherit-fill="true" height="16" />
              <div class="text-off">{{ $t('no-microphone-found') }}</div>
            </div>
          </div>

          <div class="w-full flex justify-end text-white">
            <div
              v-if="canOpenSetting"
              class="dots-container h-8 w-8 cursor-pointer rounded-full btn-bg-color"
              @click="clickSettingButton"
            >
              <SvgIcon class="h-4 w-4" icon="three-dots" :inherit-fill="true" height="16"></SvgIcon>
            </div>
          </div>
        </div>

        <div class="camera-notify">
          <span v-if="!cameraEnabled && isCameraFound && allowedCamera" class="text-off">{{
            $t('camera-off')
          }}</span>
          <span v-else-if="!isCameraFound || !allowedCamera" class="text-off">{{
            $t('no-camera-found')
          }}</span>
        </div>
        <div v-if="isReady" class="video-button">
          <ControlButton
            v-if="isCameraFound && allowedCamera"
            :disabled="!cameraEnabled"
            icon="cam"
            height="18"
            icon-disabled="cam-disabled"
            class="control-btn w-9 h-9"
            :class="[isProcessing && 'btn-disabled']"
            @click="toggleCamera"
          />
          <div v-if="!isCameraFound || !allowedCamera" class="media-block">
            <ControlButton
              :disabled="true"
              icon="cam"
              height="18"
              icon-disabled="cam-disabled"
              class="control-btn w-9 h-9"
            />
            <div class="icon-container">
              <SvgIcon class="control-icon" icon="warning" :inherit-fill="true" height="8" />
            </div>
          </div>
          <ControlButton
            v-if="isMicrophoneFound && allowedMicrophone"
            :disabled="!microphoneEnabled"
            icon="mic"
            height="18"
            icon-disabled="mic-disabled"
            class="control-btn w-9 h-9"
            :class="[isProcessing && 'btn-disabled']"
            @click="toggleMicrophone"
          />
          <div v-if="!isMicrophoneFound || !allowedMicrophone" class="media-block">
            <ControlButton
              :disabled="true"
              icon="mic"
              height="18"
              icon-disabled="mic-disabled"
              class="control-btn w-9 h-9"
            />
            <div class="icon-container">
              <SvgIcon
                class="control-icon"
                icon="warning"
                :inherit-fill="true"
                height="8"
                @click="toggleMicrophone"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.video-container {
  --track-radius: 10px;
  position: relative;
  width: v-bind(widthPixel);
  height: v-bind(heightPixel);
  border-radius: var(--track-radius);
  margin-top: 1rem;
  background: #272727;
  overflow: hidden;

  .track-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--track-radius);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;

    .microphone-notify-container {
      padding: 0.75rem;
      display: flex;
      font-size: 12px;
      justify-content: space-between;
      width: 100%;
      .microphone-notify {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 42px;
        padding: 12px 24px;
        background: #1d1d1d;
        border-radius: 24px;
        transform: translateX(-80px);
        opacity: 0;
        transition-property: transform, opacity;
        transition-duration: 0.5s;
        transition-timing-function: ease;

        &.active {
          transform: translateX(0);
          opacity: 1;
          transition-delay: 0ms;
        }
      }
    }
    .camera-notify {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 18px;
    }

    .video-button {
      margin-top: auto;
      padding: 0.75rem;
      display: flex;
      .control-btn {
        border-radius: 50%;
        width: 36px !important;
        height: 36px !important;
        background: transparent;
        border: solid 1px;
        color: #dedede;
        transition-duration: 0.4s;
        &.btn-disabled {
          cursor: not-allowed;
        }
        &:not(:last-child) {
          margin-right: 0.75rem;
        }
        @include media-breakpoint-up-custom(768px) {
          width: 48px !important;
          height: 48px !important;
        }
        &:hover {
          color: #6b6b6b;
          background-color: rgba(255, 255, 255, 0.9) !important;
        }
        &.-disabled,
        &.-disabled:hover {
          background: $danger !important;
          color: white !important;
        }
      }

      > .media-block {
        position: relative;
        .icon-container {
          position: absolute;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 14px;
          height: 14px;
          border-radius: 50%;
          border: solid 1px #f67519;
          top: -5px;
          right: 5px;
          background-color: #f67519;
          color: #ffffff;
          @include media-breakpoint-up-custom(768px) {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
    .background-setting {
      position: absolute;
      right: 0;
      bottom: 0;
      padding: 0.75rem;
      .control-btn {
        border-radius: 50%;
        width: 36px !important;
        height: 36px !important;
        background: transparent;
        border: solid 1px;
        color: #dedede;
        transition-duration: 0.4s;
        background-color: #4f4f4f;
        @include media-breakpoint-up-custom(768px) {
          width: 48px !important;
          height: 48px !important;
        }
        &:hover {
          color: #6b6b6b;
          background-color: rgba(255, 255, 255, 0.7) !important;
        }
      }

      .beta {
        position: absolute;
        top: 5px;
        right: 5px;
      }
    }
    .text-off {
      width: auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      justify-content: center;
    }
  }
}

.btn-bg-color {
  background-color: $gs-2;
  &:hover {
    color: #6b6b6b;
    background-color: rgba(255, 255, 255, 0.7) !important;
    transition-duration: 0.4s;
  }
}

::v-deep(.dots-container > .svg-container) {
  display: block !important;
  margin: auto;
  margin-top: 8px;
}
</style>
