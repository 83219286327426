export enum BrowserDetect {
  CHROME = 'Chrome',
  FIREFOX = 'Mozilla Firefox',
  IE = 'Internet Exploder',
  EDGE = 'Edge',
  SAFARI = 'Safari',
  OPERA = 'Opera',
  OTHERS = 'Others',
}

export enum OperatingSystem {
  WINDOWS = 'windows',
  MAC_OS = 'mac_os',
  IOS = 'ios',
  ANDROID = 'android',
  UNKNOWN = 'unknown',
}
