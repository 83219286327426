import { ITwilioParticipant } from '@/types/interfaces/participant.interface'
import { LocalVideoTrack, RemoteVideoTrack } from 'twilio-video'
import { computed, ref } from 'vue'

export const localParticipant = ref<ITwilioParticipant | undefined>(undefined)
export const remoteParticipants = ref<ITwilioParticipant[]>([])
export const screenTrack = ref<LocalVideoTrack | RemoteVideoTrack | undefined>(undefined)
export const isMobile = ref<boolean>(false)
export const isConnecting = ref<boolean>(false)
export const multipleSessions = ref<boolean>(false)
export const multipleTabs = ref<boolean>(false)
export const currentResolution = ref<string>('Auto')

export const participants = computed<ITwilioParticipant[]>(() =>
  localParticipant.value
    ? [localParticipant.value, ...remoteParticipants.value]
    : remoteParticipants.value,
)
