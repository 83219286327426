export enum Stage {
  GET_STARTED,
  CHECK_PERMISSIONS,
  PERMISSION_ERROR,
  CAMERA_TEST,
  AUDIO_TEST,
  CONNECTIVITY,
  RESULTS,
}

export enum QualityScore {
  Poor,
  Normal,
  Good,
}

export enum Topic {
  GET_STARTED,
  DEVICE_SETUP,
  CONNECTIVITY,
  RESULTS,
}

export enum ZoomVideoError {
  // Cannot detect camera device.
  CAN_NOT_DETECT_CAMERA = 'CAN_NOT_DETECT_CAMERA',
  // The provided camera device ID is not included in the camera device list.
  CAN_NOT_FIND_CAMERA = 'CAN_NOT_FIND_CAMERA',
  // The user has forbidden the use of the camera. They can allow camera and rejoin the session.
  VIDEO_USER_FORBIDDEN_CAPTURE = 'CAN_NOT_DETECT_CAMERA',
  // Video WebSocket is broken.
  VIDEO_ESTABLISH_STREAM_ERROR = 'VIDEO_ESTABLISH_STREAM_ERROR',
  // User's camera is taken by other programs.
  VIDEO_CAMERA_IS_TAKEN = 'VIDEO_CAMERA_IS_TAKEN',
}
