<script setup lang="ts">
import { FaceSmileIcon, FaceFrownIcon } from '@heroicons/vue/24/solid'
import FaceSmileIconOutline from '@heroicons/vue/24/outline/FaceSmileIcon'
import FaceFrownIconOutline from '@heroicons/vue/24/outline/FaceFrownIcon'
import { useI18n } from 'vue-i18n'
import { useToggle } from '@vueuse/core'
const emit = defineEmits(['complete', 'not-working'])

const [sadActive, toggleSad] = useToggle()
const [smiledActive, toggleSmile] = useToggle()

const { t } = useI18n()

const onClickProblem = () => {
  emit('not-working')
}

const onClickComplete = () => {
  emit('complete')
}
</script>

<template>
  <div class="feedback-box">
    <div class="feedback-box__body">
      <h3 class="feedback-box__content">{{ t('feedback-help-request.content') }}</h3>
      <div class="flex justify-evenly">
        <div
          class="icon"
          @mouseover="toggleSad(true)"
          @mouseleave="toggleSad(false)"
          @click="onClickProblem"
        >
          <FaceFrownIconOutline
            v-show="!sadActive"
            class="h-12 w-12 text-gray-400"
            aria-hidden="true"
          />
          <FaceFrownIcon v-show="sadActive" class="h-12 w-12 text-red-600" aria-hidden="true" />
        </div>
        <div
          class="icon"
          @mouseover="toggleSmile(true)"
          @mouseleave="toggleSmile(false)"
          @click="onClickComplete"
        >
          <FaceSmileIconOutline
            v-show="!smiledActive"
            class="h-12 w-12 text-gray-400"
            aria-hidden="true"
          />
          <FaceSmileIcon
            v-show="smiledActive"
            class="h-12 w-12 text-green-500"
            aria-hidden="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.feedback-box {
  position: fixed;
  bottom: 75px;
  right: 20px;
  z-index: 9;
  border-radius: 6px;
  overflow: hidden;
  background: white;
  padding: 2rem;
  @include primary-box-shadow;
  &__content {
    color: #4a4a4a;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 1.5rem;
  }
  &__footer {
    padding: 15px;
    background: #fcfcfc;
    border-top: 1px solid #f3f3f3;
    display: flex;
    .btn {
      font-size: 15px;
      @include flexColumn(2, 10px);
      margin-bottom: 0;
    }
  }
  .icon {
    cursor: pointer;
  }
}
</style>
