<script setup lang="ts">
import { computed, ref } from 'vue'

const props = defineProps({
  icon: {
    type: String,
    default: 'cam',
  },
  iconDisabled: {
    type: String,
    default: undefined,
  },
  height: {
    type: String,
    default: '24',
  },
  name: {
    type: String,
    default: '',
  },
  badge: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  isReady: {
    type: Boolean,
    default: true,
  },
  isDangerDisabled: {
    type: Boolean,
    default: false,
  },
  isWarning: {
    type: Boolean,
    default: false,
  },
})

const customCss = computed(() => {
  return {
    '-enabled': !props.disabled,
    '-disabled': props.disabled,
    '-red': props.disabled && props.isDangerDisabled,
    '-not-ready': !props.isReady,
  }
})
</script>

<template>
  <div class="control-item _pointer-on-hover _prevent-text-select" :class="customCss">
    <div v-if="isWarning" class="icon-container">
      <SvgIcon class="control-icon" icon="warning" :inherit-fill="true" width="10" height="10" />
    </div>
    <SvgIcon
      v-if="disabled && iconDisabled"
      class="control-icon"
      :icon="iconDisabled"
      :inherit-fill="true"
      :height="height"
    />
    <SvgIcon
      v-if="!disabled || !iconDisabled"
      class="control-icon"
      :icon="icon"
      :inherit-fill="true"
      :height="height"
    />
    <span v-if="name" class="control-text">{{ name }}</span>
    <div v-show="!!badge" class="badge">{{ badge }}</div>
    <!-- <div class="loading-bg" /> -->
  </div>
</template>

<style lang="scss" scoped>
.control-item {
  position: relative;
  width: 50px;
  height: 50px;
  background: rgba(var(--control-btn-color-rgb), 0.05);
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgba(var(--control-btn-color-rgb), 0.8);

  @include media-breakpoint-up-custom(700px) {
    width: 60px;
    height: 60px;
  }

  @media (hover: hover) {
    &:hover {
      background: rgba(var(--control-btn-color-rgb), 0.1);
    }
  }

  .control-text {
    margin-top: 2px;
    display: none;
    font-size: 0.825rem;

    @include media-breakpoint-up-custom(700px) {
      display: inline-block;
    }
  }

  .loading-bg {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
  }

  .icon-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: solid 1px #f67519;
    top: -8px;
    right: -8px;
    background-color: #f67519;
    color: #ffffff;
    z-index: 1;
    @include media-breakpoint-up-custom(768px) {
      width: 24px;
      height: 24px;
    }
  }
  &.-not-ready {
    pointer-events: none;
    background: rgba(var(--control-btn-color-rgb), 0.15) !important;
    color: rgba(var(--control-btn-color-rgb), 0.4) !important;

    @keyframes placeholder-shimmer {
      0% {
        background-position: -100px 0;
      }
      100% {
        background-position: 100px 0;
      }
    }
    // .loading-bg {
    //   animation-duration: 1.75s;
    //   animation-fill-mode: forwards;
    //   animation-iteration-count: infinite;
    //   animation-name: placeholder-shimmer;
    //   animation-timing-function: linear;
    //   background: linear-gradient(
    //     to right,
    //     rgba(#000, 0.55) 30%,
    //     rgba(#000, 0.25) 50%,
    //     rgba(#000, 0.55) 70%
    //   );
    //   background-size: 200px 200px;
    //   width: 100px;
    //   height: 100px;
    // }
  }

  &.-disabled {
    background: rgba(var(--control-btn-color-rgb), 0.05);
    color: rgba(var(--control-btn-color-rgb), 0.6);

    &:hover {
      background: rgba(var(--control-btn-color-rgb), 0.1);
      color: rgba(var(--control-btn-color-rgb), 0.9);
    }

    &.-red {
      background: rgba(247, 71, 91, 0.15);
      color: $danger;

      &:hover {
        background: rgba(247, 71, 91, 0.3);
      }
    }
  }

  &.-enabled {
    background: rgba(38, 160, 109, 0.15);
    color: #26a06d;
    &:hover {
      background: rgba(38, 160, 109, 0.3);
    }
  }

  .badge {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    min-width: 18px;
    min-height: 18px;
    text-align: center;
    top: 4px;
    right: 4px;
    background: var(--primary-color);
    color: #fff;
    border-radius: 10px;
    font-size: 0.75rem;
    line-height: 1;
    padding: 0 5px;
  }
}
body.dark {
  .control-item {
    &.-disabled.-red {
      background: rgba(247, 71, 91, 0.3);
      &:hover {
        background: rgba(247, 71, 91, 0.5);
      }
    }

    &.-enabled {
      background: rgba(38, 160, 109, 0.3);
      &:hover {
        background: rgba(38, 160, 109, 0.5);
      }
    }
  }
}

body:not(.dark) {
  .control-item.-disabled:not(.-red) {
    background: white;
    border: 1px solid rgba(var(--control-btn-color-rgb), 0.3);
  }
  .control-item.-disabled:not(.-red):hover {
    background: rgba(var(--control-btn-color-rgb), 0.1);
    color: rgba(var(--control-btn-color-rgb), 0.9);
  }
}
</style>
