<script setup lang="ts">
import DiagnosticSection from '@/components/video-diagnostic/DiagnosticSection.vue'
import { Stage } from '@/types/enums/zoom-diagnostic.enum'

interface GetStartedEmits {
  (e: 'nextStage', value: number): void
}

defineProps({
  stage: {
    type: Number,
    required: true,
  },
})

const emit = defineEmits<GetStartedEmits>()
</script>

<template>
  <DiagnosticSection
    :id="Stage.GET_STARTED"
    :class="
      stage === Stage.GET_STARTED || stage === Stage.RESULTS
        ? 'block'
        : 'hidden md:block opacity-30 pointer-events-none'
    "
  >
    <template #default>
      <div class="row-span-2 text-sm sm:text-base lg:text-sm xl:text-base pt-10 lg:pt-20">
        <h1 class="font-bold text-xl sm:text-4xl lg:text-xl xl:text-4xl">
          {{ $t('zoom-diagnostic.get-start-title') }}
        </h1>
        <p class="py-4 sm:py-10">
          {{ $t('zoom-diagnostic.start-detail') }}
        </p>
        <button
          :class="{ 'opacity-30 pointer-events-none': stage === Stage.RESULTS }"
          class="text-white border-2 rounded-md border-white py-2 px-4"
          type="button"
          @click="emit('nextStage', Stage.CHECK_PERMISSIONS)"
        >
          {{ $t('zoom-diagnostic.get-start') }}
        </button>
      </div>

      <div class="text-sm sm:text-base lg:text-sm xl:text-base row-span-2">
        <div class="w-72 h-72 m-auto">
          <img src="@/assets/video-diagnostic/Hello.png" alt="" />
        </div>
        <!-- <div>
          <span class="font-bold">Not sure about something?</span> Skip that section for now, and
          your support administrator can help later.
        </div> -->
      </div>
    </template>
  </DiagnosticSection>
</template>

<style lang="scss" scoped>
button {
  background-color: #f67519;
}
</style>
