<script setup lang="ts">
import { chatEnable } from '@/composables/room/useRoomLayout'
import { IPreviewMessage } from '@/types/interfaces/common.interface'
import { onImageError } from '@/helpers/favicon'
import { PropType, ref, watch } from 'vue'

const props = defineProps({
  newMessage: {
    type: Object as PropType<IPreviewMessage>,
    default: undefined,
  },
})

const displayMessage = (msg: IPreviewMessage) => {
  if (msg.type === 'image') {
    return `${msg.participant.name} sent you an image`
  } else if (msg.type === 'file') {
    return `${msg.participant.name} sent you a file`
  } else {
    return msg.message
  }
}

const messagesStore = ref<IPreviewMessage[]>([])
const clearAllMessages = () => {
  messagesStore.value = []
}

const addNewMessage = (msg: IPreviewMessage) => {
  messagesStore.value.push(msg)
  setTimeout(() => {
    messagesStore.value = messagesStore.value.filter((ms) => ms.id !== msg.id)
  }, 3000)
}

watch(
  () => props.newMessage,
  (msg?: IPreviewMessage) => {
    if (!msg) return
    addNewMessage(msg)
  },
  { deep: true },
)

watch(
  () => chatEnable.value,
  (enable) => {
    if (enable) {
      clearAllMessages()
    }
  },
)
</script>

<template>
  <transition-group name="message-preview-list" tag="div" class="message-preview">
    <div
      v-for="msg in messagesStore"
      :key="msg.id"
      class="message message-preview-list-item _pointer-on-hover"
      @click="clearAllMessages"
    >
      <div class="bubble">{{ displayMessage(msg) }}</div>
      <div class="avatar">
        <img :src="msg.participant.avatar || ''" draggable="false" @error.once="onImageError" />
      </div>
    </div>
  </transition-group>
</template>

<style lang="scss" scoped>
.message-preview {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .message {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 10px;
    text-align: right;

    .bubble {
      max-width: 260px;
      word-wrap: break-word;
      padding: 8px 16px;
      background-color: rgba($gs-1, 0.8);
      backdrop-filter: blur(7px);
      border-radius: 16px 16px 4px 16px;
      color: $gs-6;
      @include font-weight(regular);
      font-size: 0.9rem;
      margin-right: 7px;
      @include primary-box-shadow;
    }

    .avatar {
      border-radius: 20px;
      overflow: hidden;
      width: 34px;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include primary-box-shadow;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.message-preview-list-item {
  transition: all 0.5s $primary-tc;
  transform-origin: 100% 50%;
}
.message-preview-list-enter,
.message-preview-list-leave-to {
  opacity: 0;
  transform: translate3d(30px, 0, 0) scale3d(0.6, 0.6, 0);
}
.message-preview-list-leave-active {
  transition: all 0.35s $primary-tc;
}
</style>
