<script setup lang="ts">
import { ref, getCurrentInstance, onMounted } from 'vue'
import CommonState from '@/services/locale-state'
const defaultLang = ref<string>(CommonState.getLocale() || 'en')
const onChangeLocale = (lang) => {
  if (lang) {
    CommonState.setLocale(lang)
  }
}

onMounted(() => {
  const vm = getCurrentInstance()
  if (vm?.ctx?.$i18n?.locale) {
    defaultLang.value = vm.ctx.$i18n.locale
  }
})
</script>

<template>
  <SvgIcon
    v-if="'vn' == $root.$i18n.locale"
    class="_pointer-on-hover mr-1 inline-flex"
    icon="vn"
    height="24"
    aria-hidden="true"
    @click="
      (e) => {
        $root.$i18n.locale = 'th'
        onChangeLocale($root.$i18n.locale)
      }
    "
  ></SvgIcon>
  <SvgIcon
    v-else-if="'th' == $root.$i18n.locale"
    class="_pointer-on-hover mr-1 inline-flex"
    icon="th"
    height="24"
    aria-hidden="true"
    @click="
      (e) => {
        $root.$i18n.locale = 'en'
        onChangeLocale($root.$i18n.locale)
      }
    "
  ></SvgIcon>
  <SvgIcon
    v-else
    class="_pointer-on-hover mr-1 inline-flex"
    icon="uk"
    height="24"
    aria-hidden="true"
    @click="
      (e) => {
        $root.$i18n.locale = 'vn'
        onChangeLocale($root.$i18n.locale)
      }
    "
  ></SvgIcon>
</template>
