import hexToRgb from '@/helpers/hex-to-rgb'
import { usePreferredDark } from '@vueuse/core'
import { computed, onBeforeMount, reactive, toRefs, watchEffect } from 'vue'

export type ThemeModeSchema = 'auto' | 'dark' | 'light'
export type TemplateModeSchema = 'kids' | 'adult'

const preferredDark = usePreferredDark()

interface UseThemeState {
  theme: ThemeModeSchema
  template: TemplateModeSchema
}

export default function useTheme() {
  const state = reactive<UseThemeState>({
    theme: 'auto',
    template: 'adult',
  })

  const isDark = computed({
    get() {
      return state.theme === 'auto' ? preferredDark.value : state.theme === 'dark'
    },
    set(v: boolean) {
      if (v === preferredDark.value) state.theme = 'auto'
      else state.theme = v ? 'dark' : 'light'
    },
  })

  const isKids = computed(() => state.template === 'kids')

  const changeTheme = (theme: ThemeModeSchema) => {
    state.theme = theme
    localStorage.setItem('theme', theme)
  }

  const getThemeFromStorage = () => {
    const theme = localStorage.getItem('theme')
    changeTheme((theme as ThemeModeSchema) || 'auto')
  }

  const setTemplate = (template: TemplateModeSchema) => {
    state.template = template
  }

  const setVariableColor = () => {
    const body = document.body
    if (!body) return
    if (isDark.value) {
      body.classList.add('dark')
    } else {
      body.classList.remove('dark')
    }
    const controlColor = isDark.value ? '#fff' : '#6b6b6b'
    document.documentElement.style.setProperty('--control-btn-color', controlColor)
    const color = hexToRgb(controlColor)
    if (color) {
      document.documentElement.style.setProperty(
        '--control-btn-color-rgb',
        `${color.r}, ${color.g}, ${color.b}`,
      )
    }
  }

  onBeforeMount(() => {
    getThemeFromStorage()
    setVariableColor()
  })
  /**
   * watchEffect callbacks will be executed each time used reactives value has changed
   */
  watchEffect(() => {
    setVariableColor()
  })

  return { ...toRefs(state), isDark, isKids, changeTheme, setTemplate }
}
