<script setup lang="ts"></script>

<template>
  <Teleport to="body">
    <NotificationGroup group="general">
      <div class="toast">
        <div class="w-full max-w-sm">
          <Notification
            v-slot="{ notifications }"
            enter="transform ease-out duration-300 transition"
            enter-from="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
            enter-to="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-500"
            leave-from="opacity-100"
            leave-to="opacity-0"
            move="transition duration-500"
            move-delay="delay-300"
          >
            <div v-for="notification in notifications" :key="notification.id">
              <div class="notification" :class="`-${notification.type}`">
                <div class="notification-icon" :class="notification.data.component">
                  <component
                    :is="notification.data.component"
                    v-bind="notification.data.props"
                  ></component>
                </div>
                <div class="notification-content">
                  <div class="notification-title">
                    {{ notification.title }}
                  </div>
                  <div class="notification-text" v-html="notification.text"></div>
                </div>
              </div>
            </div>
          </Notification>
        </div>
      </div>
    </NotificationGroup>
  </Teleport>
</template>

<style lang="scss">
.toast {
  @apply fixed inset-0 flex items-start justify-end p-6 px-4 py-6 pointer-events-none;
  z-index: $z-index-popup + 1;
  .notification {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    text-align: left;
    font-size: 10px;
    padding: 0.5rem 1rem;
    margin: 8px;
    margin-bottom: 0;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    color: $gs-5;
    color: var(--default-text-color);
    background: var(--notyf-bg) !important;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    &,
    & > div {
      box-sizing: border-box;
    }

    &.-success {
      border-bottom: 4px solid #26a06d;
    }
    &.-warning {
      border-bottom: 4px solid #fbba00;
    }
    &.-fail {
      border-bottom: 4px solid #e32c32;
    }
    .notification-icon {
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;

      &.img {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        margin: 1rem 0.5rem 1rem 1rem;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
      }
      &.svg-icon {
        padding: 1rem 0.5rem 1rem 1rem;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .custom-toast-close {
      flex: 0 1 auto;
      padding: 0 1rem;
      font-size: 16px;
      opacity: 0.2;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
    .notification-content {
      padding: 10px 1rem;
      flex: 1 0 auto;
      width: 0;
      .notification-title {
        text-transform: capitalize;
        font-size: 1rem;
        @include font-weight(bold);
      }
      .notification-text {
        font-size: 0.9rem;
      }
    }
  }
}
</style>
