<script setup lang="ts">
import { PropType, computed } from 'vue'
import { ISpaceParticipants } from '@/types/interfaces/participant.interface'
import { onImageError } from '@/helpers/favicon'
/**
 * define props
 */
const props = defineProps({
  controlBy: {
    type: String,
    default: 'none',
  },
  toggleFollowMe: {
    type: Boolean,
    default: false,
  },
  toggleWritable: {
    type: Boolean,
    default: false,
  },
  activeParticipants: {
    type: Array as PropType<ISpaceParticipants[]>,
    default: () => [],
  },
  canToggleWritable: {
    type: Boolean,
    default: false,
  },
  canToggleFollowing: {
    type: Boolean,
    default: false,
  },
  hasDocPath: {
    type: Boolean,
    default: false,
  },
  showBackMsg: {
    type: Boolean,
    default: false,
  },
  canOpenOriginalDoc: {
    type: Boolean,
    default: false,
  },
})
/**
 * define emits
 */
const emit = defineEmits([
  'update:toggleFollowMe',
  'update:toggleWritable',
  'on-toggle-follow',
  'toggle-readonly',
  'toggle-original-material',
])
/**
 * computed
 */
const isDisabledFollowing = computed(() => {
  return props.controlBy === 'other' || !props.canToggleFollowing
})
/**
 * methods
 */
const onToggleFollowMe = () => {
  /**
   * emit update:toggleFollowMe and toggle follow event
   * when controlBy not equal "other"
   */
  if (props.controlBy !== 'other' && props.canToggleFollowing) {
    emit('update:toggleFollowMe', !props.toggleFollowMe)
    emit('on-toggle-follow', !props.toggleFollowMe)
  }
}

const onOpenOriginalMaterial = () => {
  emit('toggle-original-material')
}
</script>

<template>
  <div class="doc-controller">
    <div v-if="hasDocPath && canOpenOriginalDoc" class="open-og-doc">
      <button
        v-if="showBackMsg"
        class="button-base open-og-doc-button"
        @click="onOpenOriginalMaterial"
      >
        <SvgIcon :icon="'left'" :inherit-fill="true" height="20" />
        Back
      </button>
    </div>
    <div v-if="canToggleWritable" class="toggle-writable">
      <button
        class="toggle-writable__button"
        :class="!canToggleWritable ? 'disabled' : ''"
        @click="canToggleWritable ? emit('update:toggleWritable', !toggleWritable) : undefined"
      >
        Enable / Disable writable document
      </button>
      <div class="writable-card" :class="toggleWritable ? 'active' : ''">
        <h4>Who can writable document</h4>
        <div v-if="activeParticipants.length > 0" class="avatar-group">
          <div
            v-for="participant in activeParticipants"
            :key="participant.id"
            class="avatar-wrapper"
            @click="emit('toggle-readonly', participant.email)"
          >
            <img
              :src="participant.avatar || ''"
              :alt="participant.name"
              :class="participant.readonly ? 'disabled-avatar' : ''"
              @error.once="onImageError"
            />
            <span>{{ participant.name }}</span>
          </div>
        </div>
        <div v-else class="waitting-text">Wait for other participants connected!</div>
        <p class="writable-text-footer">
          Select participant you want to "Enable" or "Disable" write document
        </p>
      </div>
    </div>
    <div class="right-menu">
      <div
        v-if="canToggleFollowing"
        class="toggle-follow-me"
        :class="[toggleFollowMe ? 'following' : '', isDisabledFollowing ? 'disabled' : '']"
      >
        <button class="button-base toggle-follow-me__button" @click="onToggleFollowMe">
          {{ toggleFollowMe ? 'Stop Follow Me' : 'Follow My Page' }}
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.text-black {
  color: black;
}
.doc-controller {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;

  .right-menu {
    display: flex;
    margin-left: auto;
  }

  .toggle-writable {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .toggle-writable__button {
      padding: 5px 10px;
      margin: 0 10px;
      border-radius: 5px;
      color: #333;
      font-size: 0.6rem;
      font-weight: 400;
      background-color: #fff;

      @include media-breakpoint-up-custom(700px) {
        font-size: 0.8rem;
      }
    }

    .writable-card {
      color: #4a4a4a;
      visibility: hidden;
      position: absolute;
      top: 75px;
      left: 20px;
      background-color: #fff;
      border-radius: 5px;
      width: 320px;

      box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.3);

      &.active {
        visibility: visible;
        // transition
        transition: transform 0.5s ease;
        transform: translateY(-15px);
      }

      h4 {
        border-bottom: lightgray solid 1px;
        font-size: 1rem;
        font-weight: 600;
        padding: 10px;
      }

      p {
        border-top: lightgray solid 1px;
        font-size: 0.7rem;
        padding: 10px;
      }

      .waitting-text {
        font-size: 0.8rem;
        padding: 20px 10px;
        width: 100%;
        text-align: center;
      }

      .avatar-group {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;
        padding: 20px 10px;

        .avatar-wrapper {
          position: relative;
          padding: 5px;

          img {
            cursor: pointer;
            width: 40px;
            height: 40px;
            object-fit: cover;
            border-radius: 50%;

            &.disabled-avatar {
              filter: grayscale(100%);
            }
          }

          span {
            visibility: hidden;
            // position
            position: absolute;
            bottom: 100%;
            // tooltip card
            background-color: #000;
            opacity: 0.8;
            border-radius: 5px;
            margin-left: -40px;
            padding: 5px 0;
            width: 120px;
            // text and font styling
            text-align: center;
            font-weight: 400;
            font-size: 0.8rem;
            color: #fff;
            z-index: 9;
          }

          &:hover {
            span {
              visibility: visible;
            }
          }
        }
      }
    }
  }

  .button-base {
    padding: 5px 10px;
    margin: 0 10px;
    border-radius: 5px;
    color: #333;
    font-size: 0.6rem;
    font-weight: 400;
    background-color: #fff;

    @include media-breakpoint-up-custom(700px) {
      font-size: 0.8rem;
    }
  }
  .toggle-follow-me {
    display: flex;
    justify-content: center;
    align-items: center;

    .toggle-follow-me__button {
      color: #fff;
      background-color: DarkOrange;
    }

    &.following button {
      background-color: Crimson;
    }
    &.disabled button {
      background-color: lightgray;
      cursor: default;
    }
  }
  .open-og-doc {
    display: flex;
    justify-content: center;
    align-items: center;
    .open-og-doc-button {
      display: flex;
      align-items: center;
    }
  }
}
</style>
