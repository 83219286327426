import { QualityScore } from '@/types/enums/video-diagnostic.enum'
import { bitrateTest } from '@/composables/video-diagnostic/useBitrateTest'
import { preflightTest } from '@/composables/video-diagnostic/usePreflightTest'
import { computed } from 'vue'

const getSingleQualityScore = (
  stat: number | undefined,
  goodThreshold: number,
  suboptimalThreshold: number,
  poorThreshold: number,
  descending = false,
) => {
  if (typeof stat === 'undefined') {
    // We ignore values that are missing
    return QualityScore.Excellent
  }

  if (descending) {
    if (stat > goodThreshold) return QualityScore.Excellent
    if (stat > suboptimalThreshold) return QualityScore.Good
    if (stat > poorThreshold) return QualityScore.Suboptimal
    return QualityScore.Poor
  }

  if (stat >= poorThreshold) return QualityScore.Poor
  if (stat >= suboptimalThreshold) return QualityScore.Suboptimal
  if (stat >= goodThreshold) return QualityScore.Good
  return QualityScore.Excellent
}

const formatNumber = (val: number | undefined) => {
  return val?.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })
}

export const getQualityScore = () => {
  const maxBitrate = computed(() =>
    bitrateTest.report?.values ? Math.max(...bitrateTest.report?.values) : 0,
  )

  const latency = computed(() => ({
    average: formatNumber(preflightTest.report?.stats?.rtt?.average),
    max: formatNumber(preflightTest.report?.stats?.rtt?.max),
    qualityScore: getSingleQualityScore(preflightTest.report?.stats?.rtt?.average, 100, 250, 400),
  }))

  const jitter = computed(() => ({
    average: formatNumber(preflightTest.report?.stats?.jitter?.average),
    max: formatNumber(preflightTest.report?.stats?.jitter?.max),
    qualityScore: getSingleQualityScore(preflightTest.report?.stats?.jitter?.average, 5, 10, 30),
  }))

  const packetLoss = computed(() => ({
    average: formatNumber(preflightTest.report?.stats?.packetLoss?.average),
    max: formatNumber(preflightTest.report?.stats?.packetLoss?.max),
    qualityScore: getSingleQualityScore(preflightTest.report?.stats?.packetLoss?.average, 1, 3, 8),
  }))

  const bitrate = computed(() => ({
    average: formatNumber(bitrateTest.report?.averageBitrate),
    max: formatNumber(maxBitrate.value),
    qualityScore: getSingleQualityScore(bitrateTest.report?.averageBitrate, 1000, 500, 150, true),
  }))

  const totalQualityScore = computed(
    () =>
      Math.min(
        latency.value.qualityScore,
        jitter.value.qualityScore,
        packetLoss.value.qualityScore,
        bitrate.value.qualityScore,
      ) as QualityScore,
  )

  return {
    latency,
    jitter,
    packetLoss,
    bitrate,
    totalQualityScore,
  }
}
