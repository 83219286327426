import { BackgroundType } from '../types/enums/background.enum'

export const covertVideoBgToBackgroundType = (videoBg?: string) => {
  switch (videoBg) {
    case undefined:
    case null:
    case BackgroundType.NONE:
      return { backgroundType: BackgroundType.NONE, imagePath: undefined }
    case BackgroundType.BLUR:
      return { backgroundType: BackgroundType.BLUR, imagePath: undefined }
    default:
      return { backgroundType: BackgroundType.IMAGE, imagePath: videoBg }
  }
}
