<script setup lang="ts">
import { computed } from 'vue'
import { useBackgroundStore, useDeviceStore } from '@/stores'

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  withBorder: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['select-background'])
const backgroundStore = useBackgroundStore()
const deviceStore = useDeviceStore()
/**
 * Computed
 */
const backgroundOptions = computed(() => Array.from(backgroundStore.backgroundGallery.values()))
/**
 * Methods
 */

const selectBackground = (value: string) => {
  if (props.disabled) return
  if (backgroundStore.selectedBackground === value) return
  backgroundStore.selectedBackground = value
  deviceStore.maskCameraOn = true
  emit('select-background', backgroundStore.selectedBackground)
}
</script>

<template>
  <div class="image-gallery">
    <div
      v-for="(image, index) in backgroundOptions"
      :key="`img-gal-${index}`"
      class="gallery-col"
      :class="{
        active: image.value === backgroundStore.selectedBackground,
        disabled,
        '--with-border': withBorder,
      }"
    >
      <div class="image-box" :title="image.name" @click="selectBackground(image.value)">
        <img v-if="image.type === 'image'" :src="image.src" :alt="image.name" class="img-preview" />
        <SvgIcon
          v-else
          class="close-icon"
          :icon="image.path"
          :inherit-fill="true"
          height="48"
        ></SvgIcon>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.image-gallery {
  padding: 1rem 0;
  margin-top: 1rem;
  &--with-border {
    border-top: 1.5px solid #ececec;
  }
  // width: 50%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .gallery-col {
    color: #4a4a4a;
    border-radius: 6px;
    @include flexColumn(1, 10px);
    // box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    border: 1.5px solid #dedede;
    cursor: pointer;
    &:hover {
      color: var(--primary-color);
      border-color: var(--primary-color);
    }

    &.active {
      color: var(--primary-color);
      border: 2px solid var(--primary-color);
    }
    &.disabled {
      opacity: 0.8;
    }
    > .image-box {
      width: 100%;
      height: 100%;
      max-height: 60px;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
      > img {
        width: 120px;
        max-height: 100%;
        object-fit: cover;
      }
    }

    @include media-breakpoint-up-custom(560px) {
      @include clearFlexColumn(1, 10px);
      @include flexColumn(2, 10px);
    }

    @include media-breakpoint-up-custom(700px) {
      @include clearFlexColumn(2, 10px);
      @include flexColumn(5, 10px);
      margin-bottom: 10px;
    }
  }
}
</style>
