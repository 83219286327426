import { ITestResultZoom } from '@/types/interfaces/video-diagnostic.interface'
import { videoInputTestPassed } from '@/composables/zoom-diagnostic/useCameraTest'
import { audioTestPassed } from '@/composables/zoom-diagnostic/useAudioTest'
import { connectionFailed } from '@/composables/zoom-diagnostic/useConnection'
import { ResultStatus } from '@/types/enums/video-diagnostic.enum'

export const getReport = (): ITestResultZoom => ({
  videoResult: videoInputTestPassed.value ? ResultStatus.PASS : ResultStatus.FAIL,
  audioResult: audioTestPassed.value ? ResultStatus.PASS : ResultStatus.FAIL,
  connectivityResult: !connectionFailed.value ? ResultStatus.PASS : ResultStatus.FAIL,
})
