import * as Track from '@/helpers/track.helper'
import { ISelectOption } from '@/types/interfaces/common.interface'
import {
  ICustomMessageCheck,
  ILocalCallback,
  ISetLocalParams,
  IUserMedia,
} from '@/types/interfaces/track.interface'
import pollAudioLevel from './poll-audio-level'
import ZoomVideo from '@zoom/videosdk'
export interface IResponseGetDevices {
  audioInputDevices: ISelectOption[]
  audioOutputDevices: ISelectOption[]
  videoInputDevices: ISelectOption[]
  haveVideo: boolean
  haveAudio: boolean
  error?: any
}

export interface IPublishOption {
  haveVideo?: boolean
  haveAudio?: boolean
  cameraOn?: boolean
  microphoneOn?: boolean
}
export const isMobileDevice = () => {
  const userAgent = navigator.userAgent.toLowerCase()
  const isTablet =
    // eslint-disable-next-line max-len
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      userAgent,
    )
  return isTablet
}

export const detectDevice = (userAgent = navigator.userAgent, platform = navigator.platform) => {
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
  const iosPlatforms = ['iPhone', 'iPad', 'iPod']
  if (macosPlatforms.indexOf(platform) !== -1) {
    return 'Mac'
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    return 'IOS'
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    return 'PC'
  } else if (/Android/.test(userAgent)) {
    return 'Android'
  } else if (/Linux/.test(platform)) {
    return 'Linux'
  }
  return ''
}

export const isIpadOS = () => {
  return (
    navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform)
  )
}

export const getDevices = async (): Promise<IResponseGetDevices> => {
  const audioInputDevices: ISelectOption[] = []
  const audioOutputDevices: ISelectOption[] = []
  const videoInputDevices: ISelectOption[] = []
  try {
    const devices = await navigator.mediaDevices.enumerateDevices()
    devices.forEach((device) => {
      const value = device.deviceId
      if (device.kind === 'audioinput') {
        const label = device.label || `microphone ${audioInputDevices.length + 1}`
        audioInputDevices.push({ value, label })
      }
      if (device.kind === 'audiooutput') {
        const label = device.label || `speaker ${audioOutputDevices.length + 1}`
        audioOutputDevices.push({ value, label })
      }
      if (device.kind === 'videoinput') {
        const label = device.label || `camera ${videoInputDevices.length + 1}`
        videoInputDevices.push({ value, label })
      }
    })
    const haveVideo = devices.some((device) => device.kind === 'videoinput' && device.deviceId)
    const haveAudio = devices.some((device) => device.kind === 'audioinput' && device.deviceId)
    if (!devices.some((it) => it.kind === 'audiooutput')) {
      audioOutputDevices.push({
        value: '',
        label: 'System Default Speaker Device',
      })
    }
    return { audioInputDevices, audioOutputDevices, videoInputDevices, haveVideo, haveAudio }
  } catch (err) {
    return {
      audioInputDevices,
      audioOutputDevices,
      videoInputDevices,
      haveVideo: false,
      haveAudio: false,
    }
  }
}
export const clearLocalTracks = (localTracks?: MediaStream) => {
  if (localTracks) {
    localTracks.getTracks().forEach((track) => {
      track.stop()
    })
  }
}

const customErrorMessage = ({ cameraCheck, microphoneCheck, error }: ICustomMessageCheck) => {
  let errMessage = ''
  const isBoth = cameraCheck && microphoneCheck
  switch (true) {
    // This error is emitted when the user or the user's system has denied permission to use the media devices
    case error?.name === 'NotAllowedError':
      if (isBoth) {
        errMessage =
          'Your microphone and the camera are now turned off. Please allow this website to use your microphone and camera.'
      } else if (cameraCheck) {
        errMessage = 'Your camera is now turned off. Please allow this website to use your camera.'
      } else if (microphoneCheck) {
        errMessage =
          'Your microphone is now turned off. Please allow this website to use your microphone.'
      }
      break
    // This error is emitted when input devices are not connected or disabled in the OS settings
    case error?.name === 'NotFoundError':
      if (isBoth) {
        errMessage =
          'The browser cannot access the microphone or camera. Please make sure all input devices are connected and enabled.'
      } else if (cameraCheck) {
        errMessage =
          'The browser cannot access the camera. Please make sure all input devices are connected and enabled.'
      } else if (microphoneCheck) {
        errMessage =
          'The browser cannot access the microphone. Please make sure all input devices are connected and enabled.'
      }
      break
    case Boolean(error):
      errMessage = 'Error acquiring media'
      break
  }
  return errMessage
}

export const detectPermissions = async (
  constraints?: MediaStreamConstraints,
): Promise<IUserMedia> => {
  const cameraCheck = !!constraints?.video
  const microphoneCheck = !!constraints?.audio
  try {
    const consent = await navigator?.mediaDevices?.getUserMedia(constraints)
    return { data: consent }
  } catch (error: any) {
    const errName = error.name
    const errMessage = customErrorMessage({ error, cameraCheck, microphoneCheck })
    return { error: { name: errName, message: errMessage } }
  }
}

export const setLocalTrackElement = (data: ISetLocalParams, callback: ILocalCallback) => {
  const { localTracks, localTrackRef, option } = data
  if (localTracks) {
    while (localTrackRef?.firstChild) localTrackRef?.removeChild(localTrackRef?.firstChild)

    if (option?.haveVideo) {
      const mediaHtml = Track.attach(localTracks)
      localTrackRef?.appendChild(mediaHtml)
      if (!option.cameraOn) {
        localTracks.getVideoTracks()[0].enabled = false
      }
    }

    if (option?.haveAudio) {
      pollAudioLevel(localTracks, (level: number) => {
        callback(level)
      })
      if (!option.microphoneOn) {
        localTracks.getAudioTracks()[0].enabled = false
      }
    }
  }
}

export const getDevicesForZoom = async (): Promise<IResponseGetDevices> => {
  const audioInputDevices: ISelectOption[] = []
  const audioOutputDevices: ISelectOption[] = []
  const videoInputDevices: ISelectOption[] = []
  try {
    const devices = await ZoomVideo.getDevices(true)
    devices.forEach((device) => {
      const value = device.deviceId
      if (device.kind === 'audioinput') {
        const label = device.label || `microphone ${audioInputDevices.length + 1}`
        audioInputDevices.push({ value, label })
      }
      if (device.kind === 'audiooutput') {
        const label = device.label || `speaker ${audioOutputDevices.length + 1}`
        audioOutputDevices.push({ value, label })
      }
      if (device.kind === 'videoinput') {
        const label = device.label || `camera ${videoInputDevices.length + 1}`
        videoInputDevices.push({ value, label })
      }
    })
    const haveVideo = devices.some((device) => device.kind === 'videoinput' && device.deviceId)
    const haveAudio = devices.some((device) => device.kind === 'audioinput' && device.deviceId)
    if (!devices.some((it) => it.kind === 'videoinput')) {
      videoInputDevices.push({
        value: '',
        label: 'camera 1',
      })
    }
    if (!devices.some((it) => it.kind === 'audioinput')) {
      audioInputDevices.push({
        value: '',
        label: 'microphone 1',
      })
    }
    if (!devices.some((it) => it.kind === 'audiooutput')) {
      audioOutputDevices.push({
        value: '',
        label: 'System Default Speaker Device',
      })
    }
    return { audioInputDevices, audioOutputDevices, videoInputDevices, haveVideo, haveAudio }
  } catch (error: any) {
    const errName = error.name
    const errMessage = customErrorMessage({ error, cameraCheck: true, microphoneCheck: true })
    return {
      error: { name: errName, message: errMessage },
      audioInputDevices: audioInputDevices.length ? audioInputDevices : [],
      audioOutputDevices: audioOutputDevices.length ? audioOutputDevices : [],
      videoInputDevices: videoInputDevices.length ? videoInputDevices : [],
      haveVideo: false,
      haveAudio: false,
    }
  }
}
