// Ref: https://www.twilio.com/docs/video/build-js-video-application-recommendations-and-best-practices#testing-the-microphone-and-camera
import { createAudioMeter } from './volume-meter'

const AudioContext = window.AudioContext || window.webkitAudioContext
const audioContext = AudioContext ? new AudioContext() : null

async function pollAudioLevel(mediaStream, onLevelChanged) {
  if (!audioContext) {
    return
  }

  // Due to browsers' autoplay policy, the AudioContext is only active after
  // the user has interacted with your app, after which the Promise returned
  // here is resolved.
  await audioContext.resume()

  // Connect the LocalAudioTrack's media source to the analyser.
  const stream = new MediaStream(mediaStream.getAudioTracks())
  const source = audioContext.createMediaStreamSource(stream)
  const meter = createAudioMeter(audioContext)
  source.connect(meter)

  let level = null

  // Periodically calculate the audio level from the captured samples,
  // and if changed, call the callback with the new audio level.
  requestAnimationFrame(function checkLevel() {
    const newLevel = meter.volume
    if (!meter.checkClipping()) {
      if (level !== newLevel) {
        level = newLevel
        onLevelChanged(level)
      }
    }

    // Continue calculating the level only if the audio track is live.
    if (mediaStream.getAudioTracks()[0].readyState === 'live') {
      requestAnimationFrame(checkLevel)
    } else {
      meter.shutdown()
      requestAnimationFrame(() => onLevelChanged(0))
    }
  })
}

export default pollAudioLevel
