import { ILocalCallback, ISetLocalParams } from '@/types/interfaces/track.interface'
import { createLocalTracks } from 'twilio-video'
import { pollAudioLevel } from './poll-audio-level-twilio'
export const createPreviewLocalTracks = (contraints?: MediaStreamConstraints) => {
  return createLocalTracks(contraints)
}

export const setLocalTrackElement = (data: ISetLocalParams, callback: ILocalCallback) => {
  const { localTrackRef, localTwilioTracks: localTracks, option } = data
  if (localTracks && localTrackRef) {
    while (localTrackRef?.firstChild) localTrackRef?.removeChild(localTrackRef?.firstChild)

    localTracks.forEach((track) => {
      if (option?.haveVideo && track.kind === 'video') {
        const mediaHtml = track.attach()
        localTrackRef?.appendChild(mediaHtml)
        track.enable(option?.cameraOn)
      }

      if (option?.haveAudio && track.kind === 'audio') {
        track.enable(option?.microphoneOn)

        pollAudioLevel(track, (level: number) => {
          callback(level)
        })
      }
    })
  }
}
