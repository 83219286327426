<script setup lang="ts">
import DiagnosticSection from '@/components/video-diagnostic/DiagnosticSection.vue'
import TipsQualityBox from '@/components/video-diagnostic/TipsQualityBox.vue'
import { Stage, QualityScore } from '@/types/enums/video-diagnostic.enum'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

interface PoorQualityTestEmits {
  (e: 'nextStage'): void
  (e: 'updateToggle'): void
}

const props = defineProps({
  stage: {
    type: Number,
    required: true,
  },
  totalQualityScore: {
    type: Number,
    required: true,
  },
})
const i18n = useI18n()

const emit = defineEmits<PoorQualityTestEmits>()

const qualityScore = computed(() =>
  props.totalQualityScore === QualityScore.Suboptimal ? 'is-poor' : 'is-bad',
)
const qualityDetail = computed(() =>
  props.totalQualityScore === QualityScore.Suboptimal ? 'suboptimal' : 'degraded',
)
const qualityDetail2 = computed(() =>
  props.totalQualityScore === QualityScore.Suboptimal ? 'could be better' : 'will be poor',
)

const displayQualityScore = computed(() => {
  const color = qualityScore.value === 'is-poor' ? 'text-yellow-500' : 'text-red-500'
  return `<span class="${color}">${i18n.t(
    `video-diagnostic.poor-quality-status.${qualityScore.value}`,
  )}</span>`
})

const displayQualityDetailcolore = computed(() => {
  const color = qualityDetail.value === 'suboptimal' ? 'text-yellow-500' : 'text-red-500'
  return `<strong class="${color}">${i18n.t(
    `video-diagnostic.poor-quality-detail-status.${qualityDetail.value}`,
  )}</strong>`
})
</script>

<template>
  <DiagnosticSection
    :id="Stage.QUALITY"
    :class="
      stage === Stage.QUALITY || stage === Stage.RESULTS
        ? 'block'
        : 'hidden md:block opacity-30 pointer-events-none'
    "
  >
    <template #default>
      <div class="row-span-1 text-sm sm:text-base lg:text-sm xl:text-base">
        <h1 class="font-bold text-xl sm:text-4xl lg:text-xl xl:text-4xl">
          <SvgIcon
            class="icon-container"
            :icon="totalQualityScore === QualityScore.Suboptimal ? 'warning-sign' : 'error-sign'"
            :inherit-fill="true"
            height="24"
          />
          <span
            v-html="$t('video-diagnostic.poor-quality', { qualityScore: displayQualityScore })"
          ></span>
        </h1>
        <div class="py-4 sm:py-10 lg:py-4 xl:py-10">
          <span
            v-html="
              $tc('video-diagnostic.poor-quality-detail', {
                qualityDetail: displayQualityDetailcolore,
                qualityDetail2: qualityDetail2,
              })
            "
          ></span>
          <SvgIcon :icon="'info'" :inherit-fill="true" height="16" @click="emit('updateToggle')" />
        </div>
      </div>

      <TipsQualityBox />

      <div class="row-span-1 text-sm sm:text-base lg:text-sm xl:text-base">
        <div :class="{ 'opacity-30 pointer-events-none': stage === Stage.RESULTS }" class="flex">
          <button
            class="text-white border-2 rounded-md border-white bg-primary-color py-2 px-4"
            type="button"
            @click="emit('nextStage')"
          >
            {{ $t('video-diagnostic.ok') }}
          </button>
          <button
            class="skip-button rounded-md py-2 px-4 ml-4"
            type="button"
            @click="emit('nextStage')"
          >
            {{ $t('video-diagnostic.skip-for-now') }}
          </button>
        </div>
      </div>
    </template>
  </DiagnosticSection>
</template>

<style lang="scss" scoped>
.bg-primary-color {
  background-color: #f67519;
}

.skip-button {
  color: #f67519;
  background-color: transparent;
  &:hover {
    background-color: #f675192a;
    transition: 0.4s;
  }
}

button.bg-white {
  &:hover {
    background-color: #32323209;
    transition: 0.4s;
  }
  &:disabled {
    background-color: inherit;
    color: rgba(0, 0, 0, 0.26);
  }
}

::v-deep(.icon-container svg) {
  display: inline-block !important;
  margin-bottom: 1rem;
}
</style>
