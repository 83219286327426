import Avatar from '@/components/common/Avatar.vue'
import AvatarStack from '@/components/common/AvatarStack.vue'
import BetaTag from '@/components/common/BetaTag.vue'
import Checkbox from '@/components/common/Checkbox.vue'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import DoubleBounceLoader from '@/components/common/DoubleBounceLoader.vue'
import Popup from '@/components/common/Popup.vue'
import Tabs from '@/components/common/Tabs.vue'
import RoomLayout from '@/layouts/RoomLayout.vue'
import Toast from '@/components/common/Toast.vue'
import SvgIcon from '@/components/common/SvgIcon.vue'
import SelectOption from '@/components/common/SelectOption.vue'
import VisualCompartment from '@/components/compartment-element/VisualCompartment.vue'
import LoadingProcess from '@/components/checkup/LoadingProcess.vue'
import Language from '@/components/room/Language.vue'
import Notifications from 'notiwind'
import Popper from 'vue3-popper'
import { App } from 'vue'
import LanguageDropdownVue from '../components/room/LanguageDropdown.vue'
import Loading from '../components/loading/Loading.vue'

// register global component
const registerGlobalComponent = (app: App) => {
  app.use(Notifications)
  app.component('Avatar', Avatar)
  app.component('AvatarStack', AvatarStack)
  app.component('BetaTag', BetaTag)
  app.component('DoubleBounceLoader', DoubleBounceLoader)
  app.component('SvgIcon', SvgIcon)
  app.component('Toast', Toast)
  app.component('Popup', Popup)
  app.component('SelectOption', SelectOption)
  app.component('Checkbox', Checkbox)
  app.component('Tabs', Tabs)
  app.component('VisualCompartment', VisualCompartment)
  app.component('Language', Language)
  app.component('Popper', Popper)
  app.component('LanguageDropdown', LanguageDropdownVue)
  app.component('Loading', Loading)
  app.component('LoadingProcess', LoadingProcess)

  // Layout
  app.component('DefaultLayout', DefaultLayout)
  app.component('RoomLayout', RoomLayout)
}

export default registerGlobalComponent
