<script setup lang="ts"></script>

<template>
  <div class="beta-icon">Beta</div>
</template>

<style lang="scss" scoped>
.beta-icon {
  font-size: 10px;
  padding: 0px 4px;
  border-radius: 6px;
  background: $warning;
  color: white;
  text-transform: uppercase;
  @include font-weight(regular);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
