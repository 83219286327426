<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import moment from 'moment-timezone'
import { COACH_ATTEND_MINUTE } from '@/data/room'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  isAttend: {
    type: Boolean,
    default: false,
  },
  startTime: {
    type: [String, Date],
    default: '',
  },
})

const { t } = useI18n()
const isOpen = ref(false)
const isClickConfirm = ref(false)

const open = () => {
  isOpen.value = true
}

const close = () => {
  isOpen.value = false
}

const confirm = () => {
  isClickConfirm.value = true
  close()
}

const countdown = () => {
  /**
   * if coach attend do nothing
   */
  if (props.isAttend) return
  const lateTime = moment(props.startTime).add(COACH_ATTEND_MINUTE.LATE, 'minute')
  const absentTime = moment(props.startTime).add(COACH_ATTEND_MINUTE.ABSENT, 'minute')
  /**
   * student attend before late time of class or in range of late time (ex. 5-9.59 min)
   * counting waiting for coach join room
   */
  const isBeforeLateTime = moment().isBefore(lateTime)
  const isRangeOfLate = moment().isBetween(lateTime, absentTime, undefined, '[)')
  if (isBeforeLateTime || isRangeOfLate) {
    const countingAgain = setInterval(() => {
      const isAbsentTime = moment().isSameOrAfter(absentTime)
      const isRangeOfLate = moment().isBetween(lateTime, absentTime, undefined, '[)')
      if (isAbsentTime || props.isAttend) {
        close()
        clearInterval(countingAgain)
      } else if (isRangeOfLate) {
        if (isClickConfirm.value) {
          clearInterval(countingAgain)
        } else {
          open()
        }
      }
    }, 1000)
  } else {
    /**
     * student attend after absent time of class (for coach) and coach not join room
     */
    close()
  }
}

watch(
  () => props.isAttend,
  () => {
    if (props.isAttend) {
      close()
    }
  },
)

onMounted(() => {
  countdown()
})
</script>

<template>
  <Popup v-if="startTime" :title="t('coach-attend.late-title')" :open="isOpen" @close="confirm">
    <span class="text-base text-content py-3"
      >{{ t('coach-attend.late-content') }}
      <SvgIcon icon="crying-face" height="20" />
    </span>
    <small class="text-sm text-content --danger py-3"
      ><i>**{{ t('coach-attend.late-ps-content') }}</i></small
    >
    <template #footer>
      <div class="flex justify-center">
        <button
          type="button"
          class="btn btn-primary text-white text-base w-full ml-2"
          @click="confirm"
        >
          {{ $t('coach-attend.late-close-btn') }}
        </button>
      </div>
    </template>
  </Popup>
</template>

<style lang="scss" scoped>
.text-content {
  color: #4a4a4a;
  &.--danger {
    color: rgba(244, 54, 60, 1);
  }
}
</style>
