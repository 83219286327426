<script setup lang="ts">
import ConnectionSuccess from '@/components/zoom-diagnostic/ConnectionSuccess.vue'
import ConnectionFailed from '@/components/zoom-diagnostic/ConnectionFailed.vue'
import LoadingBox from '@/components/zoom-diagnostic/Loading.vue'
import { Stage, Topic } from '@/types/enums/zoom-diagnostic.enum'
import { computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { IUpdateTopic } from '@/types/interfaces/video-diagnostic.interface'
import { connectionProgress, connectionFailed } from '@/composables/zoom-diagnostic/useConnection'

interface ConnectivityEmits {
  (e: 'nextStage', value: number): void
  (e: 'inprogress'): void
  (e: 'updateTopic', value: IUpdateTopic): void
}

const props = defineProps({
  stage: {
    type: Number,
    required: true,
  },
})

const i18n = useI18n()

const emit = defineEmits<ConnectivityEmits>()

const header = computed(() => i18n.t('zoom-diagnostic.hang-tight'))
const message = computed(() => i18n.t('zoom-diagnostic.hang-tight-detail'))

const inprogress = computed(() => connectionProgress.value)
const failed = computed(() => connectionFailed.value)

watch(inprogress, (value) => {
  if (value === false) {
    emit('inprogress')
  }
})

watch(
  () => props.stage,
  (stage, prevStage) => {
    if (stage === Stage.CONNECTIVITY) {
      emit('updateTopic', { topic: Topic.CONNECTIVITY, active: true })
    }
    if (prevStage === Stage.CONNECTIVITY && stage === Stage.AUDIO_TEST) {
      emit('updateTopic', { topic: Topic.CONNECTIVITY, active: false })
    }
  },
)
</script>

<template>
  <LoadingBox v-if="inprogress" :stage="stage" :header="header" :message="message"></LoadingBox>
  <ConnectionSuccess
    v-else-if="!failed"
    :stage="stage"
    @next-stage="emit('nextStage', Stage.RESULTS)"
  ></ConnectionSuccess>
  <ConnectionFailed
    v-else
    :stage="stage"
    @next-stage="emit('nextStage', Stage.RESULTS)"
  ></ConnectionFailed>
</template>

<style lang="scss" scoped>
thead > tr {
  background-color: #32323209;
}

::v-deep(svg) {
  display: inline-block !important;
  margin-bottom: 0.4rem;
}
</style>
