export enum HelpAction {
  RELOAD = 'reload',
  CHANGE_MAIN_ENGINE = 'change_main_engine',
  CHANGE_FALLBACK_ENGINE = 'change_fallback_engine',
  CHANGE_WHEREBY = 'change_whereby',
  CANNOT_SEE_DOC = 'cannot_see_document',
  BLUETOOTH_PROBLEM = 'bluetooth_problem',
  CANNOT_SEE_COACH = 'cannot_see_coach',
  COACH_CANT_HEAR_STUDENT = 'coach_cannot_hear_student',
  STUDENT_CANT_HEAR_COACH = 'student_cannot_hear_coach',
  VIDEO_PROBLEM = 'video_problem',
  CANNOT_SEE_STUDENT = 'cannot_see_student',
}

export enum SolveAction {
  RELOAD_ROOM = 'reload_room',
  MOVE_ROOM = 'move_room',
  SHARE_SCREEN = 'share_screen',
  MUTE_CAMERA = 'mute_camera',
  OPEN_ORIGINAL_MATERIAL = 'open_original_material',
}

export enum SubmitType {
  CANCEL = 'cancel',
  CONFIRM = 'confirm',
}

export enum HelpRole {
  SENDER = 'sender', //student
  RECEIVER = 'receiver', //coach
}

export enum HelpResolved {
  YES = 1,
  NO = 0,
}
