import { io, Socket } from 'socket.io-client'
import { inject, InjectionKey, provide } from 'vue'
import { BACKEND_BASE_URL } from '@/composables/api'

/**
 * declear Symbol for injection key socket
 */
export const DocumentSocketSymbol: InjectionKey<Socket> = Symbol()

/**
 * ***************************************
 *      exported socket interface
 * ***************************************
 */
export interface IWsInitDocument {
  documentId: string
  authorId: string
  email: string
  followMe: boolean
}

export interface IWsJoinDocument extends IWsInitDocument {
  socket: Socket
}
export interface IUserDocState {
  id: string
  email: string
  followMe: boolean
  writableDoc?: boolean
}

export interface IWsUpdateControllerPage {
  page?: number
  authorId: string
  documentId: string
}

export interface IUpdatePermissionDoc {
  authorId: string
  documentId: string
  targetId?: string
  writable?: boolean
}

/**
 * **********************************************
 *      provide and inject document socket
 * **********************************************
 */

export const provideDocumentSocket = () => {
  const socket = io(BACKEND_BASE_URL, { withCredentials: true })
  provide(DocumentSocketSymbol, socket)
  return socket
}

/**
 * @NOTE inject MUST be used in child component
 * and MUST be called synchronously
 * ex.
 *  - do not use v-if with jnject component
 *  - do not use asynchronous before inject
 *
 * if you got undefined just read NOTE again
 */
export const injectDocumentSocket = () => {
  const socket = inject<Socket>(DocumentSocketSymbol)
  if (!socket) {
    throw new Error('Socket not properly injected in document')
  }
  return socket
}

/**
 * *********************************
 *      init document socket
 * *********************************
 */

export const initDocumentSocket = () => io(BACKEND_BASE_URL, { withCredentials: true })
