<script setup lang="ts">
import { computed, PropType } from 'vue'
interface IAvatar {
  avatar: string
  label?: string
}

const props = defineProps({
  avatars: {
    type: Array as PropType<IAvatar[]>,
    default: () => [],
  },
  limit: {
    type: Number,
    default: 3,
  },
  size: {
    type: Number,
    default: 48,
  },
  hasDot: {
    type: Boolean,
    default: false,
  },
  showAvatarList: {
    type: Boolean,
    default: false,
  },
})
const width = computed(() => `${props.size}px`)
const height = computed(() => `${props.size}px`)
const limitAvatars = computed(() => props.avatars.slice(0, props.limit))
const isMore = computed(() => props.avatars.length > props.limit)
const avatarListLabel = computed(() => limitAvatars.value.map(({ label }) => label).join(', '))
const nMoreAvatar = computed(() => (isMore.value ? props.avatars.length - props.limit : 0))
</script>

<template>
  <div class="avatar-stack-wrapper">
    <div class="avatar-stack">
      <Avatar
        v-for="(user, index) in limitAvatars"
        :key="index"
        :size="size"
        :avatar="user.avatar"
        has-dot
      />

      <div v-if="isMore" class="avatar-more avatar-wrapper">
        <span class="is-more">
          <span class="inner">
            <span>+{{ nMoreAvatar }}</span>
          </span>
        </span>
      </div>
    </div>
    <div v-if="showAvatarList" class="avatar-list-content">
      <template v-if="!$slots['avatar-list']">
        <span class="mr-1">{{ avatarListLabel }}</span>
        <span v-if="isMore">And {{ nMoreAvatar }} more</span>
      </template>
      <template v-else>
        <slot
          name="avatar-list"
          :props="{ avatarListLabel, nMoreAvatar }"
          :avatar-list-label="avatarListLabel"
          :n-more-avatar="nMoreAvatar"
        />
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.avatar-stack {
  display: flex;

  .avatar-wrapper:not(:first-child) {
    $var: 14;

    @for $i from 1 through 99 {
      &:nth-child(#{$i}) {
        margin-left: -#{$var}px;
      }
    }
  }
  .avatar-more {
    position: relative;
    width: v-bind(width);
    height: v-bind(height);
    display: flex;
    align-items: center;
    justify-content: center;

    background: $gs-4;
    border-radius: 50%;
    font-size: 1rem;
    @include font-weight(regular);
  }
}
.avatar-list-content {
  padding: 1rem;
  font-size: 1rem;
}
</style>
