<script setup lang="ts">
import DiagnosticSection from '@/components/video-diagnostic/DiagnosticSection.vue'
import { Stage } from '@/types/enums/video-diagnostic.enum'
import { useRouter } from 'vue-router'

interface ConnectionFailedEmits {
  (e: 'updateToggle'): void
  (e: 'nextStage'): void
}

const router = useRouter()

defineProps({
  stage: {
    type: Number,
    required: true,
  },
})

const emit = defineEmits<ConnectionFailedEmits>()

const refreshPage = () => {
  router.go(0)
}
</script>

<template>
  <DiagnosticSection
    :id="Stage.CONNECTIVITY"
    :class="
      stage === Stage.CONNECTIVITY || stage === Stage.RESULTS
        ? 'block'
        : 'hidden md:block opacity-30 pointer-events-none'
    "
  >
    <template #default>
      <div class="row-span-1 text-sm sm:text-base lg:text-sm xl:text-base">
        <h1 class="font-bold text-xl sm:text-4xl lg:text-xl xl:text-4xl">
          <SvgIcon class="icon-container" :icon="'error-sign'" :inherit-fill="true" height="24" />
          {{ $t('video-diagnostic.connection-fail') }}
        </h1>
        <p class="py-4 sm:py-10 lg:py-4 xl:py-10">
          {{ $t('video-diagnostic.connection-fail-detail') }}
        </p>
      </div>

      <div class="max-w-sm row-span-2 text-sm sm:text-base lg:text-sm xl:text-base">
        <div
          class="p-6 h-fit bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700"
        >
          <p class="mb-8 xl:mb-14">
            {{ $t('video-diagnostic.connection-fail-conduct-viedo-call') }}
          </p>
          <p>
            {{ $t('video-diagnostic.learn') }}
            <a href="https://www.twilio.com/docs/video/ip-addresses" target="_blank">
              {{ $t('video-diagnostic.how-to-confugure') }}</a
            >
          </p>
        </div>
      </div>

      <div class="row-span-1 text-sm sm:text-base lg:text-sm xl:text-base">
        <p class="font-bold py-4 sm:py-10 lg:py-4 xl:py-10">
          {{ $t('video-diagnostic.update-internet') }}
        </p>
        <div :class="{ 'opacity-30 pointer-events-none': stage === Stage.RESULTS }" class="flex">
          <button
            class="text-white border-2 rounded-md border-white bg-primary-color py-2 px-4"
            type="button"
            @click="refreshPage"
          >
            {{ $t('video-diagnostic.refresh-page') }}
          </button>
          <button
            class="skip-button rounded-md py-2 px-4 ml-4"
            type="button"
            @click="emit('nextStage')"
          >
            {{ $t('video-diagnostic.skip-for-now') }}
          </button>
        </div>
      </div>
    </template>
  </DiagnosticSection>
</template>

<style lang="scss" scoped>
.bg-primary-color {
  background-color: #f67519;
}

.skip-button {
  color: #f67519;
  background-color: transparent;
  &:hover {
    background-color: #f675192a;
    transition: 0.4s;
  }
}

button.bg-white {
  &:hover {
    background-color: #32323209;
    transition: 0.4s;
  }
  &:disabled {
    background-color: inherit;
    color: rgba(0, 0, 0, 0.26);
  }
}

.h-fit {
  height: fit-content;
}

.w-fit {
  width: fit-content;
}

::v-deep(.icon-container svg) {
  display: inline-block !important;
  margin-bottom: 1rem;
}

a {
  text-decoration: underline !important;
  &:hover {
    text-decoration: underline !important;
  }
}
</style>
