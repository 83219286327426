import { computed, onMounted, reactive, toRefs } from 'vue'

import GphApiClient from 'giphy-js-sdk-core'
import { encouragementSticker, rewardSticker, specialSticker } from '@/data/sticker'
import useSpaceApi from '../api/useSpaceApi'
import moment from 'moment'
import { sortSpecialStickerDate } from '../../helpers/sticker.helper'
import { ISpecialSticker } from '../../types/interfaces/sticker.interface'
import useSocketEvent from './useSocketEvent'

interface StickerObjectInterFace {
  stickerPath: string
  fullscreen: boolean
}

interface UseStickerState {
  stickers: any
  categories: string[]
  activeCategory: string
  showingSticker: boolean
  showingFullScreen: boolean
  stickerPathToShow?: string
}

const DISPLAY_DURATION = 2500
export default function useSticker() {
  const state = reactive<UseStickerState>({
    stickers: [],
    categories: ['encouragement', 'reward', 'special'],
    activeCategory: 'encouragement',
    showingSticker: false,
    showingFullScreen: false,
    stickerPathToShow: undefined,
  })
  const { socket } = useSocketEvent()
  const { saveStickers } = useSpaceApi()

  const fullscreen = computed(
    () => state.activeCategory === 'reward' || state.activeCategory === 'special',
  )

  const initSticker = async () => {
    const client = GphApiClient('HMzfI9AMifKbDVaLK9ghM8M03Kx1ZR3W')
    const today = moment()
    const encouragement = await client
      .gifsByIDs({
        ids: encouragementSticker,
      })
      .catch((err: any) => console.log(err))
    state.stickers.encouragement = encouragement.data

    const reward = await client
      .gifsByIDs({
        ids: rewardSticker,
      })
      .catch((err: any) => console.log(err))
    state.stickers.reward = reward.data
    const firstSpecialSticker = specialSticker.shift()
    const specialStickerOrderByDate = specialSticker.sort((i: ISpecialSticker) =>
      sortSpecialStickerDate({ date: today, stickerList: i }),
    )
    const special = await client
      .gifsByIDs({
        ids: [firstSpecialSticker]
          .concat(specialStickerOrderByDate)
          .map((i) => (i ? i.ids : undefined)),
      })
      .catch((err: any) => console.log(err))
    state.stickers.special = special.data
  }

  let prevTimeoutId: NodeJS.Timeout

  socket.on('stickerPop', (stickerObject: StickerObjectInterFace) => {
    displaySticker(stickerObject)
  })
  const displaySticker = (stickerObject: StickerObjectInterFace) => {
    state.showingFullScreen = false
    // const { stickerPath, fullscreen } = stickerObject;
    if (stickerObject?.stickerPath) {
      if (prevTimeoutId) clearTimeout(prevTimeoutId)
      state.stickerPathToShow = stickerObject?.stickerPath
      state.showingSticker = true
      state.showingFullScreen = stickerObject?.fullscreen

      prevTimeoutId = setTimeout(() => {
        state.showingSticker = false
        state.showingFullScreen = false
      }, DISPLAY_DURATION)
    }
  }

  const sendSticker = async (stickerPath: string) => {
    const emitData = { stickerPath, fullscreen: fullscreen.value }
    socket?.emit('stickerPop', emitData)
    await saveStickers({
      stickerPath,
      category: state.activeCategory,
    })
  }

  const changeCategory = (category: string) => {
    state.activeCategory = category
  }

  onMounted(() => {
    initSticker()
  })

  return { ...toRefs(state), fullscreen, changeCategory, sendSticker }
}
