<script setup lang="ts">
import { WELCOME_STUDENT_MESSAGES } from '@/data/room'
import { useRoomStore } from '@/stores'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  isInvisible: {
    type: Boolean,
    default: false,
  },
})

const roomStore = useRoomStore()
const { locale, t } = useI18n()

const language = computed(() => {
  const isAdult = roomStore.room.metaData?.engderType === 'adult'
  const isThai = roomStore.room.metaData?.country === 'th'
  const isVietnam = roomStore.room.metaData?.country === 'vn'
  if (isAdult && isThai) return 'en'
  else {
    switch (locale.value) {
      case 'vn':
        return isThai ? 'en' : 'vn'
      case 'th':
        return isVietnam ? 'en' : 'th'
      default:
        return 'en'
    }
  }
})

const message = computed(() => {
  if (props.isInvisible) return `Please click '${btnText.value}'`
  const randomNumber = Math.floor(Math.random() * WELCOME_STUDENT_MESSAGES.length)
  return WELCOME_STUDENT_MESSAGES[randomNumber][language.value]
})
const emit = defineEmits(['update:modelValue', 'join-room'])

const btnText = computed(() => (props.isInvisible ? 'Join speaker only' : t('start-your-class')))
const close = () => {
  emit('update:modelValue', false)
  emit('join-room')
}
</script>

<template>
  <Popup :open="modelValue" :title="`${$t('message-from-globish')}`" width="560" @close="close">
    <div class="text-lg text-content p-4">{{ message }}</div>
    <template #footer>
      <div class="coach-popup-footer">
        <button type="button" class="btn btn-primary text-lg" @click="close">
          {{ btnText }}
        </button>
      </div>
    </template>
  </Popup>
</template>

<style lang="scss" scoped>
.text-content {
  color: #4a4a4a;
  text-align: center;
}
.coach-popup-footer {
  display: flex;
  justify-content: center;
  border-top: none;
}
</style>
