<script setup lang="ts">
import useSpaceApi from '@/composables/api/useSpaceApi'
import useAuth from '@/composables/api/useAuth'
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  exitPath: {
    type: String,
    default: '',
  },
})
const emit = defineEmits(['update:modelValue', 'click-leave'])
const { saveLogsLeaveButton } = useSpaceApi()
const { getAccessInfo } = useAuth()
const close = () => {
  emit('update:modelValue', !props.modelValue)
}
const leave = async () => {
  const { roomId, participantId } = getAccessInfo()
  await saveLogsLeaveButton(roomId || '', participantId || '')
  close()
  window.location.href = props.exitPath
}
</script>

<template>
  <Popup :title="`${$t('leave')}`" :open="modelValue" @close="close">
    <div class="text-base leave-container">
      {{ $t('leave-message') }}
      {{ exitPath }}
    </div>
    <template #footer>
      <div class="footer-setting">
        <button type="button" class="btn btn-leave bg-red-500" @click="leave">
          {{ $t('leave-confirm-button') }}
        </button>

        <button type="button" class="btn btn-tertiary" @click="close">
          {{ $t('leave-cancel') }}
        </button>
      </div>
    </template>
  </Popup>
</template>

<style lang="scss" scoped>
.leave-container {
  color: #4a4a4a;
  padding: 1rem;
}

.footer-setting {
  .btn {
    width: 100%;
    font-size: 1.1rem;
    &.btn-leave {
      color: white;
      margin-bottom: 1rem;
    }
  }

  @include media-breakpoint-up-custom(700px) {
    .btn {
      width: 50%;
      &.btn-leave {
        order: 1;
        margin-bottom: 0;
        margin-left: 1rem;
      }
    }
  }
}
</style>
