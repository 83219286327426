import { getDevices } from '@/helpers/device.helper'
import { ISelectOption } from '@/types/interfaces/common.interface'
import { reactive, toRefs, onBeforeUnmount } from 'vue'

interface IUseDevices {
  audioInputDevices: ISelectOption[]
  audioOutputDevices: ISelectOption[]
  videoInputDevices: ISelectOption[]
}

export function useDevices() {
  const state = reactive<IUseDevices>({
    audioInputDevices: [],
    audioOutputDevices: [],
    videoInputDevices: [],
  })

  const setDevices = async () => {
    const data = await getDevices()
    state.audioInputDevices = data.audioInputDevices
    state.videoInputDevices = data.videoInputDevices
    state.audioOutputDevices = data.audioOutputDevices
  }

  navigator.mediaDevices.addEventListener('devicechange', setDevices)
  setDevices()

  onBeforeUnmount(() => {
    navigator.mediaDevices.removeEventListener('devicechange', setDevices)
  })

  return {
    ...toRefs(state),
  }
}
