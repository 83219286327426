<script setup lang="ts">
import { computed, onMounted, PropType, onUnmounted } from 'vue'
import DocController from '@/components/document/DocController.vue'
import useWebViewer from '@/composables/document/useDocWebViewer'
import { TParticipantInfo } from '@/types/transforms/participant.transform'

/**
 * define props
 */
const props = defineProps({
  documentId: {
    type: String,
    required: true,
  },
  participants: {
    type: Array as PropType<TParticipantInfo[]>,
    required: true,
  },
  participantInfo: {
    type: Object as PropType<TParticipantInfo>,
    required: true,
  },
  hasDocPath: {
    type: Boolean,
    default: false,
  },
  isCoachRole: {
    type: Boolean,
    default: false,
  },
})
/**
 * use web viewer
 */
const {
  viewerRef,
  onToggleFollowMe,
  activeUsers,
  toggleReadonly,
  toggleFollowMe,
  toggleWritable,
  controlBy,
  initWebViewer,
  socket,
  isConnected,
} = useWebViewer()

const emit = defineEmits(['toggle-original-material'])

/**
 * computed
 */
const activeParticipants = computed(() => {
  /**
   * find all active user and map to new type
   * if not active user return undefined and filter out
   * then sort by move the owner user to first element
   */
  return (props.participants || [])
    .map((i) => {
      const isActiveUser = activeUsers.value.find((u) => u.email === i.email)
      const isOwner = i.email === props.participantInfo.email
      // takes only active user and not owner
      if (isActiveUser && !isOwner) {
        return { ...i, readonly: isActiveUser.readonly }
      }
      return undefined
    })
    .filter((i) => !!i?.email)
})
const canToggleWritable = computed(() => {
  return !!props.participantInfo.capabilities.writableControlDoc
})
const canToggleFollowing = computed(() => {
  return !!props.participantInfo.capabilities.followDoc
})
const canOpenOriginalDoc = computed(() => {
  return props.hasDocPath && (props.isCoachRole || !isConnected.value)
})

const showDocController = computed(() => {
  return canToggleWritable.value || canToggleFollowing.value || canOpenOriginalDoc.value
})

// disconnect socket
onUnmounted(() => {
  socket.value.disconnect()
})

/**
 * init web viewer
 */
onMounted(async () => {
  await toggleInitWebViewer()
})

const toggleInitWebViewer = async () => {
  await initWebViewer({
    documentId: props.documentId,
    email: props.participantInfo.email,
    canToggleWritable: props.participantInfo.capabilities.writableControlDoc,
    canToggleFollowing: props.participantInfo.capabilities.followDoc,
  })
}
</script>

<template>
  <div class="doc-wrapper" :class="!showDocController ? 'disable-control' : ''">
    <DocController
      v-if="showDocController"
      v-model:control-by="controlBy"
      v-model:toggle-follow-me="toggleFollowMe"
      v-model:toggle-writable="toggleWritable"
      :active-participants="activeParticipants"
      :can-toggle-writable="canToggleWritable && isConnected"
      :can-toggle-following="canToggleFollowing && isConnected"
      :has-doc-path="hasDocPath"
      :can-open-original-doc="canOpenOriginalDoc"
      @toggle-readonly="toggleReadonly"
      @on-toggle-follow="onToggleFollowMe"
      @toggle-original-material="emit('toggle-original-material')"
    />
    <div v-show="isConnected" id="viewer" ref="viewerRef"></div>
    <div v-if="!isConnected" class="connection-fail-wrapper">
      <div class="m-auto">
        <h1 class="title font-bold text-xl sm:text-4xl lg:text-xl xl:text-4xl">
          <SvgIcon :icon="'error-sign'" :inherit-fill="true" height="24" />
          {{ $t('video-diagnostic.connection-fail') }}
        </h1>
        <button
          class="text-white border-2 rounded-md border-white bg-primary-color py-2 px-4"
          type="button"
          @click="toggleInitWebViewer"
        >
          {{ $t('join-fail-reload-button') }}
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.doc-wrapper {
  width: 100%;
  height: 100%;

  &.disable-control {
    #viewer {
      width: 100%;
      height: 100%;
    }
  }

  #viewer {
    width: 100%;
    height: calc(100% - 50px);
  }
}

.connection-fail-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .title {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
  }
}
</style>
