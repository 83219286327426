<script setup lang="ts">
import { computed, onMounted, PropType, ref, watch } from 'vue'

import useFirestore, { IParticipant, ISendMessage } from '@/composables/useFirestore'
import { IRoom } from '@/types/interfaces/room.interface'
import { IParticipantInfo } from '@/types/interfaces/participant.interface'
import Message from './Message.vue'
import { IPreviewMessage } from '@/types/interfaces/common.interface'

interface ChatboxEmits {
  (e: 'unread-updated', value: number): void
  (e: 'new-messages', value?: IPreviewMessage): void
  (e: 'close'): void
}
const props = defineProps({
  enableChat: {
    type: Boolean,
    default: false,
  },
  room: {
    type: Object as PropType<IRoom>,
    required: true,
  },
  localParticipant: {
    type: Object as PropType<IParticipantInfo>,
    required: true,
  },
})

const emit = defineEmits<ChatboxEmits>()
/**
 * Use Composible
 */
const {
  messages,
  chatboxRef,
  isSending,
  setRoomIfNotExist,
  addParticipant,
  onSnapshotMessage,
  sendMessage,
  uploadFileURL,
  deleteMessage,
  unreadMessages,
  newMessages,
} = useFirestore()

const inputFileRef = ref<HTMLInputElement>()
const inputMessage = ref('')
const isUploading = ref(false)

const roomRefId = ref<string>('')
const currentUser = computed<IParticipant>(() => {
  return {
    name: props.localParticipant.name,
    email: props.localParticipant.email,
    avatar: props.localParticipant.avatar,
    // TODO: change role from capabilities???
    role: 'student',
  }
})

watch(
  () => unreadMessages.value,
  (unread) => {
    if (unread) {
      emit('unread-updated', unread.length)
    }
  },
)

watch(
  () => newMessages?.value,
  () => {
    if (newMessages?.value) {
      emit('new-messages', newMessages.value)
    }
  },
  { deep: true },
)

/**
 * Watch
 */
watch(
  () => props.enableChat,
  (nVal) => {
    if (nVal) {
      setTimeout(() => {
        if (chatboxRef?.value) {
          chatboxRef.value.scrollTop = chatboxRef?.value?.scrollHeight
        }
      }, 50)
    }
  },
)
// watch(isSending, (nVal) => {
//   if (nVal) {
//     setTimeout(() => {
//       if (chatboxRef?.value) {
//         chatboxRef.value.scrollTop = chatboxRef?.value?.scrollHeight
//       }
//     }, 50)
//   }
// })

/**
 * Methods
 */
const isSelfMessage = (email: string) => {
  return email === (props.localParticipant.email || 'student@gmail.com')
}
const isShowAvatar = (sendByEmail: string, idx: number) => {
  return idx === 0 || sendByEmail !== messages.value[idx - 1].send_by.email
}
const onSendMessage = async () => {
  if (inputMessage.value && roomRefId.value) {
    try {
      const params: ISendMessage = {
        roomRefId: roomRefId.value,
        message: {
          message: inputMessage.value,
          sendBy: currentUser.value,
          fileURL: '',
          type: 'text',
        },
      }
      inputMessage.value = ''
      await sendMessage(params)
    } catch (error) {
      console.error(error)
    }
  }
}
const onImageIcon = async () => {
  if (!inputFileRef.value || !inputFileRef.value.files) return
  const file = inputFileRef.value.files[0]
  if (file?.name && roomRefId.value) {
    /**
     * upload file url
     */
    isUploading.value = true
    await uploadFileURL({
      roomRefId: roomRefId.value,
      user: currentUser.value,
      file,
    })
    isUploading.value = false
  }
}
const onDeleteMessage = async (msgId: string) => {
  if (!roomRefId.value) return
  await deleteMessage({
    roomRefId: roomRefId.value,
    messageRefId: msgId,
  })
}

/**
 * On Mounted
 */
onMounted(async () => {
  /**
   * create chat room if not exist
   */
  await setRoomIfNotExist({
    spaceRoomId: props.room.id,
    externalRoomId: props.room.externalRoomId,
    name: props.room.name,
  })

  roomRefId.value = props.room.id
  /**
   * add participant into chat room
   */
  await addParticipant({
    participant: currentUser.value,
    roomRefId: props.room.id,
  })
  /**
   * detech a listener messages
   */
  onSnapshotMessage({
    currentUser: currentUser.value,
    roomRefId: props.room.id,
  })
})
</script>

<template>
  <div v-show="enableChat" class="chatbox-container">
    <div class="chat-room">
      <h1>Chat</h1>

      <div class="close-button _pointer-on-hover" @click="emit('close')">
        <SvgIcon class="close-icon" icon="close" :inherit-fill="true" height="26"></SvgIcon>
      </div>
    </div>
    <!-- Chatbox -->
    <div ref="chatboxRef" class="chatbox" :class="isSending ? 'is-loading' : ''">
      <!-- <div v-if="messages.length === 0" class="message-empty-container">Empty Chatbox</div> -->
      <div
        v-for="(msg, idx) in messages"
        :key="msg.id"
        class="message-container"
        :class="isSelfMessage(msg.send_by.email) ? 'owned-message' : ''"
      >
        <!-- Display Avatar and Name -->
        <div
          v-if="isShowAvatar(msg.send_by.email, idx) && !isSelfMessage(msg.send_by.email)"
          class="send-by"
        >
          <!-- <img :src="msg.send_by.avatar" :alt="msg.send_by.name" /> -->
          <span class="name">{{ msg.send_by.name }}</span>
        </div>
        <!-- Display Message by type -->

        <Message
          :msg="msg"
          :is-sending="isSending && idx === messages.length - 1"
          :is-my-self="isSelfMessage(msg.send_by.email)"
          @unsend="onDeleteMessage(msg.id)"
        />
      </div>

      <Message
        v-if="isUploading"
        :msg="{ message: 'Uploading' }"
        :is-sending="isUploading"
        is-my-self
      />

      <!-- Message Loading... -->
      <!-- <div v-show="isSending" class="loading-wrapper" @click="isSending = false">
        <div class="lds-hourglass"></div>
      </div> -->
    </div>
    <!-- Input Chatbox -->
    <div class="input-chatbox">
      <div class="input-message">
        <input
          v-model="inputMessage"
          type="text"
          :placeholder="`${$t('chat-placeholder')}`"
          @keyup.enter="onSendMessage"
        />
        <button v-show="inputMessage" class="send-btn" @click="onSendMessage">
          <SvgIcon
            class="action-icon _pointer-on-hover"
            icon="send-message"
            :inherit-fill="true"
            height="26"
          ></SvgIcon>
        </button>
      </div>

      <div class="image-upload">
        <label for="file-input" class="mx-3">
          <SvgIcon
            class="action-icon _pointer-on-hover"
            icon="attach"
            :inherit-fill="true"
            height="26"
          ></SvgIcon>
        </label>
        <input
          id="file-input"
          ref="inputFileRef"
          type="file"
          accept="image/*, .pdf, .doc, .docx"
          @change="onImageIcon"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
// * {
//   box-sizing: border-box;
// }

*::-webkit-scrollbar {
  width: 8px;
  // background-color: $gs-6;
}

*::-webkit-scrollbar-thumb {
  // border: 1px solid $gs-3;
  border-radius: 6px;
  background-color: var(--bg-chat-msg);
}

.message-empty-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  // font styling
  font-weight: bold;
  color: #c2c2c2;
}
.chatbox-container {
  width: 100%;
  height: 100%;
  background: var(--bg-chat);
  border-radius: 6px 6px 0 0;
  overflow: hidden;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  color: var(--text-chat);
  // display: block;
  // background-color: #ededed;
  // border-radius: 5px;
  // max-width: 85%;
  // height: 70%;
  // width: 480px;
  // padding: 10px;
  // z-index: 99;
  @include media-breakpoint-up-custom(1200px) {
    border-radius: 6px;
  }

  --input-control-height: 50px;
  --chat-header-height: 48px;
  .chat-room {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--primary-color);
    color: white;
    padding: 8px 20px;
    @include font-weight(regular);
    height: var(--chat-header-height);
    h1 {
      font-size: 1rem;
      @include font-weight(regular);
    }
  }

  .loading-wrapper {
    display: flex;
    justify-content: flex-start;
    cursor: pointer;

    .lds-hourglass {
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;
    }
    .lds-hourglass:after {
      content: ' ';
      display: block;
      border-radius: 50%;
      width: 0;
      height: 0;
      margin: 8px;
      box-sizing: border-box;
      border: 32px solid #e9ae50;
      border-color: #e9ae50 transparent #e9ae50 transparent;
      animation: lds-hourglass 1.2s infinite;
    }
    @keyframes lds-hourglass {
      0% {
        transform: rotate(0);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
      50% {
        transform: rotate(900deg);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      }
      100% {
        transform: rotate(1800deg);
      }
    }
  }

  .chatbox {
    border: transparent solid 1px;
    border-radius: 4px;
    height: calc(100% - var(--input-control-height) - var(--chat-header-height));
    width: 100%;

    // overflow: hidden;
    // overflow-x: hidden;
    scroll-behavior: smooth;
    padding: 0.5rem;
    overflow-y: scroll;

    // &:hover {
    //   overflow: scroll;
    //   overflow-x: hidden;
    // }

    .message-container {
      display: block;

      .send-by {
        display: flex;
        margin-top: 1rem;

        .name {
          font-size: 0.8rem;
          color: $gs-3;
          padding: 5px;
        }

        // img {
        //   height: 32px;
        //   width: 32px;
        //   border-radius: 50%;
        //   object-fit: cover;
        //   object-position: 50% 50%;
        // }
      }
    }
  }

  .chatbox.is-loading {
    overflow: scroll;
    overflow-x: hidden;
    padding-right: 0;
  }

  .input-chatbox {
    display: flex;
    position: relative;
    border: 0;
    height: var(--input-control-height);
    box-shadow: 0px -1px 0 0 rgba(0, 0, 0, 0.15);
    width: 100%;

    .input-message {
      position: relative;
      display: flex;
      align-items: center;
      flex-grow: 2;

      input {
        width: 100%;
        @include font-weight(regular);
        font-size: 0.9rem;
        height: 100%;
        border: 0;
        border-radius: 0;
        padding: 0 38px 0 8px;
        &:focus {
          outline: none;
          box-shadow: 0 0 0 transparent;
          border-color: var(--primary-color);
        }
      }

      .send-btn {
        position: absolute;
        right: 0;
        padding: 5px;
        word-break: break-word;
      }
    }

    .upload-btn {
      background-color: #f09300;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      width: 12%;

      input {
        display: none;
      }

      svg.h-5.w-5 {
        width: 1.4rem;
        height: 1.4rem;
      }

      @include media-breakpoint-up-custom(700px) {
        width: 12%;

        svg.h-5.w-5 {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
  }
}

.image-upload {
  display: flex;
  align-items: center;
  border: 0;
  background: var(--bg-chat);
  box-shadow: -1px 0 0 0 $gs-5;
  height: 100%;
  color: $gs-3;
  input {
    display: none;
  }
  label {
    margin-bottom: 0;
  }
}

.input-message input {
  background: rgba(0, 0, 0, 0.03);
}
.dark {
  .input-message input {
    background: var(--bg-chat-msg);
  }
  .send-by .name {
    color: $gs-4 !important;
  }
}
</style>
