import { reactive, ref } from 'vue'

export const getUserMediaContent = reactive({
  headline: '',
  message: '',
})
export const permissions = ref(true)

export const useCheckPermissions = async () => {
  try {
    // get audio and video permissions
    const localTracks = await navigator.mediaDevices.getUserMedia({ audio: true, video: true })
    localTracks.getTracks().forEach((track) => {
      track.stop()
    })
    // The devicechange event is not fired after permissions are granted, so we fire it
    // ourselves to update the useDevices hook. The 100 ms delay is needed so that device labels are available
    // when the useDevices hook updates.
    setTimeout(() => navigator.mediaDevices.dispatchEvent(new Event('devicechange')), 500)
  } catch (error: any) {
    getUserMediaContent.headline = ''
    getUserMediaContent.message = ''

    switch (true) {
      // This error is emitted when the user or the user's system has denied permission to use the media devices
      case error?.name === 'NotAllowedError':
        getUserMediaContent.headline =
          'video-diagnostic.permission-error.not-allowed-error-headline'

        if (error.message === 'Permission denied by system') {
          // Chrome only
          getUserMediaContent.message =
            'video-diagnostic.permission-error.not-allowed-error-message'
        } else {
          getUserMediaContent.message =
            'video-diagnostic.permission-error.not-allowed-error-message2'
        }

        break

      // This error is emitted when input devices are not connected or disabled in the OS settings
      case error?.name === 'NotFoundError':
        getUserMediaContent.headline = 'video-diagnostic.permission-error.not-found-error-headline'
        getUserMediaContent.message = 'video-diagnostic.permission-error.not-found-error-message'
        break

      // Other getUserMedia errors are less likely to happen in this app. Here we will display
      // the system's error message directly to the user.
      case Boolean(error):
        getUserMediaContent.headline = 'video-diagnostic.permission-error.error-acquiring-media'
        getUserMediaContent.message = 'video-diagnostic.permission-error.something-went-wrong'
        break
    }

    permissions.value = false
    throw error
  }
}
