<script setup lang="ts">
import { computed } from 'vue'
import BackgroundSetting from '@/components/checkup/BackgroundSetting.vue'
import { useBackgroundStore, useDeviceStore, useRoomStore } from '@/stores'
import { covertVideoBgToBackgroundType } from '../../helpers/background.helper'
import useSpaceApi from '../../composables/api/useSpaceApi'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['update:modelValue', 'apply-background'])

const { saveLogsChangeBackground } = useSpaceApi()

/**
 * Store
 */
const device = useDeviceStore()
const background = useBackgroundStore()
const roomStore = useRoomStore()

/**
 * State
 */

const isChanged = computed(() => background.backgroundChanged || device.cameraOnChanged)
const engine = computed(() => roomStore.currentEngine)

/**
 * Methods
 */
const toggle = () => {
  device.cameraOn = device.maskCameraOn
  device.setStateEnableToLocal()
  emit('update:modelValue', !props.modelValue)
}

const cancel = () => {
  background.selectedBackground = background.currentBackground
  toggle()
  emit('update:modelValue', false)
}

const submit = () => {
  background.setStateBackground(background.selectedBackground)
  // Log Change Background
  const { backgroundType, imagePath } = covertVideoBgToBackgroundType(background.currentBackground)
  saveLogsChangeBackground({ engine: engine.value, backgroundType, imagePath })
  toggle()
  emit('apply-background')
}
</script>

<template>
  <Popup title="Video Effect Setting" :open="modelValue" @close="toggle">
    <template #title>
      <div class="flex items-center">
        <h3 class="text-lg leading-6 font-medium text-gray-900">Video Effect Setting</h3>
        <BetaTag class="ml-1" />
      </div>
    </template>
    <div>
      <BackgroundSetting v-if="modelValue" :engine="engine" />
    </div>
    <template #footer>
      <div class="footer-setting">
        <template v-if="isChanged">
          <button type="button" class="btn btn-tertiary" @click="cancel">Cancel</button>
          <button type="button" class="btn btn-primary" @click="submit">Apply</button>
        </template>
        <template v-else>
          <button type="button" class="btn btn-tertiary" @click="cancel">
            {{ $t('close-button') }}
          </button>
        </template>
      </div>
    </template>
  </Popup>
</template>

<style lang="scss" scoped>
.btn {
  font-size: 1.1rem;
  @include font-weight(bold);
}
</style>
