/* eslint-disable @typescript-eslint/no-empty-function */
import useSpaceApi from '@/composables/api/useSpaceApi'
import promiseHandler from '@/helpers/promise-handler'
import { isAndroidOrIOSBrowser, isIOSMobile } from '@/helpers/zoom-device.helper'
import { ConnectingLogsAction, Engine } from '@/types/enums/room.enum'
import ZoomVideo, {
  AudioOption,
  CaptureVideoOption,
  ConnectionState,
  ExecutedFailure,
  Participant,
  RecordingStatus,
  Stream,
  VideoClient,
} from '@zoom/videosdk'
import { defineStore } from 'pinia'
import UAParser from 'ua-parser-js'
import { computed, reactive, ref, toRefs } from 'vue'
import { useDeviceStore } from './device.store'
import { getDeviceEnableState } from '@/services/device-state'
import { useRoomStore } from './room.store'
import AuthState from '@/services/auth-state'
import useGChatLog from '@/composables/api/useGChatLog'
import { LogDeviceProblemAction } from '@/types/enums/log.enum'
import { useBackgroundStore } from './background.store'
import { useRoomLayoutStore } from './room-layout.store'
import { ZOOM_MY_VIDEO_ELEMENT_ID } from '@/data/room'
import { detectConnectionChanged } from '@/helpers/zoom.helper'
import { ZoomVideoError } from '@/types/enums/zoom-diagnostic.enum'
import { IDeviceProblem } from '@/types/interfaces/zoom.interface'
import { INetworkQuality } from '@/types/interfaces/zoom-quality.interface'

interface IConnectZoom {
  token: string
  roomName: string
  name: string
  password?: string
  isInvisible: boolean
  isCanvas?: boolean
}

interface ISetLocalState {
  camera?: boolean
  mic?: boolean
}

interface IUsestateState {
  connectData?: IConnectZoom
  isInRoom: boolean
  zoomClient?: typeof VideoClient
  mediaStream?: typeof Stream
  isConnecting: boolean
  isSafari: boolean
  isIosMobile: boolean
  isAndroidOrIOSBrowser: boolean
  isInvisible: boolean
  audioEncode?: boolean
  audioDecode?: boolean
  connectionState: ConnectionState
  connectingErrorMsg?: string
  isStartShareScreenWithVideoElement?: boolean

  allParticipants: Participant[]
  localParticipant?: Participant
  speakingParticipantIds: number[]
  isZoomLocalShareScreen: boolean
  shareScreenUserId?: number
  micEnabled: boolean
  cameraEnabled: boolean
  isInitZoom: boolean
  isJoinAudioRoom: boolean
  isJoiningAudioRoom: boolean
  isJoinZoomSuccess: boolean
  isFailoverSafari: boolean
  requestToShareScreen: boolean
  latestStopShared?: number
  videoProblem?: IDeviceProblem
  micProblem?: IDeviceProblem
  audioProblem?: IDeviceProblem
  networkQualities: INetworkQuality[]
}

const IS_RECORD = ['master', 'staging'].includes(process.env.BRANCH || '')

export const useZoomStore = defineStore('zoom', () => {
  const videoShareScreenRef = ref<HTMLVideoElement>()
  const canvasShareScreenRef = ref<HTMLCanvasElement>()
  const isRequiredCanvas = ref<boolean>(false)
  const state = reactive<IUsestateState>({
    connectData: undefined,
    isInRoom: false,
    zoomClient: undefined,
    mediaStream: undefined,
    isConnecting: false,
    isSafari: false,
    isIosMobile: false,
    isAndroidOrIOSBrowser: false,
    audioEncode: false,
    audioDecode: false,
    connectionState: ConnectionState.Closed,
    connectingErrorMsg: undefined,
    isInvisible: false,
    isStartShareScreenWithVideoElement: undefined,
    micEnabled: getDeviceEnableState().audio,
    cameraEnabled: getDeviceEnableState().video,
    isInitZoom: false,
    isJoinAudioRoom: false,
    isJoiningAudioRoom: false,
    latestStopShared: undefined,

    allParticipants: [],
    localParticipant: undefined,
    speakingParticipantIds: [],
    isZoomLocalShareScreen: false,
    shareScreenUserId: undefined,
    isJoinZoomSuccess: false,
    isFailoverSafari: false,
    requestToShareScreen: false,
    videoProblem: undefined,
    micProblem: undefined,
    audioProblem: undefined,
    networkQualities: [],
  })
  const { saveLogsAttend, saveConnectingLogs } = useSpaceApi()

  const roomStore = useRoomStore()
  const deviceStore = useDeviceStore()
  const backgroundStore = useBackgroundStore()
  const roomLayoutStore = useRoomLayoutStore()
  const { catchMediaErrorToChat, sendMessageToChatByGroup } = useGChatLog()

  /**
   * Computed
   */

  const isRenderSingleCanvas = computed(
    () =>
      isRequiredCanvas.value ||
      !state.mediaStream?.isSupportMultipleVideos() ||
      state.isAndroidOrIOSBrowser ||
      state.isInvisible,
  )
  const displayZoomGridMode = computed(() =>
    isRenderSingleCanvas.value ? 'canvas' : 'video-player',
  )

  const currentUserCanJoinMic = computed(() =>
    state.isInvisible ? false : deviceStore.allowedMicrophone,
  )

  const filterParticipantPublishCam = computed(() =>
    state.allParticipants.filter(
      (user) => !roomStore.participants.find((p) => p.email === user.userIdentity)?.isInvisible,
    ),
  )

  const allAlreadyParticipant = computed(() =>
    filterParticipantPublishCam.value.map((participant) => ({
      ...participant,
      avatar: roomStore.participants.find((p) => p.email === participant.userIdentity)?.avatar,
    })),
  )

  const mustCheckMediaSDK = computed(() => state.isSafari || state.isIosMobile)

  const setLocalParticipantState = (setting?: ISetLocalState) => {
    state.localParticipant = state.zoomClient?.getCurrentUserInfo()
    if (state.localParticipant)
      state.localParticipant.avatar = roomStore.localParticipantInfo?.avatar
    if (setting?.camera) {
      deviceStore.maskCameraOn = deviceStore.cameraOn = !!state.localParticipant?.bVideoOn
    }
    if (setting?.mic) {
      deviceStore.maskMicrophoneOn = deviceStore.microphoneOn =
        state.localParticipant?.muted === false
    }
  }

  const catchZoomSessionError = async <T>(action: LogDeviceProblemAction, p?: Promise<T>) => {
    const error = await catchMediaErrorToChat(action, p)
    checkIsNotInSession(error)
    return error
  }

  const setConnectDate = (params: IConnectZoom) => {
    state.connectData = params
  }

  const initZoomState = async (params: IConnectZoom) => {
    state.isConnecting = true
    setConnectDate(params)
    isRequiredCanvas.value = !!params.isCanvas
    const uaResult = new UAParser().getResult()
    state.isSafari = !!uaResult.browser.name?.toLowerCase().includes('safari')
    state.isIosMobile = isIOSMobile()
    state.isAndroidOrIOSBrowser = isAndroidOrIOSBrowser()
    const { token, roomName, name, password, isInvisible } = params
    state.zoomClient = ZoomVideo.createClient()
    state.isInvisible = isInvisible
    const isSAB = typeof SharedArrayBuffer === 'function'
    await state.zoomClient.init('en-US', 'Global', {
      enforceVirtualBackground: true,
      enforceMultipleVideos: !isSAB,
      patchJsMedia: true,
      stayAwake: true,
      leaveOnPageUnload: true,
    })
    const [, error] = await promiseHandler(state.zoomClient.join(roomName, token, name, password))
    state.mediaStream = state.zoomClient.getMediaStream()

    deviceStore.isDeviceSupportVirtualBackground = state.mediaStream.isSupportVirtualBackground()
    state.isInitZoom = true
    if (error) {
      let isReconnectSuccess = false
      if (error.type === 'JOIN_MEETING_FAILED' && state.connectData) {
        isReconnectSuccess = await initZoomState(state.connectData)
      }
      if (!isReconnectSuccess) {
        state.connectionState = ConnectionState.Fail
        saveConnectingLogs({
          action: ConnectingLogsAction.CONNECT,
          json: JSON.stringify({ ...error, status: 'fail', action: 'TIGER_ZOOM_CLIENT_JOIN' }),
          engine: Engine.ZOOM,
        })
        state.isJoinZoomSuccess = false
      }
    } else {
      state.isInRoom = true
      if (mustCheckMediaSDK.value) {
        await subscribeMediaSDK()
      }
      setStateAllParticipant()
      subscribeUserEvent()
      setLocalParticipantState()
      recordZoomRoom()
      saveLogsAttend(Engine.ZOOM)
      saveConnectingLogs({
        action: ConnectingLogsAction.CONNECT,
        json: JSON.stringify({
          mode: displayZoomGridMode.value,
          status: 'success',
          sessionId: state.zoomClient.getSessionInfo().sessionId,
        }),
        engine: Engine.ZOOM,
      })

      state.allParticipants.forEach((participant) => {
        if (participant.sharerOn) {
          triggerDisplayShareScreenCanvas(participant.userId)
        }
      })
      if (state.localParticipant) AuthState.setZoomUserId(state.localParticipant.userId)
      state.isJoinZoomSuccess = true
    }
    subscribeSessionQuality()
    state.isConnecting = false
    return !error
  }

  const checkIsNotInSession = (error?: ExecutedFailure) => {
    const isBreakOperation = error?.type === 'IMPROPER_MEETING_STATE'
    const isInMeeting = !!state.zoomClient?.getSessionInfo().isInMeeting
    state.isInRoom = !isBreakOperation && isInMeeting
    if (!state.isInRoom) {
      state.connectionState = ConnectionState.Closed
      if (state.connectData) {
        initZoomState(state.connectData)
      }
    }

    return !state.isInRoom
  }

  const subscribeMediaSDK = () => {
    state.zoomClient?.on('media-sdk-change', (payload) => {
      const { action, type, result } = payload
      if (type === 'audio' && result === 'success') {
        if (action === 'encode') {
          // encode for sending audio stream (speak)
          state.audioEncode = true
        } else if (action === 'decode') {
          // decode for receiving audio stream (hear)
          state.audioDecode = true
        }
      }
    })
  }

  const resetWithActiveDevice = () => {
    if (!state.mediaStream) return
    const currentUsedMic = state.mediaStream.getActiveMicrophone()
    const micList = deviceStore.audioInputDevices
    const microphoneId = micList.find((list) => list.value === deviceStore.selectedAudio)?.value
    if (deviceStore.microphoneOn && currentUsedMic && currentUsedMic !== microphoneId) {
      deviceStore.setMicrophoneDevice(currentUsedMic)
    }
    const speakerList = deviceStore.audioOutputDevices
    const speakerId = speakerList.find((list) => list.value === deviceStore.selectedSpeaker)?.value
    const currentUsedSpeaker = state.mediaStream.getActiveSpeaker()
    if (deviceStore.canSetSpeaker && currentUsedSpeaker && currentUsedSpeaker !== speakerId) {
      deviceStore.setSpeakerDevice(currentUsedSpeaker)
    }
  }

  const joinZoomAudioRoom = async () => {
    if (!state.mediaStream) return
    await getAllDeviceList()
    state.isJoiningAudioRoom = true
    const micList = deviceStore.audioInputDevices
    const microphoneId = micList.find((list) => list.value === deviceStore.selectedAudio)?.value
    const speakerList = deviceStore.audioOutputDevices
    const speakerId = speakerList.find((list) => list.value === deviceStore.selectedSpeaker)?.value
    const speakerOnly = !currentUserCanJoinMic.value ? true : undefined
    const option: AudioOption = {
      microphoneId: speakerOnly ? undefined : microphoneId,
      speakerId: deviceStore.canSetSpeaker ? speakerId : undefined,
      speakerOnly,
    }
    const error = await catchZoomSessionError(
      LogDeviceProblemAction.START_AUDIO_ZOOM,
      state.mediaStream.startAudio(option),
    )
    state.isJoiningAudioRoom = false
    if (error) {
      const isWaiting =
        error.type === 'INVALID_OPERATION' &&
        error.reason === 'Computer audio has been loading, please wait.'
      if (!isWaiting) {
        setMicProblem({ type: error.type, reason: error.reason })
        saveConnectingLogs({
          action: ConnectingLogsAction.CONNECT,
          json: JSON.stringify({ ...error, status: 'fail' }),
          engine: Engine.ZOOM,
        })
      }
    } else {
      await resetWithActiveDevice()
      if (!deviceStore.microphoneOn) {
        await state.mediaStream.muteAudio()
      }
      state.isJoinAudioRoom = true
      state.isFailoverSafari = false
      state.micProblem = undefined
    }
    setLocalParticipantState({ mic: true })
  }

  const joinFirstSessionWithCamAndMic = async () => {
    if (mustCheckMediaSDK.value) {
      // await subscribeMediaSDK()
      if (state.isJoinAudioRoom) return
      // desktop Safari, check if desktop Safari has initialized audio
      if (state.audioEncode && state.audioDecode) {
        // desktop Safari has initialized audio, continue to start audio
        await joinZoomAudioRoom()
      } else {
        // desktop Safari has not initialized audio, retry or handle error
        state.isFailoverSafari = true
        await joinZoomAudioRoom()
        if (state.isFailoverSafari && !state.isJoinAudioRoom) {
          state.connectionState = ConnectionState.Fail
          const reason = 'cannot join audio with encode or decode fail'
          saveConnectingLogs({
            action: ConnectingLogsAction.CONNECT,
            json: JSON.stringify({
              reason,
              status: 'fail',
              action: 'TIGER_ZOOM_CANNOT_JOIN_AUDIO',
            }),
            engine: Engine.ZOOM,
          })
          sendMessageToChatByGroup(LogDeviceProblemAction.AUDIO_PROBLEM, reason)
        }
      }
    } else {
      // not desktop Safari, continue to start audio
      await joinZoomAudioRoom()
    }

    // join camera
    if (deviceStore.cameraOn && deviceStore.allowedCamera && !state.isInvisible) {
      await toggleVideo()
    }
    listenerDeviceChange()
  }

  const subscribeRecordSession = async () => {
    const cloudRecording = state.zoomClient?.getRecordingClient()
    if (!cloudRecording) return
    const status = cloudRecording.getCloudRecordingStatus()
    if (status === RecordingStatus.Recording) return
    const [, error] = await promiseHandler(cloudRecording?.startCloudRecording())
    if (error) {
      console.error(error)
    }
  }

  const recordZoomRoom = () => {
    if (roomStore.room.record && IS_RECORD) {
      subscribeRecordSession()
      state.zoomClient?.on('recording-change', () => {
        subscribeRecordSession()
      })
    }
  }

  const setVideoProblem = (problem?: IDeviceProblem) => {
    const isVideoError = problem && Object.values<string>(ZoomVideoError).includes(problem.type)
    if (problem && isVideoError) {
      state.videoProblem = problem
      deviceStore.setVideoError(problem?.reason)
    } else {
      state.videoProblem = undefined
      deviceStore.setVideoError()
    }
  }

  const setMicProblem = (problem?: IDeviceProblem) => {
    state.micProblem = problem
    deviceStore.setMicError(problem?.reason)
  }

  const setAudioProblem = (problem?: IDeviceProblem) => {
    state.audioProblem = problem
    deviceStore.setSpeakerError(problem?.reason)
  }

  const setNetworkQuality = (payload: INetworkQuality) => {
    const ids = state.networkQualities?.map(({ userId }) => userId)
    if (ids?.includes(payload.userId)) {
      const userIndex = state.networkQualities?.findIndex((nq) => nq.userId === payload.userId)
      state.networkQualities[userIndex] = payload
    } else {
      state.networkQualities?.push(payload)
    }
  }

  const subscribeSessionQuality = () => {
    state.zoomClient?.on('network-quality-change', (payload) => {
      // 0-1: poor quality
      // 2: normal quality
      // 3-5: good quality
      setNetworkQuality(payload)
    })
    /**
     * zoom session quality
     * TODO: https://developers.zoom.us/docs/video-sdk/web/quality/
     */
    if (process.env.BRANCH === 'dev') {
      state.zoomClient?.on('video-statistic-data-change', (payload) => {
        // encoding true when sender, false for remote participant
        console.log(payload, ' video-statistic-data-change')
      })
      state.zoomClient?.on('audio-statistic-data-change', (payload) => {
        // encoding true when sender, false for remote participant
        console.log(payload, ' audio-statistic-data-change')
      })
    }
  }

  const unsubscribeSessionQuality = () => {
    state.zoomClient?.off('network-quality-change', () => {})

    if (process.env.BRANCH === 'dev') {
      state.zoomClient?.off('video-statistic-data-change', () => {})
      state.zoomClient?.off('audio-statistic-data-change', () => {})
    }
    state.networkQualities = []
  }

  const startVideo = async (action: LogDeviceProblemAction) => {
    if (!state.mediaStream) return
    const cameraList = deviceStore.videoInputDevices
    const cameraSelected = cameraList.find((list) => list.value === deviceStore.selectedVideo)
    const cameraId = cameraSelected?.videoMode || cameraSelected?.value
    if (!cameraId) {
      setVideoProblem({
        type: ZoomVideoError.CAN_NOT_FIND_CAMERA,
        reason: 'The provided camera device ID is not included in the camera device list.',
      })
      return
    }
    const option = {
      videoElement:
        isRenderSingleCanvas.value && state.mediaStream.isRenderSelfViewWithVideoElement()
          ? (document.querySelector(`#${ZOOM_MY_VIDEO_ELEMENT_ID}`) as HTMLVideoElement)
          : undefined,
      mirrored: true,
      cameraId,
      hd: false,
      fullHd: false,
      originalRatio: true,
      virtualBackground: deviceStore.canChangeBackground
        ? backgroundStore.virtualBackgroundZoom
        : undefined,
    } as CaptureVideoOption

    let [, error] = await promiseHandler(state.mediaStream?.startVideo(option))
    if (!checkIsNotInSession(error) && error?.type === 'VIDEO_BACKGROUND_FAILED') {
      setLocalParticipantState({ camera: true })
      await toggleVideo()
      if (!state.videoProblem) error = undefined
    }

    const isWaiting =
      error?.type === 'INVALID_OPERATION' && error?.reason === 'Camera is starting,please wait.'
    if (error && !isWaiting) {
      sendMessageToChatByGroup(action, JSON.stringify(error))
    }
    setVideoProblem(error?.type || error?.reason ? error : undefined)
    return error
  }

  const toggleVideo = async () => {
    if (!state.mediaStream || state.isInvisible || !state.localParticipant) return
    // check latest status of video before toggle video
    const isVideoOn = !!state.localParticipant?.bVideoOn
    let isSuccess = true
    if (isVideoOn) {
      const error = await catchZoomSessionError(
        LogDeviceProblemAction.STOP_VIDEO_ZOOM,
        state.mediaStream?.stopVideo(),
      )
      isSuccess = !error
      if (!isRenderSingleCanvas.value && isSuccess) {
        await state.mediaStream.detachVideo(state.localParticipant.userId)
      }
    } else {
      await startVideo(LogDeviceProblemAction.START_VIDEO_ZOOM)
    }
    // set latest after toggle camera
    setLocalParticipantState({ camera: true })
  }

  const changeBackground = async () => {
    backgroundStore.setStateBackground(backgroundStore.selectedBackground)
    if (!state.mediaStream) return
    const isVideoOn = !!state.localParticipant?.bVideoOn
    // when user stop video set only background state and do nothing
    if (!isVideoOn) return
    // when user video is started stop before start
    if (isVideoOn) {
      await catchZoomSessionError(
        LogDeviceProblemAction.STOP_VIDEO_ZOOM,
        state.mediaStream?.stopVideo(),
      )
      if (!state.localParticipant) return
      if (!isRenderSingleCanvas.value) {
        await state.mediaStream.detachVideo(state.localParticipant.userId)
      }
    }
    await startVideo(LogDeviceProblemAction.CHANGE_BACKGROUND)
    setLocalParticipantState({ camera: true })
  }

  const switchCamera = async () => {
    if (!state.mediaStream || state.isInvisible) return
    const isVideoOn = !!state.localParticipant?.bVideoOn
    const cameraList = deviceStore.videoInputDevices
    const cameraSelected = cameraList.find((list) => list.value === deviceStore.maskSelectedVideo)
    const cameraId = cameraSelected?.videoMode || cameraSelected?.value

    const isTurnOffCamera = !deviceStore.maskCameraOn && isVideoOn
    const isTurnOnCamera = deviceStore.maskCameraOn && !isVideoOn
    const isNotToggleCamera = deviceStore.maskCameraOn === isVideoOn
    const isChangeCamera = cameraId !== state.mediaStream.getActiveCamera()

    if (isTurnOffCamera) {
      await catchZoomSessionError(
        LogDeviceProblemAction.STOP_VIDEO_ZOOM,
        state.mediaStream?.stopVideo(),
      )
      if (!state.localParticipant) return
      await state.mediaStream.detachVideo(state.localParticipant.userId)
    }
    if (isTurnOnCamera) {
      await toggleVideo()
    } else if (isChangeCamera && isVideoOn && isNotToggleCamera) {
      await catchZoomSessionError(
        LogDeviceProblemAction.SWITCH_CAM,
        state.mediaStream.switchCamera(cameraId || state.mediaStream.getActiveCamera()),
      )
    }
    setLocalParticipantState({ camera: true })
  }

  const toggleMicrophone = async () => {
    if (!state.mediaStream || state.isInvisible) return
    // check latest status of microphone before toggle microphone
    const isMuted = !!state.localParticipant?.muted
    if (state.isJoinAudioRoom) {
      if (isMuted) {
        await state.mediaStream?.unmuteAudio()
      } else {
        await state.mediaStream?.muteAudio()
      }
    } else {
      await joinZoomAudioRoom()
    }
    setLocalParticipantState({ mic: true })
  }

  const switchMicrophone = async () => {
    if (!state.mediaStream) return
    if (!state.isJoinAudioRoom) await joinZoomAudioRoom()
    const isMuted = !!state.localParticipant?.muted
    if (deviceStore.maskMicrophoneOn !== !isMuted) {
      await toggleMicrophone()
    }
    const microphoneList = deviceStore.audioInputDevices
    const micId = microphoneList.find((list) => list.value === deviceStore.maskSelectedAudio)?.value
    if (micId !== state.mediaStream.getActiveMicrophone()) {
      await catchZoomSessionError(
        LogDeviceProblemAction.SWITCH_MIC,
        state.mediaStream.switchMicrophone(micId || state.mediaStream.getActiveMicrophone()),
      )
      setLocalParticipantState({ mic: true })
    }
  }

  const switchSpeaker = async () => {
    if (!state.mediaStream || !deviceStore.canSetSpeaker) return
    if (state.isJoinAudioRoom) {
      const speakerList = deviceStore.audioOutputDevices
      const speakerId = speakerList.find(
        (list) => list.value === deviceStore.maskSelectedSpeaker,
      )?.value

      await catchZoomSessionError(
        LogDeviceProblemAction.SWITCH_SPEAKER,
        state.mediaStream.switchSpeaker(speakerId || state.mediaStream.getActiveSpeaker()),
      )
    }
    setLocalParticipantState()
  }

  const startScreenShare = async (
    isEnable: boolean,
    elementRender?: HTMLVideoElement | HTMLCanvasElement,
  ) => {
    if (!state.mediaStream) return
    if (isEnable) {
      if (elementRender) {
        const [, err] = await promiseHandler(state.mediaStream.startShareScreen(elementRender))
        checkIsNotInSession(err)
        return { isEnable, isSuccess: !err }
      }
    }
  }

  const stopScreenShare = async (isEnable: boolean) => {
    if (!state.mediaStream) return
    if (!isEnable) {
      const [, err] = await promiseHandler(state.mediaStream?.stopShareScreen())
      checkIsNotInSession(err)
      if (!err) resetScreenShare()
      return { isEnable, isSuccess: !err }
    }
  }

  const resetScreenShare = () => {
    state.latestStopShared = state.shareScreenUserId
    roomLayoutStore.screenShareEnable = false
    roomLayoutStore.participantSharedScreen = ''
    state.requestToShareScreen = false
    state.shareScreenUserId = undefined
    roomLayoutStore.changeCurrentTab()
  }

  const triggerDisplayShareScreenCanvas = (userId?: number) => {
    if (userId) {
      state.shareScreenUserId = userId
      state.latestStopShared = undefined
      roomLayoutStore.screenShareEnable = !!state.shareScreenUserId
      roomLayoutStore.changeCurrentTab(state.shareScreenUserId ? 'screenShare' : '')
    } else {
      resetScreenShare()
    }
  }

  const sendLogReportSession = () => {
    // send to zoom when change engine
    if (IS_RECORD) state.zoomClient?.getLoggerClient().reportToGlobalTracing()
  }

  const setStateAllParticipant = () => {
    state.allParticipants = state.zoomClient?.getAllUser() || []
  }

  const getAllDeviceList = () => {
    if (!state.isAndroidOrIOSBrowser) {
      const cameraList = state.mediaStream?.getCameraList() || []
      deviceStore.videoInputDevices = cameraList.map((list, idx) => ({
        label: list.label || `camera ${idx + 1}`,
        value: list.deviceId,
      }))
    }
    const speakerList = state.mediaStream?.getSpeakerList() || []
    deviceStore.audioOutputDevices = speakerList.map((list, idx) => ({
      label: list.label || `speaker ${idx + 1}`,
      value: list.deviceId,
    }))

    const micList = state.mediaStream?.getMicList() || []
    deviceStore.audioInputDevices = micList.map((list, idx) => ({
      label: list.label || `microphone ${idx + 1}`,
      value: list.deviceId,
    }))
  }

  const subscribeUserEvent = () => {
    // listening participant join session
    state.zoomClient?.on('user-added', () => {
      setStateAllParticipant()
    })
    // listening participant updated ex. participant mute/unmute camera, microphone or switch device
    state.zoomClient?.on('user-updated', () => {
      setStateAllParticipant()
    })
    // listening participant left session
    state.zoomClient?.on('user-removed', () => {
      setStateAllParticipant()
    })
    // listening participant is speaking
    state.zoomClient?.on('active-speaker', (payload) => {
      state.speakingParticipantIds = payload.map(({ userId }) => userId)
    })

    state.zoomClient?.on('passively-stop-share', () => {
      resetScreenShare()
    })
    state.zoomClient?.on('active-share-change', async (payload) => {
      if (payload.state === 'Active') {
        triggerDisplayShareScreenCanvas(payload.userId)
      } else if (payload.state === 'Inactive') {
        await state.mediaStream?.stopShareView()
        resetScreenShare()
      }
    })
    state.zoomClient?.on('connection-change', (e) => {
      const response = detectConnectionChanged(e)
      state.connectionState = response.status
      state.connectingErrorMsg = response.content
    })
  }

  const listenerDeviceChange = () => {
    state.zoomClient?.on('device-permission-change', async (e) => {
      if (e.name === 'camera') {
        deviceStore.allowedCamera = e.state === 'granted'
        if (deviceStore.allowedCamera) {
          setVideoProblem()
          await getAllDeviceList()
        } else {
          if (state.localParticipant?.bVideoOn) {
            await toggleVideo()
          }
          setVideoProblem({
            type: ZoomVideoError.CAN_NOT_DETECT_CAMERA,
            reason: 'Please allow permission',
          })
        }
      }
      if (e.name === 'microphone') {
        deviceStore.allowedMicrophone = e.state === 'granted'
        if (deviceStore.allowedMicrophone) {
          setMicProblem()
          await getAllDeviceList()
        } else {
          if (state.localParticipant?.muted === false) {
            await toggleMicrophone()
          }
          setMicProblem({ type: 'PERMISSION_DENIED', reason: 'Please allow permission' })
        }
      }
    })
    // listening detect new device for current user
    state.zoomClient?.on('device-change', async () => {
      await getAllDeviceList()

      if (!state.isAndroidOrIOSBrowser) {
        const alreadyExist = deviceStore.videoInputDevices.find(
          (device) => device.value === deviceStore.selectedVideo,
        )
        if (!alreadyExist) {
          deviceStore.setVideoDevice(deviceStore.videoInputDevices[0].value)
        }
      }
      if (!state.isJoinAudioRoom) {
        joinZoomAudioRoom()
        return
      }
      // for speaker
      const alreadySpeakerExist = deviceStore.audioOutputDevices.find(
        (device) => device.value === deviceStore.selectedSpeaker,
      )
      if (!alreadySpeakerExist && deviceStore.audioOutputDevices.length) {
        deviceStore.setSpeakerDevice(deviceStore.audioOutputDevices[0].value)
        await switchSpeaker()
      }

      // for microphone
      const alreadyMicExist = deviceStore.audioInputDevices.find(
        (device) => device.value === deviceStore.selectedAudio,
      )
      if (!alreadyMicExist && deviceStore.audioInputDevices.length) {
        deviceStore.setMicrophoneDevice(deviceStore.audioInputDevices[0].value)
        await switchMicrophone()
      }
      setLocalParticipantState()
    })
  }

  const leaveRoom = () => {
    state.zoomClient?.leave()
    state.isInRoom = false
    unsubscribeSessionQuality()
  }

  return {
    ...toRefs(state),
    videoShareScreenRef,
    canvasShareScreenRef,

    // computed
    allAlreadyParticipant,
    displayZoomGridMode,

    // method
    initZoomState,
    joinZoomAudioRoom,
    joinFirstSessionWithCamAndMic,
    currentUserCanJoinMic,
    sendLogReportSession,
    toggleVideo,
    toggleMicrophone,
    startScreenShare,
    stopScreenShare,
    resetScreenShare,
    leaveRoom,
    switchCamera,
    switchMicrophone,
    switchSpeaker,
    changeBackground,
    checkIsNotInSession,
  }
})
