<script setup lang="ts">
import { listVideoResolution } from '@/data/video'
import { useDeviceStore } from '@/stores'
const device = useDeviceStore()
</script>

<template>
  <div
    v-for="(resolution, index) in listVideoResolution"
    :key="`listVideoResolution-${index}`"
    class="flex p-4 text-base cursor-pointer hover:bg-gray-100 duration-300 text-color"
    @click="device.setSelectedVideoQuality(resolution)"
  >
    <div class="w-7">
      <SvgIcon
        v-if="resolution === device.resolution"
        icon="done"
        :inherit-fill="true"
        height="20"
      ></SvgIcon>
    </div>
    <span>{{ resolution }}</span>
  </div>
</template>

<style lang="scss" scoped>
.text-color {
  color: #4a4a4a;
}
</style>
