<script setup lang="ts"></script>

<template>
  <div class="container mx-auto px-4 py-0">
    <table class="w-full text-sm">
      <thead>
        <tr class="border-b-2">
          <th class="p-4">{{ $t('connection-success-card.status') }}</th>
          <th class="p-4">{{ $t('connection-success-card.type') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="border-b-2">
          <td class="p-4">
            <div class="inline-flex">
              <div class="rounded-full p-2 w-2 h-2 mr-2 bg-green-500"></div>
              <p class="font-bold">{{ $t('connection-success-card.connected') }}</p>
            </div>
          </td>
          <td class="p-4">{{ $t('connection-success-card.private-classroom') }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<style lang="scss" scoped>
thead > tr {
  background-color: #32323209;
}

::v-deep(svg) {
  display: inline-block !important;
  margin-bottom: 0.4rem;
}
</style>
