<template>
  <div class="compartment">
    <slot></slot>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.compartment {
  width: 100%;
  border-radius: 6px;
  background: var(--compartment-body-color);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
</style>
