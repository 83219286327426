<script setup lang="ts">
import { computed, ref } from 'vue'
import { getOptimalFlexRowCol } from '@/helpers/layout.helper'
import { useElementSize } from '@vueuse/core'
const props = defineProps({
  nCompartments: {
    type: Number,
    required: true,
  },
  isFocused: {
    type: Boolean,
    default: false,
  },
  isBarHidden: {
    type: Boolean,
    default: false,
  },
  isRoomControlSmall: {
    type: Boolean,
    default: false,
  },
})

const othersRef = ref<HTMLElement | null>(null)
const { width: elementWidth } = useElementSize(othersRef)
const nOthers = computed(() => props.nCompartments)

const flexValue = computed(() => {
  const [row, col] = getOptimalFlexRowCol(
    nOthers.value,
    props.isFocused,
    elementWidth.value < 1000 ? 'md' : undefined,
  )
  return {
    nRows: row,
    nCols: col,
  }
})

const nColumns = computed(() => flexValue.value.nCols)
const nRows = computed(() => flexValue.value.nRows)

const isOnlyOnceChild = computed(() => props.nCompartments === 1 && !props.isFocused)
</script>

<template>
  <div ref="othersRef" class="compartment-grid" :class="{ '-once-child': isOnlyOnceChild }">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.compartment-grid {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  justify-content: flex-start;
  overflow-x: auto;
  transition: padding 0.5s $primary-tc;

  &::-webkit-scrollbar {
    display: none;
  }

  &:not(.-once-child) ::v-deep(.compartment) {
    margin: 0;
    width: 100%;
    height: 100%;
    // TODO: Adjust width dynamically
    min-width: 180px;
    max-width: 200px;
  }

  @include media-breakpoint-up-custom(700px) {
    width: 100%;
    display: grid;
    grid-template-rows: repeat(v-bind(nRows), 1fr);
    grid-template-columns: repeat(v-bind(nColumns), 1fr);
    gap: 10px;
    align-items: unset;
    &:not(.-once-child) ::v-deep(.compartment),
    &.-once-child ::v-deep(.compartment) {
      margin: 0;
      width: 100%;
      height: 100%;
      min-width: unset;
      max-width: unset;
    }
  }
}
</style>
