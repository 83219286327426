<script setup lang="ts">
import { computed } from 'vue'

interface CheckboxEmits {
  (e: 'update:modelValue', value: Array): void
}

const props = defineProps({
  id: {
    type: String,
    default: '',
  },
  value: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  modelValue: {
    type: Array,
    default: () => [],
  },
})

const emit = defineEmits<CheckboxEmits>()
const model = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
})
</script>

<template>
  <div class="checkbox-item">
    <input :id="id" v-model="model" type="checkbox" :value="value" class="checkbox-item-input" />
    <label class="checkbox-item-label _prevent-text-select" :for="id">{{ label }}</label>
  </div>
</template>

<style lang="scss">
.checkbox-item {
  cursor: pointer;
}
// Checkbox
.checkbox-item-label::before,
.checkbox-item-label::after {
  top: 0.05rem;
  width: 1.9rem;
  height: 1.9rem;
  left: -2.9rem !important;
  border-radius: 4px !important;
}

.checkbox-item-label {
  margin-left: 0.9rem;
  @include font-weight(light);
}

.checkbox-item .checkbox-item-input:checked ~ .checkbox-item-label::before {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.checkbox-item .checkbox-item-input:focus ~ .checkbox-item-label::before {
  border-color: rgba(var(--primary-color-rgb), 0.4);
  box-shadow: 0 0 0 0.3rem rgba(var(--primary-color-rgb), 0.25);
}
input:checked {
  background-color: var(--primary-color);
}
</style>
